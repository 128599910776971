import { useEffect, useState } from "react";
import { Tabs } from "antd/lib/index";
import CarouselSet from "./CarouselSet";
import ServiceSet from "./ServiceSet";
import AlbumSet from "./AlbumSet";
import ContactSet from "./ContactSet";

const { TabPane } = Tabs;
const SettingPart = ({ refreshFrame }) => {
	const [activeKey, setKey] = useState("carousel");
	function tab(data) {
		console.log(data);
		setKey(data);
	}
	return (
		<div className='info_container__right' style={{ marginRight: "1%", flex: 1 }}>
			<Tabs activeKey={activeKey} onChange={tab} type='card' tabBarStyle={{ marginBottom: "0px" }} style={{ flex: 1 }}>
				<TabPane tab='轮播图设置' key='carousel'>
					<CarouselSet refreshFrame={refreshFrame} />
				</TabPane>
				<TabPane tab='服务项目' key='service'>
					<ServiceSet refreshFrame={refreshFrame} />
				</TabPane>
				<TabPane tab='团队展示' key='group'>
					<ServiceSet refreshFrame={refreshFrame} team={true} />
				</TabPane>
				<TabPane tab='门店相册' key='album'>
					<AlbumSet refreshFrame={refreshFrame} />
				</TabPane>
				<TabPane tab='联系方式' key='contact'>
					<ContactSet refreshFrame={refreshFrame} tabKey={activeKey} />
				</TabPane>
			</Tabs>
		</div>
	);
};
export default SettingPart;
