import { Table, Row, Col, Tooltip } from "antd";
import PageControl from "./PageControl";
import PageControlImprove from "./PageControlmprove";
import mathFunc from "../core/mathfunc";
const ChargeDetailsTable = ({ state, getChargeList }) => {
  const columns = [
    {
      title: "会员卡号",
      dataIndex: "member_card",
      align: "left",
    },
    {
      title: "姓名",
      align: "left",
      dataIndex: "linkman",
    },

    {
      title: "充值日期",
      align: "left",
      dataIndex: "gmt_creat",
    },
    {
      title: "支付方式",
      align: "left",
      dataIndex: "pay_type",
    },

    {
      title: "实收金额",
      align: "left",
      dataIndex: "money_real",
    },
    {
      title: "充值次数",
      align: "left",
      dataIndex: "member_num",
      render: (text) => mathFunc.reservedCount(text),
    },
    // {
    //   title: "套餐",
    //   align: "left",
    //   dataIndex: "remark",
    //   render: (text) => (
    //     <Tooltip placement="top" title={text}>
    //       <div
    //         style={{
    //           width: "100px",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           whiteSpace: "nowrap",
    //           color: "#FF9551",
    //         }}
    //       >
    //         {text}
    //       </div>
    //     </Tooltip>
    //   ),
    // },
    {
      title: "录入人员",
      align: "left",
      dataIndex: "input_user",
    },
    {
      title: "备注",
      align: "left",
      dataIndex: "remark",
    },
  ];
  return (
    <>
      <Table
        rowKey={(record) => record.id}
        dataSource={state.list}
        columns={columns}
        bordered
        style={{ marginTop: "20px", marginBottom: "30px" }}
        pagination={false}
      />

      <PageControlImprove
        total={state.total}
        pageSize={state.pageSize}
        current={state.pageNo}
        showSizeChanger
        toPage={(page, count) => {
          getChargeList({ page, pages: count });
        }}
      >
        <Row justify="end" align="center">
          <Col span={24} style={{ textAlign: "right" }}>
            <span
              style={{ marginRight: "10px" }}
            >{`本页实收金额${state.realMoneyPage}元`}</span>
            <span
              style={{ marginRight: "10px" }}
            >{`累计实收金额${state.realMoneyAll}元`}</span>{" "}
            {/* <span
            style={{ marginRight: "10px" }}
          >{`本页赠送金额${state.zsMoneyPage}元`}</span>
          <span>{`累计赠送金额${state.zsMoneyAll}元`}</span> */}
          </Col>
        </Row>
      </PageControlImprove>
    </>
  );
};

export default ChargeDetailsTable;
