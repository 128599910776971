import React, { Component, useState, useContext, useEffect } from "react";
import { Tabs } from "antd/lib/index";
import "./OrgnizationPage.less";
import "./ShowPreview.less";
import "./MicroWebsitePreview.less";
import "./new.less";
import ShopContext from "../../core/context-manager";

import PreviewPart from "./PreviewPart";
import SettingPart from "./SettingPart";

const MicroWeb = ({}) => {
	const { shopInfo } = useContext(ShopContext);
	const [link, setLink] = useState("");
	useEffect(() => {
		initLink();
	}, []);
	function initLink() {
		setLink(
			"https://api.sanfeng9.com/customer/weixin_public.php?cmd=web_start&shop_id=" +
				shopInfo.id +
				"&time=" +
				new Date().valueOf()
		);
	}
	return (
		<div className='template-org-desc page' style={{ zIndex: 0 }}>
			<section className='content-header'>
				<h1>微官网</h1>
			</section>
			<div className='box' style={{ paddingTop: "12px" }}>
				<div className='box-body'>
					<div className='site-info-page'>
						<div className='info_container'>
							<PreviewPart microWebLink={link} shopName={shopInfo.name} />
							<SettingPart refreshFrame={initLink} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MicroWeb;
