import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import _ from "underscore";
import "./CommonSelect.less";
import "./StaticSelect.less";

const CommonSelect = (props) => {
	const { style, label, id, placeholder, defaultValue, options, onSelect, hasNext, nextPage, selectValue } = props;
	let value = defaultValue || selectValue || undefined;
	return (
		<div className='common-select staticSelect' style={style}>
			{label && <div className='label'>{label}:</div>}
			<Select
				id={id}
				showSearch={true}
				allowClear={true}
				placeholder={placeholder}
				value={value}
				onChange={(value) => {
					// console.log(value);
					onSelect(value);
				}}>
				{options.map((item, index) => (
					<Select.Option key={item.id} value={item.value || item.name}>
						{item.name}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};
export default CommonSelect;
