import { Modal } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import SuccessRecharge from "../../libs/success-recharge.png";
import mathFunc from "../../core/mathfunc";

import "./RechargeDetails.less";

const RechargeDetails = ({ visible, slip, closeModal }) => {
  return (
    <Modal
      title="充值详情"
      width={520}
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      className="recharge-details"
    >
      <div className="recharge-success">
        <img src={SuccessRecharge} alt="" />
      </div>
      <div className="recharge-title">充值成功</div>
      <div className="recharge-price">
        <span className="actually-received">
          {/* {slip.money_member === "0.0" ? `实收：￥${slip.money_member}` : `实收：￥${slip.money_real}`}{" "} */}
          {`实收：￥${mathFunc.returnFloat(slip.money_real)}`}
        </span>
        <span className="give-away">
          {slip.member_num === "0.0"
            ? `赠送：￥${mathFunc.returnFloat(slip.money_member)}`
            : `充值：${mathFunc.reservedDecimal(slip.member_num)}次`}
        </span>
      </div>
      <div className="recharge-info">
        <div className="recharge-item">
          <p className="left">会员卡号:</p>
          <p className="right">{slip.member_card}</p>
        </div>
        <div className="recharge-item">
          <p className="left">姓名:</p>
          <p className="right">{slip.linkman}</p>
        </div>
        <div className="recharge-item">
          <p className="left">充值方式:</p>
          <p className="right">{slip.pay_type}</p>
        </div>
        <div className="recharge-item">
          <p className="left">充值时间:</p>
          <p className="right">{slip.gmt_modify}</p>
        </div>
        <div className="recharge-item">
          <p className="left">录入人员:</p>
          <p className="right">{slip.input_user}</p>
        </div>
        <div className="recharge-box">
          <p className="left">备注:</p>
          <p className="right">{slip.remark}</p>
        </div>
      </div>
    </Modal>
  );
};
export default RechargeDetails;
