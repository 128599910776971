import { useState, Fragment, useEffect } from "react";
import { Tooltip, Row, Col, DatePicker, InputNumber, Table, Tio } from "antd";
import SearchBar from "../../components/SearchBar";
import StaticSelect from "../../components/StaticSelect";
import CommenSelect from "../../components/CommonSelect";
import PageControl from "../../components/PageControl";
import moment from "moment";
import _Api from "../../core/api";
const { RangePicker } = DatePicker;

const OperationLog = ({}) => {
	const [state, setState] = useState({
		keyword: "",
		dateStart: "",
		dateEnd: "",
		logType: "",
		ip: "",
		worker: "",
		filter: false,
		list: [],
		total: "0",
		pageNo: "1",
		pageSize: "10",
	});
	const [logTypes, setLogTypes] = useState([]);
	const [workers, setWorkers] = useState([]);
	useEffect(() => {
		getLogList();
	}, [state.logType, state.worker, state.dateStart, state.dateEnd]);
	useEffect(() => {
		getLogTypes();
		getWorkers();
	}, []);
	function getLogList(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1",
			{ keyword, dateStart, dateEnd, logType, ip, worker } = state,
			body = {
				name: worker,
				ip,
				keyword,
				log_type: logType,
				start_time: dateStart,
				end_time: dateEnd,
				count: pageSize,
				page: pageNo,
			};
		_Api.logSelect(body).then((res) => {
			setState({
				...state,
				list: res.data.content,
				total: res.data.allcount,
				pageNo: res.data.curpage,
				pageSize: res.data.pagecount,
			});
		});
	}
	function getLogTypes(params) {
		_Api.logTypeSelect().then((res) => {
			let data = res.map((item) => ({ id: item.data_code, name: item.data_value }));
			setLogTypes(data);
		});
	}
	function getWorkers(params) {
		let data = {
			name_phone: "",
			sex: "",
			count: 1000,
		};
		_Api.userSelect(data).then((res) => {
			let data = res.data.content.map((item) => ({ id: item.id, name: item.name }));
			setWorkers(data);
		});
	}

	function reset() {
		setState({ ...state, keyword: "", dateStart: "", dateEnd: "", logType: "", ip: "", worker: "" });
	}
	function toggleFilter() {
		setState({ ...state, filter: !state.filter });
	}
	const columns = [
		{
			title: "操作时间",
			dataIndex: "gmt_creat",
			width: "280px",
		},
		{
			title: "IP地址",
			dataIndex: "log_ip",
			width: "300px",
			key: "log_ip",
		},
		{
			title: "操作类型",
			dataIndex: "log_type",
			width: "160px",
		},
		{
			title: "操作人员",
			dataIndex: "name",
			width: "100px",
		},
		{
			title: "操作内容",
			dataIndex: "log_content",
		},
	];

	return (
		<>
			<div className='template-student-index page settlemen'>
				<div className='page-content'>
					<section className='content-header'>
						<h1>操作日志</h1>
					</section>
					<div className='box'>
						<div className='box-header'>
							<Row type='flex' justify='space-around' align='top'>
								<Col>
									<SearchBar
										searchText={state.keyword}
										onChangeText={(text) => {
											setState({ ...state, keyword: text });
										}}
										onSearch={getLogList}
										label='关键字'
										placeholder='请输入操作内容关键字'
									/>
									<StaticSelect label='操作日期:'>
										<RangePicker
											style={{ width: "100%" }}
											value={[state.dateStart && moment(state.dateStart), state.dateEnd && moment(state.dateEnd)]}
											onChange={(memo, str) => {
												setState({ ...state, dateStart: str[0], dateEnd: str[1] });
											}}
										/>
									</StaticSelect>
									<CommenSelect
										selectValue={state.logType}
										label='操作类型'
										options={logTypes}
										onSelect={(selectValue) => {
											setState({ ...state, logType: selectValue });
										}}
										placeholder='请选择操作类型'
									/>

									{state.filter && (
										<>
											<SearchBar
												searchText={state.ip}
												onChangeText={(text) => {
													setState({ ...state, ip: text });
												}}
												onSearch={getLogList}
												label='IP地址'
												placeholder='请输入IP地址'
											/>
											<CommenSelect
												selectValue={state.worker}
												label='操作人员'
												options={workers}
												onSelect={(selectValue) => {
													setState({ ...state, worker: selectValue });
												}}
												placeholder='请选择操作人员'
											/>
										</>
									)}
								</Col>
								<Col>
									<Tooltip title='清空筛选'>
										<span onClick={reset} id='reset_btn' className='resetBtn iconfont icon'>
											&#xe662;
										</span>
									</Tooltip>
									<span className='text-sub xm-filter' onClick={toggleFilter} style={{ cursor: "pointer" }}>
										{state.filter ? (
											<span>
												收起
												<span className='iconfont icon ml5'>&#xe663;</span>
											</span>
										) : (
											<span>
												展开
												<span className='iconfont icon ml5'>&#xe665;</span>
											</span>
										)}
									</span>
								</Col>
							</Row>
						</div>
						<div className='box-body'>
							<Table
								rowKey={(record) => record.id}
								dataSource={state.list}
								columns={columns}
								bordered
								style={{ marginTop: "20px", marginBottom: "40px" }}
								pagination={false}
							/>
							<PageControl
								total={state.total}
								pageSize={state.pageSize}
								current={state.pageNo}
								showSizeChanger
								toPage={(page, count) => {
									getLogList({ pageNo: page, pageSize: count });
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default OperationLog;
