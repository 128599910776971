import React, { Component, useState, useEffect, useRef, useCallback } from "react";
import { Button, Switch, message, Divider, Popconfirm, Table } from "antd";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import _Api from "../../core/api";
import DragableBodyRow from "../../components/DragableBodyRow";
import CarouselEditModal from "../../components/modals/CarouselEdit";
import "../core/table.less";

import $ from "jquery";

const CarouselSet = ({ refreshFrame }) => {
	const [modalState, setModalState] = useState({
		visible: false,
		editType: false,
		curCarousel: {},
	});
	const [data, setData] = useState([]);
	useEffect(() => {
		getCarousel();
	}, []);
	function getCarousel() {
		const body = {
			type: "index_img",
		};
		_Api.webSelect(body).then((res) => {
			let data = res.data.map((item) => {
				return {
					num: res.data.length,
					id: item.id,
					img: item.image,
					url: item.link,
					enabled: item.is_show === "1" ? true : false,
				};
			});
			setData(data);
		});
	}

	function handleEnabled(index, enabled) {
		let item = data[index];
		item.enabled = enabled;
		data.splice(index, 1, item);
		setData([...data]);
		_Api
			.webIsShow({
				id: item.id,
				is_show: enabled ? 1 : 0,
			})
			.then((res) => {
				message.success("修改成功");
				refreshFrame();
			});
	}
	function handleDelete(id) {
		_Api.webDelete({ id }).then((res) => {
			message.success(res.msg);
			getCarousel();
			refreshFrame();
		});
	}
	const columns = [
		{
			title: "编号",
			key: "index",
			width: "10%",
			dataIndex: "id",
		},
		{
			title: "图片",
			dataIndex: "img",
			width: "20%",
			align: "center",
			render: (text) =>
				text ? (
					<div className='img-wrap' style={{ overflowY: "auto", maxHeight: "80px", width: "100%" }}>
						<img src={text} alt='' width={"100%"} />
					</div>
				) : null,
		},
		{
			title: "跳转链接",
			dataIndex: "url",
		},
		{
			title: "启用",
			dataIndex: "enabled",
			render: (text, record, index) => (
				<Switch
					size='small'
					checked={text}
					onChange={(checked) => {
						handleEnabled(index, checked);
					}}
				/>
			),
		},
		{
			title: "操作",
			key: "operation",
			dataIndex: "id",
			render: (text, record) => (
				<>
					<span
						className='click-able theme-color'
						onClick={() => {
							setModalState({ ...modalState, editType: true, visible: true, curCarousel: record });
						}}>
						编辑
					</span>
					<Divider type='vertical' />
					<Popconfirm
						placement='left'
						title='删除后无法恢复，是否确认删除'
						onConfirm={() => {
							handleDelete(text);
						}}
						okText='确认'
						cancelText='取消'>
						<span className='click-able theme-color'>删除</span>
					</Popconfirm>
				</>
			),
		},
	];
	const components = {
		body: {
			row: DragableBodyRow,
		},
	};

	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = data[dragIndex];
			let _data = update(data, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRow],
				],
			});
			setData(_data);
			const order = _data.map((item) => item.id);
			let body = { type: "index_img", ids: order };
			_Api.webOrders(body).then((res) => {
				message.success(res.msg);
				refreshFrame();
			});
		},
		[data]
	);
	return (
		<div className='setting-page'>
			<div className='setting-header'>
				<Button
					type='primary'
					onClick={() => {
						setModalState({ ...modalState, visible: true, editType: false });
					}}>
					添加轮播图
				</Button>
				（最多添加5张图片,拖动进行排序）
			</div>
			<div className='setting-body'>
				<DndProvider backend={HTML5Backend}>
					<Table
						className='carousel-table'
						rowKey={(record) => record.id}
						columns={columns}
						dataSource={data}
						components={components}
						onRow={(record, index) => ({
							index,
							moveRow: moveRow,
						})}
						bordered
						pagination={false}
						size='middle'
						locale={{ emptyText: "请点击‘添加轮播图’按钮进行添加" }}
					/>
				</DndProvider>
			</div>
			<CarouselEditModal
				visible={modalState.visible}
				curCarousel={modalState.curCarousel}
				editType={modalState.editType}
				handleComplete={() => {
					setModalState({ ...modalState, visible: false });
					getCarousel();
					refreshFrame();
				}}
				closeModal={() => {
					setModalState({ ...modalState, visible: false });
				}}
			/>
		</div>
	);
};

export default CarouselSet;
