import React from "react";
import "./StaticSelect.less";

const StaticSelect = (props) => {
	return (
		<span className='staticSelect'>
			<div className='label'>{props.label ? props.label : null}</div>
			{props.children}
		</span>
	);
};
export default StaticSelect;
