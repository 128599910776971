import { useEffect, useState } from "react";
import {
  Tooltip,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Table,
  Button,
  Drawer,
  message,
} from "antd";
import SearchBar from "../../components/SearchBar";
import StaticSelect from "../../components/StaticSelect";
import CommenSelect from "../../components/CommonSelect";
import PageControl from "../../components/PageControl";
import moment from "moment";
import _Api from "../../core/api";
import SaleEditModal from "../../components/modals/SaleEdit";
import SaleDetail from "../../components/modals/SaleDetail";
import SlipDetailModal from "../../components/modals/SlipDetail";
import PurchasingEditModal from "../../components/modals/PurchasingEdit";
import PurchasingReturnModal from "../../components/modals/PurchasingReturn";
const { RangePicker } = DatePicker;

const StockDetails = ({}) => {
  const [state, setState] = useState({
    partId: "",
    partName: "",
    orderType: "",
    orderId: "",
    dateStart: "",
    dateEnd: "",
    filter: false,
    list: [],
    total: "0",
    pageNo: "1",
    pageSize: "10",
  });
  const [modalState, setModalState] = useState({
    curCustomer: "",
    curMemberCard: "",
    curPartZk: "",
    curPartContent: { ids: [], data: [] },
    saleReturn: false,
    saleV: false,
    content: {},
    slip: {},
    orderV: false,
    curSupplier: "",
    curInvoice: "",
    purchasingV: false,
    purchasingReturnV: false,
  });
  useEffect(() => {
    getStockList();
  }, [state.orderType, state.dateStart, state.dateEnd]);
  function getStockList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10",
      pageNo = (pageState && pageState.pageNo) || "1";
    const { partId, partName, orderType, orderId, dateStart, dateEnd } = state;
    let data = {
      part_bianhao: partId,
      part_name: partName,
      order_bianhao: orderId,
      order_type: orderType,
      start_time: dateStart,
      end_time: dateEnd,
      count: pageSize,
      page: pageNo,
    };
    _Api.stockSelect(data).then((res) => {
      setState({
        ...state,
        list: res.data.content,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
      });
    });
  }
  function reset() {
    setState({
      ...state,
      partId: "",
      partName: "",
      orderType: "",
      orderId: "",
      dateStart: "",
      dateEnd: "",
    });
  }
  function toggleFilter() {
    setState({ ...state, filter: !state.filter });
  }
  function handleScan(record) {
    console.log(record);
    switch (record.order_type) {
      case "销售单":
        _Api
          .partSaleSelect({
            keyword: record.order_bianhao,
            sale_type: parseFloat(record.set_num) < 0 ? "销售" : "退货",
            order_state: "已结算",
          })
          .then((res) => {
            if (res.data.content.length === 0) {
              return message.error("本条数据存在异常！");
            }
            const { wx_openid } = res.data.content[0];
            const data = res.data.content[0];
            if (wx_openid) {
              _Api.wxUserSelect({ openid: wx_openid }).then((res) => {
                let wxInfo = res.data.content[0];
                setModalState({
                  ...modalState,
                  saleV: true,
                  content: {
                    ...data,
                    wx_name: wxInfo.name,
                  },
                });
              });
            } else {
              setModalState({
                ...modalState,
                saleV: true,
                content: data,
              });
            }
          });
        // _Api
        //   .partSaleSelect({
        //     keyword: record.order_bianhao,
        //     sale_type: parseFloat(record.set_num) < 0 ? "销售" : "退货",
        //     order_state: "已结算",
        //   })
        //   .then((res) => {
        //     if (res.data.content.length === 0) {
        //       return message.error("本条数据存在异常！");
        //     }
        //     const { customer, member_card, part_zk, parts } =
        //       res.data.content[0] || {};
        //     setModalState({
        //       ...modalState,
        //       saleReturn: parseFloat(record.set_num) > 0,
        //       curCustomer: customer,
        //       curMemberCard: member_card,
        //       curPartZk: part_zk,
        //       curPartContent: JSON.parse(parts),
        //       saleV: true,
        //     });
        //   });
        break;

      case "工单":
        _Api.orderSelect({ keyword: record.order_bianhao }).then((res) => {
          let data = res.data.content[0];
          console.log(data);
          data = {
            ...data,
            part_content: JSON.parse(data.part_content),
            service_content: JSON.parse(data.service_content),
          };
          if (data.car_imgs === "null") {
            data.car_imgs = {
              frontLeft: [],
              frontRight: [],
              backLeft: [],
              backRight: [],
              other: [],
            };
          } else {
            data.car_imgs = JSON.parse(data.car_imgs);
          }
          console.log(data);
          setModalState({ ...modalState, slip: data, orderV: true });
        });
        break;
      case "采购单":
        _Api
          .partBuySelect({
            keyword: record.order_bianhao,
            buy_type: parseFloat(record.set_num) > 0 ? "采购" : "退货",
          })
          .then((res) => {
            if (res.data.content.length === 0) {
              return message.error("本条数据存在异常！");
            }
            let _record = res.data.content[0];
            setModalState({
              ...modalState,
              curSupplier: _record.supplier,
              curInvoice: _record.invoice,
              curPartContent: JSON.parse(_record.parts),
              purchasingV: parseFloat(record.set_num) > 0,
              purchasingReturnV: parseFloat(record.set_num) < 0,
            });
          });

      default:
        break;
    }
  }
  const orderTypes = [
    { id: "销售单", name: "销售单" },
    { id: "销售退货", name: "销售退货" },
    { id: "采购单", name: "采购单" },
    { id: "采购退货", name: "采购退货" },
    { id: "盘点", name: "盘点" },
    { id: "工单", name: "工单" },
  ];
  const columns = [
    {
      title: "单据编号",
      dataIndex: "order_bianhao",
    },
    {
      title: "配件编号",
      align: "center",
      dataIndex: "part_bianhao",
    },
    {
      title: "配件名称",
      align: "center",
      dataIndex: "part_name",
    },
    {
      title: "品牌及规格型号",
      align: "center",
      dataIndex: "brand",
      render: (text, record) => `${text}  ${record.model}`,
    },
    {
      title: "单据类型",
      align: "left",
      dataIndex: "order_type",
      render: (text, record) =>
        text === "销售单" && parseFloat(record.set_num) > 0
          ? "销售退货"
          : text === "采购单" && parseFloat(record.set_num) < 0
          ? "采购退货"
          : text,
    },
    {
      title: "出入库时间",
      align: "center",
      dataIndex: "gmt_creat",
    },
    {
      title: "数量",
      align: "center",
      dataIndex: "set_num",
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "id",
      render: (text, record) =>
        record.order_type === "盘点" ? (
          <Tooltip title={record.remark}>
            <span className="theme-color">调整原因</span>
          </Tooltip>
        ) : (
          <span
            className="theme-color click-able"
            onClick={() => handleScan(record)}
          >
            查看
          </span>
        ),
    },
  ];
  return (
    <div className="page wage-manage-page">
      <div className="page-content">
        <div className="content-header">
          <h1>出入库明细</h1>
        </div>
        <div className="box">
          <div className="box-header">
            <Row type="flex" justify="space-around" align="top">
              <Col>
                <SearchBar
                  searchText={state.partId}
                  onChangeText={(text) => {
                    setState({ ...state, partId: text });
                  }}
                  onSearch={getStockList}
                  label="配件编号"
                  placeholder="请输入配件编号"
                />
                <SearchBar
                  searchText={state.partName}
                  onChangeText={(text) => {
                    setState({ ...state, partName: text });
                  }}
                  onSearch={getStockList}
                  label="配件名称"
                  placeholder="请输入配件名称"
                />
                <CommenSelect
                  selectValue={state.orderType}
                  label="单据类型"
                  options={orderTypes}
                  onSelect={(selectValue) => {
                    setState({ ...state, orderType: selectValue });
                  }}
                  placeholder="请选择单据类型"
                />
                {state.filter && (
                  <>
                    <SearchBar
                      searchText={state.orderId}
                      onChangeText={(text) => {
                        setState({ ...state, orderId: text });
                      }}
                      onSearch={getStockList}
                      label="单据编号"
                      placeholder="请输入单据编号"
                    />
                    <StaticSelect label="出入库日期:">
                      <RangePicker
                        style={{ width: "100%" }}
                        value={[
                          state.dateStart && moment(state.dateStart),
                          state.dateEnd && moment(state.dateEnd),
                        ]}
                        onChange={(memo, str) => {
                          setState({
                            ...state,
                            dateStart: str[0],
                            dateEnd: str[1],
                          });
                        }}
                      />
                    </StaticSelect>
                  </>
                )}
              </Col>
              <Col>
                <Tooltip title="清空筛选">
                  <span
                    onClick={reset}
                    id="reset_btn"
                    className="resetBtn iconfont icon"
                  >
                    &#xe662;
                  </span>
                </Tooltip>
                <span
                  className="text-sub xm-filter"
                  onClick={toggleFilter}
                  style={{ cursor: "pointer" }}
                >
                  {state.filter ? (
                    <span>
                      收起
                      <span className="iconfont icon ml5">&#xe663;</span>
                    </span>
                  ) : (
                    <span>
                      展开
                      <span className="iconfont icon ml5">&#xe665;</span>
                    </span>
                  )}
                </span>
                <div style={{ paddingTop: "10px" }}></div>
              </Col>
            </Row>
          </div>
          <div className="box-body">
            <Table
              rowKey={(record) => record.id}
              dataSource={state.list}
              columns={columns}
              bordered
              style={{ marginTop: "20px", marginBottom: "40px" }}
              pagination={false}
            />

            <PageControl
              total={state.total}
              pageSize={state.pageSize}
              current={state.pageNo}
              showSizeChanger
              toPage={(page, count) => {
                getStockList({ pageNo: page, pageSize: count });
              }}
            />
          </div>
        </div>
      </div>
      {/* 查看配件退货 */}
      <SaleDetail
        visible={modalState.saleV}
        readOnly={true}
        modalState={modalState.content}
        // orderEdit={(data) => {
        //   handleEdit(data);
        // }}
        // handleReturn={handleReturn}
        // handleCanclePaid={handleCancleReturn}
        closeModal={() => {
          setModalState({
            ...modalState,
            saleV: false,
          });
        }}
      ></SaleDetail>
      {/* <SaleEditModal
        saleReturn={modalState.saleReturn}
        viewMode
        modalState={modalState}
        visible={modalState.saleV}
        closeModal={() => {
          setModalState({
            ...modalState,
            curCustomer: "",
            curMemberCard: "",
            curPartZk: 1,
            curPartContent: { data: [] },
            saleV: false,
          });
        }}
      /> */}
      <SlipDetailModal
        slipType="incomeDetail"
        visible={modalState.orderV}
        slipContent={modalState.slip}
        closeModal={() => {
          setModalState({ ...modalState, orderV: false, slip: {} });
        }}
      />
      <PurchasingEditModal
        modalState={modalState}
        viewMode
        visible={modalState.purchasingV}
        closeModal={() => {
          setModalState({
            ...modalState,
            curSupplier: "",
            curInvoice: "",
            curPartContent: { ids: [], data: [] },
            purchasingV: false,
          });
        }}
      />
      <PurchasingReturnModal
        modalState={modalState}
        viewMode
        visible={modalState.purchasingReturnV}
        closeModal={() => {
          setModalState({
            ...modalState,
            curSupplier: "",
            curInvoice: "",
            curPartContent: { ids: [], data: [] },
            purchasingReturnV: false,
          });
        }}
      />
    </div>
  );
};
export default StockDetails;
