import { useEffect, useState } from "react";
import { Button, Col, Popconfirm, message, Row, Table, Divider } from "antd";
import PageControl from "../../components/PageControl";
import _Api from "../../core/api";
import SearchBar from "../../components/SearchBar";
import PartEditModal from "../../components/modals/PartEdit";
import InventoryAdjustModal from "../../components/modals/InventoryAdjust";
const FittingInventory = ({}) => {
  const [partState, setPartState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
  });
  const [modalState, setModalState] = useState({
    visible: false,
    adjustV: false,
    editType: false,
    curPart: {},
  });
  useEffect(() => {
    getPartList();
  }, []);

  function getPartList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    let data = {
      keyword: partState.keyword,
      count: pageSize,
      page: pageNo,
    };
    _Api.partSelect(data).then((res) => {
      let _data = res.data;
      setPartState({
        ...partState,
        list: _data.content,
        total: _data.allcount,
        pageNo: _data.curpage,
        pageSize: _data.pagecount,
      });
    });
  }

  function handleDelete(id) {
    _Api.partDelete({ id }).then((res) => {
      message.success(res.msg);
      let { pageNo, pageSize } = partState;
      getPartList({ pageNo, pageSize });
    });
  }
  const columns = [
    {
      title: "编号",
      dataIndex: "bianhao",
      render: (text) => (text ? text : "--"),
    },
    {
      title: "配件名称",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "品牌及规格",
      dataIndex: "brand",
      align: "center",
      render: (text, record) => {
        let brand = text ? text : "-",
          model = record.model ? record.model : "-";
        return brand + " " + model;
      },
    },
    {
      title: "当前库存",
      dataIndex: "cur_num",
      align: "center",
      render: (text, record) =>
        parseFloat(text) <= parseFloat(record.safe_num) ? (
          <span style={{ color: "red" }}>{text}</span>
        ) : (
          text
        ),
    },
    {
      title: "安全库存",
      dataIndex: "safe_num",
      align: "center",
    },
    {
      title: "单位",
      dataIndex: "unit",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "成本价",
      dataIndex: "price_basic",
      align: "center",
      render: (text) => text + "元",
    },
    {
      title: "销售价",
      dataIndex: "price_sale",
      align: "center",
      render: (text) => text + "元",
    },
    {
      title: "质保时间",
      dataIndex: "quality_month",
      align: "center",
      render: (text) => text + "个月",
    },
    {
      title: "操作",
      dataIndex: "name",
      align: "center",
      render: (text, record, index) => (
        <>
          <span
            className="theme-color click-able"
            onClick={() => {
              setModalState({ ...modalState, adjustV: true, curPart: record });
            }}
          >
            调整
          </span>
          <Divider type="vertical" />
          <span
            className="theme-color click-able"
            onClick={() => {
              setModalState({
                ...modalState,
                visible: true,
                editType: true,
                curPart: record,
              });
            }}
          >
            修改
          </span>
          <Divider type="vertical" />
          <Popconfirm
            placement="left"
            title="删除后无法恢复，是否确认删除？"
            okText="确认"
            cancelText="取消"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <span className="theme-color click-able">删除</span>
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <div className="template-dynamic-show-list page">
      <section className="content-header">
        <h1>配件库存</h1>
      </section>
      <div className="box">
        <div className="box-header">
          <Row type="flex" justify="space-between">
            <Col>
              <SearchBar
                searchText={partState.keyword}
                onChangeText={(text) => {
                  setPartState({ ...partState, keyword: text });
                }}
                onSearch={getPartList}
                label="搜索"
                placeholder="请输入编号/名称/品牌/型号"
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  setModalState({
                    ...modalState,
                    visible: true,
                    editType: false,
                  });
                }}
              >
                新增
              </Button>
            </Col>
          </Row>
        </div>
        <div className="box-body">
          <Table
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={partState.list}
            columns={columns}
            bordered
            style={{ marginBottom: "40px" }}
          />
          <PageControl
            total={partState.total}
            pageSize={partState.pageSize}
            current={partState.pageNo}
            showSizeChanger
            toPage={(page, count) => {
              getPartList({ pageNo: page, pageSize: count });
            }}
          />
        </div>
      </div>
      <PartEditModal
        partInfo={modalState.curPart}
        visible={modalState.visible}
        editType={modalState.editType}
        closeModal={() => {
          setModalState({
            ...modalState,
            visible: false,
            editType: false,
            curPart: {},
          });
        }}
        handleComplete={() => {
          setModalState({
            ...modalState,
            visible: false,
            editType: false,
            curPart: {},
          });
          let { pageNo, pageSize } = partState;
          getPartList({ pageNo, pageSize });
        }}
      />
      <InventoryAdjustModal
        visible={modalState.adjustV}
        partInfo={modalState.curPart}
        closeModal={() => {
          setModalState({ ...modalState, adjustV: false, curPart: {} });
        }}
        handleComplete={() => {
          setModalState({ ...modalState, adjustV: false, curPart: {} });
          let { pageNo, pageSize } = partState;
          getPartList({ pageNo, pageSize });
        }}
      />
    </div>
  );
};

export default FittingInventory;
