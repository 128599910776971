import { useState, useEffect, useMemo, useContext } from "react";
import { Input, Modal, Tabs, Button, message, Select, Radio } from "antd";
import axios from "../../core/axios";
import { ReactSortable } from "react-sortablejs";
import MobilePreview from "../../components/modals/Preview";
import ShopContext from "../../core/context-manager";
import _Api from "../../core/api";
const { Option } = Select;

const WxMenu = () => {
	const { shopInfo, resetShopInfo } = useContext(ShopContext);
	const [state, setState] = useState({
		shopName: "",
		sorting: false,
		list: [],
		menu_tips: true,

		radio_label: 1,
		address1: "",
		address2: "",
		address3: "http://",
		menu_index: 0,
		menu_name: "",
		menu_name_num: Number,
		sub_num: 0,
		sub_menu_index: 0,
		sub_menu_name: "",
		sub_menu_name_num: Number,
		is_sub: false,
		modal: null,
	});

	useEffect(() => {
		getShopInfo();
	}, []);
	const status = useMemo(() => {
		if (state.is_sub) {
			if (state.sub_menu_name_num > 16 || state.sub_menu_name_num === 0) {
				return {
					flag: false,
					reason: "子菜单名称不合格！",
				};
			}
		} else {
			if (state.menu_name_num > 8 || state.menu_name_num === 0) {
				return {
					flag: false,
					reason: "菜单名称不合格！",
				};
			}
		}

		if (state.radio_label === 3) {
			if (!state.address3) {
				return {
					flag: false,
					reason: "自定义链接必须以'http://'或者'https://'开头",
				};
			}
			if (state.address3.indexOf("https://") === 0 || state.address3.indexOf("http://") === 0) {
				return {
					flag: true,
					reason: "",
				};
			} else {
				return {
					flag: false,
					reason: "自定义链接必须以'http://'或者'https://'开头",
				};
			}
		}
		return { flag: "true", reason: "" };
	}, [state.is_sub, state.address3, state.menu_name_num, state.sub_menu_name_num, state.radio_label]);

	function getShopInfo() {
		let { radio_label, address3 } = state,
			wx_menu = shopInfo.wx_menu;
		if (wx_menu) {
			let button = JSON.parse(wx_menu).button;
			let list = button.map((item) => {
				if (item.sub_button) {
					return {
						current: item.current,
						radio: 1,
						name: item.name,
						url: "",
						sub_button: item.sub_button.map((e) => {
							return {
								current: e.current,
								radio: e.radio,
								name: e.name,
								url: e.url,
							};
						}),
					};
				} else {
					return {
						current: item.current,
						radio: item.radio,
						name: item.name,
						url: item.url,
						sub_button: [],
					};
				}
			});
			for (var i = 0; i < list.length; i++) {
				list[i].current = false;
				for (let j = 0; j < list[i].sub_button.length; j++) {
					list[i].sub_button[j].current = false;
				}
			}
			if (list[0].sub_button.length > 0) {
				list[0].sub_button[0].current = true;
				radio_label = list[0].sub_button[0].radio;
				if (list[0].sub_button[0].radio == 3) {
					address3 = list[0].sub_button[0].url;
				}
			} else {
				list[0].current = true;
				radio_label = list[0].radio;
			}

			console.log(list);

			setState({
				...state,
				radio_label,
				address3,
				list,
				shopName: shopInfo.name,
				menu_tips: false,
				menu_name: list[0].name,
				sub_menu_name: list[0].sub_button[0] ? list[0].sub_button[0].name : "",
				address1: "https://api.sanfeng9.com/customer/weixin_public.php?cmd=web_start&shop_id=" + shopInfo.id,
				address2: "https://api.sanfeng9.com/customer/weixin_public.php?cmd=member_login_start&shop_id=" + shopInfo.id,
			});
		} else {
			setState({
				...state,
				shopName: shopInfo.name,
				address1: "https://api.sanfeng9.com/customer/weixin_public.php?cmd=web_start&shop_id=" + shopInfo.id,
				address2: "https://api.sanfeng9.com/customer/weixin_public.php?cmd=member_login_start&shop_id=" + shopInfo.id,
			});
		}
	}

	function submit(list) {
		let arr = list.map((item) => {
			if (item.sub_button.length > 0) {
				return {
					current: item.current,
					radio: item.radio,
					name: item.name,
					sub_button: item.sub_button.map((e) => {
						return {
							current: e.current,
							radio: e.radio,
							type: "view",
							name: e.name,
							url: e.url,
						};
					}),
				};
			} else {
				return {
					current: item.current,
					radio: item.radio,
					type: "view",
					name: item.name,
					url: item.url,
				};
			}
		});
		console.log(arr);
		const json = {
			button: arr,
		};
		_Api
			.wxMenuCreat({
				menu_json: JSON.stringify(json),
			})
			.then((res) => {
				message.success("保存成功");
				resetShopInfo();
			});
	}

	function edit(menu, index, e) {
		e.stopPropagation();
		if (!status.flag) {
			return message.error(status.reason);
		}
		let address3;
		let list = handlelist();

		list[index].current = true;
		if (menu.radio == 3) {
			address3 = menu.url;
		} else {
			address3 = "http://";
		}
		setState({
			...state,
			address3,
			is_sub: false,
			sub_num: list[index].sub_button.length,
			menu_tips: false,
			list,
			menu_index: index,
			sub_menu_index: 0,
			menu_name: menu.name,
			radio_label: menu.radio,
			menu_name_num: Number,
			sub_menu_name_num: Number,
		});
	}

	function add() {
		let list = state.list;
		for (var i = 0; i < list.length; i++) {
			list[i].current = false;
			for (let j = 0; j < list[i].sub_button.length; j++) {
				list[i].sub_button[j].current = false;
			}
		}
		list.push({
			current: true,
			name: "菜单名称",
			radio: 1,
			url: "",
			sub_button: [],
		});
		setState({
			...state,
			is_sub: false,
			sub_num: 0,
			menu_tips: false,
			list,
			menu_index: list.length - 1,
			menu_name: "菜单名称",
			radio_label: 1,
			address3: "http://",
			menu_name_num: Number,
			sub_menu_name_num: Number,
		});
	}

	function handleDelete() {
		let list = state.list;
		if (state.is_sub) {
			list[state.menu_index].sub_button.splice(state.sub_menu_index, 1);
			list[state.menu_index].current = false;
		} else {
			list.splice(state.menu_index, 1);
		}
		setState({
			...state,
			is_sub: false,
			list,
			menu_tips: true,
		});
	}

	function sub_add(index, e) {
		e.stopPropagation();
		let list = state.list;
		if (list[index].sub_button.length > 4) {
			message.warning("最多添加五个子菜单");
		} else {
			for (var i = 0; i < list.length; i++) {
				list[i].current = false;
				for (let j = 0; j < list[i].sub_button.length; j++) {
					list[i].sub_button[j].current = false;
				}
			}
			list[index].sub_button.push({
				current: true,
				name: "子菜单名称",
				radio: 1,
				url: "",
			});
			setState({
				...state,
				is_sub: true,
				menu_tips: false,
				list,
				sub_menu_index: list[index].sub_button.length - 1,
				sub_menu_name: "子菜单名称",
				radio_label: 1,
				address3: "http://",
				menu_name_num: Number,
				sub_menu_name_num: Number,
			});
		}
	}

	function sub_edit(index, sub, sub_index, e) {
		e.stopPropagation();
		if (!status.flag) {
			return message.error(status.reason);
		}
		let list = handlelist(),
			address3;
		list[index].sub_button[sub_index].current = true;
		if (sub.radio == 3) {
			address3 = sub.url;
		} else {
			address3 = "http://";
		}
		setState({
			...state,
			is_sub: true,
			menu_tips: false,
			list,
			address3,
			sub_menu_index: sub_index,
			sub_menu_name: sub.name,
			radio_label: sub.radio,
			menu_name_num: Number,
			sub_menu_name_num: Number,
		});
	}

	function sortClick(item, index, e) {
		e.stopPropagation();
		let list = state.list;
		for (var i = 0; i < list.length; i++) {
			list[i].current = false;
		}
		list[index].current = true;
		setState({
			...state,
			list,
		});
	}

	function preview(list) {
		let modal = (
			<MobilePreview
				list={list}
				name={state.nickName ? state.nickName : state.defaultNickName}
				close={() => {
					// let list = this.state.list;
					// for (var i = 0; i < list.length; i++) {
					// 	list[i].current = false;
					// 	for (let j = 0; j < list[i].sub_button.length; j++) {
					// 		list[i].sub_button[j].current = false;
					// 	}
					// }
					// const { menu_index, sub_menu_index } = this.state;
					// if (this.state.is_sub) {
					// 	list[menu_index].sub_button[sub_menu_index].current = true;
					// } else {
					// 	list[menu_index].current = true;
					// }
					setState({ ...state, modal: null });
				}}
			/>
		);
		setState({ ...state, modal });
	}
	function textName(e) {
		for (var t = 0, i = 0; i < e.length; i++) {
			var s = e.charCodeAt(i);
			(s >= 1 && 126 >= s) || (s >= 65376 && 65439 >= s) ? t++ : (t += 2);
		}
		return t;
	}

	function menuSort() {
		if (!status.flag) {
			return message.error(status.reason);
		}
		let list = handlelist(),
			{ menu_index } = state;
		list[menu_index].current = true;
		if (list.length > 0) {
			setState({
				...state,
				list,
				is_sub: false,
				menu_tips: true,
				sorting: !sorting,
				menu_name_num: Number,
				sub_menu_name_num: Number,
			});
		} else {
			message.warning("请添加菜单");
		}
	}
	function handleSave() {
		if (!status.flag) {
			return message.error(status.reason);
		}
		let list = state.list;
		if (list.length > 0) {
			list = handlelist();
			submit(list);
		} else {
			message.warning("请添加菜单");
		}
	}
	function handlePreview() {
		if (!status.flag) {
			return message.error(status.reason);
		}
		let { list } = state;
		if (list.length > 0) {
			list = handlelist();
			preview(list);
		} else {
			message.warning("请添加菜单");
		}
	}
	function handlelist() {
		let { list, menu_index, sub_menu_index, menu_name, sub_menu_name, radio_label, address1, address2, address3 } =
			state;
		for (var i = 0; i < list.length; i++) {
			list[i].current = false;
			for (let j = 0; j < list[i].sub_button.length; j++) {
				list[i].sub_button[j].current = false;
			}
		}
		list[menu_index].name = menu_name;
		if (list[menu_index].sub_button.length > 0) {
			list[menu_index].sub_button[sub_menu_index].name = sub_menu_name;
			if (radio_label == 1) {
				list[menu_index].sub_button[sub_menu_index].url = address1;
				list[menu_index].sub_button[sub_menu_index].radio = 1;
			} else if (radio_label == 2) {
				list[menu_index].sub_button[sub_menu_index].url = address2;
				list[menu_index].sub_button[sub_menu_index].radio = 2;
			} else if (radio_label == 3) {
				if (address3) {
					list[menu_index].sub_button[sub_menu_index].url = address3;
					list[menu_index].sub_button[sub_menu_index].radio = 3;
				}
			}
		} else {
			if (radio_label == 1) {
				list[menu_index].url = address1;
				list[menu_index].radio = 1;
			} else if (radio_label == 2) {
				list[menu_index].url = address2;
				list[menu_index].radio = 2;
			} else if (radio_label == 3) {
				if (address3) {
					list[menu_index].url = address3;
					list[menu_index].radio = 3;
				}
			}
		}
		return list;
	}

	const {
		radio_label,
		address1,
		address2,
		address3,
		address4,
		menu_tips,
		sorting,
		list,
		menu_name,
		sub_menu_name,
		is_sub,
		sub_num,
		modal,
		showList,
		menu_name_num,
		sub_menu_name_num,
	} = state;
	return (
		<div>
			<div className='wechat-menu-page clearfix'>
				<div className='menu_preview_area'>
					<div className='mobile_menu_preview'>
						<div className='mobile_hd'>{state.shopName}</div>
						<div className='mobile_bd'>
							<ul className='pre_menu_list'>
								{sorting ? (
									<ReactSortable
										list={list}
										setList={(newState) =>
											setState({
												...state,
												list: newState,
												menu_index: newState.findIndex((item) => item.current == true),
											})
										}>
										{list.map((item, index) => (
											<li
												onClick={(e) => sortClick(item, index, e)}
												key={index}
												className={`pre_menu_item size1of${list.length}`}>
												<a className='pre_menu_link'>
													<i className='icon20_common sort_gray'></i>
													<span>{item.name}</span>
												</a>
												{item.current && item.sub_button.length > 0 && (
													<div className='sub_pre_menu_box'>
														<ul className='sub_pre_menu_list'>
															<ReactSortable
																list={item.sub_button}
																setList={(newSubState) => {
																	let list = state.list;
																	list[index].sub_button = newSubState;
																	setState({ ...state, list });
																}}>
																{item.sub_button &&
																	item.sub_button.map((sub, s_index) => (
																		<li key={s_index} className={`${sub.current ? "current" : ""}`}>
																			<a>
																				<span className='sub_pre_menu_inner'>{sub.name}</span>
																			</a>
																		</li>
																	))}
															</ReactSortable>
														</ul>
													</div>
												)}
											</li>
										))}
									</ReactSortable>
								) : (
									<>
										{list.map((item, index) => (
											<li
												onClick={(e) => edit(item, index, e)}
												key={index}
												className={`pre_menu_item size1of${list.length < 3 ? list.length + 1 : list.length} ${
													item.current ? "current" : ""
												}`}>
												<a className='pre_menu_link'>
													<i className='icon_menu_dot js_icon_menu_dot'></i>
													<span>{item.name}</span>
												</a>
												{(item.current || (item.sub_button && item.sub_button.find((fmenu) => fmenu.current))) && (
													<div className='sub_pre_menu_box'>
														<ul className='sub_pre_menu_list'>
															{item.sub_button &&
																item.sub_button.map((sub, sub_index) => (
																	<li
																		onClick={(e) => sub_edit(index, sub, sub_index, e)}
																		key={sub_index}
																		className={`${sub.current ? "current" : ""}`}>
																		<a>
																			<span className='sub_pre_menu_inner'>{sub.name}</span>
																		</a>
																	</li>
																))}
															<li onClick={(e) => sub_add(index, e)}>
																<a title='最多添加5个子菜单'>
																	<span className='sub_pre_menu_inner'>
																		<i className='icon14_menu_add'></i>
																	</span>
																</a>
															</li>
														</ul>
													</div>
												)}
											</li>
										))}
										{list.length < 3 && (
											<li onClick={add} className={`pre_menu_item size1of${list.length + 1}`}>
												<a className='pre_menu_link'>
													<i className='icon14_menu_add'></i>
												</a>
											</li>
										)}
									</>
								)}
							</ul>
						</div>
					</div>
					{sorting ? (
						<Button
							className='sort_btn_wrp'
							onClick={() => {
								setState({
									...state,
									list,
									menu_tips: false,
									sorting: !sorting,
								});
							}}>
							完成
						</Button>
					) : (
						<Button className='sort_btn_wrp' onClick={menuSort}>
							菜单排序
						</Button>
					)}
				</div>
				<div className='menu_form_area'>
					{menu_tips ? (
						<div className='menu_initial_tips'>
							{sorting ? "请通过拖拽左边的菜单进行排序" : "点击左侧菜单进行编辑操作"}
						</div>
					) : (
						<div className='portable_editor to_left'>
							<div className='editor_inner'>
								<div className='menu_form_hd'>
									<div className='global_info'>{is_sub ? sub_menu_name : menu_name}</div>
									<div className='global_extra'>
										<a onClick={handleDelete}>删除{is_sub ? "子" : ""}菜单</a>
									</div>
								</div>
								<div className='menu_form_bd'>
									{!is_sub && (
										<div className='frm_control_group'>
											<label className='frm_label'>
												<strong className='title'>菜单名称</strong>
											</label>
											<div className='frm_controls'>
												<Input
													onChange={(e) => {
														const num = textName(e.target.value);
														setState({ ...state, menu_name: e.target.value, menu_name_num: num });
													}}
													value={menu_name}
													style={{ width: 300 }}
												/>
												{menu_name_num == 0 && <p style={{ paddingTop: "4px", color: "red" }}>请输入菜单名称</p>}
												{menu_name_num > 8 && <p style={{ paddingTop: "4px", color: "red" }}>字数超过上限</p>}
												<p style={{ paddingTop: "4px", color: "#ff8534" }}>
													仅支持中英文和数字，字数不超过4个汉字或8个字母
												</p>
											</div>
										</div>
									)}
									{is_sub && (
										<div className='frm_control_group'>
											<label className='frm_label'>
												<strong className='title'>子菜单名称</strong>
											</label>
											<div className='frm_controls'>
												<Input
													onChange={(e) => {
														const num = textName(e.target.value);
														setState({ ...state, sub_menu_name: e.target.value, sub_menu_name_num: num });
													}}
													value={sub_menu_name}
													style={{ width: 300 }}
												/>
												{sub_menu_name_num == 0 && <p style={{ paddingTop: "4px", color: "red" }}>请输入菜单名称</p>}
												{sub_menu_name_num > 16 && <p style={{ paddingTop: "4px", color: "red" }}>字数超过上限</p>}
												<p style={{ paddingTop: "4px", color: "#ff8534" }}>
													仅支持中英文和数字，字数不超过8个汉字或16个字母
												</p>
											</div>
										</div>
									)}
									{(is_sub || sub_num == 0) && (
										<div className='frm_control_group'>
											<label className='frm_label'>
												<strong className='title'>{is_sub ? "子菜单内容" : "菜单内容"}</strong>
											</label>
											<div className='frm_controls frm_vertical_pt'>
												<Radio.Group
													buttonStyle='solid'
													defaultValue={radio_label}
													value={radio_label}
													onChange={(e) => {
														setState({
															...state,
															radio_label: e.target.value,
														});
													}}>
													<Radio.Button value={1}>微官网</Radio.Button>
													<Radio.Button value={2}>会员中心</Radio.Button>
													<Radio.Button value={3}>自定义链接</Radio.Button>
												</Radio.Group>
											</div>
										</div>
									)}
									{(is_sub || sub_num == 0) && (
										<div className='menu_content_container'>
											<div className='menu_content'>
												<p className='menu_content_tips tips_global'>订阅者点击该子菜单会跳到以下链接</p>
												{radio_label == 1 && (
													<div className='frm_control_group'>
														<label className='frm_label'>页面地址</label>
														<div className='frm_controls'>
															<Input disabled value={address1} style={{ width: 600 }} />
														</div>
													</div>
												)}
												{radio_label == 2 && (
													<div className='frm_control_group'>
														<label className='frm_label'>页面地址</label>
														<div className='frm_controls'>
															<Input disabled value={address2} style={{ width: 600 }} />
														</div>
													</div>
												)}
												{radio_label == 3 && (
													<div className='frm_control_group'>
														<label className='frm_label'>页面地址</label>
														<div className='frm_controls'>
															<Input
																value={address3}
																onChange={(e) => {
																	setState({ ...state, address3: e.target.value });
																}}
																style={{ width: 600 }}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='tool_bar'>
				<Button type='primary' style={{ marginRight: 20 }} onClick={handleSave}>
					保存并发布
				</Button>
				<Button onClick={handlePreview}>预览</Button>
			</div>
			{modal}
		</div>
	);
};

export default WxMenu;
