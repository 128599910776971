const mathFunc = {
  multi(a, b) {
    return this.returnFloat(parseFloat(a) * parseFloat(b));
  },
  returnFloat(value) {
    value = Math.round(parseFloat(value) * 100) / 100;
    let xsd = value.toString().split(".");
    if (xsd.length == 1) {
      value = value.toString() + ".00";
      return value;
    }
    if (xsd.length > 1) {
      if (xsd[1].length < 2) {
        value = value.toString() + "0";
      }
      return value;
    }
  },
  cashToChinese(cash) {
    let digits = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"],
      largeUnits = ["元", "万", "亿"],
      units = ["", "拾", "佰", "仟"],
      fractions = ["角", "分"],
      cStr = "";
    cash = cash.toString();
    if (cash === "" || cash === undefined || cash === null) {
      return cStr;
    }
    // if (parseInt(cash) === 0) {
    //   return "零";
    // }
    let preCash = cash.split(".")[0] || "";
    let lastCash = cash.split(".")[1] || "00";
    if (preCash.length > 12 || lastCash.length > 2) {
      return cStr;
    }
    //遍历小数位
    for (let i = 0; i < fractions.length; i++) {
      cStr += (digits[lastCash.charAt(i)] + fractions[i]).replace(/零./, "");
    }
    cStr = cStr || "整";
    //遍历整数位，用str来记录每4位的值
    for (let i = preCash.length - 1, n = 0, str = ""; i >= 0; i--) {
      let largeUnit = "";
      str = digits[preCash[i]] + units[n % 4] + str;
      //用n计数每隔4位，或者遍历到头的时候加单位（'元','万','亿'）
      if ((n + 1) % 4 === 0 || i === 0) {
        largeUnit = largeUnits[Math.floor(n / 4)];
        //考虑替换末位全是0时替换位空 或者中间连续0时替换为零
        str = str.replace(/(零.)*零$/g, "").replace(/(零.)+/g, "零");
        str += largeUnit;
        cStr = str + cStr;
        str = "";
      }
      n++;
    }
    //考虑处理（'元','万','亿'）单位前为10的情况，和整数位为0的情况
    return cStr
      .replace(/壹拾(.){0,1}([元|万|亿])/g, "拾$1$2")
      .replace(/^元/, "");
  },
  checkPrice(val) {
    let checkPlan = "" + val;
    checkPlan = checkPlan
      .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
      .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
      .replace(/^\./g, "") // 保证第一个为数字而不是.
      .replace(".", "$#$")
      .replace(/\./g, "")
      .replace("$#$", ".");
    if (checkPlan.indexOf(".") < 0 && checkPlan !== "") {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      checkPlan = parseFloat(checkPlan) + "";
    } else if (checkPlan.indexOf(".") >= 0) {
      checkPlan = checkPlan.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3"); // 只能输入两个小数
    }
    return checkPlan;
  },
  // 小数转化 百分数
  getPercent(num) {
    const _num = num + "",
      _index = (num + "").indexOf(".");
    if (_index == -1 || parseInt(num) == 1) {
      return "100";
    } else {
      const _str = _num.slice(_index + 1);
      if (_str[0] === "0") {
        return _str[1];
      } else {
        if (_str.length === 1) {
          return _str + "0";
        } else {
          return _str;
        }
      }
    }
  },
  // 处理整数
  reservedInteger(num) {
    const _num = num + "",
      index = (num + "").indexOf(".");
    const str = _num.substring(0, index);
    return str;
  },
  // 处理小数
  reservedDecimal(num) {
    const _num = num + "";
    const index = (num + "").indexOf(".");
    let str;

    if (_num[index + 1] !== "0") {
      str = _num.substring(0, index + 2);
    }
    if (_num[index + 2] !== "0") {
      str = _num.substring(0, index + 3);
    } else {
      str = _num.substring(0, index);
    }
    return str;
  },
  // 处理次数
  reservedCount(num) {
    const _num = num + "";
    const index = (num + "").indexOf(".");
    let str;
    if (_num[0] === "-") {
      if (_num[index + 1] === "0") {
        str = _num.substring(1, index);
      } else {
        str = _num.substring(1, index + 2);
      }
    } else {
      if (_num[index + 1] === "0") {
        str = _num.substring(0, index);
      } else {
        str = _num.substring(0, index + 2);
      }
    }
    return str;
  },
  // 保留整数
  reservedIntegerOnchage(num) {
    let _num = num + "";
    _num = this.filterNum(_num);
    const index = _num.indexOf(".");
    if (index === -1) {
      return _num;
    } else {
      return _num.substring(0, index);
    }
  },
  // 保留小数
  reservedDecimalOnchage(num) {
    let _num = num + "";
    _num = this.filterNum(_num);
    const index = (num + "").indexOf(".");
    if (index === -1) {
      return _num;
    } else {
      if (_num[index + 3]) {
        return _num.substring(0, index + 3);
      }
      return _num;
    }
  },

  filterNum(num) {
    return num
      .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
      .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
      .replace(/^\./g, "") // 保证第一个为数字而不是.
      .replace(".", "$#$")
      .replace(/\./g, "")
      .replace("$#$", ".");
  },
};
export default mathFunc;
