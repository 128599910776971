import { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Divider,
  Popconfirm,
  message,
  DatePicker,
  Tabs,
} from "antd";
import Api from "../../../core/api";
import PageControl from "../../../components/PageControl";
import SearchBar from "../../../components/SearchBar";
import StaticSelect from "../../../components/StaticSelect";
import PurchasingEditModal from "../../../components/modals/PurchasingEdit";
import PartSelectModal from "../../../components/modals/PartSelect";
import PartAddModal from "../../../components/modals/PartEdit";
import moment from "moment";
import _Api from "../../../core/api";
const { RangePicker } = DatePicker;
const PartPurchasing = () => {
  const [purchasingState, setPurchasingState] = useState({
    keyword: "",
    dateStart: "",
    dateEnd: "",
    list: [],
    total: "0",
    pageSize: "10",
    pageNo: "1",
  });
  const [modalState, setModalState] = useState({
    editV: false,
    viewMode: false,
    selectV: false,
    addV: false,
    curSupplier: "",
    curInvoice: "",
    curPartContent: { ids: [], data: [], total: "" },
  });

  useEffect(() => {
    getPurchasingList();
  }, [purchasingState.dateStart, purchasingState.dateEnd]);

  function getPurchasingList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    const { keyword, dateEnd, dateStart } = purchasingState;
    let data = {
      keyword: keyword,
      start_time: dateStart,
      end_time: dateEnd,
      buy_type: "采购",
      count: pageSize,
      page: pageNo,
    };
    _Api.partBuySelect(data).then((res) => {
      let _list = res.data.content.map((item) => {
        return {
          ...item,
          parts: JSON.parse(item.parts),
        };
      });
      setPurchasingState({
        ...purchasingState,
        list: _list,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
      });
    });
  }

  function handleDelete(id) {
    _Api.partBuyDelete({ id }).then((res) => {
      message.success(res.msg);
      const { pageNo, pageSize } = purchasingState;
      getPurchasingList({ pageNo, pageSize });
    });
  }

  function handlePartAdd(params) {
    let _list = [...modalState.curPartContent.data, ...params],
      amount = 0,
      ids = _list.map((item) => {
        let price = item.priceBasic ? parseFloat(item.priceBasic) : 0;
        let num = item.num ? parseFloat(item.num) : 0;
        amount += price * num;
        return item.id;
      });
    setModalState({
      ...modalState,
      addV: false,
      selectV: false,
      curPartContent: { data: _list, ids, amount },
    });
  }
  function handlePurchasingSave(params) {
    const { curSupplier, curInvoice, curPartContent } = modalState;
    let data = {
      supplier: curSupplier,
      invoice: curInvoice,
      parts: curPartContent,
      buy_type: "采购",
    };
    if (!curSupplier) {
      return message.error("请选择供应商");
    }
    console.log(data);
    _Api.partBuyInsert(data).then((res) => {
      message.success(res.msg);
      setModalState({
        ...modalState,
        editV: false,
        curSupplier: "",
        curInvoice: "",
        curPartContent: { ids: [], data: [], total: "" },
      });
      const { pageNo, pageSize } = purchasingState;
      getPurchasingList({ pageNo, pageSize });
    });
  }
  const columns = [
    {
      title: "单号",
      dataIndex: "bianhao",
      align: "center",
      render: (text, record) => (
        <span
          className="theme-color click-able"
          onClick={() =>
            setModalState({
              ...modalState,
              editV: true,
              viewMode: true,
              curSupplier: record.supplier,
              curInvoice: record.invoice,
              curPartContent: record.parts,
            })
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "配件",
      dataIndex: "parts",
      align: "center",
      render: (text) => {
        let nameArr = text.data.map((item) => item.name);
        if (nameArr.length > 3) {
          let _arr = nameArr.slice(0, 2);
          return _arr.join("、") + "等" + nameArr.length + "种配件";
        } else {
          return nameArr.join("、");
        }
      },
    },
    {
      title: "开单日期",
      dataIndex: "gmt_creat",
      align: "center",
    },
    {
      title: "供应商",
      dataIndex: "supplier",
      align: "center",
    },
    {
      title: "发票号",
      dataIndex: "invoice",
      align: "center",
    },
    {
      title: "总金额",
      dataIndex: "parts",
      key: "part-amount",
      align: "center",
      render: (text) => text.amount,
    },
    {
      title: "操作",
      key: "operation",
      align: "center",
      render: (text, record) => (
        <>
          <Popconfirm
            placement="left"
            title="删除后无法恢复，是否确认删除？"
            okText="确认"
            cancelText="取消"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <span className="theme-color click-able">删除</span>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className="page package-setting-page">
      <div className="page-content">
        <section className="content-header">
          <h1>配件采购</h1>
        </section>
        <div className="box">
          <div className="box-header">
            <Row type="flex" justify="space-between">
              <Col>
                <SearchBar
                  searchText={purchasingState.keyword}
                  onChangeText={(text) => {
                    setPurchasingState({ ...purchasingState, keyword: text });
                  }}
                  onSearch={getPurchasingList}
                  label="搜索"
                  placeholder="请输入会员单号/供应商/配件"
                />
                <StaticSelect label="开单日期：">
                  <RangePicker
                    style={{ width: "100%" }}
                    value={[
                      purchasingState.dateStart &&
                        moment(purchasingState.dateStart),
                      purchasingState.dateEnd &&
                        moment(purchasingState.dateEnd),
                    ]}
                    onChange={(memo, str) => {
                      setPurchasingState({
                        ...purchasingState,
                        dateStart: str[0],
                        dateEnd: str[1],
                      });
                    }}
                  />
                </StaticSelect>
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setModalState({
                      ...modalState,
                      editV: true,
                      viewMode: false,
                    });
                  }}
                >
                  新增
                </Button>
              </Col>
            </Row>
          </div>
          <div className="box-body">
            <Table
              rowKey={(record) => record.id}
              pagination={false}
              dataSource={purchasingState.list}
              columns={columns}
              bordered
              style={{ marginBottom: "40px" }}
            />
            <PageControl
              total={purchasingState.total}
              pageSize={purchasingState.pageSize}
              current={purchasingState.pageNo}
              showSizeChanger
              toPage={(page, count) => {
                getPurchasingList({ pageNo: page, pageSize: count });
              }}
            />
          </div>
        </div>
      </div>
      <PurchasingEditModal
        visible={modalState.editV}
        viewMode={modalState.viewMode}
        modalState={modalState}
        handleModalState={(data) => {
          setModalState({ ...modalState, ...data });
        }}
        openSelect={() => {
          console.log("openSelect");
          setModalState({ ...modalState, selectV: true });
        }}
        openAdd={() => {
          setModalState({ ...modalState, addV: true });
        }}
        closeModal={() => {
          setModalState({
            ...modalState,
            editV: false,
            curSupplier: "",
            curInvoice: "",
            curPartContent: { ids: [], data: [], total: "" },
          });
        }}
        handleSave={handlePurchasingSave}
      />
      <PartSelectModal
        purchase
        selectedIds={modalState.curPartContent.ids}
        visible={modalState.selectV}
        partAdd={handlePartAdd}
        closeModal={() => {
          setModalState({ ...modalState, selectV: false });
        }}
      />
      <PartAddModal
        visible={modalState.addV}
        closeModal={() => {
          setModalState({ ...modalState, addV: false });
        }}
        handleComplete={(data) => {
          handlePartAdd([data]);
        }}
      />
    </div>
  );
};
export default PartPurchasing;
