import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Input, Select } from "antd";
import TitleBar from "../TitleBar";
import moment from "moment";
import GoodsTable from "../../modules/common/GoodsTable";
import _Api from "../../core/api";

const SaleReturnModal = ({
	visible,
	viewMode,
	editType,
	closeModal,
	openAdd,
	openSelect,
	openWxSelect,
	modalState,
	handleModalState,
	handleSave,
	handleClearVip,
}) => {
	const layout = {
		labelCol: { span: 5 },
		wrapperCol: { span: 19 },
	};

	return (
		<Modal
			title={editType ? "编辑退货单" : "新增退货单"}
			zIndex={990}
			width={1200}
			visible={visible}
			onCancel={closeModal}
			onOk={viewMode ? closeModal : handleSave}>
			<TitleBar>
				<span>{"开单日期：" + moment().format("YYYY-MM-DD")}</span>
			</TitleBar>
			<Form {...layout} style={{ marginBottom: "24px" }}>
				<Row style={{ marginTop: "10px" }} justify='space-between'>
					<Col span={6}>
						<Form.Item label='客户'>
							<Input
								disabled={viewMode}
								value={modalState.curCustomer}
								onChange={(e) => {
									handleModalState({ curCustomer: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='微信客户'>
							<Row type='flex' wrap={false} align='middle'>
								<Input disabled value={modalState.curWxName} />
								<Button type='primary' style={{ margin: "0 10px", padding: "0 3px" }} onClick={openWxSelect}>
									选择
								</Button>
								<Button
									style={{ padding: "0 3px" }}
									onClick={() => {
										handleModalState({ curWxOpenid: "", curWxName: "" });
									}}>
									清空
								</Button>
							</Row>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name='car-vip' label='会员卡'>
							<Row type='flex' wrap={false} align='middle'>
								<Input value={modalState.curMemberCard} disabled />
								<Button
									className='btn-green'
									type='primary'
									style={{ margin: "0 10px", padding: "0 3px" }}
									onClick={() => {
										handleModalState({ vipSelectV: true });
									}}>
									选择
								</Button>
								<Button style={{ padding: "0 3px" }} onClick={handleClearVip}>
									清空
								</Button>
							</Row>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<TitleBar>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>商品配件</span>
					{!viewMode && (
						<div>
							<Button size='middle' style={{ marginRight: "10px" }} onClick={openSelect}>
								选择
							</Button>
							<Button size='middle' onClick={openAdd}>
								新建
							</Button>
						</div>
					)}
				</div>
			</TitleBar>
			<GoodsTable
				viewmode={viewMode}
				discount={modalState.curPartZk || 1}
				slip={modalState.curPartContent.data.length > 0}
				tableState={modalState.curPartContent}
				setTable={(data) => {
					handleModalState({ curPartContent: data });
				}}
			/>
		</Modal>
	);
};
export default SaleReturnModal;
