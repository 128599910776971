import React from "react";
import { Row, Col, Button, Select, Input, Form, Table, Empty, Divider, Popconfirm } from "antd";
import PageControl from "../../components/PageControl";
import moment, { duration } from "moment";
import mathFunc from "../../core/mathfunc";
export default ({ openDetail, tableState, openReceive, cancelComplete, getTableList }) => {
	const columns = [
		{
			title: "序号",
			key: "index",
			width: "60px",
			render: (text, record, index) => index + 1,
		},
		{
			title: "单号",
			align: "center",
			dataIndex: "bianhao",
			render: (text, record,index) => (
				<span
					className='theme-color click-able'
					onClick={() => {
						openDetail({ slipType: "finished", data: record,index });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "车牌号",
			align: "center",
			width: "120px",
			dataIndex: "car_id",
		},
		{
			title: "车型",
			dataIndex: "car_type",
		},
		{
			title: "联系人",
			dataIndex: "car_linkman",
		},
		{
			title: "手机号码",
			dataIndex: "car_phone",
		},
		{
			title: "开单时间",
			dataIndex: "gmt_creat",
			key: "timeStart",
		},
		{ title: "完工时间", dataIndex: "end_time" },
		{
			title: "本次耗时",
			align: "center",
			key: "duration",
			render: (text, record) => {
				const startTime = moment(record.gmt_creat, "YYYY-MM-DD HH:mm:ss"),
					endTime = moment(record.end_time, "YYYY-MM-DD HH:mm:ss");
				let _duration = endTime.diff(startTime) / (1000 * 60),
					hours = Math.floor(_duration / 60),
					minute = Math.ceil(_duration % 60),
					result = "";
				if (hours > 0) {
					result = result + hours + "小时";
				}

				if (minute > 0) {
					result = result + minute + "分钟";
				}
				return result;
			},
		},
		{
			title: "总金额",
			// dataIndex: "part_content",
			dataIndex: "money_all",
			render: (text, record) => mathFunc.returnFloat(text)
			// {
			// 	let partAr = JSON.parse(record.part_content).ar||0,
			// 		serviceAr = JSON.parse(record.service_content).ar||0;
			// 	return mathFunc.returnFloat(parseFloat(partAr) + parseFloat(serviceAr)) + "元";
			// },
		},
		{
			title: "微信通知",
			dataIndex: "wechat",
			key: "wechat",
		},
		{
			title: "操作",
			align: "center",
			dataIndex: "operation",
			key: "operation",
			render: (text, record) => (
				<>
					<span
						className='click-able theme-color'
						onClick={() => {
							openReceive(record);
						}}>
						结算
					</span>
					<Divider type='vertical' />
					<Popconfirm
						placement='left'
						title='是否确认取消完工'
						onConfirm={() => cancelComplete(record)}
						okText='确认'
						cancelText='取消'>
						<span className='click-able theme-color'>取消完工</span>
					</Popconfirm>
				</>
			),
		},
	];

	return (
		<>
			<Table
				rowKey={(record) => record.id}
				dataSource={tableState.list}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
				pagination={false}
			/>
			<PageControl
				total={tableState.total}
				pageSize={tableState.pageSize}
				current={tableState.pageNo}
				showSizeChanger
				toPage={(page, count) => {
					getTableList({ pageNo: page, pageSize: count });
				}}
			/>
		</>
	);
};
