import { NAVBAR } from "./NavBarPic";
const PreviewPart = ({ microWebLink, shopName }) => {
	return (
		<div className='info_container__left'>
			<div
				className='component-view-page'
				style={{
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
					padding: 0,
					borderRadius: 5,
					overflow: "hidden",
				}}>
				<div className='component-view-content' style={{ paddingBottom: '0px',minHeight: '500px' }}>
					<div className='view-area-title' style={{ backgroundImage: `url(${NAVBAR})`, position: "relative" }}>
						<div
							style={{
								position: "absolute",
								display: "flex",
								backgroundColor: "#222222",
								width: "100%",
								height: "40px",
								left: 0,
								bottom: 0,
								alignItems: "center",
								justifyContent: "space-between",
								flexDirection: "row",
								padding: "0 10px",
							}}>
							<div style={{ fontSize: "18px" }}>
								<span style={{ fontSize: "20px" }}>&lt;</span>返回
							</div>
							<div style={{ fontSize: "18px" }}>{shopName}</div>
							<div style={{ fontSize: "40px", lineHeight: "0px", height: "20px" }}>…</div>
						</div>
					</div>
					<div className='view-item-wrapper'>
						<iframe
							title='MicroWebsitePreview'
							src={microWebLink}
							className='preivew-iframe'
							frameBorder='0'
							scrolling='no'
							width='100%'
							height='580px'></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PreviewPart;
