import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Form,
  Table,
  Empty,
  Divider,
  Popconfirm,
  message,
} from "antd";
import PageControl from "../../components/PageControl";
import moment from "moment";
import _Api from "../../core/api";
import mathFunc from "../../core/mathfunc";
export default ({
  editOrder,
  scanOrder,
  tableState,
  getTableList,
  getTableListAll,
}) => {
  function handleComplete(data) {
    // console.log(data);
    data = {
      ...data,
      order_state: "已完工",
      end_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      part_content: JSON.parse(data.part_content),
      service_content: JSON.parse(data.service_content),
    };
    _Api.orderInsert(data).then((res) => {
      message.success(res.msg);
      getTableListAll();
    });
  }
  function handleDelete(id) {
    const data = { id };
    _Api.orderDelete(data).then((res) => {
      message.success(res.msg);
      getTableList();
    });
  }
  const columns = [
    {
      title: "序号",
      key: "index",
      width: "60px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "单号",

      align: "center",
      dataIndex: "bianhao",
      render: (text, record, index) => (
        <span
          className="theme-color click-able"
          onClick={() => {
            scanOrder(record, index);
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "车牌号",
      align: "center",
      width: "120px",
      dataIndex: "car_id",
    },
    {
      title: "车型",
      dataIndex: "car_type",
    },
    {
      title: "联系人",
      dataIndex: "car_linkman",
    },
    {
      title: "手机号码",
      dataIndex: "car_phone",
    },
    {
      title: "开单时间",
      dataIndex: "gmt_creat",
      key: "timeStart",
    },
    {
      title: "本次耗时",
      dataIndex: "gmt_creat",
      key: "duration",
      render: (text, record) => moment(text).fromNow(true),
    },
    {
      title: "总金额",
      // dataIndex: "part_content",
      dataIndex: "money_all",
      render: (text, record) => mathFunc.returnFloat(text),
      // {
      //   let partAr = JSON.parse(text) ? JSON.parse(text).ar : 0;
      //   let serviceAr = JSON.parse(record.service_content)
      //     ? JSON.parse(record.service_content).ar
      //     : 0;
      //   return (
      //     mathFunc.returnFloat(parseFloat(partAr) + parseFloat(serviceAr)) +
      //     "元"
      //   );
      // },
    },
    {
      title: "微信通知",
      dataIndex: "wechat",
      key: "wechat",
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "operation",
      key: "operation",
      render: (text, record, index) => (
        <span>
          <a
            onClick={() => {
              editOrder(record, index);
            }}
          >
            修改
          </a>
          <Divider type="vertical" />
          <Popconfirm
            placement="left"
            title="是否确认工单完工"
            onConfirm={() => handleComplete(record)}
            okText="确认"
            cancelText="取消"
          >
            <a>完工</a>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            placement="left"
            title="删除后无法恢复，是否确认删除？"
            onConfirm={() => handleDelete(record.id)}
            okText="确认"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        dataSource={tableState.list}
        columns={columns}
        bordered
        style={{ marginTop: "20px", marginBottom: "40px" }}
        pagination={false}
      />

      <PageControl
        total={tableState.total}
        pageSize={tableState.pageSize}
        current={tableState.pageNo}
        showSizeChanger
        toPage={(page, count) => {
          getTableList({ pageNo: page, pageSize: count });
        }}
      />
    </>
  );
};
