import { useState, useEffect, useContext } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Input, Button, Dropdown, Menu, Tooltip, Avatar } from "antd";
import { SaveTwoTone, UserOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import axios from "../../core/axios";
import User from "../../core/user";
import NewEditFastEntryModal from "../center/NewEditFastEntryModal";
import moment from "moment";
import $, { event } from "jquery";
import "./Header.less";
import "../../modules/search/BigSearch.less";
import logo from "../../libs/logo.png";
import logoBig from "../../libs/logo_02.png";
import _Api from "../../core/api";
import ShopContext from "../../core/context-manager";

const Header = ({ branch_info, user_info, web_set, user_list, toggleCollapsed}) => {
	const history = useHistory();
	const { setCarSearched } = useContext(ShopContext);
	const [state, setState] = useState({
		text: "",
		visible: false,
		selectedList: window.userPermissionList.filter((item) => item.is_show == 1 && item.parent != 0),
		collapsed: false,
		newEditFastEntryModal: null,
	});
	const [menuState, setMenuState] = useState({
		page: 1,
		dataSet: [],
		isAll: true,
	});
	const [focusState, setFocusState] = useState(false);
	let timer;
	useEffect(() => {
		document.title = branch_info.name;
	}, [branch_info]);
	useEffect(() => {
		if (state.text && state.visible) {
			console.log("触发carget", state.visible);
			clearTimeout(timer);
			timer = setTimeout(() => {
				getCarList(state.text);
			}, 300);
		}
	}, [state.text, state.visible]);

	function getMenu() {
		let arr = JSON.parse(user_info.menu_index) || [];
		let selectedList = window.userPermissionList.filter((item) => arr.indexOf(item.id) != -1);
		setState({ ...state, menu_index: arr, selectedList });
	}
	function handleToggle() {
		toggleCollapsed();
		setState({
			...state,
			collapsed: !state.collapsed,
		});
	}
	function handleEnter(text) {
		console.log("触发handleEnter", text);
		text = text ? text.replace(/^\s+/gm, "") : "";
		if (text) {
			setState({ ...state, text, visible: true });
		} else {
			setState({ ...state, text, visible: true });
			setMenuState({ ...menuState, dataSet: [] });
		}
	}
	function getCarList(text, page = "1") {
		const body = { keyword: text, page: page, count: 10 };
		_Api.carGet(body).then((res) => {
			const data = res.data;
			let cars = [];
			data.content.forEach((item) => {
				if (item.car_type) {
					let arr = item.car_type.split(" ");
					item.car_type = arr[0];
				}
				cars.push(item);
			});
			if (page === "1") {
				setMenuState({ page: data.curpage, dataSet: cars, isAll: data.content.length >= data.allcount });
			} else {
				cars = [...menuState.dataSet, ...cars];
				setMenuState({ ...state, page: data.curpage, dataSet: cars, isAll: page * 10 >= data.allcount });
			}
		});
	}

	function newEditEntry() {
		const { selectedList } = state;
		let newEditFastEntryModal = (
			<NewEditFastEntryModal
				selectedList={selectedList}
				totalItems={window.userPermissionList}
				onCancel={() => {
					setState({ ...state, newEditFastEntryModal: null });
				}}
				onOk={() => {
					setState({ ...state, newEditFastEntryModal: null });
					getMenu();
				}}
			/>
		);
		setState({ ...state, newEditFastEntryModal });
	}

	function logout() {
		axios.get("shop/login.php?cmd=logout").then((res) => {
			User.logout();
		});
	}

	function userChange(id) {
		axios.post("shop/user.php?cmd=user_change", { id, user_type: "shop" }).then((res) => {
			window.location.reload();
		});
	}
	const LeftMenu = () => {
		const { selectedList } = state;
		if (state.text) {
			return (
				<div>
					<Menu style={{ width: 380 }} className='seachStatic'>
						{menuState.dataSet.map((item, index) => (
							<Menu.Item
								style={{ padding: "0 16px" }}
								onClick={() => {
									setState({ ...state, visible: false, text: item.car_id });
									setCarSearched({ id: item.car_id, searched: false });
									if (window.location.href.indexOf("#/carmanagement") === -1) {
										history.push("carmanagement");
									}
								}}
								key={index}>
								{`${item.car_id} - ${item.linkman} - ${item.phone} - ${item.car_type}`}
							</Menu.Item>
						))}
					</Menu>
				</div>
			);
		} else {
			return (
				<Menu className='seachStatic' style={{ width: "380px" }}>
					<Menu.Item key='0'>
						<div className='title'>
							快捷入口
							<div onClick={newEditEntry} style={{ float: "right", cursor: "pointer" }}>
								<span className='icon iconfont'>&#xe661;</span>
							</div>
						</div>
						<div className='tagList'>
							{selectedList.map((item, index) => (
								<div
									onClick={() => {
										window.XZHistory.push(item.link);
										this.setState({ visible: false });
									}}
									key={index}
									className='tag'>
									{item.name}
								</div>
							))}
						</div>
					</Menu.Item>
				</Menu>
			);
		}
	};
	const branchMenu = (
		<Menu className='header-user-change'>
			{user_list.map((item, index) => (
				<Menu.Item key={index} onClick={userChange.bind(this, item.user_info.id)}>
					{item.branch_info.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const menu = (
		<Menu style={{ maxWidth: "250px", marginTop: "5px" }}>
			<Menu.Item key='0'>
				<Link to='/user_info'>个人账号</Link>{" "}
			</Menu.Item>
			<Menu.Item key='1' onClick={logout}>
				退出登录
			</Menu.Item>
		</Menu>
	);

	const { collapsed, newEditFastEntryModal } = state;
	return (
		<div id='top-container' className='top-container'>
			<div className='top top-nav'>
				<div
					style={{ cursor: "pointer" }}
					onClick={() => {
						window.XZHistory.push("/");
					}}>
					{/* {collapsed ? <img src={logo} className='logo' alt='' /> : <img src={web_set.logo} className='logo' alt='' />} */}
					{collapsed ? <img src={logo} className='logo' alt='' /> : <img src={logoBig} className='logo' alt='' />}
				</div>
				{collapsed ? (
					<span onClick={handleToggle} style={{ cursor: "pointer" }} className='icon iconfont cursor ml20  handLike'>
						&#xe7d3;
					</span>
				) : (
					<span onClick={handleToggle} style={{ cursor: "pointer" }} className='icon iconfont cursor ml20  handLike'>
						&#xe719;
					</span>
				)}

				<div style={{ position: "relative" }}>
					<div>
						<Dropdown
							style={{ width: 500 }}
							visible={state.visible}
							onVisibleChange={(visible) => setState({ ...state, visible })}
							overlay={<LeftMenu />}
							trigger={["click"]}>
							<div className={`bigSearch ${focusState ? "active" : ""}`}>
								<Input
									placeholder='搜索车牌号/车型/联系人/手机'
									prefix={<SearchOutlined />}
									suffix={
										state.text ? (
											<span
												style={{ color: "#8C8E93" }}
												onClick={(e) => {
													e.stopPropagation();
													setState({ ...state, text: "" });
												}}
												className='icon iconfont '>
												&#xe62b;
											</span>
										) : null
									}
									autoComplete='off'
									onFocus={() => {
										getMenu();
										setFocusState(true);
									}}
									onBlur={() => {
										setTimeout(() => {
											setFocusState(false);
										}, 300);
									}}
									value={state.text}
									onChange={(e) => handleEnter(e.target.value)}
								/>
							</div>
						</Dropdown>
					</div>
					{newEditFastEntryModal}
				</div>
				<div className='message-help'>
					<div className='inst-container'>
						<div className='inst'>
							<Tooltip title={branch_info ? branch_info.name : null}>
								<div className='name'>{branch_info ? branch_info.name : null}</div>
							</Tooltip>
							{user_list.length > 0 && (
								<Dropdown overlay={branchMenu} placement='bottomRight'>
									<i className='icon iconfont change'>&#xe70a;</i>
								</Dropdown>
							)}
						</div>
					</div>
					<div
						className='message-menu drop-menu'
						style={{ cursor: "pointer" }}
						onClick={() => {
							window.open("https://www.sanfeng9.com/");
						}}>
						<span style={{ color: "#939393" }} className='icon iconfont'>
							&#xe669;
						</span>
						<span style={{ marginLeft: "4px", color: "#333" }} className='icon-text'>
							官网首页
						</span>
					</div>
					<div
						className='message-menu drop-menu'
						onClick={() => {
							window.open("https://www.sanfeng9.com/help/");
						}}>
						<div className='help-menu drop-menu'>
							<div className='help-button'>
								<span className='icon iconfont'>&#xee7a;</span>
								<span className='icon-text'>帮助中心</span>
							</div>
						</div>
					</div>
					<Dropdown overlay={menu} className='user'>
						<div>
							<Avatar
								icon={<UserOutlined style={{ fontSize: "24px" }} />}
								size='default'
								src={user_info ? user_info.head_img : null}
							/>
							<span className='name'>{user_info ? user_info.name : "尚未注册"}</span>
						</div>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};

export default Header;
