import React, { Component } from "react";
import { Modal, Table,message } from "antd";
import axios from "../../core/axios";

import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";

import "./EditShowDataModal.less";

let dragingIndex = -1;

class BodyRow extends React.Component {
	render() {
		const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
		const style = { ...restProps.style, cursor: "move" };

		let { className } = restProps;
		if (isOver) {
			if (restProps.index > dragingIndex) {
				className += " drop-over-downward";
			}
			if (restProps.index < dragingIndex) {
				className += " drop-over-upward";
			}
		}

		return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />));
	}
}

const rowSource = {
	beginDrag(props) {
		dragingIndex = props.index;
		return {
			index: props.index,
		};
	},
};

const rowTarget = {
	drop(props, monitor) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Time to actually perform the action
		props.moveRow(dragIndex, hoverIndex);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	},
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))(
	DragSource("row", rowSource, (connect) => ({
		connectDragSource: connect.dragSource(),
	}))(BodyRow)
);

export class EditDataModal extends Component {
	state = {
		dataSet: [],
		loading: false,
		selectedList: [],
		unselectedList: [],
	};

	componentDidMount() {
		this.selectColumns();
	}

	selectColumns = () => {
		const body = {
			code: "",
		};
		const { dataIndex } = this.props;
		axios
			.post("shop/branch.php?cmd=get_branch_data", body)
			.then((res) => {
				let selectedList = dataIndex.map((id) => {
					return res.data.find((item) => item.code === id);
				});
				let unselectedList = res.data.filter((item) => dataIndex.indexOf(item.code) === -1);
				this.setState({ selectedList, unselectedList });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	onOK = () => {
		const { selectedList } = this.state;

		let arr = [];
		selectedList.map((item) => {
			arr.push(item.code);
		});
		if(arr.length <3 ) {
			return message.info("至少设置三项")
		}

		let body = {
			menu_code: arr,
			menu_type: "data",
		};
		axios
			.post("shop/branch.php?cmd=set_index_menu", body)
			.then((res) => {
				this.props.onOk();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		let { selectedList, unselectedList } = this.state;

		const selectedColumns = [
			{
				title: "数据名称",
				dataIndex: "name",
				width: "20%",
				render: (text, record) => <span>{record.title}</span>,
			},
			{
				title: "描述",
				dataIndex: "intro",
				width: "20%",
			},
			{
				title: "操作",
				dataIndex: "constrol",
				width: "10%",
				render: (text, record, index) => (
					<span
						onClick={() => {
							let [_selectedList, _unselectedList] = [selectedList, unselectedList];
							_selectedList.splice(index, 1);
							_unselectedList.push(record);
							this.setState({ unselectedList: [..._unselectedList], selectedList: [..._selectedList] });
						}}
						className='iconfont icon delete'>
						&#xe693;
					</span>
				),
			},
		];

		const unselectedColumns = [
			{
				title: "数据名称",
				dataIndex: "name",
				width: "20%",
				render: (text, record) => <span>{record.title}</span>,
			},
			{
				title: "描述",
				dataIndex: "intro",
				width: "20%",
			},
			{
				title: "操作",
				dataIndex: "constrol",
				width: "10%",
				render: (text, record, index) => (
					<span
						onClick={() => {
							let [_selectedList, _unselectedList] = [selectedList, unselectedList];
							_unselectedList.splice(index, 1);
							_selectedList.push(record);
							this.setState({ unselectedList: [..._unselectedList], selectedList: [..._selectedList] });
						}}
						className='iconfont icon add'>
						&#xe612;
					</span>
				),
			},
		];

		const components = {
			body: {
				row: DragableBodyRow,
			},
		};

		const moveRow = (dragIndex, hoverIndex) => {
			const { selectedList } = this.state;
			const dragRow = selectedList[dragIndex];

			this.setState(
				update(this.state, {
					selectedList: {
						$splice: [
							[dragIndex, 1],
							[hoverIndex, 0, dragRow],
						],
					},
				})
			);
		};

		return (
			<Modal
				title='自定义数据'
				visible={true}
				okText='保存'
				cancelText='取消'
				onOk={this.onOK}
				onCancel={this.props.onCancel}
				width={771}
				className='edit-data-modal'>
				<p style={{ marginTop: "4px" }}>
					已选数据<span style={{ color: "rgb(102, 102, 102)" }}>（点击拖动调整排序）</span>
				</p>
				<DndProvider backend={HTML5Backend}>
					<Table
						columns={selectedColumns}
						dataSource={selectedList}
						components={components}
						onRow={(record, index) => ({
							index,
							moveRow: moveRow,
						})}
						bordered
						pagination={false}
						size='middle'
						locale={{ emptyText: "请在下方选择需要查看的数据" }}
					/>
				</DndProvider>
				<p>可选数据</p>
				<Table
					dataSource={unselectedList}
					columns={unselectedColumns}
					bordered
					pagination={false}
					size='middle'
					locale={{ emptyText: "没有了" }}
				/>
			</Modal>
		);
	}
}
export default EditDataModal;
