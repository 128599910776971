import { useState, useEffect } from "react";
import { Link, Switch, Route } from "react-router-dom";
import _Api from "../../core/api";
import {
  Row,
  Col,
  Button,
  Table,
  Checkbox,
  Menu,
  Dropdown,
  Input,
  Form,
  InputNumber,
  Pagination,
  Tooltip,
  message,
  Modal,
  Tabs,
  DatePicker,
} from "antd";
import SearchBar from "../../components/SearchBar";
import OwedTable from "./OwedTable";
import PageControl from "../../components/PageControl";
import ReceiveModal from "../../components/modals/Receive";
import SlipDetailModal from "../../components/modals/SlipDetail";

const CreditManagement = () => {
  const [creditState, setCreditState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
  });
  const [modalState, setModalState] = useState({
    receiveV: false,
    curSlip: {},
    slipType: "",
    chargeV: false,
    slipDetailV: false,
  });
  useEffect(() => {
    getCreditList();
  }, []);

  function getCreditList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    let data = {
      keyword: creditState.keyword,
      count: pageSize,
      page: pageNo,
      order_state: "挂账",
    };
    _Api.orderSelect(data).then((res) => {
      let _data = res.data;
      setCreditState({
        ...creditState,
        list: _data.content,
        total: _data.allcount,
        pageNo: _data.curpage,
        pageSize: _data.pagecount,
      });
    });
  }
  return (
    <div className="template-student-index page settlemen">
      <div className="page-content">
        <section className="content-header">
          <h1>挂账管理</h1>
        </section>
        <div className="box">
          <div className="box-header">
            <Row>
              <Col span={8}>
                <SearchBar
                  label="搜索"
                  placeholder="请输入车牌号/联系人/手机号"
                />
              </Col>
            </Row>
          </div>
          <div className="box-body" style={{ marginTop: "20px" }}>
            <Row>
              <span
                className="icon iconfont theme-color"
                style={{ fontSize: "16px", marginRight: "5px" }}
              >
                &#xe60b;
              </span>
              <span className="theme-color" style={{ fontSize: "16px" }}>
                挂账列表
              </span>
            </Row>
            <OwedTable
              tableData={creditState}
              openDetail={(params) => {
                let { slipType, data } = params;
                data = {
                  ...data,
                  part_content: JSON.parse(data.part_content),
                  service_content: JSON.parse(data.service_content),
                  car_imgs: JSON.parse(data.car_imgs),
                };
                console.log(data);
                if (data.car_imgs instanceof Array) {
                  let str = data.car_imgs.join("");
                  if (str === "null") {
                    data.car_imgs = null;
                  }
                }
                if (data.car_imgs === "null") {
                  data.car_imgs = {
                    frontLeft: [],
                    frontRight: [],
                    backLeft: [],
                    backRight: [],
                    other: [],
                  };
                }
                setModalState({
                  ...modalState,
                  slipDetailV: true,
                  slipType,
                  curSlip: data,
                });
              }}
              openReceive={(data) => {
                data = {
                  ...data,
                  part_content: JSON.parse(data.part_content),
                  service_content: JSON.parse(data.service_content),
                  type: "挂账"
                };
                setModalState({ ...modalState, curSlip: data, receiveV: true });
              }}
            />
            {/* <Row justify="end">
              <span>欠款总额：1000元</span>
            </Row> */}
            {/* <PageControl total={creditState.total} /> */}
            <PageControl
              total={creditState.total}
              pageSize={creditState.pageSize}
              current={creditState.pageNo}
              showSizeChanger
              toPage={(page, count) => {
                getCreditList({ pageNo: page, pageSize: count });
              }}
            />
          </div>
        </div>
      </div>
      {/* 收款 弹框 */}
      <ReceiveModal
        visible={modalState.receiveV}
        closeModal={() => {
          setModalState({ ...modalState, receiveV: false });
        }}
        modalInfo={modalState.curSlip}
        handlePayOver={() => {
          setModalState({
            ...modalState,
            slipDetailV: false,
            curSlip: {},
            slipType: "",
            receiveV: false,
          });
          getCreditList();
        }}
        handleCharge={() => {
          setModalState({ ...modalState, chargeV: true });
        }}
      />
      <SlipDetailModal
        visible={modalState.slipDetailV}
        slipContent={modalState.curSlip}
        slipType={modalState.slipType}
        orderEdit={() => {}}
        openReceive={() => {
          setModalState({ ...modalState, receiveV: true });
        }}
        handleComplete={(data) => {}}
        cancelComplete={(data) => {
          // console.log(data);
          // data = { ...data, order_state: "进行中" };
          // _Api.orderInsert(data).then((res) => {
          // 	message.success(res.msg);
          // 	handleModalState({ slipDetailV: false, curSlip: {}, slipType: "" });
          // 	getOrderListAll();
          // });
        }}
        cancelPaid={(id) => {
          let data = { id };
          _Api.orderCaiwuDelete(data).then((res) => {
            message.success(res.msg);
            setModalState({
              ...modalState,
              slipDetailV: false,
              curSlip: {},
              slipType: "",
            });
            getCreditList();
          });
        }}
        closeModal={() =>
          setModalState({
            ...modalState,
            slipDetailV: false,
            curSlip: {},
            slipType: "",
          })
        }
      />
    </div>
  );
};
export default CreditManagement;
