import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Form, DatePicker } from "antd";
import moment from "moment";
const { TextArea } = Input;

//eslint-disable-next-line
export default ({ visible, closeModal, orderState, handleBaseState, handleOk }) => {
	const [nextMaintain, setNextMaintain] = useState({
		time: "",
		mileage: "",
		remark: "",
	});
	useEffect(() => {
		if (visible) {
			const { nextMaintainMileage, nextMaintainTime, nextMaintainRemark } = orderState;
			setNextMaintain({
				time: nextMaintainTime,
				mileage: nextMaintainMileage,
				remark: nextMaintainRemark,
			});
		}
	}, [visible]);
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};
	const { time, mileage, remark } = nextMaintain;
	return (
		<Modal title='下次保养说明' footer={null} width={500} visible={visible} onCancel={closeModal}>
			<Form {...layout} colon={false}>
				<Row>
					<Col span={12}>
						<Form.Item label='保养日期'>
							<DatePicker
								value={time && moment(time, "YYYY-MM-DD")}
								onChange={(mome, str) => {
									setNextMaintain({ ...nextMaintain, time: str });
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='保养里程'>
							<Input
								value={mileage}
								onChange={(e) => {
									setNextMaintain({ ...nextMaintain, mileage: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row type='flex' justify='space-between' align='middle'>
					<Col span={24}>
						<Form.Item label='保养内容' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
							<TextArea
								rows={4}
								value={remark}
								onChange={(e) => {
									setNextMaintain({ ...nextMaintain, remark: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row type='flex' justify='end' style={{ marginTop: "10px" }}>
					<Button
						type='primary'
						style={{ marginRight: "10px" }}
						onClick={() => {
							handleOk({ time, mileage, remark });
						}}>
						确定
					</Button>
					<Button
						onClick={() => {
							setNextMaintain({ time: "", mileage: "", remark: "" });
						}}>
						重置
					</Button>
				</Row>
			</Form>
		</Modal>
	);
};
