import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Divider,
  Popconfirm,
  message,
  Tabs,
  Pagination,
  Upload,
} from "antd";
import PublicTemplate from "./PublicTemplate";
import PublicTemplateActive from "./PublicTemplateActive";
import PageControl from "../../components/PageControl";
import ProgressBar from "../../components/modals/ProgressBar";
import ImportResults from "./ImportResults";
import Api from "../../core/api";
import axios from "axios";
import user from "../../core/user";
import "./index.less";
import OneDefault from "../../libs/import_one.png";
import HistoryEmpty from "../../libs/import_history_empty.png";
const { TabPane } = Tabs;
const source = axios.CancelToken.source();
let xhr = new XMLHttpRequest();
const ImportExport = () => {
  const [state, setState] = useState({
    cmd_type: "",
    log_type: "",
    selectList: [
      // { id: 1, title: "完工结算", active: false, cmd_type: "", log_type: "" },
      {
        id: 2,
        title: "服务项目",
        active: false,
        cmd_type: "service_up",
        log_type: "service",
      },
      {
        id: 3,
        title: "配件库存",
        active: false,
        cmd_type: "parts_up",
        log_type: "parts",
      },
      {
        id: 4,
        title: "车辆档案",
        active: false,
        cmd_type: "cars_up",
        log_type: "cars",
      },
    ],
    msg: "",
  });
  const [stateExport, setStateExport] = useState({
    cmd_type: "",
    log_type: "",
    selectList: [
      // { id: 1, title: "完工结算", active: false, cmd_type: "" },
      { id: 2, title: "服务项目", active: false, cmd_type: "service_download" },
      { id: 3, title: "配件库存", active: false, cmd_type: "parts_download" },
      { id: 4, title: "车辆档案", active: false, cmd_type: "cars_download" },
    ],
    msg: "",
  });
  const [progressData, setProgressData] = useState({
    percent: 0,
  });
  const [fileName, setFileName] = useState("");

  const [historyData, setHistoryData] = useState({
    list: [],
    total: 0,
    pageSize: "10",
    pageNo: "1",
  });
  const [activeKey, setActiveKey] = useState("1");
  const [visibleState, setVisibleState] = useState({
    importV: false,
    exportV: false,
    ResultsV: false,
  });
  useEffect(() => {
    if (state.log_type) {
      getHistoryData();
    } else {
      setHistoryData({
        ...historyData,
        list: [],
      });
    }
  }, [state.log_type]);
  function getHistoryData(data) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    const date = {
      count: pageSize,
      page: pageNo,
      log_type: state.log_type,
    };
    Api.importDataLog(date).then((res) => {
      console.log(res);
      const { allcount, content, curpage, pagecount } = res.data;
      setHistoryData({
        ...historyData,
        list: content,
        total: allcount,
        pageSize: pagecount,
        pageNo: curpage,
      });
    });
  }
  function handleVisible(data) {
    if (!data?.importV || !data?.exportV) {
      console.log("进度条清零");
      setProgressData({ percent: 0 });
    }
    setVisibleState({
      ...visibleState,
      ...data,
    });
  }
  function chooseType(data) {
    const { id, cmd_type, active, log_type } = data;
    if (activeKey === "1") {
      const list = state.selectList.map((item) => {
        if (item.id === id) {
          item.active = !active;
        } else {
          item.active = false;
        }
        return item;
      });
      if (!active) {
        setState({
          ...state,
          cmd_type,
          log_type,
          selectList: list,
        });
      } else {
        setState({
          ...state,
          cmd_type: "",
          log_type: "",
          selectList: list,
        });
      }
    } else if (activeKey === "2") {
      const list = stateExport.selectList.map((item) => {
        if (item.id === id) {
          item.active = !active;
        } else {
          item.active = false;
        }
        return item;
      });
      if (!active) {
        setStateExport({
          ...state,
          cmd_type,
          selectList: list,
        });
      } else {
        setStateExport({
          ...state,
          cmd_type: "",
          selectList: list,
        });
      }
    }
  }
  function downloadTemplate() {
    const a = document.createElement("a");
    switch (state.cmd_type) {
      case "service_up":
        a.href = "https://c.sanfeng9.com/ExcelTemplate/service_mod.xlsx";
        break;
      case "parts_up":
        a.href = "https://c.sanfeng9.com/ExcelTemplate/part_mod.xlsx";
        break;
      case "cars_up":
        a.href = "https://c.sanfeng9.com/ExcelTemplate/car_mod.xlsx";
        break;
      default:
        break;
    }
    a.click();
  }
  function uploadFiles() {}

  // 标签页改变
  function onChangeKey(key) {
    console.log(key);
    if (key === "1") {
      setActiveKey("1");
    }
    if (key === "2") {
      setActiveKey("2");
    }
  }
  function handleActiveKey(data) {
    setActiveKey(data);
  }
  function handleImportFile(info) {
    handleVisible({ importV: true });
    console.log(info);
    let files = info.file;
    let name = files.name;
    setFileName(name);
    let suffix = name.substr(name.lastIndexOf("."));
    if (".xls" != suffix && ".xlsx" != suffix) {
      return message.error("仅支持xls、xlsx格式文件");
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      cancelToken: source.token,
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent);
        let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
        setProgressData({
          percent: complete,
        });
      },
    };
    const formData = new FormData();
    formData.append("file", files);
    formData.append("cmd_type", state.cmd_type);
    axios
      .post(
        "https://api.sanfeng9.com/shop/data_import.php?cmd=data_creat&token=" +
          user.token(),
        formData,
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          setState({
            ...state,
            msg: res.data.msg,
          });
          getHistoryData();
          handleVisible({ importV: false, ResultsV: true });
        } else {
          message.error(res.data.msg);
          handleVisible({ importV: false });
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled", thrown.message);
          handleVisible({ importV: false });
        }
      });
  }
  function exportTemplate() {
    console.log("导出", stateExport);
    const config = {
      cancelToken: source.token,
    };
    const formData = new FormData();
    formData.append("cmd_type", stateExport.cmd_type);
    axios
      .post(
        "https://api.sanfeng9.com/shop/data_import.php?cmd=data_creat&token=" +
          user.token(),
        // "/api_image/shop/data_import.php?cmd=data_creat&token=" + user.token(),
        formData
      )
      .then((res) => {
        if (res.data.response === "200") {
          const URL = res.data.url;
          let a = document.createElement("a");
          a.href = URL;
          a.click();
          a.remove();
          // handleVisible({ exportV: true });
          // const name = URL.substring(URL.lastIndexOf("/") + 1);
          // setFileName(name);
          // downloadFile(URL, name);
          // async function downloadFile(fileUrl, fileName) {
          //   let blob = await getBlob(fileUrl);
          //   saveFile(blob, fileName);
          // }
          // function getBlob(fileUrl) {
          //   return new Promise((resolve) => {
          //     const xhr = new XMLHttpRequest();
          //     xhr.open("GET", fileUrl, true);
          //     //监听进度事件
          //     xhr.addEventListener(
          //       "progress",
          //       function (evt) {
          //         if (evt.lengthComputable) {
          //           let percentComplete = evt.loaded / evt.total;
          //           // percentage是当前下载进度，可根据自己的需求自行处理
          //           let percentage = percentComplete * 100;
          //           console.log(percentage);
          //         }
          //       },
          //       false
          //     );
          //     xhr.responseType = "blob";
          //     xhr.onload = () => {
          //       if (xhr.status === 200) {
          //         resolve(xhr.response);
          //       }
          //     };
          //     xhr.send();
          //   });
          // }
          // function saveFile(blob, fileName) {
          //   // ie的下载
          //   if (window.navigator.msSaveOrOpenBlob) {
          //     navigator.msSaveBlob(blob, fileName);
          //   } else {
          //     // 非ie的下载
          //     const link = document.createElement("a");
          //     const body = document.querySelector("body");

          //     link.href = window.URL.createObjectURL(blob);
          //     link.download = fileName;

          //     // fix Firefox
          //     link.style.display = "none";
          //     body.appendChild(link);

          //     link.click();
          //     body.removeChild(link);

          //     window.URL.revokeObjectURL(link.href);
          //   }
          // }
          // const index = URL.lastIndexOf("com");
          // const str = URL.substring(index + 3);
          // // const downloadUrl = URL;
          // const downloadUrl = "/api_image" + str;
          // xhr.open("GET", downloadUrl, true);
          // xhr.addEventListener(
          //   "progress",
          //   function (event) {
          //     if (event.lengthComputable) {
          //       let percentComplete = (event.loaded / event.total) * 100;
          //       setProgressData({
          //         percent: percentComplete,
          //       });
          //       console.log(percentComplete); // 最后输出1;
          //     }
          //   },
          //   false
          // );
          // xhr.onload = function (e) {
          //   console.log(e, xhr);
          //   if (xhr.readyState === 4 && xhr.status === 200) {
          //     // let complete = ((e.loaded / e.total) * 100) | 0;
          //     // setProgressData({
          //     //   percent: complete,
          //     // });
          //     const URL = xhr.responseURL;
          //     console.log(URL);
          //     let a = document.createElement("a");
          //     a.href = URL;
          //     a.click();
          //     a.remove();
          //   }
          // };
          // xhr.send();
        }
      });
  }
  function canCelAxios() {
    source.cancel("取消导入");
  }
  function canCelXhr() {
    xhr.abort();
  }
  function beforeUpload(file) {
    console.log(file);
    const str = file.name.split(".");
    console.log(str);
    if (str[1] !== "xls" && str[1] !== "xlsx") {
      message.warning("仅支持xls、xlsx格式文件");
      return false;
    }
    return true;
  }
  function onChange(info) {
    console.log(info);
    const event = info.event;
    if (event) {
      // 一定要加判断，不然会报错
      let percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ ...state, percent });
      console.log(percent); // percent就是进度条的数值
    }
  }
  function filterType(type) {
    switch (type) {
      // case "cars":
      //   return "完工结算";
      case "service":
        return "服务项目";
      case "parts":
        return "配件库存";
      case "cars":
        return "车辆档案";
      default:
        return "";
    }
  }
  console.log(state);
  console.log(stateExport);
  console.log(visibleState);

  return (
    <div className="page import-export-page">
      <div className="page-content">
        <section className="content-header">
          <h1>数据导入导出</h1>
        </section>
        <div className="box package-seeting" style={{ padding: 0 }}>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            onChange={onChangeKey}
            type="card"
          >
            <TabPane tab="批量导入" key="1" style={{ padding: "20px 20px 0" }}>
              <div className="batch-import">
                <div className="batch-type">
                  <div className="batch-title">
                    <img src={OneDefault} alt="" />
                    <span>选择数据类型</span>
                  </div>
                  {state.selectList.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          item.active ? "batch-item active" : "batch-item"
                        }
                        onClick={() => chooseType(item)}
                      >
                        {item.title}
                        {item.active && (
                          <div className="batch-active-img"></div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="batch-next"></div>
                {state.cmd_type ? (
                  <PublicTemplateActive
                    type="download"
                    title="下载导入模版，按格式填写"
                  >
                    <button onClick={downloadTemplate}>下载模板</button>
                  </PublicTemplateActive>
                ) : (
                  <PublicTemplate
                    type="download"
                    title="下载导入模版，按格式填写"
                  >
                    <button
                      onClick={() => {
                        message.warning("请先选择数据类型");
                      }}
                    >
                      下载模板
                    </button>
                  </PublicTemplate>
                )}

                <div className="batch-next"></div>
                {state.cmd_type ? (
                  <PublicTemplateActive
                    type="upload"
                    title="上传填好的excle文件自动导入"
                  >
                    <Upload
                      name="file"
                      accept=".xls,.xlsx"
                      action="#"
                      maxCount={1}
                      showUploadList={false}
                      // beforeUpload={beforeUpload}
                      // onChange={onChange}
                      customRequest={handleImportFile}
                    >
                      <Button>上传文件</Button>
                    </Upload>
                    {/* <button onClick={uploadFiles}>上传文件</button> */}
                  </PublicTemplateActive>
                ) : (
                  <PublicTemplate
                    type="upload"
                    title="上传填好的excle文件自动导入"
                  >
                    <button
                      onClick={() => {
                        message.warning("请先选择数据类型");
                      }}
                    >
                      上传文件
                    </button>
                  </PublicTemplate>
                )}
              </div>
              <div className="batch-import-history">
                <div className="history-title">
                  <div className="xian"></div>
                  <h4>导入历史</h4>
                </div>
                {historyData.list?.length < 1 ? (
                  <div className="history-empty">
                    <img src={HistoryEmpty} alt="" />
                    <p>暂无数据～</p>
                  </div>
                ) : (
                  <div className="history-content">
                    <div className="content-box">
                      {historyData.list.map((item, index) => {
                        return (
                          <div key={item.id} className="content-item">
                            <span>{index + 1}</span>
                            <p>{`${item.name}于${
                              item.gmt_modify
                            }批量导入【${filterType(item.log_type)}】，${
                              item.log_content
                            }；`}</p>
                          </div>
                        );
                      })}
                    </div>
                    <PageControl
                      total={historyData.total}
                      pageSize={historyData.pageSize}
                      current={historyData.pageNo}
                      showSizeChanger
                      toPage={(pageNo, pageSize) => {
                        console.log(pageNo, pageSize);
                        getHistoryData({ page: pageNo, pages: pageSize });
                      }}
                    />
                  </div>
                )}
              </div>
            </TabPane>
            <TabPane tab="批量导出" key="2" style={{ padding: "20px 20px 0" }}>
              <div className="batch-export">
                <div className="batch-type">
                  <div className="batch-title">
                    <img src={OneDefault} alt="" />
                    <span>选择数据类型</span>
                  </div>
                  {stateExport.selectList.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          item.active ? "batch-item active" : "batch-item"
                        }
                        onClick={() => chooseType(item)}
                      >
                        {item.title}
                        {item.active && (
                          <div className="batch-active-img"></div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="batch-next"></div>
                {stateExport.cmd_type ? (
                  <PublicTemplateActive
                    type="export"
                    title="点击导出按钮导出全部工单"
                    isTrue={true}
                  >
                    <button onClick={exportTemplate}>导出</button>
                  </PublicTemplateActive>
                ) : (
                  <PublicTemplate
                    type="export"
                    title="点击导出按钮导出全部工单"
                    isTrue={true}
                  >
                    <button
                      onClick={() => {
                        message.warning("请先选择数据类型");
                      }}
                    >
                      导出
                    </button>
                  </PublicTemplate>
                )}
              </div>
            </TabPane>
          </Tabs>
          <ProgressBar
            visible={visibleState.importV}
            progress={progressData}
            title={fileName}
            type="import"
            canCelAxios={canCelAxios}
          ></ProgressBar>
          <ProgressBar
            visible={visibleState.exportV}
            progress={progressData}
            title={fileName}
            type="export"
            canCelAxios={canCelXhr}
            canCel={() => {
              handleVisible({ exportV: false });
            }}
          ></ProgressBar>
          <ImportResults
            visible={visibleState.ResultsV}
            msg={state.msg}
            handleVisible={handleVisible}
          ></ImportResults>
        </div>
      </div>
    </div>
  );
};
export default ImportExport;
