import { Table, Divider, Popconfirm,Empty } from "antd";
import mathFunc from "../../core/mathfunc";
const PackageTable = ({
  TableData,
  selectType,
  handleEdit,
  handleDelete,
  handleSelect,
  deault,
}) => {
  const columns = [
    {
      title: "序号",
      key: "index",
      align: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "套餐名称",
      dataIndex: "name",
      align: "left",
    },
    {
      title: "套餐售价",
      dataIndex: "price",
      align: "left",
    },
    {
      title: "赠送金额",
      dataIndex: "price_zs",
      align: "left",
    },
    {
      title: "项目折扣",
      dataIndex: "service_zk",
      align: "left",
      render: (text) => mathFunc.getPercent(text) + "%",
    },
    {
      title: "配件折扣",
      dataIndex: "part_zk",
      align: "left",
      render: (text) => mathFunc.getPercent(text) + "%",
    },
    {
      title: "套餐说明",
      dataIndex: "remark",
      width: "200px",
      align: "left",
      render: (text) => (
        <span
          style={{
            display: "inline-block",
            width: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text ? text : "--"}
        </span>
      ),
    },
    {
      title: "操作",
      width: "150px",
      key: "operation",
      align: "left",
      render: (text, record, index) =>
        selectType ? (
          <span
            style={{ color: "#FF9551" }}
            className="click-able"
            onClick={() => handleSelect(record)}
          >
            选择
          </span>
        ) : (
          <>
            <span
              className="theme-color click-able"
              onClick={() => {
                handleEdit(record);
              }}
            >
              修改
            </span>
            <Divider type="vertical" />
            <Popconfirm
              placement="left"
              title="删除后无法恢复，是否确认删除？"
              okText="确认"
              cancelText="取消"
              onConfirm={() => {
                handleDelete(record.id);
              }}
            >
              <span style={{ color: "#EF0000" }} className="click-able">
                删除
              </span>
            </Popconfirm>
          </>
        ),
    },
  ];
  return (
    <Table
      rowKey={(record) => record.id}
      pagination={false}
      dataSource={TableData ? TableData : []}
      columns={columns}
      bordered
      style={{ marginBottom: "30px" }}
      locale={
        selectType
          ? {}
          : deault
          ? { emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
          : { emptyText: "请点击表格右上方按钮新增套餐" }
      }
    />
  );
};
export default PackageTable;
