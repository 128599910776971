import { useState, useMemo } from "react";
import { Radio, Table } from "antd";
import "./Rank.less";
import first from "../../libs/2.png";
import second from "../../libs/3.png";
import three from "../../libs/4.png";
const Rank = ({ rankData, type, handleTypeChange }) => {
	const columns = [
		{
			title: <span style={{ fontSize: "18px", color: "#b5b5b5" }}>排名</span>,
			dataIndex: "rank",
			align: "center",
			width: "80px",
			render: (text, record, index) => (
				<span>
					{index == 0 && <img src={first} alt='' />}
					{index == 1 && <img src={second} alt='' />}
					{index == 2 && <img src={three} alt='' />}
					{index != 0 && index != 1 && index != 2 && (
						<span style={{ fontSize: "18px", color: "#999" }}>{index + 1}</span>
					)}
				</span>
			),
		},
		{
			title: <span style={{ fontSize: "18px", color: "#b5b5b5" }}>分店</span>,
			dataIndex: "name",
			align: "center",
			ellipsis: true,
			render: (text) => <span style={{ fontSize: "18px", color: "#999" }}>{text}</span>,
		},
		{
			title: <span style={{ fontSize: "18px", color: "#b5b5b5" }}>{type === "num" ? "进场车辆" : "本月实收"}</span>,
			dataIndex: type === "num" ? "all_cars" : "all_income",
			align: "center",
			width: "120px",
			render: (text) => <span style={{ fontSize: "18px", color: "#999" }}>{text}</span>,
		},
	];

	return (
		<div className='product-dynamic-list'>
			<div className='top'>
				<div className='name'>分店排行</div>
			</div>
			<Radio.Group size='large' value={type} onChange={handleTypeChange} style={{ marginBottom: 16 }}>
				<Radio.Button value='num'>进场车辆</Radio.Button>
				<Radio.Button value='income'>本月实收</Radio.Button>
			</Radio.Group>
			<Table
				rowKey={(record) => record.id}
				size='middle'
				columns={columns}
				bordered={false}
				dataSource={rankData}
				pagination={false}
				scroll={{ y: 260 }}
			/>
		</div>
	);
};

export default Rank;
