import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Avatar, Input, Row, Col, Button, message, Modal, Popover } from "antd";
import axios from "../../core/axios";
import CropperModal from "../../components/CropperModal";
import "./UserInfo.less";
import CheckBeforeSendCode from "../root/CheckBeforeSendCode";
import _ from "underscore";
import ShopContext from "../../core/context-manager";
import { UserOutlined } from "@ant-design/icons";

const UserInfo = ({}) => {
	const { userInfo, resetShopInfo } = useContext(ShopContext);
	const timerRef = useRef();

	const [state, setState] = useState({
		edit_flag: false,
		tip_flag: false,
		button_click: false,
		button_text: "获取验证码",
		certificate: "", //短信验证返回的凭证
		checkObject: {},
		openCheck: false,
	});
	const [veriState, setVeriState] = useState({
		regWaitStatus: false, // 验证码是否在倒计时
		timerStr: "获取验证码",
	});
	const [inputState, setInputState] = useState({
		code: "",
		new_password: "",
	});

	function changeEdit() {
		setState({
			...state,
			edit_flag: false,
		});
	}
	function handleOk() {
		const { code, new_password } = inputState;
		const { certificate } = state;
		const body = {
			phone: userInfo.phone,
			password: new_password,
			s_id: certificate,
			code,
		};
		axios
			.post("shop/user.php?cmd=password_modify", body)
			.then((res) => {
				if (res.response == 200) {
					message.success(res.msg);
					resetShopInfo();
					clearTimeout(timerRef.current);
					setState({
						...state,
						timerStr: "获取验证码",
						edit_flag: false,
					});
					setInputState({
						new_password: undefined,
						code: undefined,
					});
				} else {
					Modal.warning({
						title: "提示信息",
						content: res.msg,
						maskClosable: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function timeSub(waitTime, unit) {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(function () {
			if (waitTime === 0) {
				setVeriState({
					timerStr: "发送验证码",
					regWaitStatus: false,
				});
				clearTimeout(timerRef.current);
			} else {
				setVeriState({
					timerStr: `${waitTime}秒后重发`,
					regWaitStatus: true,
				});
				timeSub(--waitTime, 1000);
			}
		}, unit || 0);
	}

	function handleSendCode(checkData) {
		// console.log(checkData);
		const { randstr, ret, ticket } = checkData;
		if (state.regWaitStatus) {
			return;
		}
		if (ret != 0) {
			return message.error("网络错误，请稍后重试");
		}
		const params = {
			phone: userInfo.phone,
			Randstr: randstr,
			Ticket: ticket,
			sms_type: "",
		};
		axios.postJSON("shop/login.php?cmd=send_sms_code", params).then((res) => {
			if (res.response == 200) {
				message.success(res.msg);
				timeSub(60);
				setState({
					...state,
					certificate: res.s_id,
				});
			} else {
				message.warning(res.msg);
			}
		});
	}

	function handleConfirm() {
		try {
			const tencentc = new window.TencentCaptcha("2007156257", handleSendCode);
			tencentc.show();
		} catch {
			console.log("载入失败");
		}
	}
	const layout = {
		labelCol: { span: 2 },
	};
	const { code, new_password } = inputState;
	const { openCheck } = state;
	const { regWaitStatus, timerStr } = veriState;
	return (
		<div className='page user-info'>
			<div className='page-content'>
				<section className='content-header'>
					<h1>个人信息</h1>
				</section>
			</div>
			<div className='box first'>
				<Form {...layout}>
					<header className='title'>个人信息</header>
					<Form.Item label='个人头像'>
						<span id='avatar_edit'>
							<span className='up-load-content'>
								<span
									className='upload'
									style={{
										width: "48px",
										height: "48px",
										borderRadius: "50%",
									}}>
									<Avatar
										style={{
											width: "48px",
											height: "48px",
											borderRadius: "50%",
										}}
										src={userInfo.head_img}
										size='large'
										icon={<UserOutlined style={{ fontSize: "24px" }} />}
									/>
								</span>
								<div
									className='edit'
									style={{
										width: "48px",
										height: "48px",
										borderRadius: "50%",
									}}
									onClick={() => {
										setState({ ...state, visible: true });
									}}>
									<span className='icon iconfont'>&#xe60c;</span>
								</div>
							</span>
						</span>
						<CropperModal
							type={"user"}
							visible={state.visible}
							id={userInfo.id}
							src={userInfo.head_img}
							onHandleOk={() => {
								setState({ ...state, visible: false });
							}}
							onHandleCancel={() => {
								setState({ ...state, visible: false });
							}}
							onHandleSuccess={() => {
								setState({ ...state, visible: false });
								resetShopInfo();
							}}
						/>
					</Form.Item>
					<Form.Item label='姓名'>
						<span>{userInfo.name}</span>
					</Form.Item>
				</Form>
			</div>
			<div className='box'>
				<header className='title'>账号与密码</header>
				<Form {...layout}>
					<Form.Item label='手机号'>
						<span>{userInfo.phone}</span>
					</Form.Item>
					{state.edit_flag ? (
						<>
							<Form.Item label='验证码'>
								<Input
									value={code}
									autoComplete='off'
									placeholder='请输入验证码'
									onChange={(e) =>
										setInputState({
											...inputState,
											code: e.target.value,
										})
									}
									style={{ width: "240px" }}
								/>
								{/* <Popover
									visible={openCheck}
									trigger='click'
									title=''
									content={
										<div>
											<span
												style={{
													fontSize: "12px",
													color: "#999",
													marginBottom: 8,
													display: "block",
												}}>
												请完成安全验证
											</span>
											<CheckBeforeSendCode
												callback={(data, nc) => {
													handleSendCode(data);
													setState({
														...state,
														checkObject: nc,
													});
													setTimeout(() => {
														setState({
															...state,
															openCheck: false,
														});
													}, 500);
												}}
											/>
										</div>
									}
									onVisibleChange={(value) => {
										if (!value) {
											setState({
												...state,
												openCheck: false,
											});
										}
									}}
									placement='bottomRight'>
									<Button
										id='send_verify_codes_btn'
										style={{ marginLeft: "10px" }}
										onClick={() => {
											if (checking) return;
											handleConfirm();
										}}>
										{timerStr}
									</Button>
								</Popover> */}
								<Button
									id='send_verify_codes_btn'
									style={{ marginLeft: "10px" }}
									onClick={() => {
										if (regWaitStatus) {
											return;
										}
										handleConfirm();
									}}>
									{timerStr}
								</Button>
							</Form.Item>
							<Form.Item label='新密码'>
								<Input.Password
									value={new_password}
									autoComplete='new-password'
									placeholder='6-16位数字或字母，区分大小写'
									onChange={(e) =>
										setInputState({
											...inputState,
											new_password: e.target.value,
										})
									}
									style={{ width: "240px" }}
								/>
							</Form.Item>
							<Form.Item>
								<Col span={2}></Col>
								<Button type='primary' id='save_new_password_btn' onClick={handleOk}>
									保存
								</Button>
								<Button id='cancel_new_password_btn' style={{ marginLeft: "16px" }} onClick={changeEdit}>
									取消
								</Button>
							</Form.Item>
						</>
					) : (
						<Form.Item label='登陆密码'>
							<div>
								******
								<Button
									id='reedit_password_btn'
									style={{ marginLeft: "10px" }}
									onClick={() => setState({ ...state, edit_flag: true })}>
									重设密码
								</Button>
							</div>
						</Form.Item>
					)}
				</Form>
			</div>
		</div>
	);
};

export default UserInfo;
