import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";

const VipSelectStored = ({ visible, closeModal, handleModalState, carId }) => {
  const [vipState, setVipState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    time_over: "200",
    package_type: "储值卡",
  });

  useEffect(() => {
    if (visible && vipState.keyword === "") {
      getVipList();
    }
  }, [vipState.keyword, visible]);
  function getVipList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    let data = {
      keyword: vipState.keyword,
      car_id: carId,
      count: pageSize,
      page: pageNo,
      time_over: "200",
      package_type: vipState.package_type
    };
    _Api.cardSelect(data).then((res) => {
      let vipList = res.data.content;
      setVipState({
        ...vipState,
        list: vipList,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
      });
    });
  }

  const columns = [
    {
      title: "会员卡号",
      dataIndex: "bianhao",
      render: (text, record) =>
        record.last_use ? (
          <span>
            {text}
            <span
              className="theme-color"
              style={{ marginLeft: "5px", fontSize: "8px" }}
            >
              {record.last_use}
            </span>
          </span>
        ) : (
          text
        ),
    },
    {
      title: "会员姓名",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "套餐",
      width: 260,
      dataIndex: "package_name",
    },
    {
      title: "余额",
      dataIndex: "left_money",
      align: "center",
    },

    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "操作",
      dataIndex: "id",
      align: "center",
      render: (text, record) => (
        <a
          onClick={() => handleModalState({ vipDetailV: true, curVip: record })}
        >
          选择
        </a>
      ),
    },
  ];
  return (
    <Modal
      title="选择会员卡"
      zIndex={999}
      footer={null}
      width={1000}
      visible={visible}
      onCancel={closeModal}
    >
      <Row type="flex" align="middle">
        <Col span={12}>
          <Input
            placeholder="卡号/姓名/手机号码"
            value={vipState.keyword}
            onChange={(e) => {
              setVipState({ ...vipState, keyword: e.target.value });
            }}
          />
        </Col>
        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          onClick={getVipList}
        >
          查询
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          onClick={() => {
            setVipState({ ...vipState, keyword: "" });
          }}
        >
          清除
        </Button>
      </Row>

      <Table
        rowKey={(record) => record.id}
        pagination={false}
        dataSource={vipState.list}
        columns={columns}
        bordered
        style={{ marginTop: "20px", marginBottom: "40px" }}
      />
      <PageControl
        total={vipState.total}
        pageSize={vipState.pageSize}
        current={vipState.pageNo}
        showSizeChanger
        toPage={(page, count) => {
          getVipList({ pageNo: page, pageSize: count });
        }}
      />
    </Modal>
  );
};
export default VipSelectStored;
