import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal, Table, message, Divider, Popconfirm } from "antd";

import DragableBodyRow from "../../components/DragableBodyRow";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import _Api from "../../core/api";
import WebItemEditModal from "../../components/modals/WebItemEdit";
import $ from "jquery";

const ServiceSet = ({ refreshFrame, team }) => {
	const [data, setData] = useState([]);
	const [modalState, setModalState] = useState({ visible: false, editType: false, curItem: {} });
	useEffect(() => {
		getInfo();
	}, []);
	function getInfo() {
		const body = {
			type: team ? "team" : "service",
		};
		_Api.webSelect(body).then((res) => {
			let data = res.data.map((item, index) => ({
				id: item.id,
				name: item.name,
				img: item.image,
				description: item.description,
				detail: item.detail_content,
				content_img: JSON.parse(item.content_img),
			}));
			setData(data);
		});
	}

	function handleDelete(id) {
		_Api.webDelete({ id }).then((res) => {
			message.success(res.msg);
			getInfo();
			refreshFrame();
		});
	}
	const columns = [
		{
			title: "编号",
			key: "index",
			width: "10%",
			dataIndex: "id",
		},
		{
			title: "图片",
			dataIndex: "img",
			width: "20%",
			align: "center",
			render: (text) =>
				text ? (
					<div className='img-wrap' style={{ overflowY: "auto", maxHeight: "80px", width: "100%" }}>
						<img src={text} alt='' width={"100%"} />
					</div>
				) : null,
		},
		{
			title: team ? "团队名称" : "项目名称",
			dataIndex: "name",
		},
		{
			title: "简介",
			dataIndex: "description",
		},
		{
			title: "操作",
			key: "operation",
			dataIndex: "id",
			render: (text, record) => (
				<>
					<span
						className='click-able theme-color'
						onClick={() => {
							setModalState({ ...modalState, editType: true, visible: true, curItem: record });
						}}>
						编辑
					</span>
					<Divider type='vertical' />
					<Popconfirm
						placement='left'
						title='删除后无法恢复，是否确认删除'
						onConfirm={() => {
							handleDelete(text);
						}}
						okText='确认'
						cancelText='取消'>
						<span className='click-able theme-color'>删除</span>
					</Popconfirm>
				</>
			),
		},
	];
	const components = {
		body: {
			row: DragableBodyRow,
		},
	};
	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = data[dragIndex];
			let _data = update(data, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRow],
				],
			});
			setData(_data);

			const order = _data.map((item) => item.id);
			let body = { type: team ? "team" : "service", ids: order };
			_Api.webOrders(body).then((res) => {
				message.success(res.msg);
				refreshFrame();
			});
		},
		[data]
	);
	return (
		<div className='setting-page'>
			<div className='setting-header'>
				<Button
					type='primary'
					onClick={() => {
						setModalState({ ...modalState, visible: true });
					}}>
					{team ? "添加团队" : "添加项目"}
				</Button>
				{team ? "（最多添加10个团队，拖动进行排序）" : "（最多添加10个项目，拖动进行排序）"}
			</div>
			<div className='setting-body'>
				<DndProvider backend={HTML5Backend}>
					<Table
						className='carousel-table'
						columns={columns}
						dataSource={data}
						rowKey={(record) => record.id}
						components={components}
						onRow={(record, index) => ({
							index,
							moveRow: moveRow,
						})}
						bordered
						pagination={false}
						size='middle'
						locale={{ emptyText: team ? "请点击‘添加团队’按钮进行添加" : "请点击‘添加项目’按钮进行添加" }}
					/>
				</DndProvider>
			</div>
			{modalState.visible && (
				<WebItemEditModal
					itemType={team ? "team" : "service"}
					visible={modalState.visible}
					editType={modalState.editType}
					curItem={modalState.curItem}
					closeModal={() => {
						setModalState({ ...modalState, visible: false, editType: false, curItem: {} });
					}}
					handleComplete={() => {
						setModalState({ ...modalState, visible: false, editType: false, curItem: {} });
						getInfo();
						refreshFrame();
					}}
				/>
			)}
		</div>
	);
};

export default ServiceSet;
