import { useEffect, useState, useCallback } from "react";
import { Link, Switch, Route, useHistory } from "react-router-dom";
import { Row, Col, Tabs, DatePicker, message, Button } from "antd";
import SearchBar from "../../components/SearchBar";
import StaticSelect from "../../components/StaticSelect";
import WorkingTable from "./WorkingTable";
import FinishedTable from "./FinishedTable";
import PaidTable from "./PaidTable";
import SlipDetailModal from "../../components/modals/SlipDetail";
import OrderDetailModal from "../../components/modals/OrderDetail";
import CarSelectModal from "../../components/modals/CarSelect";
import VipSelectModal from "../../components/modals/VipSelect";
import VipDetailModal from "../../components/modals/VipDetail";
import CarEditModal from "../../components/modals/CarEdit";
import MaintenanceInfoModal from "../../components/modals/MaintenanceInfo";
import ServeSelectModal from "../../components/modals/ServeSelect";
import ServeAddModal from "../../components/modals/ServeAdd";
import PartSelectModal from "../../components/modals/PartSelect";
import PartAddModal from "../../components/modals/PartEdit";
// import ReceiveModal from "../../components/modals/Receive";
// import VipSelectModal from "../../components/modals/VipSelect";
import ReceiveCompleted from "../../components/modals/ReceiveCompleted";
import MemberCardSelect from "../../components/modals/MemberCardSelect";
import moment from "moment";
import VipChargeModal from "../../components/modals/VipCharge";
import ReceivePrintModal from "../../components/modals/ReceivePrint";
import CarImage from "../../components/modals/CarImage";
import _Api from "../../core/api";
import imageFu from "../../core/imageFu";
import mathFunc from "../../core/mathfunc";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const Settlemen = ({}) => {
  const [orderState, setOrderState] = useState({
    keyword: "",
    startDate: "",
    endDate: "",
    tableKey: "1",

    detailFlag: false,

    workingState: {
      list: [],
      total: "",
      allPage: "",
      pageNo: "1",
      pageSize: "10",
    },
    finishedState: {
      list: [],
      total: "",
      allPage: "",
      pageNo: "1",
      pageSize: "10",
    },
    paidState: {
      list: [],
      total: "",
      allPage: "",
      pageNo: "1",
      pageSize: "10",
    },
  });
  const [modalState, setModalState] = useState({
    orderDetailV: false,
    orderScan: false,
    curOrderBasic: {
      id: "",
      bianhao: "",
      carId: "",
      carType: "",
      linkman: "",
      phone: "",
      curMileage: "",
      memberCard: "",
      partZk: 1,
      serviceZk: 1,
      nextMaintainMileage: "",
      nextMaintainTime: "",
      nextMaintainRemark: "",
      worker: "",
      car_imgs: {
        frontLeft: [],
        frontRight: [],
        backLeft: [],
        backRight: [],
        other: [],
      },
    },
    curServiceContent: { ids: [], data: [], amount: 0, ar: 0 },
    curPartContent: { ids: [], data: [], amount: 0, ar: 0 },
    slipDetailV: false,
    curSlip: {},
    curSlipSet: {},
    slipType: "",
    receiveV: false,
    chargeV: false,
    carSelectV: false,
    carAddV: false,
    carEditType: false,
    carImageV: false,
    vipSelectV: false,
    vipDetailV: false,
    curVip: {},
    maintenanceV: false,
    serviceSelectV: false,
    serviceAddV: false,
    partSelectV: false,
    partAddV: false,
    printContent: {},
    printV: false,
    installV: false,
  });
  const [modal, setModal] = useState({
    packageV: false,
    vipDetailV: false,
    selectedPackage: {}, // 选择会员卡回填
    selectMelber: {}, // 确定之后会员卡信息
  });

  const [workingImage, setWokingImage] = useState([]);
  const uploadWorking = (data) => {
    console.log("---1---111");
    const list = orderState.workingState.list;
    const newList = list.map((item) => {
      if (item.car_imgs.frontLeft) {
        return item;
      } else {
        console.log("解析啦");
        console.log(JSON.parse(item.car_imgs));
        // item.car_imgs = JSON.parse(item.car_imgs);
        // console.log(item.car_imgs);
        item.car_imgs = {
          frontLeft: [],
          frontRight: [],
          backLeft: [],
          backRight: [],
          other: [],
          ...JSON.parse(item.car_imgs),
        };
        return item;
      }
    });
    setWokingImage(newList);
  };
  const [orderIndex, setorderIndex] = useState(0);
  const [keyIndex, setKeyIndex] = useState("1");
  const history = useHistory();
  const uploadOrderIndex = (data) => {
    setorderIndex(data);
  };
  useEffect(() => {
    let str = history.location.search;
    console.log(str[5]);
    if (str) {
      console.log("查看结算单");
      setKeyIndex(str[5]);
    }
  }, [history]);
  useEffect(() => {
    uploadWorking();
  }, [orderState.workingState]);
  // 修改图片
  const handleBaseCarImgs = (data, types) => {
    // console.log("改变图片", data, types);
    // let arr = workingImage;
    // console.log(arr, orderIndex);
    // if (type === "other") {
    //   arr[orderIndex].car_imgs.other = data;
    // } else {
    //   arr[orderIndex].car_imgs[type] = data;
    // }
    // setWokingImage(arr);
    setModalState({
      ...modalState,
      curOrderBasic: {
        ...modalState.curOrderBasic,
        car_imgs: { ...modalState.curOrderBasic.car_imgs, [types]: data },
      },
    });
  };
  const handleCarImgs = (data) => {
    // console.log("确定上传图片", data);
    // let arr = workingImage;
    // arr[orderIndex].car_imgs = data;
    // setWokingImage(arr);
    setModalState({
      ...modalState,
      curOrderBasic: { ...modalState.curOrderBasic, car_imgs: data },
    });
  };

  useEffect(() => {
    getOrderListAll();
  }, [orderState.startDate, orderState.endDate]);

  useEffect(() => {
    if (orderState.detailFlag && !modalState.orderDetailV) {
      const { pageSize, pageNo } = orderState.workingState;
      getOrderList("进行中", { pageSize, pageNo });
    }
  }, [modalState.orderDetailV, orderState.detailFlag]);
  // 修改状态  弹框显示
  function handleModalState(data) {
    console.log(data);
    setModalState({ ...modalState, ...data });
    setModal({ ...modal, ...data });
  }
  // 完工结算弹框  数据处理
  function openSlipDetail(params) {
    let { slipType, data, index } = params;
    console.log(data, slipType);
    if (slipType === "working") {
      data = {
        ...data,
        // car_imgs: JSON.parse(data.car_imgs),
        part_content: JSON.parse(data.part_content),
        service_content: JSON.parse(data.service_content),
        index,
      };
    } else {
      data = {
        ...data,
        car_imgs: JSON.parse(data.car_imgs),
        part_content: JSON.parse(data.part_content),
        service_content: JSON.parse(data.service_content),
        index,
      };
      if (data.car_imgs === "null") {
        data.car_imgs = {
          frontLeft: [],
          frontRight: [],
          backLeft: [],
          backRight: [],
          other: [],
        };
      }
    }

    console.log(data);
    handleModalState({
      slipDetailV: true,
      slipType,
      curSlip: data,
    });
  }
  // 获取页面数据
  function getOrderListAll(params) {
    const stateList = ["进行中", "已完工", "已结算"];
    const { keyword, endDate, startDate } = orderState;
    let funcList = [];
    stateList.forEach((item) => {
      let data = {
        keyword: keyword,
        start_time: startDate,
        end_time: endDate,
        order_state: item,
      };
      funcList.push(
        _Api.orderSelect(data).then((res) => {
          let typeKey =
            item === "进行中"
              ? "workingState"
              : item === "已完工"
              ? "finishedState"
              : "paidState";
          return {
            [typeKey]: {
              list: res.data.content,
              total: res.data.allcount,
              pageNo: res.data.curpage,
              allPage: res.data.allpage,
              pageSize: res.data.pagecount,
            },
          };
        })
      );
    });
    let results = Promise.all(funcList);
    results
      .then((res) => {
        console.log("完结", res);
        let resList = {};
        res.forEach((item) => {
          resList = { ...resList, ...item };
        });
        setOrderState({ ...orderState, ...resList });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function getOrderList(type, pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    const { keyword, endDate, startDate } = orderState;
    let data = {
      keyword: keyword,
      start_time: startDate,
      end_time: endDate,
      order_state: type,
      count: pageSize,
      page: pageNo,
    };
    let typeKey =
      type === "进行中"
        ? "workingState"
        : type === "已完工"
        ? "finishedState"
        : "paidState";
    _Api.orderSelect(data).then((res) => {
      setOrderState({
        ...orderState,
        [typeKey]: {
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          allPage: res.data.allpage,
          pageSize: res.data.pagecount,
        },
      });
    });
  }
  // 点击修改  弹框
  function handleOrderEdit(dataIndex, slip) {
    const data = dataIndex.data;
    const index = dataIndex.index;
    setorderIndex(index);
    let _data = dataTrans(data, index);
    setOrderState({ ...orderState, detailFlag: true, index });
    setModalState({
      ...modalState,
      orderDetailV: true,
      curOrderBasic: _data,
      curServiceContent: slip
        ? data.service_content
        : JSON.parse(data.service_content).ids
        ? JSON.parse(data.service_content)
        : { ...JSON.parse(data.service_content), data: [], ids: [] },
      curPartContent: slip
        ? data.part_content
        : JSON.parse(data.part_content).ids
        ? JSON.parse(data.part_content)
        : {
            ...JSON.parse(data.part_content),
            data: [],
            ids: [],
          },
    });
  }
  function dataTrans(data, index) {
    const {
      car_imgs,
      bianhao,
      car_id,
      car_linkman,
      car_phone,
      car_type,
      cur_mileage,
      gmt_creat,
      id,
      member_card,
      next_maintain_mileage,
      next_maintain_remark,
      next_maintain_time,
      worker,
      part_zk,
      service_zk,
    } = data;
    let _data = {
      car_imgs,
      index,
      id,
      bianhao,
      startTime: moment(gmt_creat).format("YYYY年MM月DD日"),
      carId: car_id,
      carType: car_type,
      linkman: car_linkman,
      phone: car_phone,
      curMileage: cur_mileage,
      memberCard: member_card,
      partZk: part_zk,
      serviceZk: service_zk,
      nextMaintainMileage: next_maintain_mileage,
      nextMaintainTime: next_maintain_time,
      nextMaintainRemark: next_maintain_remark,
      worker,
    };
    return _data;
  }

  function handleCarChange(params) {
    const {
      id,
      memberCard,
      partZk,
      serviceZk,
      worker,
      curMileage,
      nextMaintainMileage,
      nextMaintainTime,
      nextMaintainRemark,
    } = modalState.curOrderBasic;
    const { car_type, car_id, linkman, phone } = params;
    let data = {
      id,
      car_id,
      car_linkman: linkman,
      car_type,
      car_phone: phone,
      member_card: memberCard,
      part_zk: partZk,
      service_zk: serviceZk,
      worker,
      cur_mileage: curMileage,
      next_maintain_mileage: nextMaintainMileage,
      next_maintain_time: nextMaintainTime,
      next_maintain_remark: nextMaintainRemark,
      end_time: "",
      order_state: "进行中",
      part_content: modalState.curPartContent,
      service_content: modalState.curServiceContent,
    };
    _Api.orderInsert(data).then((res) => {
      // message.success(res.msg);
    });
  }

  function handleVipSet(data) {
    const { bianhao, service_zk, part_zk } = data;
    let serviceAr = 0,
      partAr = 0;
    if (modalState.curServiceContent.amount != 0) {
      serviceAr = modalState.curServiceContent.amount * parseFloat(service_zk);
    }
    if (modalState.curPartContent.amount != 0) {
      partAr = modalState.curPartContent.amount * parseFloat(part_zk);
    }
    setModalState({
      ...modalState,
      curOrderBasic: {
        ...modalState.curOrderBasic,
        memberCard: bianhao,
        serviceZk: service_zk,
        partZk: part_zk,
      },
      curServiceContent: { ...modalState.curServiceContent, ar: serviceAr },
      curPartContent: { ...modalState.curPartContent, ar: partAr },
      vipSelectV: false,
      vipDetailV: false,
      curVip: {},
    });
  }

  function handleServiceAdd(list) {
    let discount = modalState.curOrderBasic.serviceZk;
    console.log(discount);
    let _list = [...modalState.curServiceContent.data, ...list];
    let amount = 0;
    let ids = _list.map((item) => {
      let price = item.price ? parseFloat(item.price) : 0;
      let hours = item.hours ? parseFloat(item.hours) : 0;
      amount += price * hours;
      return item.id;
    });
    let ar = amount * parseFloat(discount);
    handleModalState({
      curServiceContent: { data: _list, ids, amount, ar },
      serviceSelectV: false,
    });
  }

  function handlePartAdd(list) {
    let discount = modalState.curOrderBasic.partZk;
    let _list = [...modalState.curPartContent.data, ...list];
    let amount = 0;
    let ids = _list.map((item) => {
      let price = item.priceSale ? parseFloat(item.priceSale) : 0;
      let num = item.num ? parseFloat(item.num) : 0;
      amount += price * num;
      return item.id;
    });
    let ar = amount * parseFloat(discount);
    handleModalState({
      curPartContent: { data: _list, ids, amount, ar },
      partSelectV: false,
    });
  }
  // 修改编辑确定逻辑
  function orderSave(params) {
    console.log("参数", params);
    const {
      id,
      carId,
      linkman,
      carType,
      phone,
      memberCard,
      partZk,
      serviceZk,
      worker,
      curMileage,
      nextMaintainMileage,
      nextMaintainTime,
      nextMaintainRemark,
      car_imgs,
    } = modalState.curOrderBasic;
    let data = {
      id,
      car_imgs,
      car_id: carId,
      car_linkman: linkman,
      car_type: carType,
      car_phone: phone,
      member_card: memberCard,
      part_zk: partZk,
      service_zk: serviceZk,
      worker,
      cur_mileage: curMileage,
      next_maintain_mileage: nextMaintainMileage,
      next_maintain_time: nextMaintainTime,
      next_maintain_remark: nextMaintainRemark,
      end_time: "",
      order_state: "进行中",
      part_content: modalState.curPartContent,
      service_content: modalState.curServiceContent,
    };
    _Api.orderInsert(data).then((res) => {
      message.success(res.msg);
      // 弹框关闭
      handleModalState({ orderDetailV: false, slipDetailV: false });
    });
  }
  // 点击取消完工
  function handleCancelComplete(params) {
    console.log(params);
    params = {
      ...params,
      order_state: "进行中",
      part_content: JSON.parse(params.part_content),
      service_content: JSON.parse(params.service_content),
      car_imgs: JSON.parse(params.car_imgs),
      // part_zk: mathFunc.getPercent(params.part_zk),
      // service_zk: mathFunc.getPercent(params.service_zk),
    };
    console.log(params);
    _Api.orderInsert(params).then((res) => {
      message.success(res.msg);
      getOrderListAll();
    });
  }
  //点击打印收据
  function handleReceivePrint(params) {
    const { bianhao, convert } = params;
    console.log(params);
    _Api.caiwuSelect({ order_bianhao: bianhao }).then((res) => {
      if (convert) {
        // 不需要解析
        setModalState({
          ...modalState,
          printContent: {
            ...params,
            input_user: res.data.content[0].input_user,
          },
          printV: true,
        });
      } else {
        setModalState({
          ...modalState,
          printContent: {
            ...params,
            service_content: JSON.parse(params.service_content),
            part_content: JSON.parse(params.part_content),
            input_user: res.data.content[0].input_user,
          },
          printV: true,
        });
      }
    });
  }
  // 选择会员卡之后的确认
  function VipCardSelect(data, member) {
    console.log(data);
    const {
      bianHao,
      serviceZk,
      partZk,
      leftNum,
      packageName,
      leftMoney,
      packageType,
    } = data;
    const { package_content } = member;
    // setModalState({
    //   ...modalState,
    //   curSlip: { ...modalState.curSlip, ...data },
    // });

    let serviceAr = 0,
      partAr = 0;
    if (modalState.curServiceContent.amount != 0) {
      serviceAr = modalState.curServiceContent.amount * parseFloat(serviceZk);
    }
    if (modalState.curPartContent.amount != 0) {
      partAr = modalState.curPartContent.amount * parseFloat(partZk);
    }
    setModal({
      ...modal,
      packageV: false,
      vipDetailV: false,
      selectMelber: { ...data, packageContent: package_content },
    });
    setModalState({
      ...modalState,
      curOrderBasic: {
        ...modalState.curOrderBasic,
        memberCard: bianHao,
        partZk,
        serviceZk,
      },
      curServiceContent: {
        ...modalState.curServiceContent,
        ar: serviceAr,
      },
      curPartContent: {
        ...modalState.curPartContent,
        ar: partAr,
      },
    });
  }
  console.log(modalState);
  return (
    <>
      <div className="template-student-index page settlemen">
        <div className="page-content">
          <section className="content-header">
            <h1>完工结算</h1>
          </section>
          <div className="box">
            <div className="box-header">
              <Row type="flex" justify="space-around" align="top">
                <Col>
                  <SearchBar
                    searchText={orderState.keyword}
                    onChangeText={(text) => {
                      setOrderState({ ...orderState, keyword: text });
                    }}
                    onSearch={getOrderListAll}
                    label="搜索"
                    placeholder="请输入单号/车牌号/联系人/手机号"
                  />
                  <StaticSelect label="开单日期">
                    <RangePicker
                      style={{ width: "350px" }}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        本周: [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        上月: [
                          moment().subtract(1, "months").startOf("month"),
                          moment().subtract(1, "months").endOf("month"),
                        ],
                        上周: [
                          moment().subtract(1, "week").startOf("week"),
                          moment().subtract(1, "week").endOf("week"),
                        ],
                      }}
                      value={[
                        orderState.startDate && moment(orderState.startDate),
                        orderState.endDate && moment(orderState.endDate),
                      ]}
                      onChange={(memo, str) => {
                        setOrderState({
                          ...orderState,
                          startDate: str[0],
                          endDate: str[1],
                        });
                      }}
                    />
                  </StaticSelect>
                </Col>
              </Row>
            </div>
            <div className="box-body">
              <Tabs
                // activeKey={orderState.tableKey}
                // onTabClick={(e) => {
                //   setOrderState({ ...orderState, tableKey: e });
                // }}
                activeKey={keyIndex}
                onTabClick={(e) => {
                  setKeyIndex(e);
                }}
              >
                <TabPane
                  tab={"进行中(" + orderState.workingState.total + ")"}
                  key="1"
                >
                  <WorkingTable
                    tableState={orderState.workingState}
                    // imageList={imageList}
                    editOrder={(data, index) => {
                      handleOrderEdit({ data, index });
                    }}
                    scanOrder={(data, index) =>
                      openSlipDetail({ slipType: "working", data, index })
                    }
                    getTableList={(data) => {
                      getOrderList("进行中", data);
                    }}
                    getTableListAll={getOrderListAll}
                  />
                </TabPane>
                <TabPane
                  tab={"已完工(" + orderState.finishedState.total + ")"}
                  key="2"
                >
                  <FinishedTable
                    tableState={orderState.finishedState}
                    openReceive={(data) => {
                      console.log(data);
                      data = {
                        ...data,
                        // car_imgs: JSON.parse(data.car_imgs),
                        part_content: JSON.parse(data.part_content),
                        service_content: JSON.parse(data.service_content),
                      };
                      handleModalState({ curSlipSet: data, receiveV: true });
                    }}
                    cancelComplete={handleCancelComplete}
                    getTableList={(data) => {
                      getOrderList("已完工", data);
                    }}
                    openDetail={(data, index) => {
                      openSlipDetail(data, index);
                    }}
                  />
                </TabPane>
                <TabPane
                  tab={"已结算(" + orderState.paidState.total + ")"}
                  key="3"
                >
                  <PaidTable
                    tableState={orderState.paidState}
                    openDetail={(data, index) => {
                      openSlipDetail(data, index);
                    }}
                    getTableList={(data) => {
                      getOrderList("已结算", data);
                    }}
                    handleCanclePaid={(id) => {
                      let data = { id };
                      _Api.orderCaiwuDelete(data).then((res) => {
                        message.success(res.msg);
                        getOrderListAll();
                      });
                    }}
                    handleReceivePrint={handleReceivePrint}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
          {/* // 编辑工单 */}
          <OrderDetailModal
            // carImgs={}
            // uploadCarImgs={uploadCarImgs}
            handleCarImgs={handleCarImgs}
            handleBaseCarImgs={handleBaseCarImgs}
            orderIndex={orderIndex}
            workingImage={workingImage}
            uploadWorking={uploadWorking}
            // imageUrl={imageUrl}
            // handlImageUrl={handlImageUrl}
            // setMimageList={setMimageList}
            visible={modalState.orderDetailV}
            // carImageV
            curBasic={modalState.curOrderBasic}
            curService={modalState.curServiceContent}
            curPart={modalState.curPartContent}
            viewMode={modalState.orderScan}
            handleModalState={handleModalState}
            handleVipSet={handleVipSet}
            closeModal={() => {
              handleModalState({ orderDetailV: false, curOrderBasic: {} });
            }}
            handleSave={orderSave}
          />
          {/* 照片弹框 */}
          <CarImage
            // handlImageUrl={handlImageUrl}
            // imageUrl={imageUrl}
            // imageList={imageList}
            // uploadImageList={uploadImageList}
            // uploadMimage={uploadMimage}
            visible={modalState.carImageV}
            handleModalState={handleModalState}
            // handleBaseState={handleBaseState}
            carImgs={modalState.curOrderBasic.car_imgs}
            handleBaseCarImgs={handleBaseCarImgs}
            handleCarImgs={handleCarImgs}
            type="edit"
          ></CarImage>
          {/* 结算单 弹框 */}
          <SlipDetailModal
            // workingImage={workingImage}
            workingImage={modalState.curOrderBasic.car_imgs}
            // MimageList={MimageList}
            // imageList={imageList}
            visible={modalState.slipDetailV}
            slipContent={modalState.curSlip}
            slipType={modalState.slipType}
            orderEdit={(index) => {
              handleOrderEdit({ data: modalState.curSlip, index }, true);
            }}
            handleReceivePrint={handleReceivePrint}
            openReceive={(data) => {
              handleModalState({
                ...modalState,
                curSlipSet: data,
                receiveV: true,
              });
            }}
            handleComplete={(data, url) => {
              console.log(url);
              data = {
                ...data,
                car_imgs: JSON.stringify(url),
                order_state: "已完工",
              };
              _Api.orderInsert(data).then((res) => {
                message.success(res.msg);
                handleModalState({
                  slipDetailV: false,
                  curSlip: {},
                  slipType: "",
                });
                getOrderListAll();
              });
            }}
            cancelComplete={(data) => {
              console.log(data);
              data = { ...data, order_state: "进行中" };
              _Api.orderInsert(data).then((res) => {
                message.success(res.msg);
                handleModalState({
                  slipDetailV: false,
                  curSlip: {},
                  slipType: "",
                });
                getOrderListAll();
              });
            }}
            cancelPaid={(id) => {
              let data = { id };
              _Api.orderCaiwuDelete(data).then((res) => {
                message.success(res.msg);
                handleModalState({
                  slipDetailV: false,
                  curSlip: {},
                  slipType: "",
                });
                getOrderListAll();
              });
            }}
            closeModal={() =>
              handleModalState({
                slipDetailV: false,
                curSlip: {},
                slipType: "",
              })
            }
          />

          <CarSelectModal
            visible={modalState.carSelectV}
            closeModal={() => {
              handleModalState({ carSelectV: false });
            }}
            handleSelect={(data) => {
              const { car_type, car_id, linkman, phone } = data;
              handleCarChange({ car_type, car_id, linkman, phone });
              let basicInfo = {
                ...modalState.curOrderBasic,
                carId: car_id,
                carType: car_type,
                linkman,
                phone,
              };
              setModalState({
                ...modalState,
                curOrderBasic: basicInfo,
                carSelectV: false,
              });
            }}
          />
          <CarEditModal
            visible={modalState.carEditV}
            editType={modalState.carEditType}
            curCarId={modalState.curOrderBasic.carId}
            openWxSelect={() => handleModalState({ wxSelectV: true })}
            setCurCar={(data) => {
              const { linkman, phone, carId, carType } = data;
              handleCarChange({
                car_type: carType,
                car_id: carId,
                linkman,
                phone,
              });
              handleModalState({
                curOrderBasic: { ...modalState.curOrderBasic, ...data },
                carEditV: false,
                carEditType: false,
              });
            }}
            closeModal={() => {
              handleModalState({ carEditV: false, carEditType: false });
            }}
          />
          {/* <VipSelectModal
            visible={modalState.vipSelectV}
            closeModal={() => {
              handleModalState({ vipSelectV: false });
            }}
            handleModalState={handleModalState}
          />
          <VipDetailModal
            visible={modalState.vipDetailV}
            cardInfo={modalState.curVip}
            handleBaseState={(data) => {
              handleModalState({ ...modalState, ...data });
            }}
            handleVipSet={handleVipSet}
            closeModal={() => {
              handleModalState({ vipDetailV: false });
            }}
          /> */}
          <MaintenanceInfoModal
            orderState={modalState.curOrderBasic}
            visible={modalState.maintenanceV}
            closeModal={() => {
              handleModalState({ maintenanceV: false });
            }}
            handleOk={(data) => {
              const { time, mileage, remark } = data;
              handleModalState({
                curOrderBasic: {
                  ...modalState.curOrderBasic,
                  nextMaintainMileage: mileage,
                  nextMaintainTime: time,
                  nextMaintainRemark: remark,
                },
                maintenanceV: false,
              });
            }}
          />
          <ServeSelectModal
            selectedIds={modalState.curServiceContent.ids}
            visible={modalState.serviceSelectV}
            serviceAdd={handleServiceAdd}
            closeModal={() => {
              handleModalState({ serviceSelectV: false });
            }}
          />
          <ServeAddModal
            visible={modalState.serviceAddV}
            closeModal={() => {
              handleModalState({ serviceAddV: false });
            }}
            handleComplete={() => {
              handleModalState({ serviceAddV: false });
            }}
          />
          <PartSelectModal
            selectedIds={modalState.curPartContent.ids}
            visible={modalState.partSelectV}
            partAdd={handlePartAdd}
            closeModal={() => {
              handleModalState({ partSelectV: false });
            }}
          />
          <PartAddModal
            visible={modalState.partAddV}
            closeModal={() => {
              handleModalState({ partAddV: false });
            }}
            handleComplete={() => {
              handleModalState({ partAddV: false });
            }}
          />
          {/* 收款 弹框 */}
          <ReceiveCompleted
            visible={modalState.receiveV}
            modalInfo={modalState.curSlipSet}
            memberData={modal.selectMelber}
            getOrderListAll={getOrderListAll}
            closeModal={() => {
              handleModalState({ receiveV: false });
              getOrderListAll();
            }}
            memberCardSelect={() => {
              setModal({
                ...modal,
                packageV: true,
              });
            }}
            handlePayOver={() => {
              handleModalState({
                slipDetailV: false,
                curSlipSet: {},
                slipType: "",
                receiveV: false,
              });
              getOrderListAll();
            }}
            handleCharge={() => {
              handleModalState({ chargeV: true });
            }}
          />
          {/* 会员卡选择弹框 */}
          <VipSelectModal
            visible={modal.packageV}
            carId={modalState.curSlipSet?.car_id}
            closeModal={() => {
              setModal({ ...modal, packageV: false });
            }}
            handleModalState={(data) => {
              console.log(data);
              setModal({
                ...modal,
                selectedPackage: data.curVip,
                vipDetailV: true,
              });
            }}
          />
          {/* 会员卡输入密码弹框 */}
          <VipDetailModal
            visible={modal.vipDetailV}
            cardInfo={modal.selectedPackage}
            // handleBaseState={handleBaseState}
            handleVipSet={VipCardSelect}
            closeModal={() => {
              setModal({ ...modal, vipDetailV: false });
            }}
          />
          {/* <MemberCardSelect
            visible={modal.packageV}
            closeModal={() => {
              setModal({ ...modal, packageV: false });
            }}
            handleSelect={(data) => {
              setModal({
                ...modal,
                selectedPackage: data,
                packageV: false,
              });
            }}
          /> */}
          <VipChargeModal
            visible={modalState.chargeV}
            bianhao={modalState.curSlip.member_card}
            closeModal={() => {
              handleModalState({ chargeV: false });
            }}
            handleComplete={() => {
              handleModalState({ chargeV: false });
            }}
          />
          {/* 打印收据 弹框 */}
          <ReceivePrintModal
            printContent={modalState.printContent}
            visible={modalState.printV}
            closeModal={() => {
              setModalState({ ...modalState, printContent: {}, printV: false });
            }}
            installV={modalState.installV}
            installClose={() => {
              setModalState({ ...modalState, installV: false });
            }}
            needInstall={() => {
              setModalState({ ...modalState, installV: true });
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Settlemen;
