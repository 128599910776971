import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Input, Select } from "antd";
import TitleBar from "../TitleBar";
import moment from "moment";
import GoodsTable from "../../modules/common/GoodsTable";
import _Api from "../../core/api";

const PurchasingReturnModal = ({
	visible,
	viewMode,
	closeModal,
	openAdd,
	openSelect,
	modalState,
	handleModalState,
	handleSave,
}) => {
	const [supplierOptions, setSupplierOptions] = useState([]);
	useEffect(() => {
		if (visible) {
			getSupplierList();
		}
	}, [visible]);
	function getSupplierList(params) {
		_Api.supplierSelect({ keyword: "" }).then((res) => {
			const options = res.data.content.map((item) => ({ value: item.name, label: item.name }));
			setSupplierOptions(options);
		});
	}
	const layout = {
		labelCol: { span: 5 },
		wrapperCol: { span: 19 },
	};

	return (
		<Modal
			title={viewMode ? "查看退货单" : "新增退货单"}
			zIndex={990}
			width={1200}
			visible={visible}
			onCancel={closeModal}
			onOk={viewMode ? closeModal : handleSave}>
			<TitleBar>
				<span>{"开单日期：" + moment().format("YYYY-MM-DD")}</span>
			</TitleBar>
			<Form {...layout}>
				<Row style={{ marginTop: "10px" }}>
					<Col span={8}>
						<Form.Item label='供应商'>
							<Select
								disabled={viewMode}
								options={supplierOptions}
								value={modalState.curSupplier}
								onSelect={(e) => {
									handleModalState({ curSupplier: e });
								}}></Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label='退货原因'>
							<Input
								disabled={viewMode}
								value={modalState.curInvoice}
								onChange={(e) => {
									handleModalState({ curInvoice: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<TitleBar>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>商品配件</span>
					{!viewMode && (
						<div>
							<Button size='middle' style={{ marginRight: "10px" }} onClick={openSelect}>
								选择
							</Button>
							<Button size='middle' onClick={openAdd}>
								新建
							</Button>
						</div>
					)}
				</div>
			</TitleBar>
			<GoodsTable
				viewmode={viewMode}
				slip={modalState.curPartContent.data.length > 0}
				tableState={modalState.curPartContent}
				setTable={(data) => {
					handleModalState({ curPartContent: data });
				}}
				purchase
				// type="parts"
				isTrue={true}
			/>
		</Modal>
	);
};
export default PurchasingReturnModal;
