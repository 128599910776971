import { useEffect, useState } from "react";
import { Modal, Form, Input, Radio, DatePicker, message } from "antd";
import moment from "moment";
import Api from "../../core/api";
const WorkerEditModal = ({ visible, closeModal, editType, curWorker,getWorkerList }) => {
	const [workerState, setWorkerState] = useState({
		id: "",
		name: "",
		sex: "",
		idNo: "",
		phone: "",
		isJiedai: "",
		isJishi: "",
		inDate: "",
		remark: "",
	});
	useEffect(() => {
		if (visible && editType) {
			console.log(curWorker);
			const { id, id_no, in_date, is_jiedai, is_jishi, name, phone, remark, sex } = curWorker;
			setWorkerState({
				id,
				idNo: id_no,
				inDate: in_date,
				isJiedai: is_jiedai,
				isJishi: is_jishi,
				name,
				phone,
				remark,
				sex,
			});
		}
	}, [visible]);
	function handleSave() {
		const {
			id,
			name,
			sex,
			idNo: id_no,
			phone,
			isJiedai: is_jiedai,
			isJishi: is_jishi,
			inDate: in_date,
			remark,
		} = workerState;
		let data = { name, sex, id_no, phone, is_jiedai, is_jishi, in_date, remark };
		if (editType) {
			data = { ...data, id };
		}
		Api.workerInsert(data).then((res) => {
			message.success(res.msg);
      getWorkerList()
			handleCancel();
		});
	}
	function handleCancel(params) {
		setWorkerState({
			id: "",
			name: "",
			sex: "",
			idNo: "",
			phone: "",
			isJiedai: "",
			isJishi: "",
			inDate: "",
			remark: "",
		});
		closeModal();
	}
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};
	const sexOptions = [
		{ value: "男", label: "男" },
		{ value: "女", label: "女" },
	];
	const typeOptions = [
		{ value: "1", label: "是" },
		{ value: "0", label: "否" },
	];
	return (
		<Modal
			title={editType ? "员工编辑" : "新增员工"}
			width={500}
			visible={visible}
			onCancel={handleCancel}
			onOk={handleSave}>
			<Form {...layout}>
				<Form.Item
					label={
						<p>
							<span style={{ color: "#EB3628" }}>*</span>姓名
						</p>
					}>
					<Input
						value={workerState.name}
						onChange={(e) => {
							setWorkerState({ ...workerState, name: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label='性别'>
					<Radio.Group
						options={sexOptions}
						optionType='button'
						value={workerState.sex}
						onChange={(e) => {
							setWorkerState({ ...workerState, sex: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label='身份证号'>
					<Input
						value={workerState.idNo}
						onChange={(e) => {
							setWorkerState({ ...workerState, idNo: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label='电话'>
					<Input
						value={workerState.phone}
						onChange={(e) => {
							setWorkerState({ ...workerState, phone: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label='是否接待人'>
					<Radio.Group
						options={typeOptions}
						optionType='button'
						value={workerState.isJiedai}
						onChange={(e) => {
							setWorkerState({ ...workerState, isJiedai: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label='是否维修技师'>
					<Radio.Group
						options={typeOptions}
						optionType='button'
						value={workerState.isJishi}
						onChange={(e) => {
							setWorkerState({ ...workerState, isJishi: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label='进厂时间'>
					<DatePicker
						value={workerState.inDate && moment(workerState.inDate, "YYYY-MM-DD")}
						onChange={(memeo, str) => {
							setWorkerState({ ...workerState, inDate: str });
						}}
					/>
				</Form.Item>
				<Form.Item label='备注'>
					<Input
						value={workerState.remark}
						onChange={(e) => {
							setWorkerState({ ...workerState, remark: e.target.value });
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default WorkerEditModal;
