import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
let dragingIndex = -1;
class CSSTransitionGroupChild extends React.Component {
	render() {
		const { isOver, connectDragSource, connectDropTarget, connectDragPreview, moveCard, ...restProps } = this.props;
		let { className } = restProps;
		if (isOver) {
			if (restProps.index > dragingIndex) {
				className += "drop-over-downward";
			}
			if (restProps.index < dragingIndex) {
				className += "drop-over-upward";
			}
		}
		return connectDragSource(
			connectDropTarget(
				<li>
					{connectDragPreview(
						<div className='img-container'>
							<img src={this.props.img} alt='' style={{ cursor: "move" }} />
						</div>
					)}

					<span
						className='icon iconfont delete-icon'
						style={{
							backgroundColor: "#fff",
							height: "16px",
							width: "16px",
							lineHeight: "16px",
							borderRadius: "16px",
							top: "-10px",
						}}
						onClick={this.props.handleDelete.bind(this, this.props.id)}>
					&#xe6a3;
					</span>
				</li>
			)
		);
	}
}
const cardSource = {
	beginDrag(props) {
		dragingIndex = props.index;
		return {
			index: props.index,
		};
	},
};
const cardTarget = {
	drop(props, monitor) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;
		console.log(hoverIndex);
		console.log(dragIndex);
		if (dragIndex === hoverIndex) {
			return;
		}
		props.moveCard(dragIndex, hoverIndex);
		monitor.getItem().index = hoverIndex;
	},
};
const DragableBodyCard = DropTarget("card", cardTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))(
	DragSource("card", cardSource, (connect) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
	}))(CSSTransitionGroupChild)
);

export default DragableBodyCard;
