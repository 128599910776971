import SearchBar from "../../components/SearchBar";
import user from "../../core/user";
import axios from "axios";
import {
  Row,
  Col,
  Radio,
  DatePicker,
  Table,
  Popconfirm,
  Divider,
  Tooltip,
  message,
  Modal,
  Button,
  Empty,
} from "antd";
import StaticSelect from "../../components/StaticSelect";
import { useState, useEffect, useRef } from "react";
import _Api from "../../core/api";
import WxCustomerModal from "../../components/modals/WxCustomer";
import PageControl from "../../components/PageControl";
import moment from "moment";
import VipSelectModal from "../../components/modals/VipSelect";
import MeberMultiSelectModal from "../../components/modals/MeberMultiSelectModal";
import CarMultiSelectModal from "../../components/modals/CarMultiSelect";
import CarEditModal from "../../components/modals/CarEdit";
import VipEditModal from "../../components/modals/VipEdit";
import RrImags from "../../libs/qr.png";
import InfoImage from "../../libs/info.png";
import "./index.less";
import URL from "../../libs/qr-ackground.png";
const { RangePicker } = DatePicker;
const WxCustomer = ({}) => {
  const [state, setState] = useState({
    list: [],
    keyword: "",
    dateStart: "",
    dateEnd: "",
    isMember: "",
    isCar: "",
    subscribe: "",
    pageNo: "1",
    pageSize: "10",
    total: "",
  });
  const [modalState, setModalState] = useState({
    customerV: false,
    curCustomer: {},
    defaultData: {},
    vipSelectV: false,
    carSelectV: false,
    carEditV: false,
    curCarId: "",
    vipEditV: false,
    curVip: {},
  });
  const [visible, setVisible] = useState(false);
  const [selectType, setSelectType] = useState(false);
  useEffect(() => {
    getUserList();
  }, [
    state.isMember,
    state.isCar,
    state.dateStart,
    state.dateEnd,
    state.subscribe,
  ]);
  function getUserList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    const {
      keyword,
      isMember: is_member,
      isCar: is_car,
      subscribe,
      dateStart: start_time,
      dateEnd: end_time,
    } = state;
    if (keyword === "") {
      setSelectType(false);
    } else {
      setSelectType(true);
    }
    let data = {
      keyword,
      is_member,
      is_car,
      subscribe,
      start_time,
      end_time,
      count: pageSize,
      page: pageNo,
    };
    _Api.wxUserSelect(data).then((res) => {
      let list = res.data.content;
      // list.forEach((element) => {
      // 	if (element.car_ids) {
      // 		element.car_ids = JSON.parse(element.car_ids);
      // 	}
      // });
      // console.log(list);
      setState({
        ...state,
        list,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
      });
    });
  }
  function handleEdit(data) {
    console.log(data);
    setModalState({ customerV: true, curCustomer: data, defaultData: data });
  }
  function handleMemberCard(card) {
    _Api.cardSelect({ keyword: card }).then((res) => {
      if (res.data.content.length > 0) {
        setModalState({
          ...modalState,
          vipEditV: true,
          curVip: res.data.content[0],
        });
      } else {
        message.warn("未找到该会员卡信息");
      }
    });
  }
  function toggleFilter() {
    setState({ ...state, filter: !state.filter });
  }
  // 点击确定修改
  function handleCustomerSave() {
    let { id, name, phone, member_card, car_ids, remark } =
      modalState.curCustomer;
    let data = { id, name, phone, member_card, car_ids, remark };
    console.log(data);
    _Api.wxUserModify(data).then((res) => {
      message.success(res.msg);
      setModalState({ ...modalState, customerV: false, curCustomer: {} });
      getUserList();
    });
  }
  function reset() {
    setState({
      ...state,
      keyword: "",
      dateStart: "",
      dateEnd: "",
      isMember: "",
      isCar: "",
      subscribe: "",
    });
  }
  const options = [
    { label: "全部", value: "" },
    { label: "是", value: "1" },
    { label: "否", value: "0" },
  ];
  const wxOptions = [
    { label: "全部", value: "" },
    { label: "已关注", value: "1" },
    { label: "已取关", value: "0" },
  ];
  const columns = [
    {
      title: "序号",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "昵称",
      dataIndex: "nickname",
      align: "center",
    },
    {
      title: "姓名",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "性别",
      dataIndex: "sex",
      align: "center",
    },
    {
      title: "手机",
      dataIndex: "phone",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "会员卡",
      dataIndex: "member_card",
      align: "center",
      render: (text) =>
        text.length > 0 ? (
          <Tooltip
            // title={
            //   <span
            //     onClick={() => handleMemberCard(text)}
            //     className="click-able"
            //   >
            //     {text}
            //   </span>
            // }
            title={<MeberItem ids={text}></MeberItem>}
          >
            <span
              className="icon iconfont theme-color click-able"
              style={{ fontSize: "18px" }}
            >
              &#xe640;
            </span>
          </Tooltip>
        ) : (
          "-"
        ),
    },
    {
      title: "车辆",
      dataIndex: "car_ids",
      align: "center",
      render: (text) =>
        text.length > 0 ? (
          <Tooltip title={<CarItem ids={text} />}>
            <span className="icon iconfont theme-color click-able">
              &#xe66c;
            </span>
          </Tooltip>
        ) : (
          "-"
        ),
    },
    {
      title: "状态",
      dataIndex: "subscribe",
      align: "center",
      render: (text) => (text === "1" ? "已关注" : "已取关"),
    },
    {
      title: "关注时间",
      dataIndex: "gmt_creat",
      align: "center",
    },

    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "操作",
      key: "operation",
      align: "center",
      render: (text, record, index) => (
        <>
          <span
            className="theme-color click-able"
            onClick={() => {
              handleEdit(record);
            }}
          >
            编辑
          </span>
        </>
      ),
    },
  ];
  const saveQRCode = () => {
    // function getImageDataURL(image) {
    //   console.log(111111);
    //   // 创建画布
    //   // const canvas = document.getElementById("myCanvas");
    //   const canvas = document.createElement("canvas");
    //   canvas.width = image.width;
    //   canvas.height = image.height;
    //   const ctx = canvas.getContext("2d");
    //   // 以图片为背景剪裁画布
    //   ctx.drawImage(image, 0, 0, image.width, image.height);
    //   // 获取图片后缀名
    //   const extension = image.src
    //     .substring(image.src.lastIndexOf(".") + 1)
    //     .toLowerCase();
    //   // 某些图片 url 可能没有后缀名，默认是 png
    //   return canvas.toDataURL("image/" + extension, 1);
    // }
    // function downLoad(downloadName, url) {
    //   const tag = document.createElement("a");
    //   // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
    //   tag.setAttribute("download", downloadName.replace(/\./g, "。"));

    //   const image = new Image();
    //   // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
    //   image.src = url + "?time=" + new Date().getTime();
    //   //重要，设置 crossOrigin 属性，否则图片跨域会报错
    //   image.setAttribute("crossOrigin", "Anonymous");
    //   // 图片未加载完成时操作会报错

    //   image.onload = () => {
    //     console.log(22222);
    //     tag.href = getImageDataURL(image);
    //     tag.click();
    //   };
    // }
    // downLoad("text", `/api_image/shop/shop_qrcode.php?token=${user.token()}`);

    let x = new XMLHttpRequest();
    x.open(
      "POST",
      `https://api.sanfeng9.com/shop/shop_qrcode.php?token=${user.token()}`,
      true
    );
    x.responseType = "blob";
    x.onload = function () {
      let url = window.URL.createObjectURL(x.response);
      let a = document.createElement("a");
      a.href = url;
      a.download = "download";
      a.click();
    };
    x.send();

    // const canvasDom = document.getElementById("myCanvas");
    // let context = canvasDom.getContext("2d");
    // let image = new Image();
    // image.setAttribute("crossOrigin", "anonymous");
    // // image.src = RrImags;
    // image.src = `/api_image/shop/shop_qrcode.php?token=${user.token()}`;
    // image.onload = () => {
    //   console.dir(image);
    //   context.drawImage(image, 0, 0, 1080, 1080);
    //   console.dir(canvasDom);
    //   let url = canvasDom.toDataURL("image/png"); //得到图片的base64编码数据
    //   let a = document.createElement("a"); // 生成一个a元素
    //   let event = new MouseEvent("click"); // 创建一个单击事件
    //   let name = new Date().getTime();
    //   a.download = name || "photo"; // 设置图片名称
    //   a.href = url; // 将生成的URL设置为a.href属性
    //   a.dispatchEvent(event); // 触发a的单击事件
    // };
  };
  const CarItem = ({ ids }) => {
    return (
      <>
        {ids.map((item, index) => (
          <p
            key={index}
            className="click-able theme-color"
            onClick={() => {
              setModalState({ ...modalState, carEditV: true, curCarId: item });
            }}
          >
            {item}
          </p>
        ))}
      </>
    );
  };
  const MeberItem = ({ ids }) => {
    return (
      <>
        {ids.map((item, index) => (
          <p
            key={index}
            className="click-able theme-color"
            onClick={() => {
              handleMemberCard(item);
              // setModalState({ ...modalState, carEditV: true, curCarId: item });
            }}
          >
            {item}
          </p>
        ))}
      </>
    );
  };
  // console.log(state, modalState);
  return (
    <div className="template-student-index student-manager page">
      <div className="page-content">
        <section className="content-header">
          <h1>微信客户</h1>
        </section>
        {/* <Row className="wx-box" justify="space-between">
          <Col span={8} className="wx-left">
            <img src={InfoImage} alt="" />
            <span>门店人员通过点击右侧“下载二维码”，获得当前门店专属二维码。</span>
          </Col>
          <Col span={5}  className="wx-right">
            <div onClick={() => setVisible(true)}>
              <img src={RrImags} alt="" />
              <span>下载二维码</span>
            </div>
          </Col>
        </Row> */}
        <div className="wx-box">
          <div className="wx-left">
            <img src={InfoImage} alt="" />
            <span>
              门店人员通过点击右侧“下载二维码”，获得当前门店专属二维码。
            </span>
          </div>
          <div className="wx-right" onClick={() => setVisible(true)}>
            <img src={RrImags} alt="" />
            <span>下载二维码</span>
          </div>
        </div>
        <div className="box">
          <div className="box-header">
            <Row type="flex" justify="space-between" align="top">
              <Col>
                <SearchBar
                  searchText={state.keyword}
                  onChangeText={(text) => {
                    setState({ ...state, keyword: text });
                  }}
                  onSearch={getUserList}
                  label="搜索"
                  placeholder="手机号/姓名/备注"
                />

                <StaticSelect label="是否绑定会员卡：">
                  <Radio.Group
                    options={options}
                    onChange={(e) => {
                      setState({ ...state, isMember: e.target.value });
                    }}
                    value={state.isMember}
                    optionType="button"
                  />
                </StaticSelect>

                <StaticSelect label="是否绑定车辆：">
                  <Radio.Group
                    options={options}
                    onChange={(e) => {
                      setState({ ...state, isCar: e.target.value });
                    }}
                    value={state.isCar}
                    optionType="button"
                  />
                </StaticSelect>
                {state.filter && (
                  <>
                    <StaticSelect label="关注日期:">
                      <RangePicker
                        style={{ width: "100%" }}
                        value={[
                          state.dateStart && moment(state.dateStart),
                          state.dateEnd && moment(state.dateEnd),
                        ]}
                        onChange={(memo, str) => {
                          setState({
                            ...state,
                            dateStart: str[0],
                            dateEnd: str[1],
                          });
                        }}
                      />
                    </StaticSelect>
                    <StaticSelect label="微信状态：">
                      <Radio.Group
                        options={wxOptions}
                        onChange={(e) => {
                          setState({ ...state, subscribe: e.target.value });
                        }}
                        value={state.subscribe}
                        optionType="button"
                      />
                    </StaticSelect>
                  </>
                )}
              </Col>
              <Col>
                <Tooltip title="清空筛选">
                  <span
                    onClick={reset}
                    id="reset_btn"
                    className="resetBtn iconfont icon"
                  >
                    &#xe662;
                  </span>
                </Tooltip>
                <span
                  className="text-sub xm-filter"
                  onClick={toggleFilter}
                  style={{ cursor: "pointer" }}
                >
                  {state.filter ? (
                    <span>
                      收起
                      <span className="iconfont icon ml5">&#xe663;</span>
                    </span>
                  ) : (
                    <span>
                      展开
                      <span className="iconfont icon ml5">&#xe665;</span>
                    </span>
                  )}
                </span>
                <div style={{ paddingTop: "10px" }}></div>
              </Col>
            </Row>
          </div>

          <div className="box-body">
            <Table
              className="updata-box-svg"
              rowKey={(record) => record.id}
              dataSource={state.list}
              pagination={false}
              bordered
              columns={columns}
              locale={
                selectType
                  ? {
                      emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                    }
                  : {
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <>
                              <p>暂无微信客户</p>
                              <p>
                                点击“二维码”并下载，客户扫码关注后，会进入此列表
                              </p>
                            </>
                          }
                        />
                      ),
                    }
              }
            />
            <PageControl
              total={state.total}
              pageSize={state.pageSize}
              current={state.pageNo}
              showSizeChanger
              toPage={(page, count) => {
                getUserList({ pageNo: page, pageSize: count });
              }}
            />
          </div>
        </div>
      </div>
      {/* 微信客户  编辑 */}
      <WxCustomerModal
        visible={modalState.customerV}
        curCustomer={modalState.curCustomer}
        handleDataChange={(data, flag) => {
          console.log("删除回填", data);
          if (flag) {
            const list = modalState.curCustomer.member_card_info.filter(
              (item) => {
                const index = data.member_card.indexOf(item.bianhao);
                console.log(item.bianhao, index);
                if (index !== -1) return item;
              }
            );
            setModalState({
              ...modalState,
              curCustomer: {
                ...modalState.curCustomer,
                member_card_info: list,
                ...data,
              },
            });
          } else {
            setModalState({
              ...modalState,
              curCustomer: {
                ...modalState.curCustomer,
                ...data,
              },
            });
          }
        }}
        closeModal={() => {
          setModalState({ ...state, customerV: false, curCustomer: {} });
        }}
        openMemberCard={() => {
          setModalState({ ...modalState, vipSelectV: true });
        }}
        openCarSelect={() => {
          setModalState({ ...modalState, carSelectV: true });
        }}
        handleComplete={() => {
          handleCustomerSave();
        }}
      />
      {/* 会员卡选择弹框 */}
      {/* <VipSelectModal
        visible={modalState.vipSelectV}
        closeModal={() => {
          setModalState({ ...modalState, vipSelectV: false });
        }}
        handleModalState={(data) => {
          console.log(data);
          let { curVip } = data;
          setModalState({
            ...modalState,
            vipSelectV: false,
            curCustomer: {
              ...modalState.curCustomer,
              member_card: curVip.bianhao,
            },
          });
        }}
      /> */}
      {/* 会员卡多选 */}
      <MeberMultiSelectModal
        visible={modalState.vipSelectV}
        // listSelected={modalState.curCustomer.member_card || []}
        listSelected={modalState.defaultData?.member_card || []}
        meberSelected={modalState.curCustomer.member_card_info || []}
        // meberSelected={modalState.curCustomer.member_card || []}
        closeModal={() => {
          setModalState({ ...modalState, vipSelectV: false });
        }}
        handleModalState={(data) => {
          console.log(data);
          let { curVip } = data;
          setModalState({
            ...modalState,
            vipSelectV: false,
            curCustomer: {
              ...modalState.curCustomer,
              member_card: curVip.bianhao,
            },
          });
        }}
        handleComplete={(data, list) => {
          console.log(data, list);
          setModalState({
            ...modalState,
            curCustomer: {
              ...modalState.curCustomer,
              member_card: data,
              member_card_info: list,
            },
            vipSelectV: false,
          });
        }}
      ></MeberMultiSelectModal>
      {/* 车辆多选 */}
      {modalState.carSelectV && (
        <CarMultiSelectModal
          visible={modalState.carSelectV}
          listSelected={modalState.curCustomer.car_ids || []}
          // selectedState={state.list[0]?.car_ids || []}
          selectedState={modalState.defaultData?.car_ids || []}
          closeModal={() => {
            setModalState({ ...modalState, carSelectV: false });
          }}
          handleComplete={(data) => {
            console.log(data);
            setModalState({
              ...modalState,
              curCustomer: { ...modalState.curCustomer, car_ids: data },
              carSelectV: false,
            });
          }}
        />
      )}

      <CarEditModal
        visible={modalState.carEditV}
        curCarId={modalState.curCarId}
        closeModal={() => {
          setModalState({ ...modalState, carEditV: false, curCarId: "" });
        }}
        wxEdit
      />

      {modalState.vipEditV && (
        <VipEditModal
          visible={modalState.vipEditV}
          closeModal={() => {
            setModalState({ ...modalState, vipEditV: false, curVip: "" });
          }}
          selectedVip={modalState.curVip}
          editType
          wxEdit
        />
      )}
      {/* 下载二维码 弹框 */}
      <Modal
        width={557}
        className="down-load"
        visible={visible}
        title="下载二维码"
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {/* <img
          ref={qrcode}
          src="https://bos-sanfeng9.bj.bcebos.com/shop/3226/37863/11652/web/16587973935212.jpg"
          alt=""
        /> */}
        <div className="down-title">
          <span>客户微信关注后 会进入你的微信客户列表，便于管理</span>
        </div>
        <div className="down-box-button">
          <img
            src={`https://api.sanfeng9.com/shop/shop_qrcode.php?token=${user.token()}`}
            alt=""
          />{" "}
          <div className="down-imgs">
            <button onClick={saveQRCode} className="down-button">
              下载
              {/* <a
              href={`https://api.sanfeng9.com/shop/shop_qrcode.php?token=${user.token()}`}
            >
              下载
            </a> */}
            </button>
          </div>
        </div>
      </Modal>
      {/* <canvas style={{ width: 1080, height: 1080 }} id="myCanvas"></canvas> */}
    </div>
  );
};
export default WxCustomer;
