//设置  账号管理
import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Radio,
  Divider,
  Popconfirm,
  message,
  Tag,
} from "antd";
import SearchBar from "../../components/SearchBar";
import logo from "../../libs/logo.png";
import PageControl from "../../components/PageControl";
import StaticSelect from "../../components/StaticSelect";
import _Api from "../../core/api";
import "./AccountPage.less";
import EditAccountModal from "../../components/modals/AccountEdit";

const Account = ({}) => {
  const [state, setState] = useState({
    keyword: "",
    sex: "",
    isJiedai: "",
    isJishi: "",
    isLogin: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "0",
  });
  const [modalState, setModalState] = useState({
    editV: false,
    editType: false,
    editPermission: [],
    info: {},
  });
  useEffect(() => {
    getAccountList();
    getEditPermission();
  }, [state.sex, state.isJiedai, state.isJishi,state.isLogin]);
  function getEditPermission(params) {
    _Api.getLimitDic({ id: "" }).then((res) => {
      let data = res.msg;
      let arr = data.map((item, index) => {
        return {
          id: index + 1,
          content: item.limit_group,
          parent: 0,
          children: item.item.map((x) => {
            return {
              id: x.data_code,
              content: x.data_value,
              parent: index + 1,
              selected: false,
            };
          }),
        };
      });
      for (let item of arr) {
        if (item.children) {
          arr = arr.concat(item.children);
          delete item.children;
        }
      }
      setModalState({ ...modalState, editPermission: arr });
    });
  }
  function getAccountList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    const {
      keyword,
      isJishi: is_jishi,
      isJiedai: is_jiedai,
      sex,
      isLogin: is_login,
    } = state;
    let data = {
      name_phone: keyword,
      sex: sex,
      is_jiedai,
      is_jishi,
      is_login,
      count: pageSize,
      page: pageNo,
    };
    _Api.userSelect(data).then((res) => {
      let _data = res.data;
      setState({
        ...state,
        list: _data.content,
        total: _data.allcount,
        pageNo: _data.curpage,
        pageSize: _data.pagecount,
      });
    });
  }
  function handleDelete(id) {
    _Api.userDelete({ id }).then((res) => {
      message.success(res.msg);
      const { pageSize, pageNo } = state;
      getAccountList({ pageSize, pageNo });
    });
  }
  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
      width: "240px",
      render: (text, record) => (
        <div className="account-name">
          <div className="avatar">
            {record.head_img ? (
              <img src={record.head_img} alt="" />
            ) : (
              <img
                src={logo}
                alt=""
                style={{ objectFit: "cover", width: "40px" }}
              />
            )}
          </div>
          <span>{text}</span>
          {record.is_super === "1" && (
            <span className="icon iconfont">&#xe66a;</span>
          )}
        </div>
      ),
    },
    {
      title: "性别",
      dataIndex: "sex",
      align: "center",
      //   render: (text) => (text ? text : "未知"),
    },
    { title: "手机", dataIndex: "phone" },

    {
      title: "职务",
      dataIndex: "is_jiedai",
      render: (text, record) => (
        <>
          {text === "1" ? <Tag color="magenta">接待</Tag> : null}
          {record.is_jishi === "1" && <Tag color="green">技师</Tag>}
        </>
      ),
    },
    {
      title: "登录权限",
      dataIndex: "is_login",
      align: "center",
      render: (text) =>
        text === "1" ? (
          <span
            className="icon iconfont theme-color"
            style={{ fontSize: "18px" }}
          >
            &#xe64b;
          </span>
        ) : (
          <span
            className="icon iconfont"
            style={{ fontSize: "16px", color: "#ccc" }}
          >
            &#xe642;
          </span>
        ),
    },
    { title: "备注", dataIndex: "remark" },
    {
      title: "操作",
      dataIndex: "is_super",
      key: "operation",
      align: "center",
      //eslint-disable-next-line
      render: (text, record) =>
        text === "1" ? null : (
          <>
            <span
              className="click-able theme-color"
              onClick={() => {
                console.log(record);
                setModalState({
                  ...modalState,
                  editType: true,
                  editV: true,
                  info: record,
                });
              }}
            >
              编辑
            </span>
            <Divider type="vertical" />
            <Popconfirm
              placement="left"
              title="删除后无法恢复，是否确认删除"
              onConfirm={() => {
                handleDelete(record.id);
              }}
              okText="确认"
              cancelText="取消"
            >
              <span className="click-able theme-color">删除</span>
            </Popconfirm>
          </>
        ),
    },
  ];
  const options = [
    { label: "全部", value: "" },
    { label: "是", value: "1" },
    { label: "否", value: "0" },
  ];
  const SexOptions = [
    { label: "全部", value: "" },
    { label: "男", value: "男" },
    { label: "女", value: "女" },
  ];
  const loginOptions = [
    { label: "全部", value: "" },
    { label: "开通", value: "1" },
    { label: "关闭", value: "0" },
  ];
  return (
    <div className="page">
      <div className="page-content">
        <section className="content-header">
          <span>员工管理</span>
        </section>
        <div className="template-orgnization-account box">
          <div className="box-header">
            <Row type="flex" justify="space-around">
              <Col>
                <div style={{ display: "flex", flexBasis: "20%" }}>
                  <SearchBar
                    searchText={state.keyword}
                    onChangeText={(text) => {
                      setState({ ...state, keyword: text });
                    }}
                    onSearch={getAccountList}
                    label="搜索员工"
                    placeholder="请输入名称/手机号"
                  />
                </div>

                <div style={{ display: "flex", flexBasis: "74%" }}>
                  <StaticSelect label="性别：">
                    <Radio.Group
                      options={SexOptions}
                      value={state.sex}
                      onChange={(e) => {
                        setState({
                          ...state,
                          sex: e.target.value,
                        });
                      }}
                      optionType="button"
                    />
                  </StaticSelect>
                  <StaticSelect label="接待员：">
                    <Radio.Group
                      options={options}
                      onChange={(e) => {
                        setState({
                          ...state,
                          isJiedai: e.target.value,
                        });
                      }}
                      value={state.isJiedai}
                      optionType="button"
                    />
                  </StaticSelect>
                  <StaticSelect label="技师：">
                    <Radio.Group
                      options={options}
                      onChange={(e) => {
                        setState({
                          ...state,
                          isJishi: e.target.value,
                        });
                      }}
                      value={state.isJishi}
                      optionType="button"
                    />
                  </StaticSelect>
                  <StaticSelect label="登录权限：">
                    <Radio.Group
                      options={loginOptions}
                      onChange={(e) => {
                        setState({
                          ...state,
                          isLogin: e.target.value,
                        });
                      }}
                      value={state.isLogin}
                      optionType="button"
                    />
                  </StaticSelect>
                </div>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setModalState({ ...modalState, editV: true });
                  }}
                >
                  新增员工
                </Button>
              </Col>
            </Row>
          </div>
          <div className="box-body">
            <Table
              bordered
              rowKey={(record) => record.id}
              pagination={false}
              dataSource={state.list}
              columns={columns}
            />
          </div>
          <div className="box-footer">
            <PageControl
              total={state.total}
              pageSize={state.pageSize}
              current={state.pageNo}
              showSizeChanger
              toPage={(page, count) => {
                getAccountList({ pageNo: page, pageSize: count });
              }}
            />
          </div>
        </div>
      </div>
      <EditAccountModal
        visible={modalState.editV}
        editType={modalState.editType}
        editPermission={modalState.editPermission}
        curAccount={modalState.info}
        closeModal={() => {
          setModalState({ ...modalState, editType: false, editV: false });
        }}
        handleComplete={() => {
          getAccountList();
          setModalState({ ...modalState, editType: false, editV: false });
        }}
      />
    </div>
  );
};
export default Account;
