import { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  Form,
  Popconfirm,
  Table,
  message,
} from "antd";
import _Api from "../../core/api";
import mathFunc from "../../core/mathfunc";

//eslint-disable-next-line
const Receive = ({
  visible,
  closeModal,
  modalInfo,
  handlePayOver,
  handleCharge,
}) => {
  const [payState, setPayState] = useState({
    type: "",
    money: "",
    inputUser: "",
    remark: "",
  });
  const [payTypes, setPayTypes] = useState([]);
  const [payHistory, setPayHistory] = useState([]);

  useEffect(() => {
    if (visible) {
      getCurrentUser();
      getPayTypes();
      getPayHistory();
    }
  }, [visible]);

  const SubTitle = ({ title }) => (
    <div
      style={{
        fontSize: "15px",
        color: "#333",
        fontWeight: "500",
        marginBottom: "10px",
        borderLeft: "solid 5px #ff8534",
        paddingLeft: "5px",
      }}
    >
      {title}
    </div>
  );
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "收款时间",
      dataIndex: "gmt_creat",
    },
    {
      title: "收款方式",
      dataIndex: "pay_type",
    },
    {
      title: "收款金额",
      dataIndex: "money_real",
    },
    {
      title: "收款人",
      align: "center",
      dataIndex: "input_user",
      render: (text) => (text ? text : "-"),
    },
  ];
  const slipAr = useMemo(() => {
    console.log(modalInfo);
    // let serviceAr = (modalInfo.service_content && modalInfo.service_content.ar) || 0,
    // 	partAr = (modalInfo.part_content && modalInfo.part_content.ar) || 0;
    // return mathFunc.returnFloat(parseFloat(serviceAr) + parseFloat(partAr));
    let moneyAll = (modalInfo.money_all && modalInfo.money_all) || 0,
      moneypay = (modalInfo.money_pay && modalInfo.money_pay) || 0;
    return mathFunc.returnFloat(parseFloat(moneyAll) - parseFloat(moneypay));
  }, [
    modalInfo.service_content && modalInfo.service_content,
    modalInfo.part_content,
  ]);

  const payAll = useMemo(() => {
    if (modalInfo.member_card) {
      return true;
    }
    let realMoney = parseFloat(payState.money) || 0;
    return realMoney >= slipAr;
  }, [slipAr, payState.money]);
  function settleConfirm(params) {
    if (!payAll) {
      return;
    } else {
      orderSettle();
    }
  }
  function orderSettle(debt) {
    const { type, money, inputUser, remark } = payState;
    let data = {
      id: modalInfo.id,
      money_real: money,
      pay_type: type,
      input_user: inputUser,
      remark,
    };
    if (modalInfo.service_content) {
      data.if_guazhang = debt ? "1" : "0";
    }
    const settleApi = modalInfo.service_content
      ? _Api.orderCaiwuInsert(data)
      : _Api.partCaiwuInsert(data);
    // console.log(data);

    settleApi
      .then((res) => {
        if (res.response === "500521" && res.card) {
          Modal.confirm({
            title: "提示",
            content: (
              <>
                <p
                  style={{
                    fontSize: "15px",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {res.msg}
                </p>
                <p style={{ textAlign: "center", margin: "10px 0" }}>
                  <span style={{ marginRight: "25px" }}>
                    {"会员卡号：" + res.card}
                  </span>
                  余额：{" "}
                  <span style={{ color: "#EB3223" }}>{res.money_left}</span>元
                </p>
                <p style={{ textAlign: "center" }}>
                  本次项目应付款：
                  <span style={{ color: "#EB3223" }}>{slipAr}</span>
                  元，最低充值金额：
                  <span style={{ color: "#EB3223" }}>{res.money_need}</span>元
                </p>
              </>
            ),
            okText: "去充值",
            onOk: () => handleCharge(),
          });
        } else if (res.response === "200") {
          message.success(res.msg);
          setPayState({ type: "", money: "", inputUser: "", remark: "" });
          handlePayOver();
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getCurrentUser(params) {
    _Api.userInfoGet().then((res) => {
      let user = res.token_info.user_shop.cur_user.user_info.name;
      setPayState({ ...payState, inputUser: user });
    });
  }

  function getPayTypes() {
    _Api.getPayType().then((res) => {
      setPayTypes(res.msg);
    });
  }

  function getPayHistory() {
    const data = { order_bianhao: modalInfo.bianhao };
    _Api.caiwuSelect(data).then((res) => {
      // console.log(res);
      setPayHistory(res.data.content);
    });
  }

  function close() {
    setPayState({
      type: "",
      money: "",
      inputUser: "",
      remark: "",
    });
    closeModal();
  }

  return (
    <Modal
      title="收款"
      footer={null}
      zIndex={900}
      width={800}
      visible={visible}
      onCancel={close}
    >
      <Form style={{ color: "#666" }}>
        <SubTitle title="信息确认" />

        <Row type="flex">
          <Col span={8}>
            <Form.Item label="单号">
              <span>{modalInfo.bianhao}</span>
            </Form.Item>
            {modalInfo.service_content && (
              <Form.Item label="车牌号">
                <span>{modalInfo.car_id}</span>
              </Form.Item>
            )}
          </Col>

          {modalInfo.service_content ? (
            <>
              <Col span={7}>
                <Form.Item label="联系人">
                  <span>{modalInfo.car_linkman}</span>
                </Form.Item>
                <Form.Item label="接待人">
                  <span>{modalInfo.worker}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="电话">
                  <span>{modalInfo.car_phone}</span>
                </Form.Item>
                <Form.Item label="结算时间">
                  <span>{modalInfo.end_time}</span>
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={8}>
              <Form.Item label="客户">
                <span>{modalInfo.customer}</span>
              </Form.Item>
            </Col>
          )}
        </Row>

        <SubTitle title="收款信息" />
        <Row type="flex" justify="space-between">
          <Form.Item label="应收金额">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "19px", color: "#333" }}>{slipAr}</span>
              <span>元</span>
            </div>
          </Form.Item>
          {modalInfo.member_card ? (
            <Form.Item label="会员卡号">{modalInfo.member_card}</Form.Item>
          ) : (
            <Form.Item label="收款金额" style={{ marginLeft: "40px" }}>
              <Input
                suffix={<span>元</span>}
                style={{ width: "100px", textAlign: "center" }}
                value={payState.money}
                onChange={(e) => {
                  setPayState({
                    ...payState,
                    money: e.target.value,
                  });
                }}
              />
              <Button
                style={{ height: "32px", marginLeft: "10px" }}
                onClick={() => {
                  setPayState({ ...payState, money: slipAr });
                }}
              >
                全额收款
              </Button>
            </Form.Item>
          )}
          <Form.Item label="经办人" style={{ marginLeft: "20px" }}>
            <Input
              value={payState.inputUser}
              onChange={(e) => {
                setPayState({ ...payState, inputUser: e.target.value });
              }}
            />
          </Form.Item>
        </Row>

        {modalInfo.member_card ? null : (
          <Form.Item label="收款方式">
            <Row>
              {payTypes.map((item) => (
                <div
                  onClick={() => {
                    setPayState({ ...payState, type: item.text });
                  }}
                  key={item.id}
                  style={{
                    height: "36px",
                    lineHeight: "36px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor:
                      item.text === payState.type ? "#ff8534" : "#D9D9D9",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "10px 10px",
                    borderRadius: "5px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="icon iconfont"
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                    style={{
                      fontSize: "22px",
                      marginRight: "3px",
                      color: item.color,
                    }}
                  ></span>
                  {item.text}
                </div>
              ))}
            </Row>
          </Form.Item>
        )}

        <Form.Item label="备注信息">
          {/* <TextArea rows={2} style={{ width: "457px" }} /> */}
          <Input
            value={payState.remark}
            onChange={(e) => {
              setPayState({ ...payState, remark: e.target.value });
            }}
          />
        </Form.Item>
      </Form>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Popconfirm
          placement="top"
          disabled={payAll}
          title={
            <>
              <p>您填写的收款金额不足应收金额</p>
              <p>请确认结算完毕还是挂帐</p>
            </>
          }
          onConfirm={() => {
            if (modalInfo.service_content) {
              orderSettle(true);
            }
          }}
          onCancel={() => {
            orderSettle();
          }}
          okText={modalInfo.service_content ? "挂帐" : "取消"}
          cancelText="结算完毕"
        >
          <Button type="primary" onClick={settleConfirm}>
            收款
          </Button>
        </Popconfirm>
      </div>
      {payHistory.length > 0 && (
        <>
          <SubTitle title="收款历史" />
          <Table
            dataSource={payHistory}
            columns={columns}
            bordered
            pagination={false}
          />
        </>
      )}
    </Modal>
  );
};
export default Receive;
