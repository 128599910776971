import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table } from "antd";
import PageControl from "../PageControl";
import Spinner from "../Spinner";
import Api from "../../core/api";
export default ({ visible, closeModal, serviceAdd, selectedIds }) => {
	const [loadFlag, setLoadFlag] = useState(true);
	const [searchContent, setSearchContent] = useState("");
	const [tableState, setTableState] = useState({
		data: [],
		total: "",
		allPage: "",
		pageSize: "10",
		pageNo: "1",
	});
	useEffect(() => {
		if (visible) {
			getServiceList();
		}
	}, [visible]);
	const getServiceList = (pageState) => {
		let pageSize = pageState&&pageState.pageSize || "10";
		let pageNo =  pageState&&pageState.pageNo || "1";
		setLoadFlag(true);
		let data = { keyword: searchContent, count: pageSize, page: pageNo };
		Api.serviceSelect(data)
			.then((res) => {
				let _data = res.data.content;
				_data.forEach((item) => {
					if (selectedIds.indexOf(item.id) > -1) {
						item.selected = 2;
					} else {
						item.selected = 0;
					}
				});
				setTableState({
					data: _data,
					pageSize: res.data.pagecount,
					total: res.data.allcount,
					allPage: res.data.allpage,
					pageNo: res.data.curpage,
				});
				setLoadFlag(false);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	const handleSelect = (id) => {
		let tableData = tableState.data;
		tableData.forEach((item) => {
			if (item.id == id) {
				item.selected = 1 - item.selected;
			}
		});
		setTableState({ ...tableState, data: tableData });
	};
	const handleOk = () => {
		let arr = [];
		tableState.data.forEach((item) => {
			if (item.selected === 1) {
				arr.push({ id: item.id, bianhao: item.bianhao, name: item.name, price: item.price, hours: item.hours, worker: "" });
			}
		});
		serviceAdd(arr);
		// closeModal();
	};

	const columns = [
		{
			title: "序号",
			dataIndex: "id",
			render: (text, record, index) => <span>{index}</span>,
		},
		{
			title: "编号",
			dataIndex: "bianhao",
		},
		{
			title: "项目名称",
			dataIndex: "name",
		},
		{
			title: "单价",
			dataIndex: "price",
			align: "center",
			render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
		},
		{
			title: "工时",
			dataIndex: "hours",
			align: "center",
			render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
		},
		{
			title: "添加",
			dataIndex: "selected",
			key: "selected",
			align: "center",
			render: (text, record) =>
				text === 2 ? (
					<span className='icon iconfont' style={{ color: "#C3C3C3", fontSize: "20px", cursor: "not-allowed" }}>
						&#xe679;
					</span>
				) : text === 1 ? (
					<span
						className='icon iconfont'
						style={{ color: "#F09F74", fontSize: "20px", cursor: "pointer" }}
						onClick={() => handleSelect(record.id)}>
						&#xe679;
					</span>
				) : (
					<span
						className='icon iconfont'
						style={{ color: "#C3C3C3", fontSize: "20px", cursor: "pointer" }}
						onClick={() => handleSelect(record.id)}>
						&#xe720;
					</span>
				),
		},
	];
	return (
		<Modal title='选择服务项目' width={900} visible={visible} onCancel={closeModal} onOk={handleOk}>
			<Row type='flex' align='middle'>
				<Col span={12}>
					<Input
						placeholder='编号/项目名称'
						value={searchContent}
						onChange={(e) => {
							setSearchContent(e.target.value);
						}}
					/>
				</Col>
				<Button type='primary' style={{ marginLeft: "10px" }} onClick={getServiceList}>
					查询
				</Button>
			</Row>

			{loadFlag ? (
				<Spinner spinner />
			) : (
				<>
					<Table
						rowKey={(record) => record.id}
						pagination={false}
						dataSource={tableState.data}
						columns={columns}
						bordered
						style={{ marginTop: "20px", marginBottom: "40px" }}
						// locale={{ emptyText: "请点击表格右上方按钮选择或新增服务项目" }}
					/>
					<PageControl
						total={tableState.total}
						pageSize={tableState.pageSize}
						current={tableState.pageNo}
						showSizeChanger
						toPage={(page, count) => {
							getServiceList({ pageNo: page, pageSize: count });
						}}
					/>
				</>
			)}
		</Modal>
	);
};
