import React from 'react';
// import PropTypes from 'prop-types';
import { Input } from 'antd';
import showEye from "../libs/show_eye.png"
import hideEye from "../libs/hide_eye.png"

export default class MeberPassword extends React.Component {
//   static defaultProps = {};

//   static propTypes = {
//     onChange: PropTypes.func,
//     id: PropTypes.string,
//   };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }  

  // 密码显示
  onShowPwd = e => {
    e.stopPropagation();
    this.setState(
      {
        isShowValue: !this.state.isShowValue,
      },
      () => {
        this.refInput.focus();
        // this.removeAttributeFun();
      },
    );
  };

  // 输入变化
  onChange = e => {
    const { onChange } = this.props;
    this.setState({
      inputValue: e.target.value,
    });
    if (onChange) {
      onChange(e.target.value);
    }
  };


  render() {
    const { id, onChange,value, ...restProps } = this.props;
    const { inputValue, isShowValue } = this.state;
    return (
      <Input
        suffix={
          isShowValue ? (
            <img src={showEye} style={{width: 20,height:20}} alt="" onClick={this.onShowPwd}></img>
            // <Icon type="eye" onClick={this.onShowPwd} />
          ) : (
            <img src={hideEye} style={{width: 20,height:20}} alt="" onClick={this.onShowPwd}></img>
            // <Icon type="eye-invisible" onClick={this.onShowPwd} />
          )
        }
        onChange={this.onChange}
        autoComplete="new-password"
        {...restProps}
        id={id || 'password'}
        ref={el => (this.refInput = el)}
        // value={inputValue}
        value={value}
        type={!isShowValue ? 'password' : 'text'}
      />
    );
  }
}

