import { useState, useEffect, useContext } from "react";
import { Form, Input, Button, message } from "antd";
import ShopContext from "../../core/context-manager";
import _Api from "../../core/api";
import ImgPicker from "../multipleshops/ImgPicker";
import PrintPreview from "./PrintPreview";

const PrintSetting = ({ tabKey }) => {
	const { branchInfo, resetShopInfo } = useContext(ShopContext);
	const [state, setState] = useState({
		title: "结算单主标题",
		subTitle: "结算单副标题",
		qrcode: "",
		phone: "",
		address: "",
		remark: "备注信息",
	});

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 16 },
	};
	useEffect(() => {
		initState();
	}, [branchInfo]);
	function initState() {
		const { print_title_1, print_title_2, print_qrcode, print_remark, phone, address } = branchInfo;
		setState({
			...state,
			title: print_title_1,
			subTitle: print_title_2,
			qrcode: print_qrcode,
			remark: print_remark,
			phone,
			address,
		});
	}
	function handleUpdate() {
		const { title, subTitle, qrcode, remark, phone, address } = state;
		let data = {
			print_title_1: title,
			print_title_2: subTitle,
			print_qrcode: qrcode,
			print_remark: remark,
			phone,
			address,
		};
		_Api.printSet(data).then((res) => {
			message.success(res.msg);
			resetShopInfo();
		});
	}
	return (
		<div className='box print-setting'>
			<div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
				<div style={{ width: "42%" }}>
					<Form {...layout}>
						<Form.Item label='主标题'>
							<Input
								placeholder='请输入主标题'
								defaultValue='结算单主标题'
								value={state.title}
								onChange={(e) => {
									setState({ ...state, title: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='副标题'>
							<Input
								placeholder='请输入副标题'
								value={state.subTitle}
								onChange={(e) => {
									setState({ ...state, subTitle: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='二维码'>
							<div style={{ border: "1px solid #E8E8E8", width: "82px" }}>
								<ImgPicker
									c_width={100}
									c_height={100}
									imgdata={state.qrcode}
									SetImg={(value) => {
										setState({ ...state, qrcode: value });
									}}
								/>
							</div>
						</Form.Item>
						<Form.Item label='联系电话'>
							<Input
								placeholder='请输入咨询电话'
								value={state.phone}
								onChange={(e) => {
									setState({ ...state, phone: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='联系地址'>
							<Input
								placeholder='请输入公司地址'
								value={state.address}
								onChange={(e) => {
									setState({ ...state, address: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='备注'>
							<Input.TextArea
								placeholder='请输入门店介绍'
								value={state.remark}
								onChange={(e) => {
									setState({ ...state, remark: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item wrapperCol={{ offset: 4, span: 16 }}>
							<Button
								type='primary'
								onClick={() => {
									handleUpdate();
								}}>
								更新信息
							</Button>
						</Form.Item>
					</Form>
				</div>
				<div style={{ flex: 1 }}>
					<PrintPreview printState={state} branchInfo={branchInfo} />
				</div>
			</div>
		</div>
	);
};
export default PrintSetting;
