import { useEffect, useState } from "react";
import {
  Tooltip,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Table,
  Button,
  Drawer,
  message,
} from "antd";
import SearchBar from "../../components/SearchBar";
import StaticSelect from "../../components/StaticSelect";
import CommenSelect from "../../components/CommonSelect";
import PageControl from "../../components/PageControl";
import moment from "moment";
import _Api from "../../core/api";
// import VipChargeModal from "../../components/modals/VipCharge";
import RechargeDetails from "../../components/modals/RechargeDetails";
import SlipDetailModal from "../../components/modals/SlipDetail";
import SaleEditModal from "../../components/modals/SaleEdit";
import SaleDetail from "../../components/modals/SaleDetail";
import ExpensesImg from "../../libs/meber_expenses.png";
const { RangePicker } = DatePicker;

const IncomeDetail = ({}) => {
  const [state, setState] = useState({
    orderId: "",
    cardId: "",
    carId: "",
    payType: "",
    caiwuType: "",
    remark: "",
    dateStart: "",
    dateEnd: "",
    moneyMin: "",
    moneyMax: "",
    filter: false,
    list: [],
    total: "0",
    pageNo: "1",
    pageSize: "10",
    moneyRealPage: "",
    moneyMemberPage: "",
    moneyCzPage: "",
    moneyCzzsPage: "",
    moneyRealAll: "",
    moneyMemberAll: "",
    moneyCzAll: "",
    moneyCzzsAll: "",
    countDrawer: false,
  });
  const [vipState, setVipState] = useState({
    cardId: "",
    visible: false,
    slip: {},
  });
  const [orderState, setOrderState] = useState({
    slip: {},
    visible: false,
  });
  const [saleState, setSaleState] = useState({
    curCustomer: "",
    curMemberCard: "",
    curPartZk: 1,
    curPartContent: { data: [] },
    visible: false,
    content: {},
  });
  const [payTypes, setPayTypes] = useState([]);
  useEffect(() => {
    getPayTypes();
  }, []);
  useEffect(() => {
    getIncomeList();
  }, [state.payType, state.caiwuType, state.dateStart, state.dateEnd]);
  function getPayTypes() {
    _Api.getPayType().then((res) => {
      let types = res.msg.map((item) => ({ id: item.id, name: item.text }));
      setPayTypes(types);
    });
  }
  function getIncomeList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10",
      pageNo = (pageState && pageState.pageNo) || "1";
    console.log(pageSize);
    const {
        orderId,
        cardId,
        carId,
        payType,
        caiwuType,
        dateStart,
        dateEnd,
        remark,
        moneyMin,
        moneyMax,
      } = state,
      data = {
        order_bianhao: orderId,
        member_card: cardId,
        car_id: carId,
        caiwu_type: caiwuType,
        pay_type: payType,
        money_real_min: moneyMin,
        money_real_max: moneyMax,
        remark,
        start_time: dateStart,
        end_time: dateEnd,
        count: pageSize,
        page: pageNo,
      };
    console.log(data);
    _Api.caiwuSelect(data).then((res) => {
      setState({
        ...state,
        list: res.data.content,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
        moneyRealPage: res.data.money_real_page,
        moneyMemberPage: res.data.money_member_page,
        moneyCzPage: res.data.money_member_cz_page,
        moneyCzzsPage: res.data.money_member_czzs_page,
        moneyRealAll: res.data.money_real_all,
        moneyMemberAll: res.data.money_member_all,
        moneyCzAll: res.data.money_member_cz_all,
        moneyCzzsAll: res.data.money_member_czzs_all,
      });
    });
  }
  function toggleFilter() {
    setState({ ...state, filter: !state.filter });
  }
  function reset() {
    setState({
      ...state,
      orderId: "",
      cardId: "",
      carId: "",
      payType: "",
      caiwuType: "",
      remark: "",
      dateStart: "",
      dateEnd: "",
      moneyMin: "",
      moneyMax: "",
    });
  }
  function handleScan(record) {
    console.log(record);
    switch (record.caiwu_type) {
      case "会员卡充值":
        return setVipState({ ...vipState, slip: record, visible: true });
      case "配件销售":
        _Api
          .partSaleSelect({
            keyword: record.order_bianhao,
            sale_type: "销售",
            order_state: "已结算",
          })
          .then((res) => {
            if (res.data.content.length === 0) {
              return message.error("本条数据存在异常！");
            }
            const { wx_openid } = res.data.content[0];
            const data = res.data.content[0];
            if (wx_openid) {
              _Api.wxUserSelect({ openid: wx_openid }).then((res) => {
                let wxInfo = res.data.content[0];
                setSaleState({
                  ...saleState,
                  visible: true,
                  content: {
                    ...data,
                    wx_name: wxInfo.name,
                  },
                });
              });
            } else {
              setSaleState({
                ...saleState,
                visible: true,
                content: data,
              });
            }
          });
        // _Api
        //   .partSaleSelect({
        //     keyword: record.order_bianhao,
        //     sale_type: "销售",
        //     order_state: "已结算",
        //   })
        //   .then((res) => {
        //     const { customer, member_card, part_zk, parts } =
        //       res.data.content[0] || {};
        //     setSaleState({
        //       curCustomer: customer,
        //       curMemberCard: member_card,
        //       curPartZk: part_zk,
        //       curPartContent: JSON.parse(parts),
        //       visible: true,
        //     });
        //   });

        break;

      case "工单结算":
        _Api.orderSelect({ keyword: record.order_bianhao }).then((res) => {
          let data = res.data.content[0];
          data = {
            ...data,
            part_content: JSON.parse(data.part_content),
            service_content: JSON.parse(data.service_content),
            car_imgs: JSON.parse(data.car_imgs),
          };
          if (data.car_imgs === "null") {
            data.car_imgs = {
              frontLeft: [],
              frontRight: [],
              backLeft: [],
              backRight: [],
              other: [],
            };
          }
          setOrderState({ slip: data, visible: true });
        });
        break;

      default:
        break;
    }
  }
  const caiwuTypes = [
    { id: "工单结算", name: "工单结算" },
    { id: "配件销售", name: "配件销售" },
    { id: "会员卡充值", name: "会员卡充值" },
  ];
  const columns = [
    {
      title: "财务类型",
      align: "center",
      dataIndex: "caiwu_type",
    },
    {
      title: "单据编号",
      dataIndex: "order_bianhao",
      align: "center",
      render: (text, record) => {
        if (
          record.caiwu_type === "工单结算" ||
          record.caiwu_type === "配件销售"
        ) {
          return (
            <div
              style={
                {
                  // display: "flex",
                  // alignItems: "center",
                }
              }
            >
              <span>{text}</span>
              {record.member_card && (
                <Tooltip title={record.member_card}>
                  <span
                    className="icon iconfont theme-color click-able"
                    style={{ fontSize: "15px", marginLeft: "5px" }}
                  >
                    &#xe640;
                  </span>
                </Tooltip>
              )}
            </div>
          );
        } else {
          if (text) {
            return text;
          } else {
            return (
              <Tooltip
                // title={
                //   <span
                //     onClick={() => handleMemberCard(text)}
                //     className="click-able"
                //   >
                //     {text}
                //   </span>
                // }
                title={record.member_card}
              >
                <div>
                  <span
                    className="icon iconfont theme-color click-able"
                    style={{
                      fontSize: "15px",
                      display: "inline-block",
                      // marginLeft: "25px",
                    }}
                  >
                    &#xe640;
                  </span>
                </div>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "car_id",
    },
    {
      title: "联系人",
      align: "center",
      dataIndex: "linkman",
    },
    {
      title: "金额",
      align: "left",
      dataIndex: "money_member",
      render: (text, record) => {
        return (
          <>
            <span>
              {record.caiwu_type === "工单结算"
                ? `${
                    record.member_card
                      ? `¥0.00`
                      : `${
                          parseInt(text) === 0
                            ? "¥" + record.money_real
                            : "¥" + text
                        }`
                  }`
                : `¥${record.money_real}`}
            </span>
            {record.caiwu_type !== "会员卡充值" && record.member_card && (
              <Tooltip
                placement="top"
                title={
                  record.member_num === "0.0"
                    ? `会员卡抵扣：${record.money_member}元`
                    : `会员卡抵扣：${record.member_num}次`
                }
              >
                <img
                  style={{ width: 16, height: 16, marginLeft: 9 }}
                  src={ExpensesImg}
                  alt=""
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "时间",
      align: "center",
      dataIndex: "gmt_creat",
    },
    {
      title: "备注",
      align: "center",
      dataIndex: "remark",
      render: (text) =>
        text && text.length > 5 ? (
          <Tooltip placement="topLeft" title={text}>
            <span>{text.substring(0, 5) + "..."}</span>
          </Tooltip>
        ) : (
          text
        ),
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "id",
      render: (text, record) => (
        <span
          className="theme-color click-able"
          onClick={() => handleScan(record)}
        >
          查看
        </span>
      ),
    },
  ];
  const CountGroup = ({ children, style }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          height: "120px",
          width: "200px",
          ...style,
        }}
      >
        {children}
      </div>
    );
  };
  const CountItem = ({ style, text }) => (
    <div style={{ height: "36px", ...style }}>{text}</div>
  );
  return (
    <div className="page wage-manage-page">
      <div className="page-content">
        <div className="content-header">
          <h1>收入明细</h1>
        </div>
        <div className="box">
          <div className="box-header">
            <Row type="flex" justify="space-around" align="top">
              <Col>
                <SearchBar
                  searchText={state.orderId}
                  onChangeText={(text) => {
                    setState({ ...state, orderId: text });
                  }}
                  onSearch={getIncomeList}
                  label="单据编号"
                  placeholder="请输入单据编号"
                />
                <SearchBar
                  searchText={state.cardId}
                  onChangeText={(text) => {
                    setState({ ...state, cardId: text });
                  }}
                  onSearch={getIncomeList}
                  label="会员卡号"
                  placeholder="请输入充值卡号"
                />
                <SearchBar
                  searchText={state.carId}
                  onChangeText={(text) => {
                    setState({ ...state, carId: text });
                  }}
                  onSearch={getIncomeList}
                  label="车牌号"
                  placeholder="请输入车牌号"
                />

                {state.filter && (
                  <>
                    <CommenSelect
                      selectValue={state.chargeType}
                      label="支付方式"
                      options={payTypes}
                      onSelect={(selectValue) => {
                        setState({ ...state, payType: selectValue });
                      }}
                      placeholder="请选择充值方式"
                    />
                    <CommenSelect
                      selectValue={state.caiwuType}
                      label="财务类型"
                      options={caiwuTypes}
                      onSelect={(selectValue) => {
                        setState({ ...state, caiwuType: selectValue });
                      }}
                      placeholder="请选择财务类型"
                    />
                    <SearchBar
                      searchText={state.remark}
                      onChangeText={(text) => {
                        setState({ ...state, remark: text });
                      }}
                      onSearch={getIncomeList}
                      label="备注"
                      placeholder="请输入备注信息"
                    />
                    <StaticSelect label="时间:">
                      <RangePicker
                        style={{ width: "100%" }}
                        value={[
                          state.dateStart && moment(state.dateStart),
                          state.dateEnd && moment(state.dateEnd),
                        ]}
                        onChange={(memo, str) => {
                          setState({
                            ...state,
                            dateStart: str[0],
                            dateEnd: str[1],
                          });
                        }}
                      />
                    </StaticSelect>
                    <StaticSelect label="金额:">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          flex: "1",
                        }}
                      >
                        <InputNumber
                          placeholder="最小值"
                          step={100}
                          style={{ flex: "1" }}
                          value={state.moneyMin}
                          onChange={(num) => {
                            setState({ ...state, moneyMin: num });
                          }}
                          onPressEnter={getIncomeList}
                        />
                        <span style={{ margin: "0 5px", color: "#bfbfbf" }}>
                          ~
                        </span>
                        <InputNumber
                          placeholder="最大值"
                          step={100}
                          style={{ flex: "1" }}
                          value={state.moneyMax}
                          onChange={(num) => {
                            setState({ ...state, moneyMax: num });
                          }}
                          onPressEnter={getIncomeList}
                        />
                      </div>
                    </StaticSelect>
                  </>
                )}
              </Col>
              <Col>
                <Tooltip title="清空筛选">
                  <span
                    onClick={reset}
                    id="reset_btn"
                    className="resetBtn iconfont icon"
                  >
                    &#xe662;
                  </span>
                </Tooltip>
                <span
                  className="text-sub xm-filter"
                  onClick={toggleFilter}
                  style={{ cursor: "pointer" }}
                >
                  {state.filter ? (
                    <span>
                      收起
                      <span className="iconfont icon ml5">&#xe663;</span>
                    </span>
                  ) : (
                    <span>
                      展开
                      <span className="iconfont icon ml5">&#xe665;</span>
                    </span>
                  )}
                </span>
                <div style={{ paddingTop: "10px" }}></div>
              </Col>
            </Row>
          </div>
          <div className="box-body">
            <Table
              rowKey={(record) => record.id}
              dataSource={state.list}
              columns={columns}
              bordered
              style={{ marginTop: "20px", marginBottom: "40px" }}
              pagination={false}
            />
            <Row>
              <Col flex={1}>
                <PageControl
                  total={state.total}
                  pageSize={state.pageSize}
                  current={state.pageNo}
                  showSizeChanger
                  toPage={(page, count) => {
                    getIncomeList({ pageNo: page, pageSize: count });
                  }}
                />
              </Col>
              <Col>
                <Button
                  style={{ marginTop: "18px", marginLeft: "10px" }}
                  type="primary"
                  onClick={() => {
                    setState({ ...state, countDrawer: true });
                  }}
                >
                  数据统计
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Drawer
        title="数据统计"
        placement="bottom"
        // closable={true}
        onClose={() => {
          setState({ ...state, countDrawer: false });
        }}
        visible={state.countDrawer}
        zIndex={1100}
        height={200}
      >
        <Row justify="center">
          <CountGroup style={{ alignItems: "center" }}>
            <CountItem text="" />
            <CountItem text="本页" />
            <CountItem text="全部" />
          </CountGroup>
          <CountGroup>
            <CountItem text="普通消费" />
            <CountItem
              text={"¥" + state.moneyRealPage}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
            <CountItem
              text={"¥" + state.moneyRealAll}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
          </CountGroup>
          <CountGroup>
            <CountItem text="会员消费" />
            <CountItem
              text={"¥" + state.moneyMemberPage}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
            <CountItem
              text={"¥" + state.moneyMemberAll}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
          </CountGroup>
          <CountGroup>
            <CountItem text="会员充值" />
            <CountItem
              text={"¥" + state.moneyCzPage}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
            <CountItem
              text={"¥" + state.moneyCzAll}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
          </CountGroup>
          <CountGroup>
            <CountItem text="充值赠送" />
            <CountItem
              text={"¥" + state.moneyCzzsPage}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
            <CountItem
              text={"¥" + state.moneyCzzsAll}
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            />
          </CountGroup>
        </Row>
      </Drawer>
      <RechargeDetails
        visible={vipState.visible}
        slip={vipState.slip}
        closeModal={() => {
          setVipState({ ...vipState, visible: false });
        }}
      />
      <SlipDetailModal
        slipType="incomeDetail"
        visible={orderState.visible}
        slipContent={orderState.slip}
        closeModal={() => {
          setOrderState({ visible: false, slip: {} });
        }}
      />
      {/* 查看配件退货 */}
      <SaleDetail
        visible={saleState.visible}
        readOnly={true}
        modalState={saleState.content}
        // orderEdit={(data) => {
        //   handleEdit(data);
        // }}
        // handleReturn={handleReturn}
        // handleCanclePaid={handleCancleReturn}
        closeModal={() => {
          setSaleState({
            ...saleState,
            visible: false,
          });
        }}
      ></SaleDetail>
      {/* <SaleEditModal
        viewMode
        modalState={saleState}
        visible={saleState.visible}
        closeModal={() => {
          setSaleState({
            curCustomer: "",
            curMemberCard: "",
            curPartZk: 1,
            curPartContent: { data: [] },
            visible: false,
          });
        }}
      /> */}
    </div>
  );
};

export default IncomeDetail;
