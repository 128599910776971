import { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Tag } from "antd";
const WxCustomerModal = ({
  visible,
  closeModal,
  curCustomer,
  handleDataChange,
  openMemberCard,
  openCarSelect,
  handleComplete,
}) => {
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 19 },
  };

  function handleCarDelete(data) {
    let list = curCustomer.car_ids;
    const newList = list.filter((item) => item !== data);
    handleDataChange({ car_ids: newList });
  }
  function handleMeberDelete(data) {
    let list = curCustomer.member_card || [];
    const newList = list.filter((item) => item !== data);
    handleDataChange({ member_card: newList },true);
  }
  return (
    <Modal
      title="微信客户"
      width={450}
      visible={visible}
      onCancel={closeModal}
      onOk={handleComplete}
      zIndex={500}
      className="wx-customer-body"
    >
      <Form {...layout}>
        <Form.Item
          label={
            <p>
              <span style={{ color: "#EB3628" }}>*</span>姓名
            </p>
          }
        >
          <Input
            value={curCustomer.name}
            onChange={(e) => {
              handleDataChange({ name: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item label="电话">
          <Input
            value={curCustomer.phone}
            onChange={(e) => {
              handleDataChange({ phone: e.target.value });
            }}
          />
        </Form.Item>

        {/* <Form.Item label='会员卡'>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Input disabled value={curCustomer.member_card} />
						<Button style={{ marginLeft: "5px" }} onClick={() => handleDataChange({ member_card: "" })}>
							清空
						</Button>
						<Button style={{ marginLeft: "5px" }} type='primary' onClick={openMemberCard}>
							选择
						</Button>
					</div>
				</Form.Item> */}
        <Form.Item label="会员卡">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "rgb(232, 232, 232) 1px solid",
                minHeight: "64px",
                borderRadius: "4px",
                width: "76%",
                padding: "2px 10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                flexWrap: "wrap",
              }}
            >
              {curCustomer.member_card &&
                curCustomer.member_card.map((item, index) => (
                  <Tag key={"meber" + index} style={{ margin: "3px 0" }}>
                    <span style={{ fontSize: "12px" }}>{item}</span>
                    <span
                      // onClick={() => handleMeberDelete(index)}
                      onClick={() => handleMeberDelete(item)}
                      className="icon iconfont theme-color click-able"
                      style={{ lineHeight: "20px", marginLeft: "3px" }}
                    >
                      &#xe601;
                    </span>
                  </Tag>
                ))}
            </div>
            <Button
              style={{ marginLeft: "10px" }}
              type="primary"
              onClick={openMemberCard}
            >
              编辑
            </Button>
          </div>
        </Form.Item>
        <Form.Item label="车辆">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "rgb(232, 232, 232) 1px solid",
                minHeight: "64px",
                borderRadius: "4px",
                width: "76%",
                padding: "2px 10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                flexWrap: "wrap",
              }}
            >
              {curCustomer.car_ids &&
                curCustomer.car_ids.map((item, index) => (
                  <Tag key={"car" + index} style={{ margin: "3px 0" }}>
                    <span style={{ fontSize: "12px" }}>{item}</span>
                    <span
                      onClick={() => handleCarDelete(item)}
                      className="icon iconfont theme-color click-able"
                      style={{ lineHeight: "20px", marginLeft: "3px" }}
                    >
                      &#xe601;
                    </span>
                  </Tag>
                ))}
            </div>
            <Button
              style={{ marginLeft: "10px" }}
              type="primary"
              onClick={openCarSelect}
            >
              编辑
            </Button>
          </div>
        </Form.Item>

        <Form.Item label="备注">
          <Input
            value={curCustomer.remark}
            onChange={(e) => {
              handleDataChange({ remark: e.target.value });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WxCustomerModal;
