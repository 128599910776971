import SearchBar from "../../components/SearchBar";
import {
  Row,
  Col,
  Radio,
  Button,
  Table,
  Popconfirm,
  Divider,
  Tag,
  message,
} from "antd";
import StaticSelect from "../../components/StaticSelect";
import { useState, useEffect } from "react";
import _Api from "../../core/api";
import WorkerEditModal from "../../components/modals/WorkerEdit";
import PageControl from "../../components/PageControl";
import InfoImage from "../../libs/info.png";
const Worker = ({}) => {
  const [workerState, setWorkerState] = useState({
    list: [],
    keykord: "",
    isJiedai: "",
    isJishi: "",
    pageNo: "1",
    pageSize: "10",
    total: "",
  });
  const [modalState, setModalState] = useState({
    visible: false,
    editType: false,
    curWorker: {},
  });
  useEffect(() => {
    getWorkerList();
  }, []);
  useEffect(() => {
    getWorkerList();
  }, [workerState.isJiedai, workerState.isJishi]);

  function getWorkerList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    const { keyword, isJishi: is_jishi, isJiedai: is_jiedai } = workerState;
    let data = { keyword, is_jiedai, is_jishi, count: pageSize, page: pageNo };
    _Api.workerSelect(data).then((res) => {
      setWorkerState({
        ...workerState,
        list: res.data.content,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
      });
    });
  }
  function handleEdit(data) {
    setModalState({ visible: true, editType: true, curWorker: data });
  }
  function handleDelete(id) {
    let data = { id };
    _Api.workerDelete(data).then((res) => {
      message.success(res.msg);
      getWorkerList();
    });
  }
  const options = [
    { label: "全部", value: "" },
    { label: "是", value: "1" },
    { label: "否", value: "0" },
  ];
  const columns = [
    {
      title: "序号",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "姓名",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "性别",
      dataIndex: "sex",
      align: "center",
    },
    {
      title: "身份证号",
      dataIndex: "id_no",
      align: "center",
    },
    {
      title: "电话",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "进厂时间",
      dataIndex: "in_date",
      align: "center",
    },
    {
      title: "职务",
      dataIndex: "is_jiedai",
      align: "center",
      render: (text, record) => (
        <>
          {text === "1" ? <Tag color="magenta">接待</Tag> : null}
          {record.is_jishi === "1" && <Tag color="green">技师</Tag>}
        </>
      ),
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "操作",
      key: "operation",
      align: "center",
      render: (text, record, index) => (
        <>
          <span
            className="theme-color click-able"
            onClick={() => {
              handleEdit(record);
            }}
          >
            修改
          </span>
          <Divider type="vertical" />
          <Popconfirm
            placement="left"
            title="删除后无法恢复，是否确认删除？"
            okText="确认"
            cancelText="取消"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <span className="theme-color click-able">删除</span>
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <div className="template-student-index student-manager page">
      <div className="page-content">
        <section className="content-header">
          <h1>员工管理</h1>
        </section>
        <div className="wx-box">
          <div className="wx-left">
            <img src={InfoImage} alt="" />
            <span>
              添加的员工可以为工单的接待、技师提供选择，如需开通系统登录账号，请到：账号管理。
            </span>
          </div>
        </div>
        <div className="box">
          <div className="box-header">
            <Row type="flex" justify="space-between">
              <Col span={6}>
                <SearchBar
                  style={{ flex: 1 }}
                  searchText={workerState.keyword}
                  onChangeText={(text) => {
                    setWorkerState({ ...workerState, keyword: text });
                  }}
                  onSearch={getWorkerList}
                  label="搜索"
                  placeholder="请输入姓名/手机号"
                />
              </Col>
              <Col span={5}>
                <StaticSelect label="是否接待员：">
                  <Radio.Group
                    options={options}
                    onChange={(e) => {
                      setWorkerState({
                        ...workerState,
                        isJiedai: e.target.value,
                      });
                    }}
                    value={workerState.isJiedai}
                    optionType="button"
                  />
                </StaticSelect>
              </Col>
              <Col span={5}>
                <StaticSelect label="是否技师：">
                  <Radio.Group
                    options={options}
                    onChange={(e) => {
                      setWorkerState({
                        ...workerState,
                        isJishi: e.target.value,
                      });
                    }}
                    value={workerState.isJishi}
                    optionType="button"
                  />
                </StaticSelect>
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setModalState({
                      ...modalState,
                      visible: true,
                      editType: false,
                    });
                  }}
                >
                  新增
                </Button>
              </Col>
            </Row>
          </div>
          <div className="box-body">
            <Table
              rowKey={(record) => record.id}
              dataSource={workerState.list}
              pagination={false}
              bordered
              columns={columns}
            />
            <PageControl
              total={workerState.total}
              pageSize={workerState.pageSize}
              current={workerState.pageNo}
              showSizeChanger
              toPage={(page, count) => {
                getWorkerList({ pageNo: page, pageSize: count });
              }}
            />
          </div>
        </div>
        <WorkerEditModal
          visible={modalState.visible}
          closeModal={() => {
            setModalState({ ...modalState, visible: false });
          }}
          editType={modalState.editType}
          curWorker={modalState.curWorker}
          getWorkerList={getWorkerList}
        />
      </div>
    </div>
  );
};
export default Worker;
