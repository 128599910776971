import { useState, useEffect, useContext } from "react";
import {
  Input,
  Form,
  Modal,
  Row,
  Col,
  Button,
  message,
  Radio,
  DatePicker,
  Space,
} from "antd";
// import TitleBar from "../TitleBar";
import TitleBarTwo from "../TitleBarTwo";
import Api from "../../core/api";
import ShopContext from "../../core/context-manager";
import WxSelectModal from "./WXSelect";
import _Api from "../../core/api";
import mathFunc from "../../core/mathfunc";
import "./VipEdit.less";
import PromoteInput from "../PromoteInput";
import MeberPassword from "../MeberPassword";
import moment, { duration } from "moment";
const { TextArea } = Input;

const VipEditModal = ({
  visible,
  editType,
  closeModal,
  handlePackageSelect,
  selectedVip,
  selectedPackage,
  getVipList,
  wxEdit,
  handleActiveKey,
}) => {
  const [vipInfo, setVipInfo] = useState({
    id: "",
    name: "",
    phone: "",
    wxOpenid: "",
    password: "",
    passwordConfirm: "",
    packageName: "",
    packagePrice: 0,
    serviceZk: "",
    partZk: "",
    remark: "",
    price: 0,
    priceZs: "",
    inputUser: "",
    payType: "",
    allNum: "",
    packageType: "",
    packageContent: "",
    leftMoney: 0,
    leftNum: "",
    end_time: moment().add(1, "years").format("YYYY-MM-DD"),
    end_times: "",
    selsectType: "12",
    visible: true,
  });
  const [passwordState, setPasswordState] = useState({
    visible: false,
    sureEdit: false,
    isEdit: false,
  });
  const [wxModal, setWxModal] = useState({
    visible: false,
    name: "",
    openid: "",
  });
  const [payTypes, setPayTypes] = useState([]);
  const { userInfo } = useContext(ShopContext);
  // 修改回填
  useEffect(() => {
    if (visible) {
      getPayTypes();
      if (editType) {
        console.log(selectedVip);
        // 修改回填
        let {
          id,
          name,
          phone,
          wx_openid,
          remark,
          package_name,
          left_money,
          part_zk,
          service_zk,
          package_type,
          package_content,
          left_num,
          password,
          end_time,
          end_times,
        } = selectedVip;
        if (password) {
          setPasswordState({
            ...passwordState,
            sureEdit: true,
          });
        }
        setVipInfo({
          ...vipInfo,
          id,
          name,
          phone,
          packageName: package_name,
          serviceZk: mathFunc.getPercent(service_zk),
          partZk: mathFunc.getPercent(part_zk),
          remark,
          leftMoney: left_money,
          packageType: package_type,
          packageContent: package_content,
          leftNum: left_num,
          end_time: end_time ? end_time : "无限制",
          end_times: end_time
            ? end_time
            : moment().format("YYYY-MM-DD"),
          selsectType: end_time ? "" : "无限制",
        });
        if (wx_openid) {
          _Api.wxUserSelect({ openid: wx_openid }).then((res) => {
            let info = res.data.content[0];
            if (info) {
              setWxModal({
                ...wxModal,
                name: info.name,
                openid: info.wx_openid,
              });
            }
          });
        }
      } else {
        setVipInfo({ ...vipInfo, inputUser: userInfo.name });
      }
    }
  }, [visible, editType, selectedVip]);
  // 选择套餐之后的回填
  useEffect(() => {
    if (editType) {
      return;
    }
    let {
      name,
      price,
      price_zs,
      part_zk,
      service_zk,
      all_num,
      package_type,
      remark,
    } = selectedPackage;
    console.log(selectedPackage);
    setVipInfo({
      ...vipInfo,
      packageName: name,
      packagePrice: price,
      price,
      priceZs: price_zs,
      partZk: mathFunc.getPercent(part_zk) || "",
      serviceZk: mathFunc.getPercent(service_zk) || "",
      allNum: mathFunc.reservedInteger(all_num) || "",
      packageType: package_type,
      // remark,
      packageContent: remark,
    });
  }, [selectedPackage, editType]);
  // 修改确定更新密码
  function sureEditPassword() {
    if (vipInfo.password !== vipInfo.passwordConfirm) {
      return message.warning("两次输入密码不相同");
    }
    if (editType && !vipInfo.password && !vipInfo.passwordConfirm) {
      Modal.confirm({
        title: "密码重置确认",
        content: "继续将重置密码为空，是否继续?",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          const data = {
            ...selectedVip,
            password: "",
          };
          Api.cardInsert(data).then((res) => {
            if (res.response === "200") {
              message.success("操作成功");
              setVipInfo({
                ...vipInfo,
                password: "",
                passwordConfirm: "",
              });
              setPasswordState({
                ...passwordState,
                visible: false,
                sureEdit: false,
              });
            }
          });
        },
      });
    } else {
      console.log("修改", selectedVip, vipInfo);
      const data = {
        ...selectedVip,
        password: vipInfo.password,
      };
      Api.cardInsert(data).then((res) => {
        if (res.response === "200") {
          message.success("操作成功");
          setVipInfo({
            ...vipInfo,
            password: "",
            passwordConfirm: "",
          });
          setPasswordState({
            ...passwordState,
            visible: false,
            sureEdit: true,
          });
        }
      });
    }
    // if (editType && !vipInfo.password) {
    //   Modal.confirm({
    //     title: "密码重置确认",
    //     content: "继续将重置密码为空，是否继续?",
    //     okText: "确认",
    //     cancelText: "取消",
    //     onOk: () => {
    //       setPasswordState({
    //         ...passwordState,
    //         visible: false,
    //         sureEdit: false,
    //         isEdit: true,
    //       });
    //     },
    //   });
    // } else {
    //   setPasswordState({
    //     ...passwordState,
    //     visible: false,
    //     sureEdit: true,
    //     isEdit: true,
    //   });
    // }
  }
  // 修改取消更新密码
  function canCelPassword() {
    setVipInfo({
      ...vipInfo,
      password: "",
      passwordConfirm: "",
    });
    setPasswordState({
      ...passwordState,
      visible: false,
    });
  }
  // 确认修改按钮逻辑
  function confirmSave() {
    // if (editType && !vipInfo.password) {
    //   Modal.confirm({
    //     title: "密码重置确认",
    //     content: "继续将重置密码为空，是否继续?",
    //     okText: "确认",
    //     cancelText: "取消",
    //     onOk: () => {
    //       handleSave();
    //     },
    //   });
    // } else {
    //   handleSave();
    // }
    handleSave();
  }
  // 确认 逻辑
  function handleSave(params) {
    const {
      id,
      inputUser,
      name,
      packageName,
      partZk,
      password,
      passwordConfirm,
      payType,
      phone,
      price,
      priceZs,
      remark,
      serviceZk,
      allNum,
      packageType,
      packageContent,
      leftNum,
      end_time,
      selsectType,
    } = vipInfo;
    const { openid } = wxModal;
    // console.log(password, passwordConfirm);
    if (password !== passwordConfirm) {
      return message.error("两次输入密码不相同");
    }
    console.log(vipInfo);
    if (price === "") {
      return message.info("请填写实收金额");
    }
    if (vipInfo.packageType === "储值卡") {
      if (priceZs === "") {
        return message.info("请填写赠送金额");
      }
      let data = {
        id,
        name,
        phone,
        wx_openid: openid,
        package_name: packageName,
        service_zk: parseFloat(serviceZk) / 100,
        part_zk: parseFloat(partZk) / 100,
        remark,
        input_user: inputUser,
        pay_type: payType,
        price,
        price_zs: priceZs,
        package_type: packageType,
        end_time: selsectType === "无限制" ? "" : end_time,
      };
      if (editType) {
        // if (passwordState.isEdit) {
        //   data = { ...data, password };
        // }
      } else {
        data = { ...data, password };
      }
      console.log(data);
      Api.cardInsert(data).then((res) => {
        message.success(res.msg);
        // 初始化数据 加关闭弹框
        handleActiveKey("1");
        getVipList();
        initState();
        closeModal();
      });
    } else {
      //   console.log("计次卡新增");
      let data = {
        id,
        name,
        phone,
        wx_openid: openid,
        package_name: packageName,
        service_zk: "",
        part_zk: "",
        remark,
        input_user: inputUser,
        pay_type: payType,
        price,
        price_zs: priceZs,
        package_type: packageType,
        package_all_num: editType ? leftNum : allNum,
        package_content: packageContent,
        end_time: selsectType === "无限制" ? "" : end_time,
      };
      if (editType) {
        if (passwordState.isEdit) {
          data = { ...data, password };
        }
      } else {
        data = { ...data, password };
      }
      console.log(data);
      Api.cardInsert(data).then((res) => {
        message.success(res.msg);
        // 初始化数据 加关闭弹框
        handleActiveKey("2");
        getVipList();
        initState();
        closeModal();
      });
    }
  }
  // 获取付款方式
  function getPayTypes() {
    Api.getPayType().then((res) => {
      setPayTypes(res.msg);
    });
  }
  // 重置套餐
  function resetPlan() {
    if (vipInfo.packageType === "储值卡") {
      setVipInfo({
        ...vipInfo,
        price: 0,
        serviceZk: "",
        partZk: "",
        priceZs: "",
        payType: "",
      });
    }
    if (vipInfo.packageType === "计次卡") {
      console.log("计次");
      setVipInfo({
        ...vipInfo,
        price: 0,
        packageContent: "",
        allNum: "",
        payType: "",
      });
    }
  }
  // 初始化数据
  function initState(params) {
    setVipInfo({
      id: "",
      name: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      packageName: "",
      packagePrice: 0,
      serviceZk: "",
      partZk: "",
      remark: "",
      price: 0,
      priceZs: "",
      inputUser: "",
      payType: "",
      end_time: moment().add(1, "years").format("YYYY-MM-DD"),
      selsectType: "12",
      visible: true,
    });
    setPasswordState({ visible: false, sureEdit: false });
    setWxModal({ visible: false, name: "", openid: "" });
  }
  // 有效期
  function onChange(e) {
    let time;
    let falg = true;
    if (editType) {
      const timer = vipInfo.end_times;
      console.log(timer);
      switch (e.target.value) {
        case "12":
          time = moment(timer).add(1, "years").format("YYYY-MM-DD");
          break;
        case "3":
          time = moment(timer).add(3, "months").format("YYYY-MM-DD");
          break;
        case "1":
          time = moment(timer).add(1, "months").format("YYYY-MM-DD");
          break;
        case "无限制":
          time = "无限制";
          break;
        case "自定义":
          falg = false;
          time = moment(timer).format("YYYY-MM-DD");
          break;
        default:
          break;
      }
    } else {
      switch (e.target.value) {
        case "12":
          time = moment().add(1, "years").format("YYYY-MM-DD");
          break;
        case "3":
          time = moment().add(3, "months").format("YYYY-MM-DD");
          break;
        case "1":
          time = moment().add(1, "months").format("YYYY-MM-DD");
          break;
        case "无限制":
          time = "无限制";
          break;
        case "自定义":
          falg = false;
          time = moment().format("YYYY-MM-DD");
          break;
        default:
          break;
      }
    }

    setVipInfo({
      ...vipInfo,
      end_time: time,
      selsectType: e.target.value,
      visible: falg,
    });
  }
  // 自定义日期
  function customDate(date, dateString) {
    setVipInfo({
      ...vipInfo,
      end_time: dateString,
    });
  }
  // 不可选择日期
  function disabledDate(current) {
    console.log(current);
    return current && current < moment().endOf("day");
  }
  // console.log(vipInfo);
  // console.log(payTypes);
  // console.log(selectedPackage);
  // console.log(selectedVip);
  // console.log(passwordState);
  const BasicForm = (
    <div
      /* className="basic-form" */ style={{
        // margin: " 10px 0px 10px",
        padding: "20px 0 10px",
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Row className="basic-row">
          <Col span={6}>
            <Form.Item label="会员姓名">
              <Input
                placeholder="请输入会员姓名"
                value={vipInfo.name}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, name: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="手机号码">
              <Input
                placeholder="请输入手机号"
                value={vipInfo.phone}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, phone: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          {editType ? (
            <Col span={6}>
              <Form.Item
                label="密码"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
              >
                {passwordState.sureEdit === true ? (
                  <span style={{ marginRight: 5 }}>已设置</span>
                ) : null}
                <span
                  style={{ color: "skyblue", cursor: "pointer" }}
                  onClick={() => {
                    setPasswordState({ ...passwordState, visible: true });
                  }}
                >
                  {passwordState.sureEdit === true ? "修改" : "未设置"} &gt;
                </span>
              </Form.Item>
            </Col>
          ) : (
            <>
              <Col span={6}>
                <Form.Item
                  label="密码"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 13 }}
                >
                  {/* <Input.Password
                    placeholder="请输入密码"
                    autoComplete="new-password"
                    value={vipInfo.password}
                    onChange={(e) => {
                      setVipInfo({ ...vipInfo, password: e.target.value });
                    }}
                  /> */}
                  <MeberPassword
                    placeholder="请输入密码"
                    value={vipInfo.password}
                    onChange={(value) => {
                      console.log(value);
                      setVipInfo({ ...vipInfo, password: value });
                    }}
                  ></MeberPassword>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="确认密码"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 13 }}
                >
                  <MeberPassword
                    style={{ marginLeft: 5 }}
                    placeholder="再次输入密码"
                    value={vipInfo.passwordConfirm}
                    onChange={(value) => {
                      console.log(value);
                      setVipInfo({ ...vipInfo, passwordConfirm: value });
                    }}
                  ></MeberPassword>
                  {/* <Input.Password
                    placeholder="再次输入密码"
                    autoComplete="new-password"
                    value={vipInfo.passwordConfirm}
                    onChange={(e) => {
                      setVipInfo({
                        ...vipInfo,
                        passwordConfirm: e.target.value,
                      });
                    }}
                  /> */}
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {editType ? (
          <Row>
            <Col span={6} style={{marginLeft:10}}>
              <Form.Item
                label="到期时间"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
              >
                {vipInfo.end_time === "无限制" ? (
                  "无限制"
                ) : (
                  <Space direction="vertical">
                    <DatePicker
                     style={{width:159}}
                      allowClear={false}
                      // value={moment(vipInfo.end_time, dateFormat)}
                      value={
                        vipInfo.end_time === ""
                          ? undefined
                          : moment(vipInfo.end_time)
                      }
                      placeholder="请先选择有效期"
                      disabled={vipInfo.visible}
                      onChange={customDate}
                      // disabledDate={disabledDate}
                    />
                  </Space>
                )}
              </Form.Item>
            </Col>
            <Col span={17}>
              <Form.Item
                label="延长时间"
                labelCol={{ span: 2.5 }}
                wrapperCol={{ span: 21.5 }}
              >
                <Radio.Group onChange={onChange} value={vipInfo.selsectType}>
                  <Radio.Button value="12">1年</Radio.Button>
                  <Radio.Button value="3">1季度</Radio.Button>
                  <Radio.Button value="1">1个月</Radio.Button>
                  <Radio.Button value="无限制">无限制</Radio.Button>
                  <Radio.Button value="自定义">自定义</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={12}>
              <Form.Item
                label="有效期"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Radio.Group onChange={onChange} value={vipInfo.selsectType}>
                  <Radio.Button value="12">1年</Radio.Button>
                  <Radio.Button value="3">1季度</Radio.Button>
                  <Radio.Button value="1">1个月</Radio.Button>
                  <Radio.Button value="无限制">无限制</Radio.Button>
                  <Radio.Button value="自定义">自定义</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="到期时间"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                {vipInfo.end_time === "无限制" ? (
                  "无限制"
                ) : (
                  <Space direction="vertical">
                    <DatePicker
                      allowClear={false}
                      // value={moment(vipInfo.end_time, dateFormat)}
                      value={
                        vipInfo.end_time === ""
                          ? undefined
                          : moment(vipInfo.end_time)
                      }
                      placeholder="请先选择有效期"
                      disabled={vipInfo.visible}
                      onChange={customDate}
                      // disabledDate={disabledDate}
                    />
                  </Space>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={12}>
            <Form.Item
              label="微信客户"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Row type="flex" wrap={false} align="middle">
                <Input disabled value={wxModal.name} />
                {!wxEdit && (
                  <>
                    <Button
                      type="primary"
                      style={{ margin: "0 10px" }}
                      onClick={() => {
                        setWxModal({ ...wxModal, visible: true });
                      }}
                    >
                      选择
                    </Button>
                    <Button
                      onClick={() => {
                        setWxModal({ ...wxModal, name: "", openid: "" });
                      }}
                    >
                      清空
                    </Button>
                  </>
                )}
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="备注"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Input
                value={vipInfo.remark}
                placeholder="请输入备注"
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, remark: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
  const PackageForm = (
    <div
      /* className="basic-form" */ style={{
        // margin: " 10px 0px 0px",
        padding: "20px 0 0px",
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        {!editType ? (
          <Form.Item
            label="选择套餐"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
          >
            <Row wrap={false} align="middle">
              <Col span={9}>
                <div className="vip-edit">
                  {!editType && selectedPackage?.id && (
                    <div className="vip-type">
                      {vipInfo.packageType === "储值卡" ? "储值" : "计次"}
                      <div className="vip-xian"></div>
                    </div>
                  )}

                  <Input
                    allowClear
                    onChange={(e) => {
                      setVipInfo({ ...vipInfo, packageName: e.target.value });
                    }}
                    value={vipInfo.packageName}
                    width={500}
                  />
                </div>
              </Col>
              <Col span={5}>
                {" "}
                <Button
                  type="primary"
                  className="btn-green"
                  size="middle"
                  style={{ marginLeft: "30px" }}
                  onClick={() => {
                    handlePackageSelect();
                  }}
                  // onClick={()=>setMoney(money+1)}
                >
                  选择
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  size="middle"
                  onClick={resetPlan}
                >
                  重置
                </Button>
              </Col>
            </Row>
          </Form.Item>
        ) : (
          <div>
            {vipInfo.packageType === "储值卡" && (
              <Row>
                <Col span={6}>
                  <Form.Item label="套餐名称">
                    <Input
                      value={vipInfo.packageName}
                      onChange={(e) => {
                        setVipInfo({
                          ...vipInfo,
                          packageName: e.target.value,
                        });
                      }}
                      placeholder="请输入套餐名称"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="套餐余额">
                    <PromoteInput
                      width={160}
                      placeholder="请输入余额"
                      value={vipInfo.leftMoney}
                      unit="元"
                      disabled={true}
                    ></PromoteInput>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="项目折扣">
                    <PromoteInput
                      width={160}
                      placeholder="请输入项目折扣"
                      value={vipInfo.serviceZk}
                      unit="%"
                      type="折扣"
                      onChange={(e) => {
                        setVipInfo({ ...vipInfo, serviceZk: e.target.value });
                      }}
                    ></PromoteInput>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="配件折扣">
                    <PromoteInput
                      width={160}
                      placeholder="请输入配件折扣"
                      value={vipInfo.partZk}
                      unit="%"
                      type="折扣"
                      onChange={(e) => {
                        setVipInfo({ ...vipInfo, partZk: e.target.value });
                      }}
                    ></PromoteInput>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {vipInfo.packageType === "计次卡" && (
              <Row>
                <Col span={12}>
                  <Form.Item
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    label="套餐名称"
                  >
                    <Input
                      value={vipInfo.packageName}
                      onChange={(e) => {
                        setVipInfo({
                          ...vipInfo,
                          packageName: e.target.value,
                        });
                      }}
                      placeholder="请输入套餐名称"
                    />
                  </Form.Item>
                  <Form.Item
                    label="套餐内容"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <TextArea
                      onChange={(e) => {
                        setVipInfo({
                          ...vipInfo,
                          packageContent: e.target.value,
                        });
                      }}
                      value={vipInfo.packageContent}
                      rows={4}
                      placeholder="请输入套餐内容"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    // label={
                    //   <p>
                    //     <span style={{ color: "#EB3628" }}>*</span>次数
                    //   </p>
                    // }
                    label="次数"
                  >
                    <PromoteInput
                      width={395}
                      placeholder="请输入套餐次数"
                      value={vipInfo.leftNum}
                      unit="次"
                      disabled={editType ? true : false}
                      onChange={(e) => {
                        setVipInfo({ ...vipInfo, leftNum: e.target.value });
                      }}
                    ></PromoteInput>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        )}
        {!editType && selectedPackage?.id && (
          <>
            {vipInfo.packageType === "储值卡" ? (
              <>
                {" "}
                <Row>
                  <Col span={6}>
                    <Form.Item label="套餐售价">
                      {/* <Input value={vipInfo.packagePrice} disabled suffix="元" /> */}
                      <PromoteInput
                        width={160}
                        placeholder="请输入套餐售价"
                        value={vipInfo.packagePrice}
                        unit="元"
                        disabled={true}
                      ></PromoteInput>
                    </Form.Item>
                    <Form.Item label="实收金额">
                      {/* <Input
                suffix="元"
                value={vipInfo.price}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, price: e.target.value });
                }}
              /> */}
                      <PromoteInput
                        width={160}
                        placeholder="请输入实收金额"
                        value={vipInfo.price}
                        unit="元"
                        type="价格"
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, price: e.target.value });
                        }}
                      ></PromoteInput>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="项目折扣">
                      <PromoteInput
                        width={160}
                        placeholder="请输入项目折扣"
                        value={vipInfo.serviceZk}
                        unit="%"
                        type="折扣"
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, serviceZk: e.target.value });
                        }}
                      ></PromoteInput>
                    </Form.Item>
                    <Form.Item label="余额">
                      <PromoteInput
                        width={160}
                        placeholder="请输入余额"
                        value={
                          ((vipInfo.price && parseFloat(vipInfo.price)) || 0) +
                          ((vipInfo.priceZs && parseFloat(vipInfo.priceZs)) ||
                            0)
                        }
                        unit="元"
                        disabled={true}
                      ></PromoteInput>
                      {/* <Input
                suffix="元"
                disabled
                value={
                  ((vipInfo.price && parseFloat(vipInfo.price)) || 0) +
                  ((vipInfo.priceZs && parseFloat(vipInfo.priceZs)) || 0)
                }
              /> */}
                    </Form.Item>
                    {/* <Form.Item label="套餐余额">
                <Input value={selectedVip.left_money} disabled suffix="元" />
              </Form.Item> */}
                  </Col>
                  <Col span={6}>
                    <Form.Item label="配件折扣">
                      <PromoteInput
                        width={160}
                        placeholder="请输入配件折扣"
                        value={vipInfo.partZk}
                        unit="%"
                        type="折扣"
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, partZk: e.target.value });
                        }}
                      ></PromoteInput>
                      {/* <Input
                suffix="%"
                value={vipInfo.partZk}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, partZk: e.target.value });
                }}
              /> */}
                    </Form.Item>{" "}
                    <Form.Item label="收款人">
                      <Input
                        defaultValue={userInfo.name}
                        value={vipInfo.inputUser}
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, inputUser: e.target.value });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="赠送金额">
                      <PromoteInput
                        width={160}
                        placeholder="请输入赠送金额"
                        value={vipInfo.priceZs}
                        unit="元"
                        type="价格"
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, priceZs: e.target.value });
                        }}
                      ></PromoteInput>
                      {/* <Input
                suffix="元"
                value={vipInfo.priceZs}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, priceZs: e.target.value });
                }}
              /> */}
                    </Form.Item>
                  </Col>
                </Row>
                {/* 收款方式 */}
                <Row>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{ span: 2 }}
                      wrapperCol={{ span: 22 }}
                      name="moneytype"
                      label="收款方式"
                    >
                      <Row>
                        {payTypes.map((item) => (
                          <div
                            onClick={() => {
                              setVipInfo({ ...vipInfo, payType: item.text });
                            }}
                            key={item.id}
                            style={{
                              height: "36px",
                              lineHeight: "36px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderColor:
                                item.text === vipInfo.payType
                                  ? "#ff8534"
                                  : "#D9D9D9",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              padding: "10px 10px",
                              borderRadius: "5px",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              className="icon iconfont"
                              dangerouslySetInnerHTML={{ __html: item.icon }}
                              style={{
                                fontSize: "22px",
                                marginRight: "3px",
                                color: item.color,
                              }}
                            ></span>
                            {item.text}
                          </div>
                        ))}
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={7}></Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      label="套餐售价"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {/* <Input value={vipInfo.packagePrice} disabled suffix="元" /> */}
                      <PromoteInput
                        disabled={true}
                        width={160}
                        placeholder="请输入套餐售价"
                        value={vipInfo.packagePrice}
                        unit="元"
                        disabled={true}
                      ></PromoteInput>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="实收金额"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <PromoteInput
                        width={160}
                        placeholder="请输入实收金额"
                        value={vipInfo.price}
                        unit="元"
                        type="价格"
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, price: e.target.value });
                        }}
                      ></PromoteInput>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="套餐内容"
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                    >
                      <TextArea
                        onChange={(e) => {
                          setVipInfo({
                            ...vipInfo,
                            packageContent: e.target.value,
                          });
                        }}
                        value={vipInfo.packageContent}
                        rows={4}
                        placeholder="请输入套餐内容"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      // label={
                      //   <p>
                      //     <span style={{ color: "#EB3628" }}>*</span>次数
                      //   </p>
                      // }
                      label="次数"
                    >
                      <PromoteInput
                        width={160}
                        placeholder="请输入套餐次数"
                        value={vipInfo.allNum}
                        unit="次"
                        type="次数"
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, allNum: e.target.value });
                        }}
                      ></PromoteInput>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{ span: 2 }}
                      wrapperCol={{ span: 22 }}
                      name="moneytype"
                      label="收款方式"
                    >
                      <Row>
                        {payTypes.map((item) => (
                          <div
                            onClick={() => {
                              setVipInfo({ ...vipInfo, payType: item.text });
                            }}
                            key={item.id}
                            style={{
                              height: "36px",
                              lineHeight: "36px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderColor:
                                item.text === vipInfo.payType
                                  ? "#ff8534"
                                  : "#D9D9D9",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              padding: "10px 10px",
                              borderRadius: "5px",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              className="icon iconfont"
                              dangerouslySetInnerHTML={{ __html: item.icon }}
                              style={{
                                fontSize: "22px",
                                marginRight: "3px",
                                color: item.color,
                              }}
                            ></span>
                            {item.text}
                          </div>
                        ))}
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={7}></Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Form.Item label="收款人">
                      <Input
                        defaultValue={userInfo.name}
                        value={vipInfo.inputUser}
                        onChange={(e) => {
                          setVipInfo({ ...vipInfo, inputUser: e.target.value });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  );
  console.log(vipInfo);
  return (
    <>
      <Modal
        title={editType ? "会员卡修改" : "会员办卡"}
        width={1000}
        zIndex={500}
        visible={visible}
        onCancel={() => {
          initState();
          closeModal();
          getVipList();
        }}
        onOk={confirmSave}
        className="vip-edit-box-body"
      >
        <TitleBarTwo>
          {editType ? (
            <span>{`会员卡号：${selectedVip.bianhao}`}</span>
          ) : (
            <span>基本信息</span>
          )}
        </TitleBarTwo>
        {BasicForm}
        <TitleBarTwo>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>套餐信息</span>
            {/* {!editType && (
							<div>
								<Button
									type='primary'
									className='btn-green'
									size='middle'
									style={{ marginRight: "10px" }}
									onClick={() => {
										handlePackageSelect();
									}}
									// onClick={()=>setMoney(money+1)}
								>
									选择套餐
								</Button>
								<Button
									className='btn-red'
									size='middle'
									onClick={() => {
										this.setState({ goodsAddVisible: true });
									}}>
									重置
								</Button>
							</div>
						)} */}
          </div>
        </TitleBarTwo>
        {PackageForm}
      </Modal>
      {/* 微信客户弹框 */}
      <WxSelectModal
        visible={wxModal.visible}
        closeModal={() => {
          setWxModal({ ...wxModal, visible: false });
        }}
        handleComplete={(data) => {
          const { name, wx_openid } = data;
          setWxModal({ visible: false, name, openid: wx_openid });
        }}
      />
      {/* 密码修改弹框 */}
      <Modal
        visible={passwordState.visible}
        title="修改密码"
        onCancel={canCelPassword}
        onOk={sureEditPassword}
      >
        <Row style={{ margin: "40px 0" }}>
          <Col span={16} offset={3}>
            <Form.Item
              label="密码"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
            >
              <MeberPassword
                placeholder="请输入新密码"
                value={vipInfo.password}
                onChange={(value) => {
                  setVipInfo({ ...vipInfo, password: value });
                }}
              ></MeberPassword>
            </Form.Item>
            <Form.Item
              label="确认密码"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
              style={{ marginTop: 30 }}
            >
              <MeberPassword
                placeholder="再次输入新密码"
                value={vipInfo.passwordConfirm}
                onChange={(value) => {
                  setVipInfo({ ...vipInfo, passwordConfirm: value });
                }}
              ></MeberPassword>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default VipEditModal;
