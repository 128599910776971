import { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Form, message } from "antd";
import Api from "../../core/api";
const { TextArea } = Input;
//eslint-disable-next-line
const SupplierEditModal = ({ visible, closeModal, editType, handleComplete, supplierInfo }) => {
	const [supplierState, setSupplierState] = useState({
		id: "",
		name: "",
		linkman: "",
		mobile: "",
		phone: "",
		addr: "",
		area: "",
		remark: "",
	});

	useEffect(() => {
		if (visible && editType) {
			console.log(supplierInfo);
			const { id, name, linkman, mobile, phone, addr, area, remark } = supplierInfo;
			setSupplierState({
				id,
				name,
				linkman,
				mobile,
				phone,
				addr,
				area,
				remark,
			});
		}
	}, [visible]);
	const handleSave = () => {
		let { id, name, linkman, mobile, phone, addr, area, remark } = supplierState;
		let body = {
			id,
			name,
			linkman,
			mobile,
			phone,
			addr,
			area,
			remark,
		};
		Api.supplierInsert(body)
			.then((res) => {
				message.success(res.msg);
				setSupplierState({
					id: "",
					name: "",
					linkman: "",
					mobile: "",
					phone: "",
					addr: "",
					area: "",
					remark: "",
				});
				handleComplete();
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};
	const oneLayout = { labelCol: { span: 4 }, wrapperCol: { span: 19 } };
	return (
		<Modal
			title={editType ? "编辑供应商" : "新增供应商"}
			footer={null}
			width={660}
			visible={visible}
			onCancel={closeModal}>
			<Form {...layout} colon={false}>
				<Row type='flex' justify='space-between' align='bottom'>
					<Col span={12}>
						<Form.Item
							label={
								<p>
									<span style={{ color: "#EB3628" }}>*</span>供应商名称
								</p>
							}>
							<Input
								value={supplierState.name}
								onChange={(e) => {
									setSupplierState({ ...supplierState, name: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='手机'>
							<Input
								value={supplierState.mobile}
								onChange={(e) => {
									setSupplierState({ ...supplierState, mobile: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='联系人'>
							<Input
								value={supplierState.linkman}
								onChange={(e) => {
									setSupplierState({ ...supplierState, linkman: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='电话'>
							<Input
								value={supplierState.phone}
								onChange={(e) => {
									setSupplierState({ ...supplierState, phone: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item label='地区' {...oneLayout}>
							<Input
								value={supplierState.area}
								onChange={(e) => {
									setSupplierState({ ...supplierState, area: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='地址' {...oneLayout}>
							<Input
								value={supplierState.addr}
								onChange={(e) => {
									setSupplierState({ ...supplierState, addr: e.target.value });
								}}
							/>
						</Form.Item>
						<Form.Item label='备注' {...oneLayout}>
							<TextArea
								value={supplierState.remark}
								onChange={(e) => {
									setSupplierState({ ...supplierState, remark: e.target.value });
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row justify='center'>
					<Button type='primary' style={{ marginTop: "10px" }} onClick={handleSave}>
						保存
					</Button>
				</Row>
			</Form>
		</Modal>
	);
};
export default SupplierEditModal;
