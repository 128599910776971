import { useState, Fragment, useEffect } from "react";
import { Link, Switch, Route } from "react-router-dom";
// import {hashHistory} from 'react-router'
import { Row, Col, Button, message, Tabs, DatePicker } from "antd";
import SearchBar from "../../components/SearchBar";
import StaticSelect from "../../components/StaticSelect";
import ListEditting from "./ListEditting";
import ListPaid from "./ListPaid";
import SaleEditModal from "../../components/modals/SaleEdit";
import SaleDetail from "../../components/modals/SaleDetail";
import VipSelectModal from "../../components/modals/VipSelect";
import VipDetailModal from "../../components/modals/VipDetail";
import PartSelectModal from "../../components/modals/PartSelect";
import PartEditModal from "../../components/modals/PartEdit";
import ReceiveModal from "../../components/modals/Receive";
import VipChargeModal from "../../components/modals/VipCharge";
import moment from "moment";
import _Api from "../../core/api";
import WxSelectModal from "../../components/modals/WXSelect";
import ReceiveSales from "../../components/modals/ReceiveSales";
import VipSelectStored from "../../components/modals/VipSelectStored";
import mathFunc from "../../core/mathfunc";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const FittingSales = ({}) => {
  const [saleState, setSaleState] = useState({
    keyword: "",
    startDate: "",
    endDate: "",
    tableKey: "1",
    finishedState: { list: [], total: "0", pageNo: "1", pageSize: "10" },
    paidState: { list: [], total: "0", pageNo: "1", pageSize: "10" },
  });
  const [modalState, setModalState] = useState({
    checkV: false,
    checkContent: {},
    editV: false,
    editType: false,
    viewMode: false,
    curId: "",
    curCustomer: "",
    curWxOpenid: "",
    curWxName: "",
    curVip: {},
    curMemberCard: "",
    curPartZk: "1",
    curPartContent: { ids: [], data: [], amount: 0, ar: 0 },
    wxSelectV: false,
    vipSelectV: false,
    vipDetailV: false,
    partSelectV: false,
    partEditV: false,
    receiveV: false,
    receiveInfo: {},
    chargeV: false,
  });
  const [modal, setModal] = useState({
    packageV: false,
    vipDetailV: false,
    selectedPackage: {}, // 选择会员卡回填
    selectMelber: {}, // 确定之后会员卡信息
  });
  useEffect(() => {
    getDataAll();
  }, [saleState.endDate, saleState.startDate]);

  function getDataAll() {
    const stateList = ["已开单", "已结算"],
      { keyword, startDate, endDate } = saleState,
      funcList = [];
    stateList.forEach((item) => {
      let data = {
        keyword: keyword,
        start_time: startDate,
        end_time: endDate,
        sale_type: "销售",
        order_state: item,
      };
      funcList.push(
        _Api.partSaleSelect(data).then((res) => {
          let typeKey = item === "已开单" ? "finishedState" : "paidState";
          return {
            [typeKey]: {
              list: res.data.content,
              total: res.data.allcount,
              pageNo: res.data.curpage,
              pageSize: res.data.pagecount,
            },
          };
        })
      );
    });
    let results = Promise.all(funcList);
    results
      .then((res) => {
        let resList = {};
        res.forEach((item) => {
          resList = { ...resList, ...item };
        });
        console.log(resList);
        setSaleState({ ...saleState, ...resList });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function getData(type, pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1",
      { keyword, endDate, startDate } = saleState,
      data = {
        keyword,
        sale_type: "销售",
        start_time: startDate,
        end_time: endDate,
        order_state: type,
        count: pageSize,
        page: pageNo,
      };
    _Api.partSaleSelect(data).then((res) => {
      let typeKey = type === "已开单" ? "finishedState" : "paidState";
      setSaleState({
        ...saleState,
        [typeKey]: {
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          allPage: res.data.allpage,
          pageSize: res.data.pagecount,
        },
      });
    });
  }
  //点击确定新增
  function handleSaleSave() {
    const {
      curId,
      curCustomer,
      curWxOpenid,
      curMemberCard,
      curPartZk,
      curPartContent,
    } = modalState;
    let data = {
      id: curId,
      wx_openid: curWxOpenid,
      customer: curCustomer,
      parts: curPartContent,
      sale_type: "销售",
      member_card: curMemberCard,
      part_zk: curPartZk,
    };
    if (!curCustomer) {
      return message.error("请输入客户名称");
    }
    _Api.partSaleInsert(data).then((res) => {
      message.success(res.msg);
      setModalState({
        ...modalState,
        curId: "",
        curCustomer: "",
        curWxOpenid: "",
        curWxName: "",
        curVip: {},
        curMemberCard: "",
        curPartZk: "1",
        curPartContent: { ids: [], data: [], amount: 0, ar: 0 },
        editV: false,
        editType: false,
        checkV: false,
        checkContent: {},
      });
      getDataAll();
    });
  }

  function handleVipSet(params, data) {
    console.log(params);
    console.log(data);
    let { bianhao, part_zk } = params,
      { curPartContent } = modalState;
    if (curPartContent.ids.length > 0) {
      curPartContent = {
        ...curPartContent,
        ar: curPartContent.amount * parseFloat(part_zk),
      };
    }
    setModalState({
      ...modalState,
      curMemberCard: bianhao,
      curPartZk: part_zk,
      curPartContent,
      curVip: {},
      vipSelectV: false,
      vipDetailV: false,
    });
    setModal({
      ...modal,
      selectMelber: { bianHao: bianhao, leftMoney: data.leftMoney },
    });
  }

  function handleClearVip() {
    let { curPartContent } = modalState;
    if (curPartContent.ids.length > 0) {
      curPartContent = { ...curPartContent, ar: curPartContent.amount };
    }
    setModalState({
      ...modalState,
      curMemberCard: "",
      curPartZk: 1,
      curPartContent,
    });
  }

  function handlePartAdd(params) {
    let discount = modalState.curPartZk,
      _list = [...modalState.curPartContent.data, ...params],
      amount = 0,
      ids = _list.map((item) => {
        let price = item.priceSale ? parseFloat(item.priceSale) : 0,
          num = item.num ? parseFloat(item.num) : 0;
        amount += price * num;
        return item.id;
      }),
      //   ar = amount * parseFloat(discount);
      ar = mathFunc.returnFloat(amount * parseFloat(discount));
    setModalState({
      ...modalState,
      partSelectV: false,
      partEditV: false,
      curPartContent: { ids, data: _list, amount, ar },
    });
  }

  function handleSaleDelete(id) {
    _Api.partSaleDelete({ id }).then((res) => {
      message.success(res.msg);
      getDataAll();
    });
  }

  function handleCanclePaid(id) {
    _Api.partCaiwuDelete({ id }).then((res) => {
      message.success(res.msg);
      getDataAll();
      setModalState({
        ...modalState,
        checkV: false,
        checkContent: {},
      });
    });
  }
  // 点击查看
  function scanOrder(data) {
    console.log(data);
    const { wx_openid } = data;
    if (wx_openid) {
      _Api.wxUserSelect({ openid: wx_openid }).then((res) => {
        let wxInfo = res.data.content[0];
        setModalState({
          ...modalState,
          checkV: true,
          checkContent: {
            ...data,
            wx_name: wxInfo.name,
          },
        });
      });
    } else {
      setModalState({
        ...modalState,
        checkV: true,
        checkContent: data,
      });
    }
  }
  // 点击修改
  function handleEdit(data) {
    console.log(data);
    const { id, customer, wx_openid, member_card, part_zk, parts } = data;
    if (wx_openid) {
      _Api.wxUserSelect({ openid: wx_openid }).then((res) => {
        let wxInfo = res.data.content[0];
        setModalState({
          ...modalState,
          editV: true,
          editType: true,
          curId: id,
          curWxName: wxInfo.name,
          curWxOpenid: wx_openid,
          curCustomer: customer,
          curMemberCard: member_card,
          curPartZk: part_zk,
          curPartContent: JSON.parse(parts),
        });
      });
    } else {
      setModalState({
        ...modalState,
        editV: true,
        editType: true,
        curId: id,
        curCustomer: customer,
        curMemberCard: member_card,
        curPartZk: part_zk,
        curPartContent: JSON.parse(parts),
      });
    }
  }
  // 点击结算
  function openReceive(data) {
    console.log(data);
    setModalState({
      ...modalState,
      receiveInfo: {
        id: data.id,
        bianhao: data.bianhao,
        member_card: data.member_card,
        customer: data.customer,
        part_content: JSON.parse(data.parts),
      },
      receiveV: true,
    });
  }
  console.log(modalState);
  return (
    <Fragment>
      <div className="template-student-index page settlemen">
        <div className="page-content">
          <section
            className="content-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>配件销售</h1>
            <Button
              type="primary"
              onClick={() => {
                setModalState({ ...modalState, editV: true, viewMode: false });
              }}
            >
              新增
            </Button>
          </section>
          <div className="box">
            <div className="box-header">
              <Row type="flex" justify="space-around" align="top">
                <Col>
                  <SearchBar
                    searchText={saleState.keyword}
                    onChangeText={(text) => {
                      setSaleState({ ...saleState, keyword: text });
                    }}
                    onSearch={getDataAll}
                    label="搜索"
                    placeholder="请输入配件名称/姓名"
                  />
                  <StaticSelect label="销售日期">
                    <RangePicker
                      style={{ width: "350px" }}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        本周: [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        上月: [
                          moment().subtract(1, "months").startOf("month"),
                          moment().subtract(1, "months").endOf("month"),
                        ],
                        上周: [
                          moment().subtract(1, "week").startOf("week"),
                          moment().subtract(1, "week").endOf("week"),
                        ],
                      }}
                      value={[
                        saleState.startDate && moment(saleState.startDate),
                        saleState.endDate && moment(saleState.endDate),
                      ]}
                      onChange={(memo, str) => {
                        setSaleState({
                          ...saleState,
                          startDate: str[0],
                          endDate: str[1],
                        });
                      }}
                    />
                  </StaticSelect>
                </Col>
              </Row>
            </div>
            <div className="box-body">
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={"已开单(" + saleState.finishedState.list.length + ")"}
                  key="1"
                >
                  <ListEditting
                    tableState={saleState.finishedState}
                    getTableList={(data) => getData("已开单", data)}
                    handleDelete={handleSaleDelete}
                    handleEdit={(data) => {
                      handleEdit(data);
                    }}
                    openReceive={(data) => {
                      openReceive(data);
                    }}
                    scanOrder={(data) => {
                      scanOrder(data);
                    }}
                  />
                </TabPane>
                <TabPane
                  tab={"已结算(" + saleState.paidState.list.length + ")"}
                  key="2"
                >
                  <ListPaid
                    tableState={saleState.paidState}
                    getTableList={(data) => getData("已结算", data)}
                    handleCanclePaid={handleCanclePaid}
                    scanOrder={(data) => {
                      scanOrder(data);
                    }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        {/* 新增配件销售 */}
        <SaleEditModal
          visible={modalState.editV}
          editType={modalState.editType}
          modalState={modalState}
          handleModalState={(data, is) => {
            console.log(data, is);
            setModalState({ ...modalState, ...data });
            setModal({
              ...modal,
              selectMelber: {
                ...modal.selectMelber,
                ...is,
              },
            });
          }}
          openSelect={() => {
            setModalState({ ...modalState, partSelectV: true });
          }}
          openAdd={() => {
            setModalState({ ...modalState, partEditV: true });
          }}
          openWxSelect={() => {
            setModalState({ ...modalState, wxSelectV: true });
          }}
          closeModal={() => {
            setModalState({
              ...modalState,
              editV: false,
              curId: "",
              curWxName: "",
              curWxOpenid: "",
              curCustomer: "",
              curSupplier: "",
              curInvoice: "",
              curMemberCard: "",
              curPartZk: "1",
              curPartContent: { ids: [], data: [], total: "" },
            });
          }}
          handleClearVip={handleClearVip}
          handleSave={handleSaleSave}
        />
        {/* 查看配件销售 */}
        <SaleDetail
          visible={modalState.checkV}
          modalState={modalState.checkContent}
          orderEdit={(data) => {
            handleEdit(data);
          }}
          openReceive={(data) => {
            openReceive(data);
          }}
          handleCanclePaid={handleCanclePaid}
          closeModal={() => {
            setModalState({
              ...modalState,
              checkV: false,
            });
          }}
        ></SaleDetail>
        <WxSelectModal
          visible={modalState.wxSelectV}
          closeModal={() => {
            setModalState({ ...modalState, wxSelectV: false });
          }}
          handleComplete={(data) => {
            const { name, wx_openid } = data;
            setModalState({
              ...modalState,
              curWxName: name,
              curWxOpenid: wx_openid,
              wxSelectV: false,
            });
          }}
        />
        {/* 会员卡选择 */}
        {/* <VipSelectModal
          visible={modalState.vipSelectV}
          closeModal={() => {
            setModalState({ ...modalState, vipSelectV: false });
          }}
          handleModalState={(data) => {
            setModalState({ ...modalState, ...data });
          }}
        /> */}
        <VipSelectStored
          visible={modalState.vipSelectV}
          closeModal={() => {
            setModalState({ ...modalState, vipSelectV: false });
          }}
          handleModalState={(data) => {
            setModalState({ ...modalState, ...data });
          }}
        ></VipSelectStored>
        {/* 会员卡选择确定 */}
        <VipDetailModal
          visible={modalState.vipDetailV}
          cardInfo={modalState.curVip}
          handleBaseState={(data) => {
            console.log(data);
          }}
          handleVipSet={handleVipSet}
          closeModal={() => {
            setModalState({ ...modalState, vipDetailV: false });
          }}
          type="销售"
        />
        {/* 配件选择弹框 */}
        <PartSelectModal
          visible={modalState.partSelectV}
          selectedIds={modalState.curPartContent.ids}
          partAdd={handlePartAdd}
          closeModal={() => {
            setModalState({ ...modalState, partSelectV: false });
          }}
        />
        <PartEditModal
          visible={modalState.partEditV}
          closeModal={() => {
            setModalState({ ...modalState, partEditV: false });
          }}
          handleComplete={(data) => {
            handlePartAdd([data]);
          }}
        />
        {/* 收款弹框 */}
        <ReceiveSales
          visible={modalState.receiveV}
          modalInfo={modalState.receiveInfo}
          memberData={modal.selectMelber}
          type="销售"
          closeModal={() => {
            setModalState({ ...modalState, receiveV: false });
            setModal({ ...modal, selectMelber: {} });
          }}
          handlePayOver={() => {
            setModalState({
              ...modalState,
              receiveV: false,
              receiveInfo: {},
              checkV: false,
              checkContent: {},
            });
            getDataAll();
          }}
          memberCardSelect={() => {
            // setModal({
            //   ...modal,
            //   packageV: true,
            // });
            setModalState({ ...modalState, vipSelectV: true });
          }}
          handleCharge={() => {
            setModalState({ ...modalState, chargeV: true });
          }}
        ></ReceiveSales>
        <VipChargeModal
          visible={modalState.chargeV}
          bianhao={
            (modalState.receiveInfo && modalState.receiveInfo.member_card) || ""
          }
          closeModal={() => {
            setModalState({ ...modalState, chargeV: false });
          }}
          handleComplete={() =>
            setModalState({ ...modalState, chargeV: false })
          }
        />
      </div>
    </Fragment>
  );
};

export default FittingSales;
