import React, { useReducer } from "react";
import { createHashHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "../modules/root/Login";
import App from "../App";
import Home from "../modules/center/Home";
import Register from "../modules/root/Register";
import UserInfo from "../modules/userinfo/UserInfo";
import BillCreate from "../modules/billcreate";
import PackageSetting from "../modules/vip/PackageSetting";
import VipManagement from "../modules/vip/vipmanagement";
import Worker from "../modules/worker";
import CarManagement from "../modules/carmanagement";
import Account from "../modules/account";
import Settlemen from "../modules/settlemen";
import FittingSales from "../modules/fittingsales";
import SalesReturn from "../modules/salesreturn";
import CreditManagement from "../modules/creditmanagement";
import ProjectManagement from "../modules/projectmanagement";
import PartPurchasing from "../modules/partpurchasing/purchasing";
import PurchasingReturn from "../modules/partpurchasing/return";
import SupplierFile from "../modules/supplierfile";
import BusinessChart from "../modules/businesschart";
import FittingInventory from "../modules/fittinginventory";
import OperationLog from "../modules/operationlog";
import { Provider } from "../store/context";
import ChargeDetail from "../modules/vip/chargedetail";
import PurchaseDetail from "../modules/vip/purchasedetail";
import IncomeDetail from "../modules/incomedetails";
import StockDetails from "../modules/stockdetails";
import InventoryAnalysis from "../modules/inventoryanalysis";
import ShopManage from "../modules/multipleshops/shopmanage";
import Brand from "../modules/multipleshops/brand";
import BranchSet from "../modules/branchset";
import PublicAccount from "../modules/publicaccount";
import WxCustomer from "../modules/wxcustomer";
import WxNotify from "../modules/wxnotify";
import MicroWeb from "../modules/microweb";
import ImportExport from "../modules/importExport";

const XZHistory = createHashHistory();
window.XZHistory = XZHistory;

const RootRouter = () => {
  const location = window.location.hash.replace("#", "");
  if (location.indexOf("token") != -1) {
    const token = location.split("token=")[1];
    window.localStorage.setItem("token", token);
  }

  if (location.indexOf("/login") != -1) {
    if (localStorage.getItem("token")) {
      window.location.hash = "#/";
    }
  }
  return (
    <Provider value={{}}>
      <Router history={XZHistory}>
        <Switch>
          <Route key="1" exact path="/login" component={Login} />
          <Route key="2" exact path="/register" component={Register} />
          <Route key="3" path="/" render={() => <App />} />
        </Switch>
      </Router>
    </Provider>
  );
};

const routes = [
  {
    path: "/user_info",
    component: UserInfo,
    name: "个人账号",
  },
];

const MainRoutes = () => {
  return (
    <Switch>
      {routes.map((item, index) => (
        <Route key={index} path={item.path} component={item.component} />
      ))}
      <Route neme="首页" key="home" path="/home" component={Home} />
      <Route
        neme="进场开单"
        key="billcreate"
        path="/billcreate"
        component={BillCreate}
      />
      <Route
        name="完工结算"
        key="settlemen"
        path="/settlemen"
        component={Settlemen}
      ></Route>
      <Route
        neme="配件销售"
        key="fittingsales"
        path="/fittingsales"
        component={FittingSales}
      />
      <Route
        neme="销售退货"
        key="salesreturn"
        path="/salesreturn"
        component={SalesReturn}
      />
      <Route
        neme="挂账管理"
        key="creditmanagement"
        path="/creditmanagement"
        component={CreditManagement}
      />
      <Route
        neme="套餐管理"
        key="packagesetting"
        path="/packagesetting"
        component={PackageSetting}
      />
      <Route
        neme="会员卡管理"
        key="vipmanagement"
        path="/vipmanagement"
        component={VipManagement}
      />
      <Route
        neme="充值明细"
        key="rechargedetail"
        path="/rechargedetail"
        component={ChargeDetail}
      />
      <Route
        neme="消费明细"
        key="purchasedetail"
        path="/purchasedetail"
        component={PurchaseDetail}
      />
      <Route neme="员工管理" key="worker" path="/worker" component={Worker} />
      <Route
        neme="车辆管理"
        key="carmanagement"
        path="/carmanagement"
        component={CarManagement}
      />
      <Route
        neme="服务项目"
        key="projectmanagement"
        path="/projectmanagement"
        component={ProjectManagement}
      />
      <Route
        neme="配件库存"
        key="fittinginventory"
        path="/fittinginventory"
        component={FittingInventory}
      />
      <Route
        neme="供应商档案"
        key="supplierfile"
        path="/supplierfile"
        component={SupplierFile}
      />
      <Route
        neme="配件采购"
        key="partpurchasing"
        path="/fittingpurchasing"
        component={PartPurchasing}
      />
      <Route
        neme="采购退货"
        key="purchasingreturn"
        path="/purchasingreturn"
        component={PurchasingReturn}
      />
      <Route
        neme="经营图表"
        key="businesschart"
        path="/businesschart"
        component={BusinessChart}
      />
      <Route
        neme="收入明细"
        key="incomedetails"
        path="/incomedetails"
        component={IncomeDetail}
      />
      <Route
        neme="出入库明细"
        key="stockdetails"
        path="/stockdetails"
        component={StockDetails}
      />
      <Route
        neme="库存分析"
        key="inventoryanalysis"
        path="/inventoryanalysis"
        component={InventoryAnalysis}
      />
      <Route
        neme="账号管理"
        key="accountmanagement"
        path="/accountmanagement"
        component={Account}
      />
      <Route
        neme="操作日志"
        key="operationlog"
        path="/operationlog"
        component={OperationLog}
      />
      <Route
        neme="门店管理"
        key="shopmanagement"
        path="/shopmanagement"
        component={ShopManage}
      />
      <Route
        neme="品牌中心"
        key="brandcenter"
        path="/brandcenter"
        component={Brand}
      />
      <Route
        neme="数据导入导出"
        key="imexport"
        path="/imexport"
        component={ImportExport}
      />
      {/* <Route
        neme="打印设置"
        key="printset"
        path="/printset"
        component={ImportExport}
      /> */}
      <Route
        neme="打印设置"
        key="printset"
        path="/printset"
        component={BranchSet}
      />
      <Route
        neme="微信公众号"
        key="publicaccount"
        path="/publicaccount"
        component={PublicAccount}
      />
      <Route
        neme="微信客户"
        key="wxcustomer"
        path="/wxcustomer"
        component={WxCustomer}
      />
      <Route
        neme="微信通知"
        key="wx_notify"
        path="/wx_notify"
        component={WxNotify}
      />
      <Route neme="微官网" key="wx_web" path="/wx_web" component={MicroWeb} />
      <Route
        key="-1"
        exact
        path="/"
        render={() => {
          return <Redirect to="/home"></Redirect>;
        }}
      />
    </Switch>
  );
};

export { RootRouter, MainRoutes };
