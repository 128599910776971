import { useEffect, useState, useContext } from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Tabs, message } from "antd";
import ShopContext from "../../core/context-manager";
import _Api from "../../core/api";

const { TextArea } = Input;
const Basic = () => {
	const { shopInfo, resetShopInfo } = useContext(ShopContext);
	const [state, setState] = useState({
		subscribe: "",
	});
	const [editType, setEditType] = useState({
		subscribe: false,
	});
	useEffect(() => {
		setState({ subscribe: shopInfo.wx_subscribe_msg });
	}, []);

	function save() {
		let data = { wx_subscribe_msg: state.subscribe };
		_Api.wxInfoSet(data).then((res) => {
			message.success(res.msg);
			setEditType({
				subscribe: false,
			});
			resetShopInfo();
		});
	}
	const layout = {
		labelCol: { span: 2 },
	};
	return (
		<div className='wechat-basic-page' style={{ marginTop: 12 }}>
			<Form {...layout}>
				<Form.Item label='关注欢迎语'>
					{editType.subscribe ? (
						<>
							<TextArea
								rows={4}
								value={state.subscribe}
								onChange={(e) => {
									setState({ ...state, subscribe: e.target.value });
								}}
								style={{ width: 300, marginLeft: 20, marginTop: 10 }}
							/>
							<span
								style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
								onClick={() => save()}
								className='icon iconfont edit'
								title='保存'>
								&#xe680;
							</span>
						</>
					) : (
						<>
							<span style={{ marginLeft: 20 }}>{state.subscribe}</span>
							<span
								style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
								onClick={() => {
									setEditType({ ...editType, subscribe: true });
								}}
								className='icon iconfont edit'
								title='修改'>
								&#xe60c;
							</span>
						</>
					)}
				</Form.Item>
			</Form>
		</div>
	);
};

export default Basic;
