import { useState, useEffect, useMemo, useContext } from "react";
import { Input, Form, Modal, Row, Col, Button, message, Table } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";

const PartHistoryModal = ({ visible, closeModal, partInfo }) => {
	const [historyState, setHistoryState] = useState({ list: [], pageNo: "1", pageSize: "10", total: "" });
	useEffect(() => {
		if (visible) {
			getHistory();
		}
	}, [visible]);
	function getHistory(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "5",
			pageNo = (pageState && pageState.pageNo) || "1";
		let data = { part_bianhao: partInfo.id, order_type: orderTypeTrans(partInfo.type), count: pageSize, page: pageNo };
		_Api.stockSelect(data).then((res) => {
			setHistoryState({
				...historyState,
				list: res.data.content,
				total: res.data.allcount,
				pageNo: res.data.curpage,
				pageSize: res.data.pagecount,
			});
		});
	}
	function orderTypeTrans(type) {
		switch (type) {
			case "采购":
				return "采购单";
			case "采购退货":
				return "采购退货";
			case "销售":
				return "销售单";
			case "销售退货":
				return "销售退货";
			case "工单使用":
				return "工单";
			case "盘点":
				return "盘点";

			default:
				break;
		}
	}
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};

	const columns = [
		{
			title: "序号",
			dataIndex: "gmt_creat",
			align: "center",
			render: (texty, record, index) => index + 1,
		},
		{
			title: partInfo.type === "盘点" ? "备注" : "单号",
			dataIndex: partInfo.type === "盘点" ? "remark" : "order_bianhao",
			align: "center",
		},
		{
			title: "出入库时间",
			dataIndex: "gmt_creat",
		},
		{
			title: "数量",
			dataIndex: "set_num",
			align: "center",
		},
	];
	return (
		<Modal title={partInfo.type + "历史"} width={700} visible={visible} onOk={closeModal} onCancel={closeModal}>
			<Form {...layout}>
				<Row justify='space-between'>
					<Col span={12}>
						<Form.Item label='编号'>{partInfo.id}</Form.Item>
						<Form.Item label='品牌'>{partInfo.brand}</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='配件名称'>{partInfo.name}</Form.Item>
						<Form.Item label='规格型号'>{partInfo.model}</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				pagination={false}
				rowKey={(record) => record.id}
				dataSource={historyState.list}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
			/>
			<PageControl
				toPage={(page, count) => {
					getHistory({ pageNo: page, pageSize: count });
				}}
				total={historyState.total}
				pageSize={historyState.pageSize}
				current={historyState.pageNo}
				showSizeChanger
			/>
		</Modal>
	);
};
export default PartHistoryModal;
