import React from "react";
import { Popconfirm, Table, Empty, Divider, Pagination } from "antd";
import _Api from "../../core/api";
import moment from "moment";
import PageControl from "../../components/PageControl";
import mathFunc from "../../core/mathfunc";
export default ({ openDetail, tableState, handleCanclePaid, getTableList, handleReceivePrint }) => {
	function printTable() {
		const el = document.getElementById("print-content");
		const iframe = document.createElement("IFRAME");
		let doc = null;
		iframe.setAttribute("style", "position:absolute;width:0px;height:0px;left:500px;top:500px;");
		document.body.appendChild(iframe);
		doc = iframe.contentWindow.document;
		doc.write(el.innerHTML);
		doc.close();
		// 获取iframe的焦点，从iframe开始打印
		iframe.contentWindow.focus();
		iframe.contentWindow.print();
		if (navigator.userAgent.indexOf("MSIE") > 0) {
			document.body.removeChild(iframe);
		}
	}
	const columns = [
		{
			title: "单号",
			dataIndex: "bianhao",
			align: "center",
			render: (text, record,index) => (
				<span
					className='theme-color click-able'
					onClick={() => {
						openDetail({ slipType: "paid", data: record,index });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "车牌号",
			align: "center",
			width: "120px",
			dataIndex: "car_id",
		},
		// {
		// 	title: "车型",
		// 	dataIndex: "car_type",
		// },
		// {
		// 	title: "联系人",
		// 	dataIndex: "car_linkman",
		// },
		{
			title: "手机号码",
			dataIndex: "car_phone",
		},
		{
			title: "开单时间",
			dataIndex: "gmt_creat",
			key: "timeStart",
			render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
		},
		{ title: "完工时间", dataIndex: "end_time", render: (text) => moment(text).format("YYYY-MM-DD HH:mm") },
		{ title: "结算时间", dataIndex: "gmt_modify" },
		{
			title: "本次耗时",
			align: "center",
			key: "duration",
			render: (text, record) => {
				const startTime = moment(record.gmt_creat, "YYYY-MM-DD HH:mm:ss"),
					endTime = moment(record.end_time, "YYYY-MM-DD HH:mm:ss");
				let _duration = endTime.diff(startTime) / (1000 * 60),
					hours = Math.floor(_duration / 60),
					minute = Math.ceil(_duration % 60),
					result = "";
				if (hours > 0) {
					result = result + hours + "小时";
				}

				if (minute > 0) {
					result = result + minute + "分钟";
				}
				return result;
			},
		},
		{
			title: "消费",
			dataIndex: "part_content",
			render: (text, record) => {
				// let partAr = JSON.parse(record.part_content).ar || 0,
				// 	serviceAr = JSON.parse(record.service_content).ar || 0;
				// return mathFunc.returnFloat(parseFloat(partAr) + parseFloat(serviceAr)) + "元";
				if(record.member_num_pay === "0.0" || record.member_num_pay === null){
					if(record.money_pay === null) {
						return 0 + "元"
					}
					return mathFunc.returnFloat(record.money_pay) + "元"
				} else {
					if(record.member_num_pay === null) {
						return 0 + "次"
					}
					return mathFunc.reservedCount(record.member_num_pay) + "次"
				}
			},
		},
		// {
		// 	title: "微信通知",
		// 	dataIndex: "wechat",
		// },
		{
			title: "操作",
			align: "center",
			dataIndex: "id",
			render: (text, record) => (
				<>
					<Popconfirm
						placement='left'
						title='是否确认取消结算？'
						onConfirm={() => handleCanclePaid(text)}
						okText='确认'
						cancelText='取消'>
						<span className='click-able theme-color'>反结算</span>
					</Popconfirm>
					<Divider type='vertical' />
					<span
						className='click-able theme-color'
						onClick={() => {
							handleReceivePrint(record);
							// printTable();
						}}>
						打印收据
					</span>
				</>
			),
		},
	];

	return (
		<>
			<Table
				rowKey={(record) => record.id}
				dataSource={tableState.list}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
				locale={{ emptyText: "请点击表格右上方按钮选择或新增服务项目" }}
				pagination={false}
			/>
			<PageControl
				total={tableState.total}
				pageSize={tableState.pageSize}
				current={tableState.pageNo}
				showSizeChanger
				toPage={(page, count) => {
					getTableList({ pageNo: page, pageSize: count });
				}}
			/>
		</>
	);
};
