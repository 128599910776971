import { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Tabs, Empty } from "antd";
import PageControl from "../PageControl";
import PackageTable from "../../modules/common/PackageTable";
import PackageCount from "../PackageCount";
import PackageEditModal from "./PackageEditModal";
import PackageAdd from "./PackageAdd";
import Api from "../../core/api";
import emptyData from "../../libs/empty_data.png";
import emptySearch from "../../libs/empty_search.png";

const { TabPane } = Tabs;
const MemberCardSelect = ({ visible, closeModal, handleSelect }) => {
  const [packageState, setPackageState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    package_type: "储值卡",
  });
  const [packageCount, setPackageCount] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    package_type: "计次卡",
  });
  const [modalState, setModalState] = useState({
    packageType: false,
    emptyType: false,
    emptyCountType: false,
  });
  const [addVisible, setAddVisible] = useState(false);
  useEffect(() => {
    if (visible) {
      getPackageList();
    }
  }, [visible, modalState.packageType]);
  // 获取基本数据
  function getPackageList(data) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.packageType) {
      if (packageCount.keyword) {
        // 是否是  查询获取
        setModalState({ ...modalState, emptyCountType: true });
      } else {
        setModalState({ ...modalState, emptyCountType: false });
      }
      // 计次请求
      let data = {
        keyword: packageCount.keyword,
        count: pageSize,
        page: pageNo,
        package_type: packageCount.package_type,
      };
      Api.packageSelect(data).then((res) => {
        setPackageCount({
          ...packageCount,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
      });
    } else {
      if (packageState.keyword) {
        // 是否是  查询获取
        setModalState({ ...modalState, emptyType: true });
      } else {
        setModalState({ ...modalState, emptyType: false });
      }
      // 储值请求
      let data = {
        keyword: packageState.keyword,
        count: pageSize,
        page: pageNo,
        package_type: packageState.package_type,
      };
      Api.packageSelect(data).then((res) => {
        setPackageState({
          ...packageState,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
      });
    }
    // let data = { keyword: packageState.keyword, count: pageSize, page: pageNo };
    // Api.packageSelect(data).then((res) =>
    //   setPackageState({
    //     ...packageState,
    //     list: res.data.content,
    //     total: res.data.allcount,
    //     pageNo: res.data.curpage,
    //     pageSize: res.data.pagecount,
    //   })
    // );
  }
  // 标签页改变
  function onChange(key) {
    console.log(key);
    if (key === "1") {
      setModalState({ ...modalState, packageType: false });
    }
    if (key === "2") {
      setModalState({ ...modalState, packageType: true });
    }
  }
  // tab 储值查询
  const valueInquire = (
    <Row align="middle">
      <Col span={17}>
        <Input
          placeholder="套餐名称/备注"
          value={packageState.keyword}
          onChange={(e) => {
            setPackageState({
              ...packageState,
              keyword: e.target.value,
            });
          }}
        />
      </Col>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        onClick={getPackageList}
      >
        查询
      </Button>
    </Row>
  );
  //   tab 计次查询
  const countInquire = (
    <Row align="middle">
      <Col span={17}>
        <Input
          placeholder="套餐名称/备注"
          value={packageCount.keyword}
          onChange={(e) => {
            setPackageCount({
              ...packageCount,
              keyword: e.target.value,
            });
          }}
        />
      </Col>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        onClick={getPackageList}
      >
        查询
      </Button>
    </Row>
  );

  return (
    <>
      <Modal
        zIndex={1010}
        title="选择会员卡"
        footer={null}
        width={900}
        visible={visible}
        onCancel={closeModal}
      >
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          tabBarExtraContent={
            modalState.packageType ? countInquire : valueInquire
          }
        >
          <TabPane tab="储值类型" key="1">
            {" "}
            {packageState.list.length === 0 ? (
              <div style={{margin: "90px 0"}}>
                {modalState.emptyType ? (
                  <Empty
                    description={
                      <div style={{ width: "100%", textAlign: "center",color:"#999" }}>
                        暂未查询到此套餐～
                      </div>
                    }
                    image={emptySearch}
                  ></Empty>
                ) : (
                  <Empty
                    description={
                      <div style={{ width: "100%", textAlign: "center" }}>
                        暂无套餐，请先
                        <span
                          style={{
                            display: "inline-block",
                            color: "#fc9c6b",
                            cursor: "pointer",
                            marginLeft: 10,
                            width: 66,
                            height: 17,
                            borderBottom: "2px solid #fc9c6b",
                          }}
                          onClick={() => {
                            setAddVisible(true);
                          }}
                        >
                          +添加套餐
                        </span>
                      </div>
                    }
                    image={emptyData}
                  ></Empty>
                )}
              </div>
            ) : (
              <>
                {/* 套餐表格 */}
                <PackageTable
                  TableData={packageState.list}
                  selectType
                  handleSelect={(data) => {
                    handleSelect(data);
                  }}
                />
                {/* 分页 */}
                <PageControl
                  total={packageState.total}
                  pageSize={packageState.pageSize}
                  current={packageState.pageNo}
                  showSizeChanger
                  toPage={(page, count) => {
                    getPackageList({ page, pages: count });
                  }}
                />
              </>
            )}
          </TabPane>
          <TabPane tab="计次类型" key="2">
            {" "}
            {packageCount.list.length === 0 ? (
              <div style={{margin: "90px 0"}}>
              {modalState.emptyCountType ? (
                <Empty
                  description={
                    <div style={{ width: "100%", textAlign: "center",color:"#999" }}>
                      暂未查询到此套餐～
                    </div>
                  }
                  image={emptySearch}
                ></Empty>
              ) : (
                <Empty
                  description={
                    <div style={{ width: "100%", textAlign: "center" }}>
                      暂无套餐，请先
                      <span
                        style={{
                          display: "inline-block",
                          color: "#fc9c6b",
                          cursor: "pointer",
                          marginLeft: 10,
                          width: 66,
                          height: 17,
                          borderBottom: "2px solid #fc9c6b",
                        }}
                        onClick={() => {
                          setAddVisible(true);
                        }}
                      >
                        +添加套餐
                      </span>
                    </div>
                  }
                  image={emptyData}
                ></Empty>
              )}
            </div>
            ) : (
              <>
                {/* 套餐表格 */}
                <PackageCount
                  TableData={packageCount.list}
                  selectType
                  handleSelect={(data) => {
                    handleSelect(data);
                  }}
                />
                {/* 分页 */}
                <PageControl
                  total={packageCount.total}
                  pageSize={packageCount.pageSize}
                  current={packageCount.pageNo}
                  showSizeChanger
                  toPage={(page, count) => {
                    getPackageList({ page, pages: count });
                  }}
                />
              </>
            )}
          </TabPane>
        </Tabs>
      </Modal>
      {/* 新增套餐 弹框 */}
      {/* <PackageEditModal
        visible={addVisible}
        getPackageList={getPackageList}
        closeModal={() => {
          setAddVisible(false);
        }}
      /> */}
      <PackageAdd
        visible={addVisible}
        getPackageList={getPackageList}
        packageType={modalState.packageType}
        closeModal={() => {
          setAddVisible(false);
        }}
      ></PackageAdd>
    </>
  );
};
export default MemberCardSelect;
