import { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table, message } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";
import "./CarMultiSelect.less";
import brushImgs from "../../libs/empty-brush.png";
import crossImgs from "../../libs/cross.png";

const CarMultiSelectModal = ({
  visible,
  closeModal,
  handleComplete,
  listSelected,
  selectedState,
}) => {
  const [carState, setCarState] = useState({
    list: [],
    curSelected: undefined,
    total: "",
    allPage: "",
    pageSize: "10",
    pageNo: "1",
    keyword: "",
  });

  useEffect(() => {
    if (visible) {
      getCarList();
    }
  }, [visible]);

  function getCarList(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    _Api
      .carGet({ keyword: carState.keyword, count: pageSize, page: pageNo })
      .then((res) => {
        const _listSelected = carState.curSelected || [...listSelected];
        let list = res.data.content.map((item) => ({
          ...item,
          selected: _listSelected.indexOf(item.car_id) != -1,
        }));
        setCarState({
          ...carState,
          list,
          curSelected: _listSelected,
          total: res.data.allcount,
          allPage: res.data.allpage,
          pageSize: res.data.pagecount,
          pageNo: res.data.curpage,
        });
      });
  }
  function handleSelect(selected, index) {
    let _curSelected = carState.curSelected,
      list = carState.list;
    if (selected) {
      list[index].selected = true;
      _curSelected.push(list[index].car_id);
    } else {
      list[index].selected = false;
      const carId = list[index].car_id;
      _curSelected.splice(_curSelected.indexOf(carId), 1);
    }
    setCarState({ ...carState, list, curSelected: _curSelected });
  }
  function delSelectData(bianhao) {
    // console.log(bianhao);
    const list = carState.list.map((item) => {
      if (item.car_id === bianhao) {
        item.selected = false;
      }
      return item;
    });
    const arr = carState.curSelected.filter((item) => item !== bianhao);
    setCarState({ ...carState, list, curSelected: arr });
  }
  function emptySelectData() {
    const list = carState.list.map((item) => {
      item.selected = false;
      return item;
    });
    setCarState({ ...carState, list, curSelected: [] });
  }
  function carMultiple() {
    const arr = carState.curSelected || [];
    const list = carState.list.filter((item) => arr.includes(item.car_id));
    for (let i = 0; i < list.length; i++) {
      if (!selectedState.includes(list[i].car_id) && list[i].wx_openid) {
        return message.error(
          `车辆 【${list[i].car_id}】, 已经被分配到其他客户名下！`
        );
      }
    }
    handleComplete(arr);
    // handleComplete(carState.curSelected);
  }

  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "车牌号",
      dataIndex: "car_id",
      key: "brand",
    },
    {
      title: "车型",
      dataIndex: "car_type",
      key: "type",
    },
    {
      title: "联系人",
      dataIndex: "linkman",
      key: "linkman",
      align: "center",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "选择",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      //eslint-disable-next-line
      render: (text, record, index) =>
        record.selected ? (
          <span
            className="icon iconfont theme-color click-able"
            style={{ fontSize: "17px" }}
            onClick={() => handleSelect(!record.selected, index)}
          >
            {/* &#xe679; */}
            &#xe730;
          </span>
        ) : (
          <span
            className="icon iconfont click-able"
            style={{ fontSize: "17px" }}
            onClick={() => handleSelect(!record.selected, index)}
          >
            {/* &#xe720; */}
            &#xe72f;
          </span>
        ),
    },
  ];
  // console.log(carState);
  // console.log(listSelected);
  return (
    <Modal
      title="车辆选择"
      width={1286}
      className="car-select-box"
      visible={visible}
      onCancel={() => {
        setCarState({ ...carState, curSelected: undefined });
        closeModal();
      }}
      onOk={carMultiple}
    >
      <div className="car-select-data">
        <div className="left">
          <Row type="flex" align="middle">
            <Col span={12}>
              <Input
                placeholder="车牌号/车型/联系人/手机号码"
                value={carState.keyword}
                onChange={(e) => {
                  setCarState({ ...carState, keyword: e.target.value });
                }}
              />
            </Col>
            <Button
              type="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                getCarList();
              }}
            >
              查询
            </Button>
          </Row>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            dataSource={carState.list}
            columns={columns}
            bordered
            style={{ marginTop: "20px", marginBottom: "40px" }}
            locale={{ emptyText: "暂无车辆信息，请先给车辆建档" }}
          />
          <PageControl
            toPage={(page, count) => {
              getCarList({ pageNo: page, pageSize: count });
            }}
            total={carState.total}
            pageSize={carState.pageSize}
            current={carState.pageNo}
            showSizeChanger
          />
        </div>
        <div className="right">
          <div className="title-top">
            <span>
              已选{carState.curSelected ? carState.curSelected.length : 0}辆
            </span>
            <img onClick={emptySelectData} src={brushImgs} alt="" />
          </div>
          <div className="content">
            {carState.curSelected &&
              carState.curSelected.map((item) => {
                return (
                  <div key={item} className="content-item">
                    <div className="member-card">{item}</div>
                    <img
                      onClick={() => delSelectData(item)}
                      src={crossImgs}
                      alt=""
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CarMultiSelectModal;
