import { useEffect, useState } from "react";
import { Row, Button, message, Divider } from "antd";
import TitleBar from "../../components/TitleBar";
import BasicForm from "../common/BasicForm";
import "./BillCreate.less";
import ServerTable from "../common/ServerTable";
import GoodsTable from "../common/GoodsTable";
import CarSelectModal from "../../components/modals/CarSelect";
import CarEditModal from "../../components/modals/CarEdit";
import CarImage from "../../components/modals/CarImage";
import VipSelectModal from "../../components/modals/VipSelect";
import MaintenanceInfoModal from "../../components/modals/MaintenanceInfo";
import PartSelectModal from "../../components/modals/PartSelect";
import ServeSelectModal from "../../components/modals/ServeSelect";
import ServeAddModal from "../../components/modals/ServeAdd";
import PartAddModal from "../../components/modals/PartEdit";
// import WXSelectModal from "../../components/modals/WXSelect";
import VipDetailModal from "../../components/modals/VipDetail";
import Api from "../../core/api";
import _Api from "../../core/api";

const BillCreate = ({ history }) => {
  // 车辆信息状态
  const [baseInfo, setBaseInfo] = useState({
    idForCar: "",
    carId: "",
    carType: "",
    linkman: "",
    phone: "",
    hasMember: "",
    curMileage: "",
    memberCard: "",
    partZk: 1,
    serviceZk: 1,
    nextMaintainMileage: "",
    nextMaintainTime: "",
    nextMaintainRemark: "",
    worker: "",
    car_imgs: {
      frontLeft: [],
      frontRight: [],
      backLeft: [],
      backRight: [],
      other: [],
    },
  });
  // 修改图片
  const handleBaseCarImgs = (data, types) => {
    setBaseInfo({
      ...baseInfo,
      car_imgs: { ...baseInfo.car_imgs, [types]: data },
    });
  };
  const handleCarImgs = (data) => {
    console.log('确定上传图片',data);
    setBaseInfo({
      ...baseInfo,
      car_imgs: data,
    });
  };
  console.log(baseInfo);
  // 图片
  const [imageList, setImageList] = useState([]);
  const uploadImageList = (url) => {
    console.log("开单页", url);
    setImageList([...imageList, url]);
    console.log(imageList);
  };

  const [imageUrl, setImageUrl] = useState([]);
  const handlImageUrl = (data, index) => {
    // console.log(data,index);
    let arr = imageUrl;
    arr.splice(index, 1, data);
    // console.log(arr);
    setImageUrl([arr]);
  };

  //弹框显示控制条件
  const [modalState, setModalState] = useState({
    carSelectV: false,
    carEditV: false,
    carEditType: false,
    carImageV: false,
    vipSelectV: false,
    vipDetailV: false,
    curVip: "",
    maintenanceV: false,
    serviceSelectV: false,
    serviceAddV: false,
    partSelectV: false,
    partAddV: false,
  });
  // 服务项目 状态
  const [serviceContent, setServiceContent] = useState({
    ids: [],
    data: [], //数据
    amount: 0, //工时费
    ar: 0, // 应收工时费
  });
  // 商品配件 状态
  const [partContent, setPartContent] = useState({
    ids: [],
    data: [],
    amount: 0,
    ar: 0,
  });
  // 车辆信息状态的改变 进行回填
  function handleBaseState(data) {
    setBaseInfo({ ...baseInfo, ...data });
  }
  // 状态的改变 控制弹框是否显示
  function handleModalState(data) {
    setModalState({ ...modalState, ...data });
  }
  //点击清除 状态的初始化
  function handleVipSet(data) {
    const { bianhao, service_zk, part_zk } = data;
    let serviceAr = 0,
      partAr = 0;
    if (serviceContent.amount != 0) {
      serviceAr = serviceContent.amount * parseFloat(service_zk);
    }
    if (partContent.amount != 0) {
      partAr = partContent.amount * parseFloat(part_zk);
    }
    setBaseInfo({
      ...baseInfo,
      memberCard: bianhao,
      serviceZk: service_zk,
      partZk: part_zk,
    });
    setPartContent({ ...partContent, ar: partAr });
    setServiceContent({ ...serviceContent, ar: serviceAr });
    setModalState({
      ...modalState,
      vipSelectV: false,
      vipDetailV: false,
      curVip: {},
    });
  }

  // 服务项目 状态改变以及 结算运算
  function handleServiceAdd(list) {
    let discount = baseInfo.serviceZk;
    // console.log(discount);
    let _list = [...serviceContent.data, ...list];
    let amount = 0;
    let ids = _list.map((item) => {
      let price = item.price ? parseFloat(item.price) : 0;
      let hours = item.hours ? parseFloat(item.hours) : 0;
      amount += price * hours;
      return item.id;
    });
    let ar = amount * parseFloat(discount);
    setServiceContent({ data: _list, ids, amount, ar });
    handleModalState({ serviceSelectV: false });
  }
  // 商品配件 状态改变以及 结算运算
  function handlePartAdd(list) {
    let discount = baseInfo.partZk;
    let _list = [...partContent.data, ...list];
    let amount = 0;
    let ids = _list.map((item) => {
      let price = item.priceSale ? parseFloat(item.priceSale) : 0;
      let num = item.num ? parseFloat(item.num) : 0;
      amount += price * num;
      return item.id;
    });
    let ar = amount * parseFloat(discount);
    setPartContent({ data: _list, ids, amount, ar });
    handleModalState({ partSelectV: false });
  }
  // 点击开单逻辑
  function handleOrderCreate() {
    const {
      car_imgs,
      carId,
      linkman,
      carType,
      phone,
      memberCard,
      partZk,
      serviceZk,
      worker,
      curMileage,
      nextMaintainMileage,
      nextMaintainTime,
      nextMaintainRemark,
    } = baseInfo;
    let data = {
      car_imgs: car_imgs,
      car_id: carId,
      car_linkman: linkman,
      car_type: carType,
      car_phone: phone,
      member_card: memberCard,
      part_zk: partZk,
      service_zk: serviceZk,
      worker,
      cur_mileage: curMileage,
      next_maintain_mileage: nextMaintainMileage,
      next_maintain_time: nextMaintainTime,
      next_maintain_remark: nextMaintainRemark,
      end_time: "",
      order_state: "进行中",
      part_content: partContent,
      service_content: serviceContent,
    };
    Api.orderInsert(data)
      .then((res) => {
        message.success(res.msg);
        history.push("/settlemen");
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  return (
    <>
      <div className="template-student-index page bill-create">
        <div className="page-content">
          <section className="content-header">
            <h1>进场开单</h1>
          </section>
          {/* 整体 */}
          <div className="box">
            <TitleBar>
              <span>车辆信息</span>
            </TitleBar>
            <BasicForm
              carImgs={baseInfo.car_imgs}
              // handlImageUrl={handlImageUrl}
              imageUrl={imageUrl}
              imageList={imageList}
              // uploadImageList={uploadImageList}
              baseInfo={baseInfo}
              handleBaseInfo={handleBaseState}
              handleModalState={handleModalState}
              handleVipSet={handleVipSet}
            />
            <TitleBar>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>服务项目</span>
                <div>
                  <Button
                    className="btn-green"
                    size="middle"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setModalState({ ...modalState, serviceSelectV: true });
                    }}
                  >
                    选择
                  </Button>
                  <Button
                    className="btn-red"
                    size="middle"
                    onClick={() => {
                      setModalState({ ...modalState, serviceAddV: true });
                    }}
                  >
                    新建
                  </Button>
                </div>
              </div>
            </TitleBar>
            <ServerTable
              slip={serviceContent.data.length > 0}
              discount={baseInfo.serviceZk}
              tableState={serviceContent}
              setTable={(data) => {
                setServiceContent({ ...data });
              }}
            />
            <TitleBar>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>商品配件</span>
                <div>
                  <Button
                    className="btn-green"
                    size="middle"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setModalState({ ...modalState, partSelectV: true });
                    }}
                  >
                    选择
                  </Button>
                  <Button
                    className="btn-red"
                    size="middle"
                    onClick={() => {
                      setModalState({ ...modalState, partAddV: true });
                    }}
                  >
                    新建
                  </Button>
                </div>
              </div>
            </TitleBar>
            <GoodsTable
              slip={partContent.data.length > 0}
              discount={baseInfo.partZk}
              tableState={partContent}
              setTable={(data) => {
                setPartContent({ ...data });
              }}
            />
            <Row type="flex" justify="center" style={{ height: "30px" }}>
              <Button
                type="primary"
                size="middle"
                onClick={() => handleOrderCreate()}
              >
                点击开单
              </Button>
            </Row>
          </div>

          {/* 车辆选择弹框组件 */}
          <CarSelectModal
            visible={modalState.carSelectV}
            closeModal={() => {
              handleModalState({ carSelectV: false });
            }}
            handleModalState={handleModalState}
            handleSelect={(data) => {
              console.log(data);
              const { id, car_type, car_id, linkman, phone, has_member } = data;
              setBaseInfo({
                ...baseInfo,
                idForCar: id,
                carId: car_id,
                carType: car_type,
                linkman,
                phone,
                hasMember: has_member,
              });
              handleModalState({ carSelectV: false });
            }}
          />
          {/* 车辆编辑与建档 */}
          <CarEditModal
            visible={modalState.carEditV}
            editType={modalState.carEditType}
            curCarId={baseInfo.carId}
            // openWxSelect={() => {
            // 	handleModalState({ wxSelectV: true });
            // }}
            closeModal={() => {
              handleModalState({carSelectV: false, carEditV: false, carEditType: false });
            }}
            setCurCar={(data) => {
              handleBaseState(data);
              handleModalState({ carEditV: false, carEditType: false });
            }}
          />
          {/* <WXSelectModal
						visible={modalState.wxSelectV}
						closeModal={() => {
							handleModalState({ wxSelectV: false });
						}}
						handleComplete={(data) => {
							console.log(data);
							const {name} = data
						}}
					/> */}
          {/* 选择会员卡弹框 */}
          <VipSelectModal
            visible={modalState.vipSelectV}
            carId={baseInfo.carId}
            hasMember={baseInfo.hasMember}
            closeModal={() => {
              handleModalState({ vipSelectV: false });
            }}
            handleModalState={handleModalState}
          />
          {/* 会员卡输入密码弹框 */}
          <VipDetailModal
            visible={modalState.vipDetailV}
            cardInfo={modalState.curVip}
            type="开单"
            handleBaseState={handleBaseState}
            handleVipSet={handleVipSet}
            closeModal={() => {
              handleModalState({ vipDetailV: false });
            }}
          />
          {/* 下次保养说明录用弹框 */}
          <MaintenanceInfoModal
            orderState={baseInfo}
            visible={modalState.maintenanceV}
            closeModal={() => {
              handleModalState({ maintenanceV: false });
            }}
            handleOk={(data) => {
              const { time, mileage, remark } = data;
              handleBaseState({
                nextMaintainMileage: mileage,
                nextMaintainTime: time,
                nextMaintainRemark: remark,
              });
              handleModalState({ maintenanceV: false });
            }}
          />
          {/* 汽车照片弹框 */}
          <CarImage
            type="add"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            imageList={imageList}
            uploadImageList={uploadImageList}
            visible={modalState.carImageV}
            handleModalState={handleModalState}
            handleBaseState={handleBaseState}
            carImgs={baseInfo.car_imgs}
            handleBaseCarImgs={handleBaseCarImgs}
            handleCarImgs={handleCarImgs}
          ></CarImage>
          {/* 服务项目选择弹框 */}
          <ServeSelectModal
            selectedIds={serviceContent.ids}
            visible={modalState.serviceSelectV}
            serviceAdd={handleServiceAdd}
            closeModal={() => {
              handleModalState({ serviceSelectV: false });
            }}
          />
          {/* 新增服务项目 */}
          <ServeAddModal
            visible={modalState.serviceAddV}
            closeModal={() => {
              handleModalState({ serviceAddV: false });
            }}
            handleComplete={() => {
              handleModalState({ serviceAddV: false });
            }}
          />
          {/* 商品配件选择弹框 */}
          <PartSelectModal
            selectedIds={partContent.ids}
            visible={modalState.partSelectV}
            partAdd={handlePartAdd}
            closeModal={() => {
              handleModalState({ partSelectV: false });
            }}
          />
          {/* 新增商品配件弹框 */}
          <PartAddModal
            visible={modalState.partAddV}
            closeModal={() => {
              handleModalState({ partAddV: false });
            }}
            handleComplete={() => {
              handleModalState({ partAddV: false });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BillCreate;
