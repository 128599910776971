import React, { Component } from "react";
import { Modal } from "antd";
import moment from "moment";
import "./WxMsgPreview.less";

const WxMsgPreviewModal = ({ closeModal, visible, data }) => {
	return (
		<Modal
			visible={visible}
			title='预览效果'
			width={658}
			className='message-tpl-preview-modal'
			footer={null}
			onCancel={closeModal}>
			<div className='content-container'>
				<div className='left'>
					<div className='inner'>
						{/* <div className='title'>{data.title}</div> */}
						<div className='user'>
							<div className='inst-name'>{data.name}</div>
							<div className='date-time'>{moment().format("YYYY-MM-DD")}</div>
						</div>
						<div className='line'></div>
						<div className='content' dangerouslySetInnerHTML={{ __html: data.content }}></div>
					</div>
				</div>
				<div className='right'>
					<div className='title'>
						<div className='label'>模板名称</div>
						<div className='value'>{data.name}</div>
					</div>
					<div className='summary'>
						<div className='label'>模板简介</div>
						<div className='value'>{data.summary}</div>
					</div>
					{/*<div className="suited-scene">
                            <div className="label">适用场景</div>
                            <div className="value">{data.suitedScene}</div>
                        </div>*/}
				</div>
			</div>
		</Modal>
	);
};

export default WxMsgPreviewModal;
