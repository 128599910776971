import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Row, Input, Col, Table, Radio } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";
import SearchBar from "../SearchBar";
import StaticSelect from "../StaticSelect";
const WxMultiSelectModal = ({ visible, closeModal, handleComplete, selectedList }) => {
	const [state, setState] = useState({
		keyword: "",
		isMember: "",
		isCar: "",
		list: [],
		pageNo: "1",
		pageSize: "10",
		total: "",
	});
	const [selected, setSelected] = useState({ ids: [], items: [] });

	useEffect(() => {
		if (visible) {
			setSelected({ ...selectedList });
		}
	}, [visible]);

	useEffect(() => {
		getWxList();
	}, [state.isMember, state.isCar]);

	const allFlag = useMemo(() => {
		console.log("bbb");
		let _index = state.list.findIndex((item) => {
			return selected.ids.indexOf(item.id) === -1;
		});
		return _index === -1;
	}, [selected.ids, state.list]);

	function getWxList(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1";
		const { keyword, isCar, isMember } = state;
		let data = { keyword, is_member: isMember, is_car: isCar, count: pageSize, page: pageNo };
		_Api.wxUserSelect(data).then((res) => {
			setState({
				...state,
				list: res.data.content,
				total: res.data.allcount,
				pageNo: res.data.curpage,
				pageSize: res.data.pagecount,
			});
		});
	}
	function handleSelect(flag, id, item) {
		let { ids, items } = selected;
		if (flag) {
			ids.push(id);
			items.push(item);
		} else {
			const index = ids.indexOf(id);
			ids.splice(index, 1);
			items.splice(index, 1);
		}
		setSelected({ ids: [...ids], items });
	}
	function selectAll() {
		const { ids, items } = selected;
		if (allFlag) {
			state.list.forEach((item) => {
				let _index = ids.indexOf(item.id);
				if (_index != -1) {
					ids.splice(_index, 1);
					items.splice(_index, 1);
				}
			});
		} else {
			state.list.forEach((item) => {
				let _index = ids.indexOf(item.id);
				if (_index === -1) {
					ids.push(item.id);
					items.push(item);
				}
			});
		}
		setSelected({ ids: [...ids], items });
	}
	function handleOk() {
		handleComplete(selected);
		setSelected({ openids: [], items: [] });
		setState({ keyword: "", isMember: "", isCar: "", list: [], pageNo: "1", pageSize: "10", total: "" });
	}
	const columns = [
		{
			title: (
				<span style={{ display: "flex", alignItems: "center" }} className='click-able' onClick={selectAll}>
					{allFlag ? (
						<span className='icon iconfont theme-color' style={{ fontSize: "18px" }}>
							&#xe679;
						</span>
					) : (
						<span className='icon iconfont' style={{ fontSize: "18px" }}>
							&#xe720;
						</span>
					)}
					全选
				</span>
			),
			dataIndex: "id",
			render: (text, record) =>
				selected.ids.indexOf(text) === -1 ? (
					<span
						className='icon iconfont click-able'
						style={{ fontSize: "18px" }}
						onClick={() => {
							handleSelect(true, text, record);
						}}>
						&#xe720;
					</span>
				) : (
					<span
						className='icon iconfont click-able theme-color'
						style={{ fontSize: "18px" }}
						onClick={() => {
							handleSelect(false, text, record);
						}}>
						&#xe679;
					</span>
				),
		},
		{
			title: "姓名",
			align: "center",
			dataIndex: "name",
		},
		{
			title: "电话",
			dataIndex: "phone",
			align: "center",
			render: (text) => (text ? <span>{text}</span> : <span>--</span>),
		},
	];

	const options = [
		{ label: "全部", value: "" },
		{ label: "是", value: "1" },
		{ label: "否", value: "0" },
	];
	return (
		<Modal
			title='客户选择'
			width={700}
			visible={visible}
			onCancel={closeModal}
			bodyStyle={{ paddingBottom: "0px" }}
			onOk={handleOk}>
			<Row type='flex' align='middle' style={{ marginBottom: "10px " }}>
				<Col span={14}>
					<SearchBar
						searchText={state.keyword}
						onChangeText={(text) => {
							setState({ ...state, keyword: text });
						}}
						onSearch={getWxList}
						label='搜索'
						placeholder='手机号/姓名/会员卡/车牌号/备注'
					/>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<StaticSelect label='是否绑定会员卡：'>
						<Radio.Group
							options={options}
							onChange={(e) => {
								setState({ ...state, isMember: e.target.value });
							}}
							value={state.isMember}
							optionType='button'
						/>
					</StaticSelect>
				</Col>
				<Col span={12}>
					<StaticSelect label='是否绑定车辆：'>
						<Radio.Group
							options={options}
							onChange={(e) => {
								setState({ ...state, isCar: e.target.value });
							}}
							value={state.isCar}
							optionType='button'
						/>
					</StaticSelect>
				</Col>
			</Row>

			<Table
				rowKey={(record) => record.id}
				pagination={false}
				dataSource={state.list}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
				// locale={{ emptyText: "请点击表格右上方按钮选择或新增服务项目" }}
			/>
			<PageControl
				style={{ paddingBottom: "0" }}
				total={state.total}
				pageSize={state.pageSize}
				current={state.pageNo}
				showSizeChanger
				toPage={(page, count) => {
					getWxList({ pageNo: page, pageSize: count });
				}}
			/>
		</Modal>
	);
};
export default WxMultiSelectModal;
