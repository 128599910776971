import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Input, Tabs, Popover, Modal, message, Button } from "antd";
import axios from "../../core/axios";

import "./Login.less";
import CheckBeforeSendCode from "./CheckBeforeSendCode";
import _ from "underscore";
import littlelogo from "../../libs/logo.png";
import User from "../../core/user";
import mainBanner from "../../libs/main_banner.png";
import logoBig from "../../libs/logo_02.png"

let timer;

export class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			account: "",
			pwd: "",
			phoneverify: "",
			// activeKey: "qrcodeLogin",
			activeKey: "phoneverifyLogin",
			qrcodeUrl: "",
			qrcodeToken: "",
			// qrcodeStatue: "timeout",
			qrcodeStatue: "",

			countdown: 60,
			timerStr: "获取验证码",
			hadClicked: false,
			msgOrVoice: "",
			certificate: "", //短信验证返回的凭证
			isFinish: false,

			openCheck: false,
			openCheckVoice: false,
			checkObject: {},
			checkObjectVoice: {},
			checking: false,
			regWaitStatus: false, // 验证码是否在倒计时
			regVoiceCodeTip: false, // 语音验证码提示状态
			calledVoiceSend: false,

			issinstName: true,
			istelePhone: true,
			telePhone: "",
			sinstName: "",
			code: "",

			source: "",
			way: "",
			addr: "北京海淀区建材城中路12号院23号楼",
			bg_color_1: "",
			bg_color_2: "",
			bg_color_3: "",
			mainBanner: "",
			color: "",
			loginAd: "",
			logo: "",
			tel: "400-181-5338（飞飞助教）",
		};
	}

	componentWillMount() {
		this.loginSet();
	}

	loginSet = () => {
		axios.post("shop/login.php?cmd=web_login_set", "").then((res) => {
			this.setState({
				addr: res.web_set.addr,
				bg_color_1: res.web_set.bg_color_1,
				bg_color_2: res.web_set.bg_color_2,
				bg_color_3: res.web_set.bg_color_3,
				color: res.web_set.font_color,
				loginAd: res.web_set.img_2,
				logo: res.web_set.logo,
				tel: res.web_set.tel,
			});
			document.title = "门店登录_飞飞汽修管理系统";
		});
	};

	timeSub = (waitTime, unit) => {
		const _this = this;
		clearTimeout(timer);
		timer = setTimeout(function () {
			if (waitTime === 0) {
				_this.setState({
					timerStr: "发送验证码",
					regWaitStatus: false,
					checking: false,
				});
				clearTimeout(timer);
			} else {
				if (waitTime < 40) {
					_this.setState({
						regVoiceCodeTip: true,
					});
				}
				_this.setState({
					timerStr: `${waitTime}秒后重发`,
					regWaitStatus: true,
				});
				_this.timeSub(--waitTime, 1000);
			}
		}, unit || 0);
	};
	handleSendCode(checkData) {
		console.log(checkData);
		const { ret, ticket, randstr, bizState } = checkData;
		if (ret != 0) {
			return message.error("验证失败");
		}
		const params = {
			phone: bizState.state.telePhone,
			Randstr: randstr,
			Ticket: ticket,
			sms_type: "shop_login",
		};
		console.log(params);
		axios.postJSON("shop/login.php?cmd=send_sms_code", params).then((res) => {
			console.log(res);
			if (res.response == "200") {
				message.success(res.msg);
				bizState.timeSub(60);
				bizState.setState({
					checking: true,
					certificate: res.s_id,
				});
			} else {
				message.warning(res.msg);
			}
		});
	}
	// 手机校验
	isMobile(phone) {
		let patrn = /^[0-9]{11}$/;
		if (!patrn.exec(phone)) return false;
		return true;
	}

	handleConfirm = () => {
		if (this.state.regWaitStatus) {
			return;
		}
		if (!this.isMobile(this.state.telePhone)) {
			message.warning("请正确填写手机号");
		} else {
			try {
				const tencentc = new window.TencentCaptcha("2007156257", this.handleSendCode, {
					bizState: this,
				});
				tencentc.show();
			} catch {
				console.log("载入失败");
			}
		}
	};
	// 点击登录
	onSubmit = (e) => {
		// 登录方式
		if (this.state.activeKey === "passwordLogin") {
			this.login();
		} else {
			this.codeLogin();
		}
	};
	// 密码登录
	login = () => {
		const body = { phone: this.state.telePhone, password: this.state.pwd };
		axios.post("shop/login.php?cmd=login_pwd", body).then((res) => {
			window.LS.set("token", res.token);
			window.XZHistory.push({
				pathname: "/",
			});
		});
	};
	// 验证码登录
	codeLogin = () => {
		const body = { phone: this.state.telePhone, s_id: this.state.certificate, code: this.state.phoneverify };
		axios.post("shop/login.php?cmd=login_sms", body).then((res) => {
			window.LS.set("token", res.token);
			window.XZHistory.push({
				pathname: "/",
			});
		});
	};
	// 表单信息修改时的 双向回填
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	// 二维码登录
	handleQrcodeLogin = async () => {
		const token = await axios.get("wx_open/qrcode/login_qrcode.php?cmd=token");
		this.setState(
			{
				qrcodeUrl: "https://api.sanfeng9.com/wx_open/qrcode/login_qrcode.php?token=" + token,
				qrcodeToken: token,
				qrcodeStatue: "scanning",
				activeKey: "qrcodeLogin",
			},
			() => {
				this.startCheck();
			}
		);
	};
	// 二维码状态
	async startCheck() {
		this.timer && clearTimeout(this.timer);
		const statue = await axios.post("shop/login.php?cmd=login_qrcode", {
			cmd_type: "check",
			pc_token: this.state.qrcodeToken,
		});
		switch (statue.response) {
			case "500251":
				this.timer = setTimeout(() => {
					this.state.activeKey === "qrcodeLogin" && this.startCheck();
				}, 1000);
				break;
			case "20000":
				if (this.state.qrcodeStatue !== "scanned") {
					console.log("设置scanned");
					this.setState({ qrcodeStatue: "scanned" });
				}
				this.timer = setTimeout(() => {
					this.state.activeKey === "qrcodeLogin" && this.startCheck();
				}, 1000);
				console.log("扫码完毕");
				break;
			case "500003":
				this.setState({ qrcodeStatue: "timeout" });
				console.log("token已过期");
				break;
			case "200":
				console.log(statue.msg);
				window.LS.set("token", this.state.qrcodeToken);
				window.XZHistory.push({
					pathname: "/",
				});
				break;
			default:
				console.log("cc");
				break;
		}
	}

	render() {
		const {
			account,
			qrcodeStatue,
			qrcodeUrl,
			pwd,
			phoneverify,
			openCheck,
			activeKey,
			telePhone,
			logo,
			loginAd,
			addr,
			tel,
			bg_color_1,
			bg_color_2,
			bg_color_3,
			color,
		} = this.state;

		//activeKey：phoneverifyLogin--验证码、passwordLogin--密码、qrcodeLogin--二维码

		return (
			<div className='wrap'>
				<div className='login-page'>
					<div className='header' style={{ backgroundColor: bg_color_1 }}>
						<div className='logo-wrap'>
							{/* <img src={logo} className='logo' alt='' /> */}
							<img src={logoBig} className='logo' alt='' />
						</div>
					</div>
					{/* <div className='login-main' style={{ background: `${bg_color_2} url(${loginAd}) content-box` }}> */}
					<div
						className='login-main'
						style={{
							backgroundImage: `url(${loginAd})`,
							backgroundColor: bg_color_2,
							backgroundPosition: "center",
						}}>
						<div className='left-banner'>{/*<img src={} />*/}</div>
						<div className='login-box stage'>
							<div className='login'>
								<div className='r'>
									<div className='title'>
										<div style={{ width: "30px", height: "30px", borderRadius: "3px", overflow: "hidden" }}>
											<img src={littlelogo} alt='' width='30px' />
										</div>

										<div style={{ fontSize: "18px", marginLeft: "10px" }}>登录飞飞汽修</div>
									</div>
									{activeKey === "qrcodeLogin" ? (
										<div className='qrcodeOuter'>
											<div style={{ width: "150px", height: "150px", position: "relative" }}>
												<img src={this.state.qrcodeUrl} alt='' width='150px' />
												{qrcodeStatue === "timeout" ? (
													<>
														<div
															style={{
																position: "absolute",
																left: 0,
																top: 0,
																width: "150px",
																height: "150px",
																backgroundColor: "#44BD59",
																opacity: 0.9,
															}}
														/>
														<div className='refreshBtn' onClick={() => this.handleQrcodeLogin()}>
															<span>二维码失效</span>
															<span>请点击刷新</span>
														</div>
													</>
												) : null}
											</div>
										</div>
									) : (
										<Form className='login-form' onFinish={this.onSubmit}>
											<div className='form'>
												<div className='username' style={{ marginBottom: "16px" }}>
													<Input
														type='text'
														autoComplete='off'
														name='telePhone'
														maxLength={11}
														id='name'
														placeholder='手机号'
														onChange={this.onChange}
														value={telePhone}
													/>
												</div>
												{activeKey === "passwordLogin" && (
													<div className='password' style={{ marginBottom: "16px" }}>
														<Input
															type='password'
															id='pwd'
															name='pwd'
															placeholder='密码'
															onChange={this.onChange}
															value={pwd}
														/>
													</div>
												)}
												{activeKey === "phoneverifyLogin" && (
													<div className='phoneverify'>
														<Input
															type='text'
															id='phoneverify'
															name='phoneverify'
															placeholder='验证码'
															autoComplete='off'
															onChange={(e) => {
																this.onChange(e);
															}}
															value={phoneverify}
														/>
														<Popover
															visible={openCheck}
															trigger='click'
															title=''
															content={
																<div>
																	<span
																		style={{
																			fontSize: "12px",
																			color: "#999",
																			marginBottom: 8,
																			display: "block",
																		}}>
																		请完成安全验证
																	</span>
																	<CheckBeforeSendCode
																		callback={(data, nc) => {
																			console.log(data);
																			this.handleSendCode(data);
																			this.setState({
																				checkObject: nc,
																			});
																			setTimeout(() => {
																				this.setState({
																					openCheck: false,
																				});
																			}, 500);
																		}}
																	/>
																</div>
															}
															onVisibleChange={(value) => {
																if (!value) {
																	this.setState({
																		openCheck: false,
																	});
																}
															}}
															placement='bottomRight'>
															<div
																className='btn'
																id='sendVerifyCode'
																onClick={() => {
																	if (this.state.checking) return;
																	this.handleConfirm();
																}}>
																{this.state.timerStr}
															</div>
														</Popover>
													</div>
												)}
											</div>
										</Form>
									)}
									<div className='submit'>
										{activeKey === "qrcodeLogin" ? (
											<div style={{ width: "100%", height: "40px", paddingTop: "20px", textAlign: "center" }}>
												{qrcodeStatue === "scanned" ? (
													<div className='scanSuccess'>扫描成功 请在手机点确认以登录</div>
												) : (
													<div>使用飞飞汽修APP扫描二维码登录</div>
												)}
											</div>
										) : (
											<div className='btn' onClick={this.onSubmit}>
												<button id='loginIn' type='submit'>
													登录
												</button>
											</div>
										)}
										<div className='apply'>
											{activeKey === "qrcodeLogin" ? null : (
												<>
													<span
														className='apply-btn'
														onClick={() => {
															this.handleQrcodeLogin();
														}}>
														APP扫码登录
													</span>
													<span className='apply-divider'>|</span>
												</>
											)}
											{activeKey === "passwordLogin" ? null : (
												<>
													<span
														className='apply-btn'
														onClick={() => {
															this.setState({ activeKey: "passwordLogin" });
														}}>
														密码登录
													</span>
													<span className='apply-divider'>|</span>
												</>
											)}

											{activeKey === "phoneverifyLogin" ? null : (
												<>
													<span
														className='apply-btn'
														onClick={() => {
															this.setState({ activeKey: "phoneverifyLogin" });
														}}>
														验证码登录
													</span>
													<span className='apply-divider'>|</span>
												</>
											)}

											<Link className='apply-btn' to={"/register"}>
												免费开通
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='footer' style={{ backgroundColor: bg_color_3, color: color }}>
						<div className='footer-main'>
							<div>
								<span className='icon iconfont'>&#xe615;</span>
								{addr}
							</div>
							<div>
								<span className='icon iconfont'>&#xe64b;</span>
								{tel}
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
