import { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import User from "../../core/user";
import _Api from "../../core/api";

const Bind = () => {
	const [state, setState] = useState({
		nickName: undefined,
		wechatQrcode: "",
		shouQuan: false,
		authorizer_info: {},
		msg_model_ok: false,
		bind_state: false,
		tip1: false,
		tip2: false,
	});
	const [modalState, setModalState] = useState({ authorizeV: false });
	const [loadState, setLoadState] = useState(false);
	useEffect(() => {
		getWechatInfo();
	}, []);
	// function getShopInfo() {
	// 	_Api.shopWxGet({}).then((res) => {
	// 		setState({ ...state, wechatQrcode: res.msg.wx_fwh_qrcode });
	// 	});
	// }
	function getWechatInfo() {
		_Api.wxInfoGet({}).then((res) => {
			console.log(res);
			setLoadState(true);
			let { nickName, authorizer_info, bind_state, tip1, tip2 } = state;
			if (res.response !== "200") {
				return setState({
					...state,
					nickName: undefined,
					shouQuan: false,
					authorizer_info: {},
					msg_model_ok: false,
					bind_state: false,
					tip1: false,
					tip2: false,
				});
			}
			if (res.msg.authorizer_info) {
				const data = res;
				console.log(data.msg.authorizer_info);
				nickName = data.msg.authorizer_info.nick_name;
				authorizer_info = data.msg.authorizer_info;
				if (
					data.msg.authorizer_info.service_type_info.id == 2 &&
					data.msg.authorizer_info.verify_type_info.id != -1 &&
					data.msg_model_ok == 1
				) {
					bind_state = true;
				}
				if (data.msg.authorizer_info.service_type_info.id != 2) {
					tip1 = true;
				}
				if (data.msg_model_ok != 1) {
					tip2 = true;
				}
				setState({
					...state,
					shouQuan: true,
					nickName,
					authorizer_info,
					bind_state,
					tip1,
					tip2,
					msg_model_ok: data.msg_model_ok,
				});
			} else {
				setState({ ...state, tip1: true, tip2: true, shouQuan: true, nickName: "" });
			}
		});
	}
	function unBind() {
		_Api.wxUnbind({ cmd_type: "select" }).then((res) => {
			message.success(res.msg);
			getWechatInfo();
		});
	}
	function handleAuthorize() {
		setModalState({ ...modalState, authorizeV: true });
		window.open("https://api.sanfeng9.com/wx_open/goto_pre.php?token=" + User.token());
	}

	function handleRetry() {
		window.open("https://api.sanfeng9.com/wx_open/goto_pre.php?token=" + User.token());
	}
	const { nickName, shouQuan, bind_state, authorizer_info, tip1, tip2, msg_model_ok } = state;
	const right = <span style={{ color: "blue" }}>√</span>;
	const wrong = <span style={{ color: "red" }}>×</span>;
	return (
		<div className='wechat-bind-page'>
			<div className='box-header'>
				<div className='container-body wechat-bind'>
					{shouQuan && (
						<div
							className='form-group'
							style={{ borderBottom: 0, marginBottom: 10, paddingBottom: 0, lineHeight: "24px" }}>
							<label>当前微信公众号的服务号为：</label>
							<div className='btn-bind' style={{ marginLeft: "20px" }}>
								{nickName ? nickName : ""}
							</div>
							{nickName && (
								<Button style={{ marginLeft: 20 }} onClick={unBind}>
									解除绑定
								</Button>
							)}
						</div>
					)}

					{shouQuan && loadState && (
						<div style={{ paddingLeft: 200, marginTop: 16, lineHeight: "36px" }}>
							<p>
								1、是否为微信服务号：
								{authorizer_info.service_type_info && authorizer_info.service_type_info.id == 2 ? right : wrong}
								&nbsp;&nbsp;
								{tip1 && (
									<a
										href='https://www.sanfeng9.com/help/?id=318&aid=1409'
										target='_blank'
										style={{ fontSize: "12px", color: "red" }}>
										&gt;&gt;如何申请并认证微信服务号
									</a>
								)}
							</p>
							<p>
								2、模板消息是否开通成功：{msg_model_ok ? right : wrong}&nbsp;&nbsp;
								{tip2 && (
									<a
										href='https://www.sanfeng9.com/help/?id=318&aid=1410'
										target='_blank'
										style={{ fontSize: "12px", color: "red" }}>
										&gt;&gt;如何申请模版消息
									</a>
								)}
							</p>
							<p>
								3、是否认证：
								{authorizer_info.verify_type_info && authorizer_info.verify_type_info.id != -1 ? right : wrong}
							</p>
						</div>
					)}
				</div>
			</div>
			{!shouQuan && (
				<div className='box-body'>
					<div className='head'>绑定自己机构的微信服务号</div>
					<div className='bind-body'>
						<div className='center'>
							<h1>绑定说明:</h1>
							<p>
								1、如果没有微信公众号，需要首先申请。
								<a href='https://www.sanfeng9.com/help/?id=318&aid=1409' target='_blank'>
									&gt;&gt;如何申请并认证微信服务号
								</a>
							</p>
							<p>2、公众号类型需要是【服务号】。</p>
							<p>
								3、如需开通会员自动通知消息，需对公众号进行认证并开通模版消息。
								<a href='https://www.sanfeng9.com/help/?id=318&aid=1410' target='_blank'>
									&gt;&gt;如何认证公众号并开通模版消息
								</a>
							</p>
							<div className='shouquan'>
								<span className='button' onClick={handleAuthorize}>
									<span className='icon iconfont'>&#xe615;</span>
									立即授权绑定
								</span>
							</div>
							<h1>授权绑定后您将开启微信公众号以下功能</h1>
							<div className='list'>
								<span className='item'>
									<span className='num'>1</span>
									关注欢迎语设置
								</span>
								<span className='item'>
									<span className='num'>2</span>
									系统自动通知
								</span>
								<span className='item'>
									<span className='num'>3</span>
									公众号菜单设置
								</span>
								<span className='item'>
									<span className='num'>4</span>
									自动回复设置
								</span>
								<span className='item'>
									<span className='num'>5</span>
									客户消息群发
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
			<Modal
				className='authorize-modal'
				visible={modalState.authorizeV}
				title='授权结果检测'
				onCancel={() => {
					getWechatInfo();
					setModalState({
						...modalState,
						authorizeV: false,
					});
				}}
				footer={[
					<Button key='retry' onClick={handleRetry}>
						授权失败 重试
					</Button>,
					<Button
						key='ok'
						type='primary'
						onClick={() => {
							getWechatInfo();
							setModalState({
								...modalState,
								authorizeV: false,
							});
						}}>
						授权成功
					</Button>,
				]}>
				<div className='text'>
					是否在新页面授权成功?{" "}
					<span
						className='tips'
						onClick={() => {
							window.open("https://www.zw78.com/help/?id=284&aid=1377");
						}}>
						如何授权
					</span>
				</div>
			</Modal>
		</div>
	);
};

export default Bind;
