import React, { Component, useState, useEffect, useRef } from "react";
import { Modal, Steps, Row, Col, Button, message, Input, Spin } from "antd";
import axios from "../../core/axios";
import Axios from "axios";
import User from "../../core/user";
import $ from "jquery";
import _Api from "../../core/api";
// import "./WebItemEdit.less";

const { TextArea } = Input;

const WebItemEditModal = ({ visible, editType, closeModal, handleComplete, itemType, curItem }) => {
	const [title, setTitle] = useState("");
	const [state, setState] = useState({
		id: "",
		name: "",
		img: "",
		description: "",
		detail: "",
		contentImg: [],
	});
	const [uploadFlag, setFlag] = useState(false);
	const imgRef = useRef(null);
	const contentRef = useRef(null);
	useEffect(() => {
		if (visible) {
			let title1 = editType ? "编辑" : "新增",
				title2 = itemType === "service" ? "项目" : "团队";
			setTitle(title1 + title2);
		}
		if (visible && editType) {
			const { id, name, img, description, detail, content_img } = curItem;
			setState({ ...state, id, name, img, description, detail, contentImg: content_img });
		}
	}, [visible, editType, itemType, curItem]);

	function handleMouseEnter(e) {
		const child = e.currentTarget.lastElementChild;
		child.style.zIndex = 1000;
	}
	function handleMouseLeave(e) {
		const child = e.currentTarget.lastElementChild;
		child.style.zIndex = -1;
	}
	function handleDeleteImg(index) {
		let _contentImg = state.contentImg;
		_contentImg.splice(index, 1);
		setState({ ...state, contentImg: _contentImg });
	}
	function handleOk() {
		const { id, name, img, description, detail, contentImg } = state;
		const body = {
			type: itemType,
			id,
			name,
			image: img,
			description,
			detail_content: detail,
			content_img: contentImg,
		};
		console.log(body);
		_Api.webInsert(body).then((res) => {
			message.success(res.msg);
			handleComplete();
		});
	}
	function imgUpload(e) {
		let file = e.target.files[0];
		const formData = new FormData();
		formData.append("file", file);
		formData.append("user_type", "shop");
		setFlag(true);
		return Axios.post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" + User.token(), formData)
			.then((res) => {
				setFlag(false);
				if (res.data.response == 200) {
					message.success("图片已上传");
					return res.data.img_url;
				}
			})
			.catch((err) => {
				setFlag(false);
				console.log(err);
			});
	}
	function handleImgUpload(e) {
		imgUpload(e).then((res) => {
			setState({ ...state, img: res });
		});
	}
	function handleContentImgUpload(e) {
		imgUpload(e).then((result) => {
			let contentImg = state.contentImg;
			contentImg.push(result);
			setState({ ...state, contentImg });
		});
	}
	return (
		<Modal width='560px' title={title} visible={visible} onOk={handleOk} onCancel={closeModal}>
			<Spin tip='图片上传中' spinning={uploadFlag}>
				<div
					className='modal-body'
					style={{
						minHeight: "350px",
						maxHeight: "500px",
						overflowY: "scroll",
					}}>
					<div className='form-control' style={{ display: "flex", alignItems: "center" }}>
						<label style={{ textAlign: "right" }}>{itemType === "service" ? "项目名称：" : "团队名称："}</label>
						<div className='input-wrap'>
							<Input
								type='text'
								value={state.name}
								placeholder={itemType === "service" ? "请输入项目名称" : "请输入团队名称"}
								onChange={(e) => {
									setState({
										...state,
										name: e.target.value,
									});
								}}
							/>
						</div>
					</div>
					<div className='form-control'>
						<label style={{ textAlign: "right" }}>上传照片：</label>
						<div className='input-wrap'>
							<input
								type='file'
								id='select-file'
								accept='image/gif,image/jpeg,image/png'
								style={{ display: "none" }}
								ref={imgRef}
								onChange={handleImgUpload}
							/>
							<Button
								onClick={() => {
									imgRef.current.click();
								}}>
								<span className='icon iconfont'>&#xe644;</span>
								选择图片
							</Button>
							<div className='tips'>建议尺寸：400 * 400像素</div>
							<div className='img-wrap square'>{state.img ? <img src={state.img} alt='' /> : null}</div>
						</div>
					</div>
					<div className='form-control'>
						<label style={{ textAlign: "right" }}>{itemType === "service" ? "项目简介：" : "团队特点："}</label>
						<div className='input-wrap'>
							<TextArea
								placeholder={
									itemType === "service"
										? "请填写项目的亮点，简短精炼，30字以内"
										: "请填写团队的特点，简短精炼，30字以内"
								}
								maxLength={30}
								value={state.description}
								onChange={(e) => {
									setState({
										...state,
										description: e.target.value,
									});
								}}
							/>
						</div>
					</div>
					<div className='form-control'>
						<label style={{ textAlign: "right" }}>{itemType === "service" ? "项目详情：" : "团队介绍："}</label>
						<div className='input-wrap'>
							<TextArea
								placeholder={
									itemType === "service" ? "请详细描述项目具体内容，1000字以内" : "请详细介绍团队和成员，1000字以内"
								}
								rows='7'
								maxLength='1000'
								style={{ width: "380px" }}
								value={state.detail}
								onChange={(e) => {
									setState({
										...state,
										detail: e.target.value,
									});
								}}
							/>
						</div>
					</div>
					<div className='form-control'>
						<label style={{ textAlign: "right" }}>{itemType === "service" ? "项目亮点：" : "明星成员："}</label>
						<div className='input-wrap'>
							<div>
								<ul className='upload clearfix'>
									{state.contentImg.length > 0
										? state.contentImg.map((item, index) => (
												<li key={index + "contentImg"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
													<img src={item} alt='' />
													<span
														className='icon iconfont'
														onClick={() => handleDeleteImg(index)}
														style={{
															position: "absolute",
															top: "5px",
															right: "5px",
															zIndex: "-1",
															transform: "rotate(45deg)",
															color: "rgb(252, 156, 107)",
															cursor: "pointer",
														}}>
														&#xe611;
													</span>
												</li>
										  ))
										: null}
									{state.contentImg.length < 9 ? (
										<li
											className='upload-add'
											onClick={() => {
												contentRef.current.click();
											}}>
											<div>
												<b className='icon iconfont'>&#xe6a3;</b>
											</div>
										</li>
									) : null}
								</ul>
								<input
									type='file'
									id='select-file2'
									accept='image/gif,image/jpeg,image/png'
									style={{ display: "none" }}
									ref={contentRef}
									onChange={handleContentImgUpload}
								/>
							</div>
							<p style={{ color: "rgb(154, 154, 154)" }}>支持扩展名：.jpg .jpeg .png，单张大小不超过5M，最多9张</p>
						</div>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export default WebItemEditModal;
