import React, { Component } from "react";
import { Pagination } from "antd";
import "./PageControl.less";

export default class PageControlImprove extends Component {
	render() {
		const { current, total, pageSize, showDataNums, showSizeChanger, size, toPage, className, onShowSizeChange,children } =
			this.props;
		const newClassName = "sf-page-control " + className;
		return (
			<div className={newClassName} style={{ ...this.props.style }}>
                <div style={{ display: "inline", float: "left",paddingTop:4 }}>{children}</div>
				<div style={{ display: "inline", float: "right" }}>
                    {(undefined === showDataNums || showDataNums) && <span style={{marginRight:10}}>共{total}条数据</span>}
					<Pagination
						size={size}
						current={parseInt(current)}
						total={total || 0}
						pageSizeOptions={["5", "10", "20", "30", "40", "50"]}
						onShowSizeChange={onShowSizeChange}
						showSizeChanger={showSizeChanger}
						pageSize={pageSize || 10}
						onChange={(page, count) => {
							console.log(7213127836217821818238721);
							console.log(page,count);
							toPage(page, count);
						}}
						showQuickJumper={true}
					/>
				</div>
			</div>
		);
	}
}
