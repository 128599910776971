import React, { useState, useEffect, useMemo, useContext } from "react";
import { Modal, Drawer } from "antd";
import _Api from "../../core/api";
import moment from "moment";
import mathFunc from "../../core/mathfunc";
import $ from "jquery";
import { getLodop } from "../../core/LodopFuncs";
import ShopContext from "../../core/context-manager";

const ReceivePrintModal = ({
  visible,
  closeModal,
  printContent,
  needInstall,
  installV,
  installClose,
}) => {
  const { branchInfo } = useContext(ShopContext);
  const [carInfo, setCarInfo] = useState({
    customer: "",
    dipanId: "",
    fdjId: "",
  });
  const [printState, setPrintState] = useState({
    title: "",
    subTitle: "",
    qrcode: "",
    address: "",
    phone: "",
    remark: "",
  });
  const nomalFontSize = "12px";
  const lineHeight = "26px";
  useEffect(() => {
    if (visible) {
      getCarInfo();
      getShopInfo();
    }
  }, [visible]);
  const slipaAount = useMemo(() => {
    let serviceAmount =
        (printContent.service_content && printContent.service_content.amount) ||
        0,
      partAmount =
        (printContent.part_content && printContent.part_content.amount) || 0;
    return parseFloat(serviceAmount) + parseFloat(partAmount);
  }, [
    printContent.service_content && printContent.service_content,
    printContent.part_content,
  ]);
  function getCarInfo() {
    _Api.carGet({ keyword: printContent.car_id }).then((res) => {
      let data = res.data.content[0],
        { customer, dipan_id, fdj_id } = data;
      setCarInfo({
        customer,
        dipanId: dipan_id,
        fdjId: fdj_id,
      });
    });
  }
  function getShopInfo() {
    const {
      address,
      phone,
      print_qrcode,
      print_remark,
      print_title_1,
      print_title_2,
    } = branchInfo;
    setPrintState({
      title: print_title_1,
      subTitle: print_title_2,
      qrcode: print_qrcode,
      address,
      phone,
      remark: print_remark,
    });
  }
  // 确定打印
  function handlePrint(params) {
    try {
      window.LODOP = getLodop();
      if (!window.LODOP) {
        return needInstall();
      }
      let e = document.getElementById("print-content").innerHTML;
      let a =
        (58 *
          ($(".a4-print-table")[0].clientHeight ||
            $(".a4-print-table")[0].offsetHeight)) /
        190;
      window.LODOP.PRINT_INIT("收据打印");
      window.LODOP.ADD_PRINT_HTM(0, "7mm", "190mm", a + "mm", e);
      window.LODOP.SET_PRINT_PAGESIZE(1, 2100, 10 * a, "CreateCustomPage");
      window.LODOP.SET_PRINT_STYLE("FontSize", 4);
      window.LODOP.PREVIEW();
    } catch (e) {
      console.log(e);
    }
  }
  const DoubleItem = ({ spanGroup, textGroup }) => (
    <>
      <div
        style={{
          flex: spanGroup[0],
          borderRight: "1px solid #000",
          borderBottom: "1px solid #000",
          textAlign: "center",
          lineHeight: lineHeight,
          boxSizing: "inherit",
          fontSize: nomalFontSize,
        }}
      >
        {textGroup[0]}
      </div>
      <div
        style={{
          flex: spanGroup[1],
          borderRight: "1px solid #000",
          borderBottom: "1px solid #000",
          lineHeight,
          boxSizing: "inherit",
        }}
      >
        <span style={{ marginLeft: "10px", fontSize: nomalFontSize }}>
          {textGroup[1]}
        </span>
      </div>
    </>
  );
  const FlexItem = ({ span, text }) => (
    <div
      style={{
        flex: span,
        borderRight: "1px solid #000",
        borderBottom: "1px solid #000",
        textAlign: "center",
        lineHeight,
        boxSizing: "inherit",
        fontSize: nomalFontSize,
        // fontFamily: "SimSun",
      }}
    >
      {text}
    </div>
  );
  const TopOuter = ({ children }) => (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        borderTop: "1px #000 solid",
        borderLeft: "1px #000 solid",
        marginTop: "10px",
        height: lineHeight,
      }}
    >
      {children}
    </div>
  );
  const MiddleOuter = ({ children }) => (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        borderLeft: "1px #000 solid",
        height: lineHeight,
      }}
    >
      {children}
    </div>
  );
  const serviceTable =
    printContent.service_content &&
    printContent.service_content.data &&
    printContent.service_content.data.map((item, index) => (
      <MiddleOuter key={item.id}>
        <FlexItem span={1} text={index + 1} />
        <FlexItem span={3.5} text={item.bianhao} />
        <FlexItem span={6} text={item.name} />
        <FlexItem span={2} text={item.price} />
        <FlexItem span={1} text={item.hours} />
        <FlexItem
          span={2}
          text={parseFloat(item.price) * parseFloat(item.hours)}
        />
        <FlexItem span={3} text={item.worker} />
      </MiddleOuter>
    ));
  const partTable =
    printContent.part_content &&
    printContent.part_content.data &&
    printContent.part_content.data.map((item, index) => (
      <MiddleOuter key={item.id}>
        <FlexItem span={1} text={index + 1} />
        <FlexItem span={3.5} text={item.bianhao} />
        <FlexItem
          span={6}
          text={`${item.name} (${item.brand} ${item.model})`}
        />
        <FlexItem span={2} text={item.priceSale} />
        <FlexItem span={1} text={item.num} />
        <FlexItem
          span={2}
          text={parseFloat(item.priceSale) * parseFloat(item.num)}
        />
        <FlexItem span={3} text={item.qualityDate} />
      </MiddleOuter>
    ));
  console.log(printContent);
  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <span style={{ fonSize: "16px" }}>打印收据</span>
            <div
              style={{ color: "#fc9c6b", fontSize: "13px", marginLeft: "20px" }}
            >
              <span
                className="icon iconfont"
                style={{
                  fontSize: "14px",
                  color: "#fc9c6b",
                  marginRight: "3px",
                }}
              >
                &#xe606;
              </span>
              收据内容设置请到：设置&gt;&gt;打印设置 中进行设设置
            </div>
          </div>
        }
        zIndex={1100}
        okText="打印"
        width={750}
        visible={visible}
        onCancel={closeModal}
        onOk={handlePrint}
      >
        {visible && (
          <div
            id="print-content"
            className="a4-print-table"
            style={{ width: "100%", color: "#000" }}
          >
            <div style={{ minHeight: "30px" }}></div>
            {printState.title && (
              <header
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {printState.title}
              </header>
            )}
            {printState.subTitle && (
              <header style={{ fontSize: "20px", textAlign: "center" }}>
                {printState.subTitle}
              </header>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <span
                style={{ fontSize: nomalFontSize }}
              >{`单号：${printContent.bianhao}`}</span>

              {printState.qrcode && (
                <div style={{ fontSize: nomalFontSize, position: "relative" }}>
                  扫码获取更多门店信息
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      position: "absolute",
                      top: "-85px",
                      left: "50%",
                      marginLeft: "-40px",
                    }}
                  >
                    <img
                      src={printState.qrcode}
                      alt=""
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <TopOuter>
              <DoubleItem
                spanGroup={["12", "55.3"]}
                textGroup={["客户", carInfo.customer]}
              />
              <DoubleItem
                spanGroup={["11", "22.02"]}
                textGroup={["车牌号", printContent.car_id]}
              />
            </TopOuter>
            <MiddleOuter>
              <DoubleItem
                spanGroup={["12", "22"]}
                textGroup={["联系人", printContent.car_linkman]}
              />
              <DoubleItem
                spanGroup={["15", "18"]}
                textGroup={["联系电话", printContent.car_phone]}
              />
              <DoubleItem
                spanGroup={["11", "22"]}
                textGroup={["车型", printContent.car_type]}
              />
            </MiddleOuter>
            <MiddleOuter>
              <DoubleItem
                spanGroup={["12", "22"]}
                textGroup={["底盘号", carInfo.dipanId]}
              />
              <DoubleItem
                spanGroup={["15", "18"]}
                textGroup={["发动机号", carInfo.fdjId]}
              />
              <DoubleItem
                spanGroup={["11", "22"]}
                textGroup={["行驶里程", printContent.cur_mileage]}
              />
            </MiddleOuter>
            <MiddleOuter>
              <DoubleItem
                spanGroup={["15", "19"]}
                textGroup={["下次保养时间", printContent.next_maintain_time]}
              />
              <DoubleItem
                spanGroup={["15", "18"]}
                textGroup={["下次保养里程", printContent.next_maintain_mileage]}
              />
              <DoubleItem
                spanGroup={["11", "22"]}
                textGroup={[
                  "开单日期",
                  moment(printContent.gmt_creat).format("YYYY-MM-DD"),
                ]}
              />
            </MiddleOuter>
            {printContent.service_content.data && (
              <>
                <section
                  style={{
                    fontWeight: "bold",
                    margin: "20px 0 12px 0",
                    textAlign: "center",
                  }}
                >
                  服务项目
                </section>
                <TopOuter>
                  <FlexItem span={1} text="序号" />
                  <FlexItem span={3.5} text="编号" />
                  <FlexItem span={6} text="服务项目名称" />
                  <FlexItem span={2} text="单价" />
                  <FlexItem span={1} text="工时" />
                  <FlexItem span={2} text="金额" />
                  <FlexItem span={3} text="维修技师" />
                </TopOuter>
                {serviceTable}
                <div
                  style={{
                    height: "36px",
                    borderWidth: "0 1px 1px 1px",
                    borderColor: "#000",
                    borderStyle: "solid",
                    textAlign: "right",
                  }}
                >
                  <span
                    style={{
                      lineHeight: "36px",
                      marginRight: "20px",
                    }}
                  >{`应收工时费：${mathFunc.returnFloat(
                    printContent.service_content.amount
                  )}元`}</span>
                  {printContent.member_num_pay === "0.0" && (
                    <span
                      style={{
                        lineHeight: "36px",
                        marginRight: "12px",
                      }}
                    >{`实收工时费：${mathFunc.returnFloat(
                      printContent.service_content.ar
                    )}元`}</span>
                  )}
                </div>
              </>
            )}
            {printContent.part_content.data && (
              <>
                <section
                  style={{
                    fontWeight: "bold",
                    margin: "20px 0 12px 0",
                    textAlign: "center",
                  }}
                >
                  维修配件
                </section>
                <TopOuter>
                  <FlexItem span={1} text="序号" />
                  <FlexItem span={3.5} text="编号" />
                  <FlexItem span={6} text="配件名称" />
                  <FlexItem span={2} text="单价" />
                  <FlexItem span={1} text="数量" />
                  <FlexItem span={2} text="金额" />
                  <FlexItem span={3} text="质保限期" />
                </TopOuter>
                {partTable}
                <div
                  style={{
                    height: "36px",
                    borderWidth: "0 1px 1px 1px",
                    borderColor: "#000",
                    borderStyle: "solid",
                    textAlign: "right",
                  }}
                >
                  <span
                    style={{
                      lineHeight: "36px",
                      marginRight: "20px",
                    }}
                  >{`应收配件费：${mathFunc.returnFloat(
                    printContent.part_content.amount
                  )}元`}</span>
                  {printContent.member_num_pay === "0.0" && (
                    <span
                      style={{
                        lineHeight: "36px",
                        marginRight: "12px",
                      }}
                    >{`实收配件费：${mathFunc.returnFloat(
                      printContent.part_content.ar
                    )}元`}</span>
                  )}
                </div>
              </>
            )}
            <section
              style={{
                fontWeight: "bold",
                margin: "20px 0 12px 0",
                textAlign: "center",
              }}
            >
              结算金额
            </section>
            <TopOuter>
              <DoubleItem
                spanGroup={["1", "2"]}
                textGroup={[
                  "应收金额",
                  `${
                    printContent.member_num_pay === "0.0"
                      ? mathFunc.returnFloat(printContent.money_all)
                      : 0
                  }`,
                ]}
              />
              <DoubleItem
                spanGroup={["1", "2"]}
                textGroup={[
                  "实收金额",
                  `${
                    printContent.member_num_pay === "0.0"
                      ? mathFunc.returnFloat(printContent.money_pay)
                      : 0
                  }`,
                ]}
              />
              <DoubleItem
                spanGroup={["1", "2"]}
                textGroup={[
                  "欠收金额",
                  mathFunc.returnFloat(
                    parseFloat(printContent.money_all) -
                      parseFloat(printContent.money_pay)
                  ),
                ]}
              />
            </TopOuter>
            <MiddleOuter>
              {printContent.member_num_pay === "0.0" ? (
                <>
                  <DoubleItem
                    spanGroup={["1", "2"]}
                    textGroup={[
                      "优惠",
                      mathFunc.returnFloat(
                        slipaAount - parseFloat(printContent.money_all)
                      ),
                    ]}
                  />
                  <DoubleItem
                    spanGroup={["1", "5.03"]}
                    textGroup={[
                      "大写",
                      mathFunc.cashToChinese(printContent.money_pay),
                    ]}
                  />
                </>
              ) : (
                <DoubleItem
                  spanGroup={["1", "8.1"]}
                  textGroup={[
                    "优惠",
                    mathFunc.returnFloat(
                      slipaAount - parseFloat(printContent.money_all)
                    ),
                  ]}
                />
              )}
            </MiddleOuter>
            {printContent.member_num_pay !== "0.0" && (
              <p
                style={{ marginTop: "5px" }}
              >{`已使用会员卡（计次类型）完成结算，本次消费${mathFunc.reservedCount(
                printContent.member_num_pay
              )}次`}</p>
            )}

            <p style={{ marginTop: "20px" }}>{`备注：${printState.remark}`}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ minWidth: "60%", textAlign: "left" }}>
                <div
                  style={{
                    lineHeight: "36px",
                    height: "36px",
                    fontFamily: "SimSun",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"联系地址：" + printState.address}
                </div>
                <div
                  style={{
                    lineHeight: "36px",
                    height: "36px",
                    fontFamily: "SimSun",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"服务顾问： " + printContent.worker}
                </div>
                <div
                  style={{
                    lineHeight: "36px",
                    height: "36px",
                    fontFamily: "SimSun",
                  }}
                >
                  客户签名：
                </div>
              </div>
              <div style={{ flex: 1, textAlign: "left" }}>
                <div
                  style={{
                    lineHeight: "36px",
                    height: "36px",
                    fontFamily: "SimSun",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"联系电话：" + printState.phone}
                </div>
                <div
                  style={{
                    lineHeight: "36px",
                    height: "36px",
                    fontFamily: "SimSun",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"结算人员： " +
                    (printContent.input_user ? printContent.input_user : "")}
                </div>
                <div
                  style={{
                    lineHeight: "36px",
                    height: "36px",
                    fontFamily: "SimSun",
                    whiteSpace: "nowrap",
                  }}
                >
                  {`结算日期：${moment(printContent.end_time).format(
                    "YYYY年MM月DD日"
                  )}`}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Drawer
        title="打印失败"
        placement="top"
        closable={false}
        onClose={installClose}
        visible={installV}
        zIndex={1100}
        height={130}
      >
        <p>
          Web打印服务CLodop未安装启动，点击这里
          <a
            href={
              "https://c.sanfeng9.com/print_driver/CLodop_Setup_for_Win32NT.exe"
            }
            target="_blank"
          >
            下载执行安装
          </a>
          ,安装完成后请重新进入或刷新网页
        </p>
      </Drawer>
    </>
  );
};

export default ReceivePrintModal;
