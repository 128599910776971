import { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  Form,
  Popconfirm,
  Table,
  message,
  Select,
  Tooltip,
} from "antd";
import _Api from "../../core/api";
import TitleBar from "../TitleBar";
import TitleBarTwo from "../TitleBarTwo";
import mathFunc from "../../core/mathfunc";
import PromoteInput from "../PromoteInput";
import VipRecharge from "./VipRecharge";
import memberCard from "../../libs/member_card.png";
import "./ReceiveCompleted.less";
const { Option } = Select;

//eslint-disable-next-line
const ReceiveCompleted = ({
  visible,
  closeModal,
  memberData,
  modalInfo,
  handlePayOver,
  handleCharge,
  memberCardSelect,
  getOrderListAll,
}) => {
  const [payState, setPayState] = useState({
    type: "",
    money: "",
    inputUser: "",
    remark: "",
    member_card: "",
    package_type: "",
    package_name: "",
    package_content: "",
    left_money: 0,
    left_num: "",
  });
  const [moneyAll, setMoneyAll] = useState(0);
  const [user, setUser] = useState("");
  const [count, setCount] = useState(1);
  const [payTypes, setPayTypes] = useState([]);
  // const [payHistory, setPayHistory] = useState([]);
  const [chargeVisible, setChargeVisible] = useState(false);
  useEffect(() => {
    if (visible) {
      const { member_card } = modalInfo;
      console.log(member_card);
      if (member_card) {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          getMemberCardInfo();
        }, 100);
      }
    }
  }, [visible]);
  useEffect(() => {
    if (visible) {
      getCurrentUser();
      getPayTypes();
      // getPayHistory();
    }
  }, [visible]);
  useEffect(() => {
    if (memberData.bianHao) {
      console.log(memberData);
      const {
        bianHao,
        leftMoney,
        leftNum,
        packageType,
        packageName,
        partZk,
        serviceZk,
        packageContent,
      } = memberData;
      const data = {
        id: modalInfo.id,
        member_card: bianHao,
      };
      console.log(data);
      console.log(bianHao, payState.member_card);
      if (modalInfo.id) {
        if (bianHao !== payState.member_card) {
          console.log("请求啦");
          _Api.orderMeberCardSet(data).then((res) => {
            console.log(res);
            setMoneyAll(res.data.money_all);
            // getOrderListAll();
          });
        }
      }
      setPayState({
        ...payState,
        member_card: bianHao,
        left_money: leftMoney || 0,
        package_type: packageType,
        left_num: leftNum,
        package_name: packageName,
        package_content: packageContent,
      });
    }
  }, [memberData]);
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "收款时间",
      dataIndex: "gmt_creat",
    },
    {
      title: "收款方式",
      dataIndex: "pay_type",
    },
    {
      title: "收款金额",
      dataIndex: "money_real",
    },
    {
      title: "收款人",
      align: "center",
      dataIndex: "input_user",
      render: (text) => (text ? text : "-"),
    },
  ];
  // 有会员卡的 余额/次数 回填
  function getMemberCardInfo() {
    const { member_card } = modalInfo;
    const data = {
      keyword: member_card,
    };
    _Api.cardSelect(data).then((res) => {
      if (!res.data.content[0]) {
        return;
      }
      const {
        package_type,
        package_name,
        left_money,
        left_num,
        package_content,
      } = res.data.content[0];
      setPayState({
        ...payState,
        package_name,
        package_type,
        left_money,
        left_num,
        type: "会员卡",
        member_card,
        package_content,
      });
    });
  }
  function getMember() {
    const data = {
      keyword: payState.member_card,
    };
    _Api.cardSelect(data).then((res) => {
      const {
        package_type,
        package_name,
        left_money,
        left_num,
        package_content,
      } = res.data.content[0];
      setPayState({
        ...payState,
        package_name,
        package_type,
        left_money,
        left_num,
        type: "会员卡",
        package_content,
      });
    });
  }
  // 应付原价
  const originalPrice = useMemo(() => {
    const partContent = modalInfo.part_content?.amount || 0;
    const serviceContent = modalInfo.service_content?.amount || 0;
    return (parseFloat(partContent) + parseFloat(serviceContent)).toFixed(2);
  }, [modalInfo]);

  //应扣金额
  const allMoney = useMemo(() => {
    if (moneyAll) {
      return parseFloat(moneyAll).toFixed(2);
    } else {
      return parseFloat(modalInfo.money_all).toFixed(2);
    }
  }, [moneyAll, modalInfo.money_all]);
  // 已优惠金额
  // const slipAr = useMemo(() => {
  //   let serviceAr =
  //     (modalInfo.service_content && modalInfo.service_content.ar) || 0;
  //   let partAr = (modalInfo.part_content && modalInfo.part_content.ar) || 0;
  //   if (memberData.serviceZk) {
  //     const serviceZk = parseFloat(memberData.serviceZk);
  //     const partZk = parseFloat(memberData.partZk);
  //     console.log(serviceZk, partZk);
  //     return mathFunc.returnFloat(
  //       parseFloat(serviceAr) * serviceZk + parseFloat(partAr) * partZk
  //     );
  //   } else {
  //     const serviceZk = parseFloat(modalInfo.service_zk);
  //     const partZk = parseFloat(modalInfo.part_zk);
  //     return mathFunc.returnFloat(
  //       parseFloat(serviceAr) * serviceZk + parseFloat(partAr) * partZk
  //     );
  //   }
  // }, [
  //   modalInfo.service_content && modalInfo.service_content,
  //   modalInfo.part_content,
  //   memberData,
  // ]);

  // 已优惠金额
  const discount = useMemo(() => {
    if (moneyAll) {
      const discountPrice = parseFloat(moneyAll);
      return mathFunc.returnFloat(originalPrice - discountPrice);
    } else {
      const discountPrice = parseFloat(modalInfo.money_all);
      return mathFunc.returnFloat(originalPrice - discountPrice);
    }
  }, [modalInfo.money_all, moneyAll]);
  // 储值余额是否能够付款
  const payAll = useMemo(() => {
    if (payState.type === "会员卡") {
      if (memberData.bianHao) {
        let realMoney = parseFloat(payState.left_money) || 0;
        if (moneyAll) {
          const price = parseFloat(moneyAll);
          console.log(realMoney, price, realMoney >= price);
          return realMoney >= price;
        } else {
          const price = parseFloat(modalInfo.money_all);
          console.log(realMoney, price, realMoney >= price);
          return realMoney >= price;
        }
      } else {
        let realMoney = parseFloat(payState.left_money) || 0; // 余额
        const price = parseFloat(modalInfo.money_all); // 应扣
        return realMoney >= price;
      }
    } else {
      const money = parseFloat(payState.money) || 0;
      console.log("对比卡");
      return money >= originalPrice;
    }
  }, [
    payState.money,
    moneyAll,
    memberData,
    modalInfo.money_all,
    payState.type,
    payState.money,
    payState.left_money,
  ]);

  // 计次 次数能否付款
  const payCount = useMemo(() => {
    if (memberData.bianHao) {
      let realMoney = parseFloat(memberData.leftNum) || 0;
      return realMoney >= count;
    } else {
      let realMoney = parseFloat(payState.left_num) || 0;
      return realMoney >= count;
    }
  }, [count, payState.left_num, memberData]);
  // 次数改变
  function handleChange(data) {
    setCount(data);
  }
  // 点击收款
  function settleConfirm() {
    // 判断是否是会员卡结账
    if (payState.type === "会员卡") {
      // 判断会员卡结账类型
      if (payState.package_type === "储值卡") {
        if (!payAll) {
          return;
        } else {
          orderSettle();
        }
      } else {
        if (!payCount) {
          return;
        } else {
          orderSettle();
        }
      }
    } else {
      console.log(356673256735765);
      if (!payAll) {
        return;
      }
      const monad = {
        id: modalInfo.id,
        member_card: "",
      };
      _Api.orderMeberCardSet(monad).then((res) => {
        if (res.response === "200") {
          orderSettle(false);
        }
      });
    }
  }
  function order(data) {
    const monad = {
      id: modalInfo.id,
      member_card: "",
    };
    _Api.orderMeberCardSet(monad).then((res) => {
      if (res.response === "200") {
        orderSettle(data);
      }
    });
  }
  // 收款逻辑
  function orderSettle(debt) {
    const if_guazhang = debt ? "1" : "0";
    const { type, money, remark, left_money, left_num } = payState;
    let data = {
      id: modalInfo.id,
      pay_type: type,
      input_user: user,
      remark,
      if_guazhang,
    };
    if (payState.type === "会员卡") {
      if (payState.package_type === "计次卡") {
        data = { ...data, member_num: count };
      }
    } else {
      console.log("非会员");
      data = { ...data, money_real: money };
    }
    console.log(data);
    _Api.orderCaiwuInsert(data).then((res) => {
      if (res.response === "500521") {
        return message.error(res.msg);
      } else if (res.response === "200") {
        message.success(res.msg);
        initalState();
        handlePayOver();
      } else {
        message.error(res.msg);
      }
    });
  }
  //获取经办人
  function getCurrentUser(params) {
    _Api.userInfoGet().then((res) => {
      let user = res.token_info.user_shop.cur_user.user_info.name;
      // setPayState({ ...payState, inputUser: user });
      setUser(user);
    });
  }

  function getPayTypes() {
    _Api.getPayType().then((res) => {
      setPayTypes(res.msg);
    });
  }
  // function getPayHistory() {
  //   const data = { order_bianhao: modalInfo.bianhao };
  //   _Api.caiwuSelect(data).then((res) => {
  //     // console.log(res);
  //     setPayHistory(res.data.content);
  //   });
  // }
  // 关闭
  function onCancel() {
    initalState();
    closeModal();
  }
  // 数据初始化
  function initalState() {
    setPayState({
      type: "",
      money: "",
      inputUser: "",
      remark: "",
      member_card: "",
      package_type: "",
      package_name: "",
      left_money: 0,
      left_num: "",
    });
  }
  console.log(modalInfo);
  return (
    <>
      <Modal
        title="收款"
        footer={null}
        zIndex={900}
        width={985}
        visible={visible}
        onCancel={onCancel}
        className="Receive-completed"
      >
        <Form style={{ color: "#666" }}>
          <TitleBarTwo style={{ marginBottom: 20 }}>
            <span>信息确认</span>
          </TitleBarTwo>
          <Row>
            <Col span={8}>
              <Form.Item label="单号">
                <span>{modalInfo.bianhao}</span>
              </Form.Item>
              <Form.Item label="车牌号">
                <span>{modalInfo.car_id}</span>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label="联系人">
                <span>{modalInfo.car_linkman}</span>
              </Form.Item>
              <Form.Item label="接待人">
                <span>{modalInfo.worker}</span>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label="电话">
                <span>{modalInfo.car_phone}</span>
              </Form.Item>
              <Form.Item label="结算时间">
                <span>{modalInfo.end_time}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="经办人">
                <Input
                  // value={payState.inputUser}
                  value={user}
                  placeholder="请输入经办人"
                  onChange={(e) => {
                    // setPayState({ ...payState, inputUser: e.target.value });
                    setUser(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={15} offset={1}>
              <Form.Item label="备注信息">
                {/* <TextArea rows={2} style={{ width: "457px" }} /> */}
                <Input
                  placeholder="请输入备注信息"
                  value={payState.remark}
                  onChange={(e) => {
                    setPayState({ ...payState, remark: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="Receive-completed-box">
            <Col span={12}>
              {modalInfo.member_card === "" ? (
                <TitleBarTwo
                  style={
                    payState.type === "会员卡"
                      ? { marginBottom: 44 }
                      : { marginBottom: 17 }
                  }
                >
                  <span>收款方式</span>
                </TitleBarTwo>
              ) : (
                <TitleBarTwo
                  style={
                    payState.type === "会员卡"
                      ? { marginBottom: 45 }
                      : { marginBottom: 17 }
                  }
                >
                  <span>收款方式</span>
                </TitleBarTwo>
              )}

              <Row>
                {payTypes.map((item, index) => (
                  <Col span={7}>
                    <div
                      onClick={() => {
                        setPayState({ ...payState, type: item.text });
                      }}
                      key={item.id}
                      style={{
                        height: "36px",
                        width: "110px",
                        lineHeight: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor:
                          item.text === payState.type ? "#ff8534" : "#D9D9D9",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        padding: "10px 10px",
                        borderRadius: "5px",
                        marginRight: "5px",
                        marginBottom: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        className="icon iconfont"
                        dangerouslySetInnerHTML={{ __html: item.icon }}
                        style={{
                          fontSize: "22px",
                          marginRight: "5px",
                          color: item.color,
                        }}
                      ></span>
                      {item.text}
                    </div>
                  </Col>
                ))}
                <Col span={7}>
                  <div
                    onClick={() => {
                      setPayState({ ...payState, type: "会员卡" });
                    }}
                    key={6}
                    style={{
                      height: "36px",
                      width: "110px",
                      lineHeight: "36px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderColor:
                        payState.type === "会员卡" ? "#ff8534" : "#D9D9D9",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      padding: "10px 10px",
                      borderRadius: "5px",
                      marginRight: "5px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {/* <img
                      style={{
                        width: 24,
                        height: 19,
                        marginRight: 3,
                        marginLeft: 3,
                      }}
                      src={memberCard}
                      alt=""
                    /> */}
                    <span
                      className="icon iconfont"
                      //   dangerouslySetInnerHTML={{ __html: item.icon }}
                      style={{
                        fontSize: "22px",
                        marginRight: "5px",
                        color: "#FCCE05 ",
                      }}
                    >
                      &#xe640;
                    </span>
                    会员卡
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <TitleBarTwo style={{ marginBottom: 30 }}>
                <span>付款金额</span>
              </TitleBarTwo>
              {payState.type !== "会员卡" && (
                <Row>
                  <Col span={24}>
                    <Form.Item label="应付金额">
                      <div
                        style={{
                          color: "#FF0000",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>￥</span>
                        <span style={{ fontSize: "19px" }}>
                          {/* {parseFloat(modalInfo.money_all).toFixed(2)} */}
                          {/* {(
                            parseFloat(modalInfo.part_content?.amount) +
                            parseFloat(modalInfo.service_content?.amount)
                          ).toFixed(2)} */}
                          {originalPrice}
                        </span>
                        {/* <span>元</span> */}
                      </div>
                    </Form.Item>
                    <Form.Item label="收款金额">
                      <Row>
                        <Col span={8}>
                          <PromoteInput
                            width={133}
                            placeholder="请输入收款金额"
                            value={payState.money}
                            unit="元"
                            onChange={(e) => {
                              setPayState({
                                ...payState,
                                money: e.target.value,
                              });
                            }}
                          ></PromoteInput>
                        </Col>
                        <Col span={5}>
                          <span
                            style={{
                              display: "inline-block",
                              height: "32px",
                              color: "#FF0000",
                              lineHeight: "40px",
                              cursor: "pointer",
                              marginLeft: 10,
                            }}
                            onClick={() => {
                              setPayState({
                                ...payState,
                                money: originalPrice,
                              });
                            }}
                          >
                            全额收款
                          </span>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {payState.type === "会员卡" && (
                <>
                  <Row>
                    <Col span={12}>
                      <Form.Item label="会员卡号">
                        <Input value={payState.member_card}></Input>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="primary"
                        className="btn-green"
                        size="middle"
                        style={{ marginLeft: "5px", width: 60, height: 32 }}
                        onClick={() => {
                          memberCardSelect();
                        }}
                      >
                        选择
                      </Button>
                    </Col>
                    {payState.package_type === "计次卡" && (
                      <Col span={7}>
                        <div style={{ fontSize: "12px", lineHeight: "42px" }}>
                          剩余次数：{payState.left_num} 次
                        </div>
                      </Col>
                    )}
                  </Row>
                  {payState.package_type === "储值卡" && (
                    <>
                      <Row>
                        <Col span={10}>
                          <Form.Item label="余额">
                            <div>
                              <span style={{ fontSize: "16px" }}>￥</span>
                              <span style={{ fontSize: "18px" }}>
                                {payState.left_money.toFixed(2)}
                              </span>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          <Form.Item label="应扣金额">
                            <div
                              style={{
                                color: "#FF0000",
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                marginLeft: 10,
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  top: 6,
                                  left: -13,
                                }}
                              >
                                ￥
                              </span>
                              <span style={{ fontSize: "19px" }}>
                                {/* {parseFloat(moneyAll).toFixed(2) ||
                                  parseFloat(modalInfo.money_all).toFixed(2)} */}
                                {allMoney}
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#666",
                                  marginLeft: 5,
                                }}
                              >
                                (已优惠￥{discount})
                              </span>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  {payState.package_type === "计次卡" && (
                    <>
                      <Row>
                        <Col span={10}>
                          <Form.Item label="套餐">
                            <span>{payState.package_name}</span>
                            <Tooltip
                              placement="top"
                              title={payState.package_content}
                            >
                              <span
                                id="abcd"
                                className="icon iconfont"
                                style={{
                                  display: "inline-block",
                                  width: 20,
                                  fontSize: "14px",
                                  color: "#FFCAA9",
                                  marginLeft: "10px",
                                }}
                              >
                                &#xe606;
                              </span>
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>
                          <Form.Item label="应扣次数">
                            <Select
                              defaultValue="1"
                              style={{
                                width: 200,
                              }}
                              onChange={handleChange}
                            >
                              <Option value="1">1次</Option>
                              <Option value="1.5">1.5次</Option>
                              <Option value="2">2次</Option>
                              <Option value="2.5">2.5次</Option>
                              <Option value="3">3次</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Form>
        {payState.type !== "会员卡" && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popconfirm
              placement="top"
              disabled={payAll}
              title={
                <>
                  <p>您填写的收款金额不足应收金额</p>
                  <p>请确认结算完毕还是挂帐</p>
                </>
              }
              onConfirm={() => {
                order(true);
              }}
              onCancel={() => {
                order();
              }}
              okText="挂帐"
              cancelText="结算完毕"
            >
              {" "}
              <Button
                style={{ width: 110, height: 42 }}
                type="primary"
                onClick={settleConfirm}
                className="antd-button-font"
              >
                收款
              </Button>{" "}
            </Popconfirm>{" "}
          </div>
        )}
        {payState.type === "会员卡" &&
          payState.package_type === "储值卡" &&
          payAll && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ width: 110, height: 42 }}
                type="primary"
                onClick={settleConfirm}
                className="antd-button-font"
              >
                收款
              </Button>{" "}
            </div>
          )}
        {payState.type === "会员卡" &&
          payState.package_type === "计次卡" &&
          payCount && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ width: 110, height: 42 }}
                type="primary"
                onClick={settleConfirm}
                className="antd-button-font"
              >
                收款
              </Button>{" "}
            </div>
          )}
        {payState.type === "会员卡" && !payState.member_card && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ width: 110, height: 42 }}
              type="primary"
              onClick={settleConfirm}
              className="antd-button-font"
            >
              收款
            </Button>{" "}
          </div>
        )}
        {payState.type === "会员卡" &&
          payState.package_type === "储值卡" &&
          !payAll &&
          payState.member_card && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#525252",
              }}
            >
              <span style={{ fontSize: "16px" }}>会员卡余额不足，请先</span>
              <span
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  height: 20,
                  lineHeight: "25px",
                  fontSize: "16px",
                  color: "#FF9551",
                  marginLeft: 5,
                  borderBottom: "2px solid #FF9551",
                }}
                onClick={() => {
                  setChargeVisible(true);
                }}
              >
                去充值&gt;
              </span>
            </div>
          )}
        {payState.type === "会员卡" &&
          payState.package_type === "计次卡" &&
          !payCount &&
          payState.member_card && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#525252",
              }}
            >
              <span style={{ fontSize: "16px" }}>会员卡余额不足，请先</span>
              <span
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  height: 20,
                  lineHeight: "25px",
                  fontSize: "16px",
                  color: "#FF9551",
                  marginLeft: 5,
                  borderBottom: "2px solid #FF9551",
                }}
                onClick={() => {
                  setChargeVisible(true);
                }}
              >
                去充值&gt;
              </span>
            </div>
          )}

        {/* </Popconfirm> */}

        {/* {payHistory.length > 0 && (
        <>
          <TitleBar>
            <span>收款历史</span>
          </TitleBar>
          <Table
            dataSource={payHistory}
            columns={columns}
            bordered
            pagination={false}
          />
        </>
      )} */}
      </Modal>

      {/* 充值 弹框 */}
      <VipRecharge
        visible={chargeVisible}
        bianhao={payState.member_card}
        closeModal={() => {
          setChargeVisible(false);
        }}
        handleComplete={() => {
          setChargeVisible(false);
          // getVipList();
          // getMemberCardInfo();
          getMember();
        }}
      />
    </>
  );
};
export default ReceiveCompleted;
