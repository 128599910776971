import { useEffect, useState, useContext } from "react";
import { Input, message } from "antd";
import Rank from "./Rank";
import "./School.less";
import _Api from "../../core/api";
import circle from "../../libs/1.png";
import ShopContext from "../../core/context-manager";
import moment from "moment";

const ShopManage = ({ history }) => {
  const { shopInfo, branchInfo, resetShopInfo } = useContext(ShopContext);
  const [state, setState] = useState({
    showDataList: [
      {
        val: 0,
        title: "进场车辆",
      },
      {
        val: 0,
        title: "办卡数量",
      },
      {
        val: 0,
        title: "会员充值",
      },
      {
        val: 0,
        title: "本月实收",
      },
    ],
    shop_level: "",
    shopData: [],
    name: "",
  });
  const [rankState, setRankState] = useState({
    list: [],
    type: "num",
  });
  useEffect(() => {
    console.log(shopInfo);
    getLoginSet();
    getShoplData();
  }, []);
  function getLoginSet() {
    setState({
      ...state,
      shop_level: shopInfo.shop_level,
      name: branchInfo.name,
    });
  }
  function getShoplData() {
    _Api.shopData({}).then((res) => {
      let all_cars = 0,
        all_cards = 0,
        member_income = 0,
        all_income = 0,
        rank = res.data.map((item, index) => {
          all_cars += Number(item.all_cars);
          all_cards += Number(item.all_cards);
          member_income += Number(item.member_income);
          all_income += Number(item.all_income);
          return {
            id: item.id,
            name: item.name,
            all_cars: item.all_cars,
            all_income: item.all_income,
          };
        });
      rank.sort((a, b) => {
        const prev = a.all_cars;
        const next = b.all_cars;
        return next - prev;
      });

      setState({
        ...state,
        shop_level: shopInfo.shop_level,
        name: branchInfo.name,
        shopData: res.data,
        showDataList: [
          { val: all_cars, title: "进场车辆" },
          { val: all_cards, title: "办卡数量" },
          { val: member_income, title: "会员充值" },
          { val: all_income, title: "本月实收" },
        ],
      });
      setRankState({ list: rank, type: "num" });
    });
  }
  function changeDomain(id) {
    _Api.branchChange({ branch_id: id }).then((res) => {
      resetShopInfo();
      history.push("/home");
    });
  }
  function branchNameSet(id, index) {
    const { shopData } = state;
    let data = { branch_id: id, branch_name: shopData[index].name };
    _Api.branchNameSet(data).then((res) => {
      message.success(res.msg);
      getShoplData();
      resetShopInfo();
    });
  }
  function rankTypeChange(e) {
    let rank = rankState.list;

    if (e.target.value == "num") {
      rank.sort((a, b) => {
        const prev = a.all_cars;
        const next = b.all_cars;
        return next - prev;
      });
    }
    if (e.target.value == "income") {
      rank.sort((a, b) => {
        const prev = a.all_income;
        const next = b.all_income;
        return next - prev;
      });
    }
    setRankState({ list: rank, type: e.target.value });
  }

  return (
    <div className="container-wrap home">
      <div className="box-container home-box">
        <div className="box-header">
          <span className="box-title">{shopInfo.name}</span>
        </div>
        <div className="box-body without-padding">
          <ul className="top-line-box">
            {state.showDataList.map((item, index) => (
              <li key={index} className="top-line-student">
                <div className="line-border">
                  <div>
                    <div
                      className="small-title-tip"
                      style={{ marginBottom: 0 }}
                    >
                      <span
                        onClick={() => {
                          if (item.link) {
                            history.push(item.link);
                          }
                        }}
                        style={{ cursor: "default" }}
                        className={item.link ? "" : "nolink"}
                      >
                        {item.title}
                      </span>
                    </div>
                    <div className="number-tip">
                      <span
                        style={{ fontSize: "32px", cursor: "default" }}
                        onClick={() => {
                          if (item.link) {
                            history.push(item.link);
                          }
                        }}
                        className={`right-text ${item.link ? "" : "nolink"}`}
                      >
                        {item.val}
                        {(index == 2 || index == 3) && (
                          <span style={{ fontSize: "14px" }}>元</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="template-home-index template-school-index">
        <div className="left">
          <div className="box-container">
            <div
              className="box-header fast-enter"
              style={{ justifyContent: "start", alignItems: "center" }}
            >
              <span className="box-title" style={{ marginRight: "25px" }}>
                门店管理
              </span>
              <div className="shop-info-outer">
                <span className="info-text">{`版本：${
                  shopInfo.shop_level === "999"
                    ? "不限门店版"
                    : shopInfo.shop_level === "500"
                    ? "五门店版"
                    : shopInfo.shop_level === "100"
                    ? "单门店版"
                    : "永久免费版"
                }`}</span>
                <div
                  className="shop-manage-btn"
                  onClick={() => {
                    // window.open("https://www.sanfeng9.com/help/", "_blank");
                    window.open("https://www.sanfeng9.com/version", "_blank");
                  }}
                >
                  升级
                </div>
              </div>
              <div className="shop-info-outer">
                {shopInfo.end_time === "2999-9-9" ? (
				  <span style={{paddingRight:13}} className="info-text">到期时间：无限制</span>
                ) : (
                  <>
                    <span className="info-text">{`到期时间：${moment(
                      shopInfo.end_time
                    ).format("YYYY-MM-DD")}`}</span>
                    <div
                      className="shop-manage-btn"
                      onClick={() => {
                        // window.open("https://www.sanfeng9.com/help/", "_blank");
                        window.open(
                          "https://www.sanfeng9.com/version",
                          "_blank"
                        );
                      }}
                    >
                      续费
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="box-body">
              <ul className="content-one-box" style={{ paddingLeft: 0 }}>
                {state.shopData &&
                  state.shopData.map((item, index) => (
                    <li onClick={() => {}} key={index} className="row">
                      <div className="content">
                        <div className="title">
                          <span>
                            <img className="circle" src={circle} alt="" />
                          </span>

                          {item.state ? (
                            <Input
                              value={item.name}
                              onChange={(e) => {
                                let data = state.shopData;
                                data[index].name = e.target.value;
                                setState({ ...state, shopData: data });
                              }}
                              style={{ width: 150 }}
                            />
                          ) : (
                            <span className="text">{item.name}</span>
                          )}
                          {item.state ? (
                            <span
                              style={{
                                marginLeft: 5,
                                cursor: "pointer",
                                verticalAlign: "baseline",
                              }}
                              onClick={() => branchNameSet(item.id, index)}
                              className="icon iconfont edit"
                              title="保存"
                            >
                              &#xe680;
                            </span>
                          ) : (
                            <span
                              style={{
                                marginLeft: 5,
                                cursor: "pointer",
                                verticalAlign: "baseline",
                              }}
                              onClick={() => {
                                let data = state.shopData;
                                if (item.name === state.name) {
                                  data[index].super_branch = true;
                                }
                                data[index].state = true;
                                setState({ ...state, shopData: data });
                              }}
                              className="icon iconfont edit"
                              title="修改"
                            >
                              &#xe60c;
                            </span>
                          )}
                          <span
                            className="link"
                            onClick={() => changeDomain(item.id)}
                          >
                            &gt;&gt;
                          </span>
                        </div>
                        {/* <div className='tab'>
													<span className={state.school_level == 200 ? "vip" : "free"}>
														到期时间：{item.end_time && item.end_time.split(" ")[0]}
													</span>
												</div> */}
                        <div>
                          <ul className="box">
                            <li className="item">
                              <div className="number-tip">
                                {item.all_income}
                              </div>
                              <div className="small-title-tip">
                                本月实收（元）
                              </div>
                            </li>
                            <li className="item">
                              <div className="number-tip">
                                {item.member_income}
                              </div>
                              <div className="small-title-tip">
                                会员充值（元）
                              </div>
                            </li>
                            <li className="item">
                              <div className="number-tip">{item.all_cars}</div>
                              <div className="small-title-tip">
                                进场车辆（辆）
                              </div>
                            </li>
                            <li className="item">
                              <div className="number-tip">{item.all_cards}</div>
                              <div className="small-title-tip">
                                办卡数量（张）
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="product-dynamic-container box-container">
            <Rank
              rankData={rankState.list}
              type={rankState.type}
              handleTypeChange={rankTypeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopManage;
