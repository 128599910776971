import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Divider,
  Popconfirm,
  message,
  DatePicker,
  Tooltip,
  Tabs,
} from "antd";
import Api from "../../core/api";
import SearchBar from "../../components/SearchBar";
import SearchBarUpdata from "../../components/SearchBarUpdata";
import PageControl from "../../components/PageControl";
import StaticSelect from "../../components/StaticSelect";
import VipEditModal from "../../components/modals/VipEdit";
import WXSelectModal from "../../components/modals/WXSelect";
import PackageSelectModal from "../../components/modals/PackageSelect";
// import VipChargeModal from "../../components/modals/VipCharge";
import VipRecharge from "../../components/modals/VipRecharge";
import VipStoredTable from "../../components/VipStoredTable";
import VipCountTable from "../../components/VipCountTable";
import CommenSelect from "../../components/CommonSelect";
import moment from "moment";
import mathFunc from "../../core/mathfunc";
import { useHistory } from "react-router-dom";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const VipManagement = () => {
  const [vipState, setVipState] = useState({
    keyword: "",
    dateStart: "",
    dateEnd: "",
    time_over: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    package_type: "储值卡",
  });
  const [vipCountState, setVipCountState] = useState({
    keyword: "",
    dateStart: "",
    dateEnd: "",
    time_over: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    package_type: "计次卡",
  });
  const [modalState, setModalState] = useState({
    visible: false,
    editType: false,
    curVip: {},
    packageV: false,
    selectedPackage: {}, // 选择套餐回填
    packageType: false,
  });
  const [chargeState, setChargeState] = useState({
    visible: false,
    vipId: "",
  });
  const [activeKey, setActiveKey] = useState("1");
  const [deault, setDefault] = useState(false);
  const history = useHistory();
  function handleActiveKey(data) {
    setActiveKey(data);
    setModalState({ ...modalState, packageType: data === "1" ? false : true });
  }
  useEffect(() => {
    console.log("改变啦");
    getVipList();
  }, [
    modalState.packageType,
    activeKey,
    vipState.time_over,
    vipState.dateStart,
    vipState.dateEnd,
    vipCountState.time_over,
    vipCountState.dateStart,
    vipCountState.dateEnd
  ]);
  // 获取会员卡数据
  function getVipList(data) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.packageType) {
      const { keyword, dateStart, dateEnd, time_over, package_type } =
        vipCountState;
      // 计次请求
      let data = {
        keyword,
        start_time: dateStart,
        end_time: dateEnd,
        time_over: filterTimeOver(time_over),
        count: pageSize,
        page: pageNo,
        package_type,
      };
      Api.cardSelect(data).then((res) => {
        setVipCountState({
          ...vipCountState,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
        if (keyword === "" && time_over === "") {
          setDefault(false);
        } else {
          setDefault(true);
        }
      });
    } else {
      const { keyword, dateStart, dateEnd, time_over, package_type } = vipState;
      // 储值请求
      let data = {
        keyword,
        start_time: dateStart,
        end_time: dateEnd,
        time_over: filterTimeOver(time_over),
        count: pageSize,
        page: pageNo,
        package_type,
      };
      Api.cardSelect(data).then((res) => {
        setVipState({
          ...vipState,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
        if (keyword === "" && time_over === "") {
          setDefault(false);
        } else {
          setDefault(true);
        }
      });
    }
  }
  // 删除会员卡逻辑
  function handleDelete(id) {
    let data = { id };
    Api.cardDelete(data).then((res) => {
      message.success(res.msg);
      getVipList();
    });
  }
  // 标签页改变
  function onChange(key) {
    console.log(key);
    if (key === "1") {
      setActiveKey("1");
      setModalState({ ...modalState, packageType: false });
    }
    if (key === "2") {
      setActiveKey("2");
      setModalState({ ...modalState, packageType: true });
    }
  }
  function filterTimeOver(str) {
    switch (str) {
      case "全部":
        return "";
        break;
      case "已过期":
        return "100";
        break;
      case "未过期":
        return "200";
        break;
      default:
        return "";
        break;
    }
  }
  const options = [
    { id: "1", name: "全部" },
    { id: "2", name: "已过期" },
    { id: "3", name: "未过期" },
  ];
  console.log(deault);
  return (
    <div className="page package-setting-page">
      <div className="page-content">
        <section className="content-header">
          <h1>会员卡管理</h1>
        </section>
        <div className="box package-seeting" style={{ padding: 0 }}>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            type="card"
            onChange={onChange}
          >
            <TabPane tab="储值类型" key="1" style={{ padding: "0 16px" }}>
              {" "}
              <div className="box-header">
                <Row type="flex" justify="space-between">
                  <Col>
                    <SearchBarUpdata
                      searchText={vipState.keyword}
                      onChangeText={(text) => {
                        setVipState({ ...vipState, keyword: text });
                      }}
                      onSearch={() => {
                        if (vipState.keyword === "") {
                          setDefault(false);
                        } else {
                          setDefault(true);
                        }
                        getVipList();
                      }}
                      label="搜索"
                      placeholder="请输入会员卡号/姓名/手机号"
                    />
                    <StaticSelect label="办卡日期：">
                      <RangePicker
                        style={{ width: "100%" }}
                        value={[
                          vipState.dateStart && moment(vipState.dateStart),
                          vipState.dateEnd && moment(vipState.dateEnd),
                        ]}
                        onChange={(memo, str) => {
                          setVipState({
                            ...vipState,
                            dateStart: str[0],
                            dateEnd: str[1],
                          });
                        }}
                      />
                    </StaticSelect>
                    <CommenSelect
                      selectValue={vipState.time_over}
                      label="有效期"
                      options={options}
                      onSelect={(selectValue) => {
                        if (selectValue === "") {
                          setDefault(false);
                        } else {
                          setDefault(true);
                        }
                        setVipState({
                          ...vipState,
                          time_over: selectValue,
                        });
                      }}
                      placeholder="请选择有效期"
                    />
                  </Col>

                  <Col>
                    <Button
                      type="primary"
                      onClick={() => {
                        setModalState({
                          ...modalState,
                          visible: true,
                          editType: false,
                        });
                      }}
                    >
                      新增
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="box-body">
                <VipStoredTable
                  TableData={vipState.list}
                  deault={deault}
                  handleEdit={(record) => {
                    setModalState({
                      ...modalState,
                      visible: true,
                      editType: true,
                      curVip: record,
                    });
                  }}
                  handleRecharge={(record) => {
                    setChargeState({
                      ...chargeState,
                      visible: true,
                      vipId: record.bianhao,
                    });
                  }}
                  handleDelete={(id) => {
                    handleDelete(id);
                  }}
                />
                {/* 分页器 */}
                <PageControl
                  total={vipState.total}
                  pageSize={vipState.pageSize}
                  current={vipState.pageNo}
                  showSizeChanger
                  toPage={(page, count) => {
                    getVipList({ page, pages: count });
                  }}
                />
              </div>
            </TabPane>
            <TabPane tab="计次类型" key="2" style={{ padding: "0 16px" }}>
              <div className="box-header">
                <Row type="flex" justify="space-between">
                  <Col>
                    <SearchBarUpdata
                      searchText={vipCountState.keyword}
                      onChangeText={(text) => {
                        setVipCountState({ ...vipCountState, keyword: text });
                      }}
                      onSearch={() => {
                        if (vipCountState.keyword === "") {
                          setDefault(false);
                        } else {
                          setDefault(true);
                        }
                        getVipList();
                      }}
                      label="搜索"
                      width={50}
                      placeholder="请输入会员卡号/姓名/手机号"
                    />
                    <StaticSelect label="办卡日期：">
                      <RangePicker
                        style={{ width: "100%" }}
                        value={[
                          vipCountState.dateStart &&
                            moment(vipCountState.dateStart),
                          vipCountState.dateEnd &&
                            moment(vipCountState.dateEnd),
                        ]}
                        onChange={(memo, str) => {
                          setVipCountState({
                            ...vipCountState,
                            dateStart: str[0],
                            dateEnd: str[1],
                          });
                        }}
                      />
                    </StaticSelect>
                    <CommenSelect
                      selectValue={vipCountState.time_over}
                      label="有效期"
                      options={options}
                      onSelect={(selectValue) => {
                        if (selectValue === "") {
                          setDefault(false);
                        } else {
                          setDefault(true);
                        }
                        setVipCountState({
                          ...vipCountState,
                          time_over: selectValue,
                        });
                      }}
                      placeholder="请选择有效期"
                    />
                  </Col>

                  <Col>
                    <Button
                      type="primary"
                      onClick={() => {
                        setModalState({
                          ...modalState,
                          visible: true,
                          editType: false,
                        });
                      }}
                    >
                      新增
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="box-body">
                <VipCountTable
                  TableData={vipCountState.list}
                  deault={deault}
                  handleEdit={(record) => {
                    setModalState({
                      ...modalState,
                      visible: true,
                      editType: true,
                      curVip: record,
                    });
                  }}
                  handleRecharge={(record) => {
                    setChargeState({
                      ...chargeState,
                      visible: true,
                      vipId: record.bianhao,
                    });
                  }}
                  handleDelete={(id) => {
                    handleDelete(id);
                  }}
                />
                {/* 分页器 */}
                <PageControl
                  total={vipCountState.total}
                  pageSize={vipCountState.pageSize}
                  current={vipCountState.pageNo}
                  showSizeChanger
                  toPage={(page, count) => {
                    getVipList({ page, pages: count });
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
        {/* 会员办卡 会员修改 弹框 */}
        <VipEditModal
          visible={modalState.visible}
          editType={modalState.editType}
          selectedVip={modalState.curVip}
          selectedPackage={modalState.selectedPackage}
          getVipList={getVipList}
          handleActiveKey={handleActiveKey}
          handlePackageSelect={() => {
            setModalState({ ...modalState, packageV: true });
          }}
          closeModal={() => {
            setModalState({
              ...modalState,
              visible: false,
              editType: false,
              curVip: {},
              selectedPackage: {},
            });
          }}
        />
        {/* 套餐选择弹框 */}
        <PackageSelectModal
          visible={modalState.packageV}
          closeModal={() => {
            setModalState({ ...modalState, packageV: false });
          }}
          handleSelect={(data) => {
            setModalState({
              ...modalState,
              selectedPackage: data,
              packageV: false,
            });
          }}
        />
        {/* 充值 弹框 */}
        <VipRecharge
          visible={chargeState.visible}
          bianhao={chargeState.vipId}
          closeModal={() => {
            setChargeState({ ...chargeState, visible: false });
          }}
          handleComplete={() => {
            setChargeState({ visible: false, vipId: "" });
            getVipList();
          }}
        />
      </div>
    </div>
  );
};
export default VipManagement;
