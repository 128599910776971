import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table, Form, Popconfirm } from "antd";
import PageControl from "../PageControl";
import TitleBar from "../TitleBar";
import _Api from "../../core/api";
import ServerTable from "../../modules/common/ServerTable";
import GoodsTable from "../../modules/common/GoodsTable";
import moment from "moment";
import mathFunc from "../../core/mathfunc";
import UploadImage from "../UploadImage";
import UexhibitImage from "../UexhibitImage";
import noImage from "../../libs/noImage.png";
import carImageURL from "../../libs/uploadImage.png";
import PreviewImage from "../PreviewImage";
const SlipDetailModal = ({
  // workingImage,
  imageList,
  MimageList,
  visible,
  closeModal,
  slipType,
  slipContent,
  openReceive,
  cancelComplete,
  handleComplete,
  cancelPaid,
  orderEdit,
  handleReceivePrint,
}) => {
  const [tableVisible, setTableVisible] = useState({
    goods: false,
    server: false,
  });
  const [payHistory, setPayHistory] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const handlePreview = (data) => {
    setPreviewVisible(data);
  };
  const ImageUrl = () => {
    let arr = [];
    // const carImage = workingImage[slipContent.index]?.car_imgs;
    const carImage = slipContent.car_imgs;
    console.log(carImage);
    for (const key in carImage) {
      // console.log(carImage[key]);
      arr = [...arr, ...carImage[key]];
    }
    setImageUrl(arr);
  };
  useEffect(() => {
    ImageUrl();
  }, [slipContent]);
  // console.log("结算弹框", imageUrl);

  // console.log(slipContent);
  useEffect(() => {
    visible && getPayHistory();
    // ImageUrl();
  }, [visible]);
  function getPayHistory() {
    const data = { order_bianhao: slipContent.bianhao };
    _Api.caiwuSelect(data).then((res) => {
      setPayHistory(res.data.content);
    });
  }
  const BasicForm = ({ handleState }) => {
    return (
      <div
        className="basic-form"
        style={{
          margin: "24px 0px 40px",
          padding: "20px 0 10px" /* , border: "#cacaca 1px solid" */,
        }}
      >
        <Form>
          <Row className="basic-row" type="flex" justify="space-around">
            <Col span={6} key="col-brand" className="col-brand">
              <Form.Item name="car-linkman" label="联系人">
                <span>{slipContent.car_linkman || "--"}</span>
              </Form.Item>
              <Form.Item name="car-brand" label="车牌号">
                <span>{slipContent.car_id}</span>
              </Form.Item>
              <Form.Item name="car-vip" label="会员卡号">
                <span>{slipContent.member_card || "--"}</span>
              </Form.Item>
            </Col>
            <Col span={6} key="col-type">
              <Form.Item name="car-phone" label="手机号码">
                <span>{slipContent.car_phone}</span>
              </Form.Item>
              <Form.Item name="car-type" label="车型">
                <span>{slipContent.car_type}</span>
              </Form.Item>
              <Form.Item name="car-ins" label="建议保养日期">
                <span>{slipContent.next_maintain_time || "--"}</span>
              </Form.Item>
            </Col>
            <Col span={6} key="col-linkman">
              <Form.Item name="car-clerk" label="接待人">
                <span>{slipContent.worker || "--"}</span>
              </Form.Item>
              <Form.Item name="car-miles" label="本次里程">
                <span>{slipContent.cur_mileage}</span>
              </Form.Item>
            </Col>
            <Col span={6} key="col-phone">
              <div className="basic-imgs">
                {imageUrl[0] ? (
                  <img
                    src={imageUrl[0]}
                    alt="example"
                    className="umultiple-img"
                  />
                ) : (
                  <img src={noImage} alt="example" className="umultiple-img" />
                )}
                {imageUrl[0] && (
                  <div
                    className="basic-counts"
                    onClick={() => {
                      setPreviewVisible(true);
                    }}
                  >
                    <p> +{imageUrl.length}</p>
                  </div>
                )}
                {/* <img src={carImageURL} alt="example" className="umultiple-img" /> */}
                <div className="basic-title">车辆照片</div>
              </div>
              {/* <UploadImage
                name="点击此处上传照片"
                title="车辆照片"
                carImageURL={noImage}
                url={ url() }
              ></UploadImage> */}
              {/* <UexhibitImage
                name="点击此处上传照片"
                title="车辆照片"
                carImageURL={noImage}
                url={
                  workingImage[slipContent.index]?.car_imgs?.frontLeft[0] ||
                  workingImage[slipContent.index]?.car_imgs?.frontRight[0] ||
                  workingImage[slipContent.index]?.car_imgs?.backLeft[0] ||
                  workingImage[slipContent.index]?.car_imgs?.backRight[0] ||
                  workingImage[slipContent.index]?.car_imgs?.other[0]
                }
              ></UexhibitImage> */}
            </Col>
          </Row>
        </Form>
        <PreviewImage
          visible={previewVisible}
          list={imageUrl}
          handlePreview={handlePreview}
        ></PreviewImage>
      </div>
    );
  };
  const PageButton = ({ text, color, func }) => (
    <div
      onClick={(data) => func(data)}
      style={{
        width: "82px",
        height: "28px",
        borderRadius: "4px",
        marginRight: "10px",
        display: "flex",
        color: "white",
        backgroundColor: color,
        fontSize: "12px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {text}
    </div>
  );
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "收款时间",
      dataIndex: "gmt_creat",
    },
    {
      title: "收款方式",
      dataIndex: "pay_type",
      render: (text, res) => {
        if (res.member_card === "") {
          return <span>{`${text}`}</span>;
        } else {
          if (res.member_num === "0.0") {
            return <span>{`${text}(储值类型)`}</span>;
          } else {
            return <span>{`${text}(计次类型)`}</span>;
          }
        }
      },
    },
    {
      title: "消费",
      dataIndex: "money_member",
      render: (text, res) => {
        if (res.member_card === "") {
          return `${res.money_real}元`;
        } else {
          if (res.member_num === "0.0") {
            return `${text}元`;
          } else {
            return `${mathFunc.reservedCount(res.member_num)}次`;
          }
        }
      },
    },
    {
      title: "收款人",
      align: "center",
      dataIndex: "input_user",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "备注",
      align: "center",
      dataIndex: "remark",
      // render: (text) => (text ? text : "-"),
    },
  ];
  console.log(slipContent);
  // console.log(payHistory);
  return (
    <Modal
      title="结算单"
      zIndex={800}
      footer={null}
      width={1000}
      visible={visible}
      onCancel={closeModal}
    >
      <TitleBar>
        <span style={{ marginRight: "50px" }}>
          {"单 号：" + slipContent.bianhao}
        </span>
        <span>
          {"开单日期：" +
            moment(slipContent.gmt_creat).format("YYYY年MM月DD日")}
        </span>
      </TitleBar>
      <Row>
        <Col span={24}>
          <BasicForm handleState={(data) => this.setState(data)} />
        </Col>
      </Row>

      <TitleBar>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <span>服务项目</span>
            <span
              style={{
                cursor: "pointer",
                marginLeft: "20px",
                color: "#CACACA",
              }}
              onClick={() => {
                setTableVisible({
                  ...tableVisible,
                  server: !tableVisible.server,
                });
              }}
            >
              {tableVisible.server ? (
                <span>
                  收起
                  <span className="iconfont icon ml5">&#xe663;</span>
                </span>
              ) : (
                <span>
                  展开
                  <span className="iconfont icon ml5">&#xe665;</span>
                </span>
              )}
            </span>
          </div>
          {!tableVisible.server && (
            <Row type="flex" justify="end" style={{ maxHeight: "20px" }}>
              <Form layout="inline">
                <Form.Item name="shouldreceive" label="">
                  <span style={{ color: "#000" }}>工时费:</span>
                  <span>{slipContent.service_content?.amount || 0}</span>
                </Form.Item>

                <Form.Item name="fee" label="">
                  <span style={{ color: "#000" }}>项目折扣:</span>
                  <span>
                    {mathFunc.getPercent(slipContent.service_zk) + "%"}
                  </span>
                </Form.Item>

                <Form.Item name="realreceive" label="">
                  <span style={{ color: "#000" }}>实收工时费:</span>
                  {/* <span>{slipContent.service_content?.ar || 0}</span> */}
                  <span>
                    {mathFunc.returnFloat(slipContent.service_content?.ar || 0)}
                  </span>
                </Form.Item>
              </Form>
            </Row>
          )}
        </div>
      </TitleBar>
      {tableVisible.server ? (
        <ServerTable
          slip
          viewmode
          discount={slipContent.service_zk}
          tableState={slipContent.service_content}
        />
      ) : (
        <div style={{ minHeight: "20px" }} />
      )}
      <TitleBar>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <span>商品配件</span>
            <span
              style={{
                cursor: "pointer",
                marginLeft: "20px",
                color: "#CACACA",
              }}
              onClick={() => {
                setTableVisible({
                  ...tableVisible,
                  goods: !tableVisible.goods,
                });
              }}
            >
              {tableVisible.goods ? (
                <span>
                  收起
                  <span className="iconfont icon ml5">&#xe663;</span>
                </span>
              ) : (
                <span>
                  展开
                  <span className="iconfont icon ml5">&#xe665;</span>
                </span>
              )}
            </span>
          </div>

          {!tableVisible.goods && (
            <Row type="flex" justify="end" style={{ maxHeight: "20px" }}>
              <Form layout="inline">
                <Form.Item name="shouldreceive" label="">
                  <span style={{ color: "#000" }}>配件费:</span>{" "}
                  <span>{slipContent.part_content?.amount || 0}</span>
                </Form.Item>

                <Form.Item name="fee" label="">
                  <span style={{ color: "#000" }}>配件折扣:</span>
                  <span>{mathFunc.getPercent(slipContent.part_zk) + "%"}</span>
                </Form.Item>

                <Form.Item name="realreceive" label="">
                  <span style={{ color: "#000" }}>实收配件费:</span>
                  {/* <span>{slipContent.part_content?.ar || 0}</span> */}
                  <span>
                    {mathFunc.returnFloat(slipContent.part_content?.ar || 0)}
                  </span>
                </Form.Item>
              </Form>
            </Row>
          )}
        </div>
      </TitleBar>
      {tableVisible.goods ? (
        <GoodsTable
          viewmode
          tableState={slipContent.part_content}
          discount={slipContent.part_zk}
          slip
        />
      ) : (
        <div style={{ minHeight: "20px" }} />
      )}
      {payHistory.length > 0 ? (
        <>
          <TitleBar>
            <span style={{ marginRight: "50px" }}>收款记录</span>
          </TitleBar>
          <Table
            dataSource={payHistory}
            columns={columns}
            style={{ paddingTop: "20px" }}
            bordered
            pagination={false}
          />
        </>
      ) : null}
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: "50px" }}
      >
        <div>
          <span style={{ fontWeight: "700", marginRight: "50px" }}>
            {slipContent.service_content && (
              <span>
                {slipContent.order_state === "已结算" ? (
                  <span>
                    {slipContent.member_num_pay === "0.0"
                      ? `消费：${mathFunc.returnFloat(slipContent.money_pay)}元`
                      : // ? `消费：${mathFunc.returnFloat(
                        //     (parseFloat(slipContent.service_content.ar) || 0) +
                        //       (parseFloat(slipContent.part_content.ar) || 0)
                        //   )}元`
                        `消费：${mathFunc.reservedCount(
                          slipContent.member_num_pay
                        )}次`}
                  </span>
                ) : (
                  `应收：${mathFunc.returnFloat(
                    parseFloat(slipContent.money_all)
                  )}元`
                  // `应收：${mathFunc.returnFloat(
                  //   (parseFloat(slipContent.service_content?.ar) || 0) +
                  //     (parseFloat(slipContent.part_content?.ar) || 0)
                  // )}元`
                )}
              </span>
            )}
          </span>
          {slipType === "paid" ||
            (slipType === "incomeDetail" && (
              <span
                style={{ fontWeight: "700" }}
              >{slipContent.member_num_pay === "0.0" ?`实收：${slipContent.money_pay}元` : null }</span>
            ))}
        </div>
        <div style={{ display: "flex" }}>
          {slipType === "working" && (
            <Button
              type="primary"
              // onClick={()=>orderEdit({orderDetailV: true})}
              onClick={() => orderEdit(slipContent.index)}
              style={{ marginRight: "10px" }}
            >
              修改
            </Button>
          )}
          {slipType === "working" && (
            <Popconfirm
              placement="left"
              title="是否确认完工"
              onConfirm={() => handleComplete(slipContent)}
              okText="确认"
              cancelText="取消"
            >
              <Button>完工</Button>
              {/* <PageButton color='#38495C' text='取消完工' /> */}
            </Popconfirm>
          )}
          {(slipType === "finished" || slipType === "credit") && (
            <Button
              type="primary"
              onClick={() => openReceive(slipContent)}
              style={{ marginRight: "10px" }}
            >
              结算
            </Button>
          )}
          {slipType === "paid" && (
            <PageButton
              color="#65C87A"
              text="打印单据"
              func={() => handleReceivePrint({ ...slipContent, convert: true })}
              // onClick={() => handleReceivePrint(slipContent)}
              // onClick={() => console.log("dayyin")}
            />
          )}
          {slipType === "finished" && (
            <Popconfirm
              placement="left"
              title="是否确认取消完工"
              onConfirm={() => cancelComplete(slipContent)}
              okText="确认"
              cancelText="取消"
            >
              <Button>取消完工</Button>
              {/* <PageButton color='#38495C' text='取消完工' /> */}
            </Popconfirm>
          )}
          {(slipType === "paid" || slipType === "credit") && (
            <Popconfirm
              placement="left"
              title="是否确认取消结算"
              onConfirm={() => cancelPaid(slipContent.id)}
              okText="确认"
              cancelText="取消"
            >
              <Button>取消结算</Button>
            </Popconfirm>
          )}
        </div>
      </Row>
    </Modal>
  );
};

export default SlipDetailModal;
