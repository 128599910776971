import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Table, Popconfirm, Divider, Modal, Form, Input, Spin, message } from "antd";
import PageControl from "../../components/PageControl";
import SearchBar from "../../components/SearchBar";
import _Api from "../../core/api";
import ServeAdd from "../../components/modals/ServeAdd";

const ProjectManagement = ({}) => {
	const [serviceState, setServiceState] = useState({
		keyword: "",
		list: [],
		pageNo: "1",
		pageSize: "10",
		total: "",
	});
	const [modalState, setModalState] = useState({ visible: false, editType: false, curService: {} });

	useEffect(() => {
		getServiceList();
	}, []);
	function getServiceList(pageState) {
		let pageSize = pageState&&pageState.pageSize || "10";
		let pageNo =  pageState&&pageState.pageNo || "1";
		let data = {
			keyword: serviceState.keyword,
			count: pageSize,
			page: pageNo,
		};
		_Api.serviceSelect(data).then((res) => {
			let _data = res.data;
			setServiceState({
				...serviceState,
				list: _data.content,
				total: _data.allcount,
				pageNo: _data.curpage,
				pageSize: _data.pagecount,
			});
		});
	}

	function handleDelete(id) {
		_Api.serviceDelete({ id }).then((res) => {
			message.success(res.msg);
			const { pageSize, pageNo } = serviceState;
			getServiceList({ pageSize, pageNo });
		});
	}

	const columns = [
		{
			title: "编号",
			dataIndex: "bianhao",
			render: (text) => (text ? text : "--"),
		},
		{
			title: "项目名称",
			dataIndex: "name",
			align: "center",
		},
		{
			title: "单价",
			dataIndex: "price",
			align: "center",
			render: (text) => (text ? text + "元" : "--"),
		},
		{
			title: "工时",
			dataIndex: "hours",
			align: "center",
		},
		{
			title: "操作",
			dataIndex: "name",
			align: "center",
			render: (text, record, index) => (
				<>
					<span
						className='theme-color click-able'
						onClick={() => {
							setModalState({ visible: true, editType: true, curService: record });
						}}>
						修改
					</span>
					<Divider type='vertical' />
					<Popconfirm
						placement='left'
						title='删除后无法恢复，是否确认删除？'
						okText='确认'
						cancelText='取消'
						onConfirm={() => {
							handleDelete(record.id);
						}}>
						<span className='theme-color click-able'>删除</span>
					</Popconfirm>
				</>
			),
		},
	];
	return (
		<div className='template-appointment-index page appointmentManage'>
			<div className='page-content'>
				<section className='content-header'>
					<h1>服务项目</h1>
				</section>
				<div className='box'>
					<div className='box-header'>
						<Row type='flex' justify='space-between'>
							<Col>
								<SearchBar
									searchText={serviceState.keyword}
									onChangeText={(text) => {
										setServiceState({ ...serviceState, keyword: text });
									}}
									onSearch={getServiceList}
									label='搜索'
									placeholder='请输入编号/名称'
								/>
							</Col>
							<Col>
								<Button
									type='primary'
									onClick={() => {
										setModalState({ ...modalState, visible: true, editType: false });
									}}>
									新增
								</Button>
							</Col>
						</Row>
					</div>
					<div className='box-body'>
						<Table
							rowKey={(record) => record.id}
							pagination={false}
							dataSource={serviceState.list}
							columns={columns}
							bordered
							style={{ marginBottom: "40px" }}
						/>
						<PageControl
							total={serviceState.total}
							pageSize={serviceState.pageSize}
							current={serviceState.pageNo}
							showSizeChanger
							toPage={(page, count) => {
								getServiceList({ pageNo: page, pageSize: count });
							}}
						/>
					</div>
				</div>
			</div>
			<ServeAdd
				visible={modalState.visible}
				closeModal={() => {
					setModalState({ visible: false, editType: false, curCarId: "" });
				}}
				curService={modalState.curService}
				editType={modalState.editType}
				handleComplete={() => {
					setModalState({ visible: false, editType: false, curCarId: "" });
					getServiceList();
				}}
			/>
		</div>
	);
};

export default ProjectManagement;
