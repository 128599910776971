import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./SearchBar.less";
import searchImage from "../libs/search.png"

const SearchBarUpdata = (props) => {
	const { type, className, label, id, placeholder, onChangeText, onSearch, style, searchText,width } = props;

	const newClassName = "xm-search-bar xm-search-bar-" + (type || "") + " " + (className || "");

	const onClick = () => {
		if (onSearch) {
			onSearch();
		}
	};

	return (
		<div className={newClassName} style={style}>
			{!!label && <div className='label'>{label}:</div>}
			<Input
				id={id ? id : ""}
				name='value'
				placeholder={placeholder}
				value={searchText || ""}
				onChange={(e) => {
					if (onChangeText) {
						onChangeText(e.target.value);
					}
				}}
				// prefix={<SearchOutlined onClick={onClick} />}
				prefix={<img onClick={onClick} style={{width:14,height:15,cursor:"pointer",marginRight:7}} src={searchImage} alt="" />}
				onPressEnter={onClick}
			/>
            
		</div>
	);
};
export default SearchBarUpdata;
