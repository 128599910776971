import React, { Component } from "react";
import { Form, Input, Checkbox, Tabs, Popover, Modal, message, Button, Result } from "antd";
import axios from "../../core/axios";
import qs from "qs";

import "./Register.less";
import success from "../../libs/success.png";

import _ from "underscore";
import CheckBeforeSendCode from "./CheckBeforeSendCode";
import logo from "../../libs/logo_01.png";

let timer;

export class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countdown: 60,
			timerStr: "获取验证码",
			hadClicked: false,
			msgOrVoice: "",
			certificate: "", //短信验证返回的凭证
			isShow: true,
			isFinish: false,

			openCheck: false,
			openCheckVoice: false,
			checkObject: {},
			checkObjectVoice: {},
			checking: false,
			regWaitStatus: false, // 验证码是否在倒计时
			regVoiceCodeTip: false, // 语音验证码提示状态
			calledVoiceSend: false,

			issinstName: true,
			istelePhone: true,
			telePhone: "",
			sinstName: "",
			code: "",

			source: "",
			way: "",
			num: 15,
			checked: true,
		};
	}

	static propTypes = {};
	componentWillMount() {
		document.title = "免费注册_让每一家门店都用上专业的汽修门店管理系统_飞飞汽修";

		if (
			navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
		) {
			//pc端访问 跳转至pc页面
			window.location.href = "https://www.sanfeng9.com/wap/index.html#/register";
		}
	}

	timeSub = (waitTime, unit) => {
		const _this = this;
		clearTimeout(timer);
		timer = setTimeout(function () {
			if (waitTime === 0) {
				_this.setState({
					timerStr: "发送验证码",
					regWaitStatus: false,
					checking: false,
				});
				clearTimeout(timer);
			} else {
				if (waitTime < 40) {
					_this.setState({
						regVoiceCodeTip: true,
					});
				}
				_this.setState({
					timerStr: `${waitTime}秒后重发`,
					regWaitStatus: true,
				});
				_this.timeSub(--waitTime, 1000);
			}
		}, unit || 0);
	};

	handleSendCode(checkData) {
		console.log(checkData);
		const { bizState, randstr, ret, ticket } = checkData;
		const regPhone = bizState.state.telePhone;
		if (bizState.state.regWaitStatus) {
			return;
		}
		if (ret != 0) {
			return message.error("网络错误，请稍后重试");
		}
		const phoneLength = regPhone.trim().length;
		if (phoneLength === 11) {
			const params = {
				phone: regPhone,
				Randstr: randstr,
				Ticket: ticket,
				sms_type: "shop_reg",
			};
			axios.postJSON("shop/login.php?cmd=send_sms_code", params).then((res) => {
				if (res.response == 200) {
					message.success(res.msg);
					bizState.timeSub(60);
					bizState.setState({
						checking: true,
						certificate: res.s_id,
					});
				} else {
					message.warning(res.msg);
				}
			});
		}
	}

	handleRegiste() {
		if (!this.state.sinstName.trim(" ")) {
			this.setState({
				// isFinish:true,
				issinstName: false,
			});
			message.warning("请填写机构名称");
			return;
		} else if (!this.isMobile(this.state.telePhone)) {
			this.setState({
				istelePhone: false,
			});
			message.warning("请正确填写手机号");
			return;
		} else if (!this.state.code.trim(" ")) {
			message.warning("请输入验证码");
			return;
		} else if (!this.state.checked) {
			message.warning("请勾选同意协议");
			return;
		}
		const params = {
			name: this.state.sinstName,
			phone: this.state.telePhone,
			code: this.state.code,
			s_id: this.state.certificate,
		};

		let token = "";

		if (window.location.hash.indexOf("?") > -1) {
			token = "&token=" + window.location.hash.split("?")[1];
		}

		axios
			.post("shop/login.php?cmd=reg" + token, params)
			.then((res) => {
				if (res.response == 200) {
					this.setState({
						sinstName: "",
						telePhone: "",
						code: "",
						isFinish: true,
					});
					let that = this;
					let timer = setInterval(() => {
						if (that.state.num == 1) {
							clearTimeout(timer);
						} else {
							that.setState({
								num: this.state.num - 1,
							});
						}
					}, 1000);

					setTimeout(() => {
						that.props.history.push("/login");
					}, 15000);
				} else {
					message.info(res.msg);
				}
			})
			.catch((res) => {});
	}

	changeInput(e, type) {
		if (type === "telePhone") {
			if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11);
		}
		const value = e.target.value;
		this.setState({
			[type]: value,
		});
	}
	isMobile(phone) {
		var patrn = /^[0-9]{11}$/;
		if (!patrn.exec(phone)) return false;
		return true;
	}

	handleConfirm = (flag) => {
		if (!this.state.sinstName.trim(" ")) {
			this.setState({
				issinstName: false,
			});
			message.warning("请填写机构名称");
		} else if (!this.isMobile(this.state.telePhone)) {
			this.setState({
				istelePhone: false,
			});
			message.warning("请正确填写手机号");
		} else {
			try {
				const tencentc = new window.TencentCaptcha("2007156257", this.handleSendCode, {
					bizState: this,
				});
				tencentc.show();
			} catch {
				console.log("载入失败");
			}
		}
	};

	render() {
		const { isShow, isFinish, sinstName, telePhone, code, issinstName, istelePhone, openCheck } = this.state;
		return (
			<div className='register-page'>
				<div className='header'>
					<div className='nav'>
						<a href='https://www.sanfeng9.com/'>
							<div className='logo'></div>
						</a>
						<span className='shu'>|</span>
						<span>商户注册</span>
					</div>
				</div>
				<div className='register-main'>
					{isFinish ? (
						<Result
							style={{ width: 430, paddingLeft: "0px !important", paddingRight: "0 !important" }}
							icon={<img src='' alt='' />}
							title={[
								<img width={38} height={38} style={{ marginRight: 20, verticalAlign: "-11px" }} src={success} />,
								<span style={{ fontSize: 24, color: "#000" }}>机构注册成功</span>,
							]}
							subTitle={<span style={{ marginTop: 30 }}>提醒：首次登录使用手机验证码登录，登录后可设詈登录密码</span>}
							extra={
								<div class='success-apply'>
									<div
										class='button'
										onClick={() => {
											this.props.history.push("/login");
										}}>
										去登录&gt;&gt;
									</div>
									<p className='tip'>
										将在
										<span className='num'>{this.state.num}</span>
										秒后自动跳转到登录页面
									</p>
								</div>
							}
						/>
					) : (
						<div className='free form'>
							<div className='title'>免费开通飞飞汽修门店管理系统</div>
							<div className='description'>开通后您将拥有专属的汽修门店管理系统</div>
							<div className='form-item'>
								<span className='label'>机构名称：</span>
								<span className='data'>
									<Input
										className={sinstName || issinstName ? "" : " waring"}
										value={sinstName}
										maxLength={32}
										placeholder='请输入门店名称
										'
										onChange={(e) => this.changeInput(e, "sinstName")}
									/>
								</span>
							</div>
							<div className='form-item'>
								<span className='label'>手机号码：</span>
								<span className='data'>
									<Input
										className={telePhone || istelePhone ? "" : " waring"}
										maxLength={11}
										value={telePhone}
										placeholder='请输入11位手机号'
										onChange={(e) => this.changeInput(e, "telePhone")}
									/>
								</span>
							</div>
							<div className='form-item'>
								<span className='label'>验证码：</span>
								<span className='data' style={{ width: 185 }}>
									<Input
										maxLength={6}
										value={code}
										placeholder='请输入验证码'
										onChange={(e) => this.changeInput(e, "code")}
									/>
								</span>
								<Popover
									visible={openCheck}
									trigger='click'
									title=''
									content={
										<div>
											<span
												style={{
													fontSize: "12px",
													color: "#999",
													marginBottom: 8,
													display: "block",
												}}>
												请完成安全验证
											</span>
											<CheckBeforeSendCode
												callback={(data, nc) => {
													this.handleSendCode(data);
													this.setState({
														checkObject: nc,
													});
													setTimeout(() => {
														this.setState({
															openCheck: false,
														});
													}, 500);
												}}
											/>
										</div>
									}
									onVisibleChange={(value) => {
										if (!value) {
											this.setState({
												openCheck: false,
											});
										}
									}}
									placement='bottomRight'>
									<Button
										style={{
											marginLeft: 9,
											position: "absolute",
											right: 0,
											bottom: 0,
											color: !this.state.checking ? "#FF8534" : "#ddd",
											borderColor: !this.state.checking ? "#FF8534" : "#ddd",
										}}
										size='small'
										type='link'
										onClick={() => {
											if (this.state.checking) return;
											this.handleConfirm(3);
										}}>
										{this.state.timerStr}
									</Button>
								</Popover>
							</div>
							<div className='form-item'>
								<div className='fuwu'>
									<Checkbox
										checked={this.state.checked}
										onChange={(e) => {
											this.setState({
												checked: e.target.checked,
											});
											if (e.target.checked == false) {
												message.warning("未勾选同意协议无法注册");
											}
										}}>
										我已阅读并同意
										<a target='_blank' href='https://www.sanfeng9.com/agreement/'>
											《飞飞汽修服务协议》
										</a>
									</Checkbox>
								</div>
							</div>
							<div
								className='submit'
								onClick={() => {
									this.handleRegiste();
								}}>
								提交
							</div>
						</div>
					)}
				</div>
				<div className='footer'>
					<div className='footer-main'>
						<a target='_blank' href='https://beian.miit.gov.cn'>
							京ICP备2021014476号-2
						</a>
						&nbsp;&nbsp;
					</div>
					<div className='footer-main'>
						<span>Copyright C 2021 sanfeng9.com.All Rights Reserved.飞飞汽修版权所有</span>
					</div>
				</div>
			</div>
		);
	}
}

export default Register;
