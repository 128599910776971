import React from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Form,
  Table,
  Empty,
  Divider,
  Pagination,
  Popconfirm,
} from "antd";
import PageControl from "../../components/PageControl";
import mathFunc from "../../core/mathfunc";
export default ({ tableState, handleCanclePaid, getTableList, scanOrder }) => {
  const columns = [
    {
      title: "单号",
      dataIndex: "bianhao",
      render: (text, record, index) => (
        <span
          className="theme-color click-able"
          onClick={() => {
            scanOrder(record);
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "配件",
      align: "center",
      dataIndex: "parts",
      key: "part-name",
      render: (text) => {
        let partList = JSON.parse(text).data;
        let nameArr = partList.map((item) => item.name);
        if (nameArr.length > 3) {
          let _arr = nameArr.slice(0, 2);
          return _arr.join("、") + "等" + nameArr.length + "种配件";
        } else {
          return nameArr.join("、");
        }
      },
    },

    {
      title: "开单日期",
      align: "center",
      dataIndex: "gmt_creat",
    },
    {
      title: "客户",
      dataIndex: "customer",
    },
    {
      title: "会员卡",
      dataIndex: "member_card",
      align: "center",
      render: (text, record) =>
        text ? (
          <>
            <span>{text}</span>
            <Divider type="vertical" />
            {/* <span>{parseFloat(record.part_zk) * 100 + "%"}</span> */}
            <span>{mathFunc.getPercent(record.part_zk)}%</span>
          </>
        ) : (
          "--"
        ),
    },

    {
      title: "消费",
      align: "center",
      dataIndex: "money_real",
      key: "part-amount",
      render: (text, record) => {
        if (record.member_card) {
          return mathFunc.returnFloat(record.money_member);
        } else {
          return mathFunc.returnFloat(text);
        }
      },
    },

    {
      title: "结算时间",
      align: "center",
      dataIndex: "gmt_modify",
    },

    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      //eslint-disable-next-line
      render: (text, record) => (
        <>
          <Popconfirm
            placement="left"
            title="是否确认取消结算？"
            onConfirm={() => handleCanclePaid(record.id)}
            okText="确认"
            cancelText="取消"
          >
            <span className="click-able theme-color">反结算</span>
          </Popconfirm>
          {/* <Divider type='vertical' />
					<span
						className='click-able theme-color'
						onClick={() => {
							console.log("打印收据");
						}}>
						打印收据
					</span> */}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        dataSource={tableState.list || []}
        columns={columns}
        bordered
        style={{ marginTop: "20px", marginBottom: "40px" }}
        pagination={false}
      />
      <PageControl
        total={tableState.total}
        pageSize={tableState.pageSize}
        current={tableState.pageNo}
        showSizeChanger
        toPage={(page, count) => {
          getTableList({ pageNo: page, pageSize: count });
        }}
      />
    </>
  );
};
