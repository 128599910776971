import { useState, useEffect, useCallback } from "react";
import mathFunc from "../../core/mathfunc";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  Form,
  message,
  Dropdown,
  Menu,
} from "antd";
import Api from "../../core/api";
export default ({
  visible,
  closeModal,
  editType,
  handleComplete,
  curService,
}) => {
  const [serverState, setServerState] = useState({
    id: "",
    name: "",
    price: "",
    hours: "",
  });
  const [menuItems, setMenu] = useState([]);
  useEffect(() => {
    if (visible && editType) {
      const { id, name, price, hours } = curService;
      setServerState({ id, name, price, hours });
    }
  }, [visible]);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  const handleSave = () => {
    const { id, name, price, hours } = serverState;
    let data = { name, price, hours };
    if (editType) {
      data = { ...data, id };
    }
    Api.serviceInsert(data)
      .then((res) => {
        message.success(res.msg);
        handleComplete();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const useDebounce = (func, delay) => {
    let timer = null;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const wordGet = (keyword) => {
    Api.keywordDic({
      keyword,
      dic_type: "service_name",
    }).then((res) => {
      let items = res.msg.map((item, index) => ({
        key: index + "",
        label: (
          <div
            onClick={() => {
              setServerState({ ...serverState, name: item });
            }}
          >
            {item}
          </div>
        ),
      }));
      setMenu(items);
    });
  };

  const wordThink = useCallback(
    useDebounce((value) => wordGet(value), 200),
    []
  );

  return (
    <Modal
      title={editType ? "服务项目编辑" : "新增服务项目"}
      footer={null}
      width={500}
      visible={visible}
      onCancel={closeModal}
    >
      <Form {...layout} colon={false}>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form.Item
              label={
                <p>
                  <span style={{ color: "#EB3628" }}>*</span>名称
                </p>
              }
            >
              <Dropdown overlay={<Menu items={menuItems} />} placement="bottom">
                <Input
                  value={serverState.name}
                  onFocus={() => wordThink(serverState.name)}
                  onChange={(e) => {
                    setServerState({ ...serverState, name: e.target.value });
                    wordThink(e.target.value);
                  }}
                />
              </Dropdown>
            </Form.Item>
            <Form.Item label="单价">
              <Input
                value={serverState.price}
                onChange={(e) => {
                  if (e.target.value.length > 8) {
                    return;
                  }
                  let price = mathFunc.checkPrice(e.target.value);
                  setServerState({ ...serverState, price });
                }}
              />
            </Form.Item>
            <Form.Item label="工时">
              <Input
                value={serverState.hours}
                onChange={(e) => {
                  let num = e.target.value.replace(/[^\d]/g, "");
                  setServerState({ ...serverState, hours: num });
                }}
              />
            </Form.Item>
          </Col>
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={handleSave}
          >
            保存
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
