class Permission {
    getUserLeftPermissionTree() {
        let userPermissionList = [];
        console.log(window.userPermissionList)
        if(window.userPermissionList){
            userPermissionList = window.userPermissionList
        }
        return userPermissionList
    }
}

export default new Permission();
