import React from "react";
import "./Loader.less";

const Loader = (props) => {
	const ClassNames = "loader " + (props.spinning ? "" : "hidden") + " " + (props.fullScreen ? "fullScreen" : "");
	return (
		<div className={ClassNames}>
			<div className='warpper'>
				<div className='inner'></div>
				<div className='text'>{props.text ? props.text : "网页载入中..."}</div>
			</div>
		</div>
	);
};

export default Loader;
