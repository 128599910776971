import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./SearchBar.less";
import $ from "jquery";

const SearchBar = (props) => {
	const { type, className, label, id, placeholder, onChangeText, onSearch, style, searchText } = props;

	const newClassName = "xm-search-bar xm-search-bar-" + (type || "") + " " + (className || "");

	const onClick = () => {
		if (onSearch) {
			onSearch();
		}
	};

	return (
		<div className={newClassName} style={style}>
			{!!label && <div className='label'>{label}:</div>}
			<Input
				id={id ? id : ""}
				name='value'
				placeholder={placeholder}
				value={searchText || ""}
				onChange={(e) => {
					if (onChangeText) {
						onChangeText(e.target.value);
					}
				}}
				addonAfter={<SearchOutlined onClick={onClick} />}
				onPressEnter={onClick}
			/>
		</div>
	);
};
export default SearchBar;
