import { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";


const CarSelectModal = ({ visible, closeModal, handleSelect,handleModalState }) => {
	const [carState, setCarState] = useState({
		list: [],
		total: "",
		allPage: "",
		pageSize: "10",
		pageNo: "1",
		keyword: "",
	});

	useEffect(() => {
		if (visible) {
			getCarList();
		}
	}, [visible]);

	function getCarList(pageState) {
		let pageSize =  pageState&&pageState.pageSize || "10";
		let pageNo = pageState&&pageState.pageNo || "1";
		_Api.carGet({ keyword: carState.keyword, count: pageSize, page: pageNo }).then((res) => {
			setCarState({
				list: res.data.content,
				total: res.data.allcount,
				allPage: res.data.allpage,
				pageSize: res.data.pagecount,
				pageNo: res.data.curpage,
			});
		});
	}
	const columns = [
		{
			title: "序号",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "车牌号",
			dataIndex: "car_id",
			key: "brand",
		},
		{
			title: "车型",
			dataIndex: "car_type",
			key: "type",
		},
		{
			title: "联系人",
			dataIndex: "linkman",
			key: "linkman",
			align: "center",
			render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
		},
		{
			title: "手机号码",
			dataIndex: "phone",
			key: "phone",
			align: "center",
			render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
		},
		{
			title: "操作",
			dataIndex: "operation",
			key: "operation",
			align: "center",
			//eslint-disable-next-line
			render: (text, record) =>
				text ? (
					<span style={{ color: "#CECECE" }}>已进厂</span>
				) : (
					<a
						onClick={() => {
							handleSelect(record);
						}}>
						选择
					</a>
				),
		},
	];
	return (
		<Modal title='车辆选择' footer={null} width={950} visible={visible} onCancel={closeModal}>
			<Row type='flex' align='middle'>
				<Col span={12}>
					<Input
						placeholder='车牌号/车型/联系人/手机号码'
						value={carState.keyword}
						onChange={(e) => {
							setCarState({ ...carState, keyword: e.target.value });
						}}
					/>
				</Col>
				<Button
					type='primary'
					style={{ marginLeft: "10px",marginRight: "10px" }}
					onClick={() => {
						getCarList();
					}}>
					查询
				</Button>
				<Button
                  className="btn-blue"
                  size="middle"
                  onClick={() => {
                    handleModalState({ carEditV: true, carEditType: false });
                  }}
                >
                  +车辆建档
                </Button>
			</Row>

			<Table
				pagination={false}
				rowKey={(record) => record.id}
				dataSource={carState.list}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
				locale={{ emptyText: "暂无车辆信息，请先给车辆建档" }}
			/>
			<PageControl
				toPage={(page, count) => {
					getCarList({ pageNo: page, pageSize: count });
				}}
				total={carState.total}
				pageSize={carState.pageSize}
				current={carState.pageNo}
				showSizeChanger
			/>
		</Modal>
	);
};
export default CarSelectModal;
