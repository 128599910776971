import React, { useEffect, useRef, useState } from "react";
import { Link, Element, animateScroll as scroll } from "react-scroll";
import _Api from "../core/api";
import "./CarTypeSelect.less";
import useWindowDimensions from "../core/useWindowDimensions";

const CarTypeSelect = ({ onSelect, value }) => {
	const { height: windowHeight, width: windowWidth } = useWindowDimensions();
	//品牌列表
	const [brandList, setBrandList] = useState({
		brand: [],
		subBrand: [],
	});
	const [selected, setSelected] = useState({
		letter: "",
		brand: "",
		brandId: "",
		subBrand: "",
	});
	const [drowpVisible, setDrowpVisible] = useState(false);

	//获取品牌数据并计算滚动节点
	useEffect(() => {
		getBrandList();
	}, []);

	useEffect(() => {
		if (drowpVisible && value) {
			console.log(value);
			let brandArr = value.split(" ");
			console.log(brandArr);
			setSelected({
				letter: "",
				brand: brandArr[0],
				subBrand: brandArr[1],
			});
		}
	}, [drowpVisible]);

	function getBrandList() {
		_Api.carBrandGet().then((res) => {
			let list = handleBrandList(res);
			setBrandList({ ...brandList, brand: list });
		});
	}
	function getSubBrand(brand) {
		console.log(brand);
		onSelect(brand.name,brand.id);
		const { id, name } = brand;
		_Api.carSubBrandGet({ brandid: id }).then((res) => {
			setBrandList({ ...brandList, subBrand: res.result.fctlist });
			setSelected({ ...selected, brand: name,brand_id:id, subBrand: "" });
		});
	}
	function handleBrandList(arr) {
		let brandData = [],
			brandDataItem = { letter: "", list: [] };

		arr.forEach((item, index) => {
			if (item.letter != brandDataItem.letter) {
				if (index != 0) {
					brandData.push(brandDataItem);
				}
				brandDataItem = { letter: item.letter, list: [item] };
			} else {
				brandDataItem = { ...brandDataItem, list: [...brandDataItem.list, item] };
				if (index === arr.length - 1) {
					brandData.push(brandDataItem);
				}
			}
		});
		return brandData;
	}
	function handleSubSelect(subBrand) {
		onSelect(`${selected.brand} ${subBrand}`);
		setSelected({ ...selected, subBrand });
		setDrowpVisible(false);
	}
	return (
		<>
			<div className='car-type-select-input'>
				<div
					className='select-bar'
					style={{ borderColor: drowpVisible ? "#f09f74" : "rgb(232, 232, 232)" }}
					onClick={() => {
						setDrowpVisible(true);
					}}>
					<span
						style={{
							width: "200px",
							marginLeft: "10px",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}>
						{selected.brand ? `${selected.brand} ${selected.subBrand}` : value}
					</span>
					<span className='icon iconfont'>&#xe665;</span>
				</div>
				{drowpVisible && (
					<div
						className='drowpdown-mask'
						onClick={() => {
							setDrowpVisible(false);
						}}
						style={{
							height: windowHeight,
							width: windowWidth,
							position: "fixed",
							top: 0,
							left: 0,
							zIndex: "200",
							opacity: "0.3",
						}}></div>
				)}
				{drowpVisible && (
					<div className='drawpdown-wrapper'>
						<ol className='index-banner'>
							{brandList.brand.map((item, index) => (
								<Link
									key={"link" + item.letter}
									activeClass='active'
									to={"scrollElement" + item.letter}
									spy={true}
									smooth={true}
									duration={300}
									containerId='brand-container'
									onSetActive={() => {
										setSelected({ ...selected, letter: item.letter });
									}}>
									{item.letter}
								</Link>
							))}
						</ol>
						<div className='brand-banner' id='brand-container'>
							{brandList.brand.map((item, index) => (
								<Element name={"scrollElement" + item.letter} key={"element" + index}>
									<div key={"brandletter" + index} className='brand-title'>
										{item.letter}
									</div>
									{item.list.map((Item, Index) => (
										<div
											key={Item.id}
											className={Item.name === selected.brand ? "brand-content brand-selected" : "brand-content"}
											onClick={() => {
												getSubBrand(Item);
											}}>
											<span style={{ cursor: "default" }}>{Item.name}</span>
											<span className='icon iconfont' style={{ cursor: "default" }}>
												&#xe666;
											</span>
										</div>
									))}
								</Element>
							))}
						</div>
					</div>
				)}
				{drowpVisible && brandList.subBrand.length > 0 && (
					<div className='subbrand-banner'>
						{brandList?.subBrand.map((item) => (
							<div key={"fct" + item.fctid}>
								<div className='fctbar'>{item.fctname}</div>
								{item.serieslist.map((Item) => (
									<div
										key={"seriesid" + Item.seriesid}
										className={selected.subBrand === Item.seriesName ? "seriesbar series-selected" : "seriesbar"}
										onClick={() => {
											handleSubSelect(Item.seriesName);
										}}>
										{Item.seriesName}
									</div>
								))}
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default CarTypeSelect;
