import React, { Component, Fragment } from "react";
import { Input, Button, Popover } from "antd";
import "./ColorPicker.less";
export class ColorPicker extends Component {
    setColor = (color) => {
        this.props.setColor(color);
    };
    render() {
        const { colorValue } = this.props;

        const colorList = [
            ["#660000", "#990000", "#cc0000", "#cc3333", "#ea4c88", "#993399"],
            ["#663399", "#333399", "#0066cc", "#0099cc", "#66cccc", "#77cc33"],
            ["#669900", "#336600", "#666600", "#999900", "#cccc33", "#ffff00"],
            ["#ffcc33", "#ff9900", "#ff6600", "#cc6633", "#996633", "#663300"],
            ["#000000", "#999999", "#cccccc", "#ffffff", "#424153", ""],
        ];
        const _colorList = colorList.map((Item, index) => {
            return (
                <tr key={index}>
                    {Item.map((item) => {
                        if (item === "") {
                            return (
                                <td key={"color-empty"}>
									<span
                                        className="color-item"
                                        style={{
                                            background:
                                                "linear-gradient(23deg, rgba(255,255,255,1) 48%,rgba(255,0,0,1) 50%,rgba(255,255,255,1) 52%)",
                                        }}
                                        onClick={() => {
                                            this.setColor(item);
                                        }}
                                    ></span>
                                </td>
                            );
                        } else {
                            return (
                                <td key={"color-" + item}>
									<span
                                        className="color-item"
                                        style={{ backgroundColor: item }}
                                        onClick={() => {
                                            this.setColor(item);
                                        }}
                                    ></span>
                                </td>
                            );
                        }
                    })}
                </tr>
            );
        });
        const content = (
            <table>
                <tbody>{_colorList}</tbody>
            </table>
        );
        return (
            <Fragment>
                <Popover
                    content={content}
                    title="选色板"
                    trigger="click"
                    placement="bottom"
                >
                    <Button>
						<span
                            className="color-preview"
                            style={{ backgroundColor: colorValue }}
                        ></span>
                        <span className="icon iconfont">&#xe618;</span>
                    </Button>
                </Popover>
                <Input
                    placeholder="请输入自定义颜色"
                    type="text"
                    onChange={(e) => {
                        this.setColor(e.target.value);
                    }}
                    value={colorValue}
                    style={{ width: 180, height: 28 }}
                />
            </Fragment>
        );
    }
}
export default ColorPicker;
