import {
  Button, Col, Empty, Input, message, Modal, Row, Table,
  Tabs
} from "antd";
import React, { useEffect, useState } from "react";
import _Api from "../../core/api";
import crossImgs from "../../libs/cross.png";
import brushImgs from "../../libs/empty-brush.png";
import PageControl from "../PageControl";
import "./MeberMultiSelectModal.less";
const { TabPane } = Tabs;

const MeberMultiSelectModal = ({
  visible,
  closeModal,
  handleModalState,
  carId,
  listSelected,
  meberSelected,
  handleComplete,
}) => {
  const [vipState, setVipState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    time_over: "200",
    package_type: "储值卡",
  });
  const [vipCountState, setVipCountState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    time_over: "200",
    package_type: "计次卡",
  });
  const [modalState, setModalState] = useState({
    packageType: false,
    // emptyType: false,
    // emptyCountType: false,
  });
  const [addVisible, setAddVisible] = useState(false);

  const [selectList, setSelectList] = useState([]);

  useEffect(() => {
    if (visible) {
      firstGetList();
      setSelectList(meberSelected || []);
    }
  }, [visible]);

  useEffect(() => {
    getVipList();
  }, [modalState.packageType]);
  // 获取数据
  function getVipList(data) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.packageType) {
      let data = {
        keyword: vipCountState.keyword,
        car_id: carId,
        count: pageSize,
        page: pageNo,
        time_over: "200",
        package_type: vipCountState.package_type,
      };
      _Api.cardSelect(data).then((res) => {
        let vipList = res.data.content;
        if (vipList !== []) {
          const list = vipList
            .map((item) => {
              item.selected = false;
              return item;
            })
            .map((item) => {
              selectList.forEach((i) => {
                if (item.bianhao === i.bianhao) {
                  item.selected = true;
                }
              });
              return item;
            });
          setVipCountState({
            ...vipCountState,
            list,
            total: res.data.allcount,
            pageNo: res.data.curpage,
            pageSize: res.data.pagecount,
          });
        }
      });
    } else {
      let Data = {
        keyword: vipState.keyword,
        car_id: carId,
        count: pageSize,
        page: pageNo,
        time_over: "200",
        package_type: vipState.package_type,
      };
      _Api.cardSelect(Data).then((res) => {
        let vipList = res.data.content;
        if (vipList !== []) {
          const list = vipList
            .map((item) => {
              item.selected = false;
              return item;
            })
            .map((item) => {
              selectList.forEach((i) => {
                if (item.bianhao === i.bianhao) {
                  item.selected = true;
                }
              });
              return item;
            });
          setVipState({
            ...vipState,
            list,
            total: res.data.allcount,
            pageNo: res.data.curpage,
            pageSize: res.data.pagecount,
          });
        }
      });
    }
  }
  function firstGetList() {
    // 计次
    let data = {
      keyword: vipCountState.keyword,
      car_id: carId,
      count: "10",
      page: "1",
      time_over: "200",
      package_type: vipCountState.package_type,
    };
    _Api.cardSelect(data).then((res) => {
      let vipList = res.data.content;
      if (vipList !== []) {
        const list = vipList
          .map((item) => {
            item.selected = false;
            return item;
          })
          .map((item) => {
            meberSelected.forEach((i) => {
              if (item.bianhao === i.bianhao) {
                item.selected = true;
              }
            });
            return item;
          });
        setVipCountState({
          ...vipCountState,
          list,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
      }
    });
    //储值
    let Data = {
      keyword: vipState.keyword,
      car_id: carId,
      count: "10",
      page: "1",
      time_over: "200",
      package_type: vipState.package_type,
    };
    _Api.cardSelect(Data).then((res) => {
      let vipList = res.data.content;
      if (vipList !== []) {
        const list = vipList
          .map((item) => {
            item.selected = false;
            return item;
          })
          .map((item) => {
            meberSelected.forEach((i) => {
              if (item.bianhao === i.bianhao) {
                item.selected = true;
              }
            });
            return item;
          });
        setVipState({
          ...vipState,
          list,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
      }
    });
  }
  //标签页发生改变
  function onChange(key) {
    if (key === "1") {
      setModalState({ ...modalState, packageType: false });
    }
    if (key === "2") {
      setModalState({ ...modalState, packageType: true });
    }
  }
  //选择会员卡
  function handleSelect(selected, index) {
    // console.log(selected, index);
    if (modalState.packageType) {
      let list = vipCountState.list;
      if (selected) {
        list[index].selected = true;
        setSelectList([...selectList, list[index]]);
      } else {
        list[index].selected = false;
        const bianHao = list[index].bianhao;
        setSelectList(selectList.filter((item) => item.bianhao !== bianHao));
      }
      setVipCountState({ ...vipCountState, list });
    } else {
      let list = vipState.list;
      if (selected) {
        list[index].selected = true;
        setSelectList([...selectList, list[index]]);
      } else {
        list[index].selected = false;
        const bianHao = list[index].bianhao;
        setSelectList(selectList.filter((item) => item.bianhao !== bianHao));
      }
      setVipState({ ...vipState, list });
    }
  }
  // 右侧删除
  function delSelectData(data) {
    const a = vipState.list.map((item) => {
      console.log(item);
    });
    const { bianhao, package_type } = data;
    setSelectList(selectList.filter((item) => item.bianhao !== bianhao));
    if (package_type === "储值卡") {
      const newList = vipState.list.map((item) => {
        if (item.bianhao === bianhao) {
          item.selected = false;
        }
        return item;
      });
      setVipState({ ...vipState, list: newList });
    } else {
      const newArr = vipCountState.list.map((item) => {
        if (item.bianhao === bianhao) {
          item.selected = false;
        }
        return item;
      });
      setVipCountState({ ...vipCountState, list: newArr });
    }
  }
  // 全部清除选中
  function emptySelectData() {
    setSelectList([]);
    const newList = vipState.list.map((item) => {
      item.selected = false;
      return item;
    });
    setVipState({ ...vipState, list: newList });
    const newArr = vipCountState.list.map((item) => {
      item.selected = false;
      return item;
    });
    setVipCountState({ ...vipCountState, list: newArr });
  }
  // 确定多选
  function MemberMultiple() {
    console.log(listSelected);
    for (let i = 0; i < selectList.length; i++) {
      if (
        !listSelected.includes(selectList[i].bianhao) &&
        selectList[i].wx_openid
      ) {
        return message.error(
          `会员卡 【${selectList[i].bianhao}】, 已经被分配到其他客户名下！`
        );
      }
    } 
    const arr = selectList.map((item) => item.bianhao);
    console.log(arr,selectList);
    handleComplete(arr, selectList);
  }
  function close() {
    closeModal();
  }
  function initData() {}
  // tab 储值查询
  const valueInquire = (
    <Row align="middle">
      <Col span={17}>
        <Input
          placeholder="卡号/姓名/手机号码"
          value={vipState.keyword}
          onChange={(e) => {
            setVipState({ ...vipState, keyword: e.target.value });
          }}
        />
      </Col>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        onClick={getVipList}
      >
        查询
      </Button>
    </Row>
  );
  //   tab 计次查询
  const countInquire = (
    <Row align="middle">
      <Col span={17}>
        <Input
          placeholder="卡号/姓名/手机号码"
          value={vipCountState.keyword}
          onChange={(e) => {
            setVipCountState({ ...vipCountState, keyword: e.target.value });
          }}
        />
      </Col>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        onClick={getVipList}
      >
        查询
      </Button>
      {/* <Button
	  style={{ marginLeft: "10px" }}
	  onClick={() => {
		setVipState({ ...vipState, keyword: "" });
	  }}
	>
	  清除
	</Button> */}
    </Row>
  );
  // 储值表头
  const columns = [
    {
      title: "会员卡号",
      dataIndex: "bianhao",
      render: (text, record) =>
        record.last_use ? (
          <span>
            {text}
            <span
              className="theme-color"
              style={{ marginLeft: "5px", fontSize: "8px" }}
            >
              {record.last_use}
            </span>
          </span>
        ) : (
          text
        ),
    },
    {
      title: "会员姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "套餐",
      width: "200px",
      dataIndex: "package_name",
    },
    {
      title: "余额",
      dataIndex: "left_money",
      align: "center",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "操作",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) =>
        record.selected ? (
          <span
            className="icon iconfont theme-color click-able"
            style={{ fontSize: "17px" }}
            onClick={() => handleSelect(!record.selected, index)}
          >
            {/* &#xe679; */}
            &#xe730;
          </span>
        ) : (
          <span
            className="icon iconfont click-able"
            style={{ fontSize: "17px" }}
            onClick={() => handleSelect(!record.selected, index)}
          >
            {/* &#xe720; */}
            &#xe72f;
          </span>
        ),
    },
  ];
  // 计次表头
  const columnsCount = [
    {
      title: "会员卡号",
      dataIndex: "bianhao",
      render: (text, record) =>
        record.last_use ? (
          <span>
            {text}
            <span
              className="theme-color"
              style={{ marginLeft: "5px", fontSize: "8px" }}
            >
              {record.last_use}
            </span>
          </span>
        ) : (
          text
        ),
    },
    {
      title: "会员姓名",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "包含次数",
      dataIndex: "left_num",
      align: "center",
    },
    {
      title: "套餐",
      dataIndex: "package_name",
    },

    {
      title: "操作",
      dataIndex: "id",
      align: "center",
      //   render: (text, record) => (
      //     <a
      //       onClick={() => handleModalState({ vipDetailV: true, curVip: record })}
      //     >
      //       选择
      //     </a>
      //   ),
      render: (text, record, index) =>
        record.selected ? (
          <span
            className="icon iconfont theme-color click-able"
            style={{ fontSize: "17px" }}
            onClick={() => handleSelect(!record.selected, index)}
          >
            {/* &#xe679; */}
            &#xe730;
          </span>
        ) : (
          <span
            className="icon iconfont click-able"
            style={{ fontSize: "17px" }}
            onClick={() => handleSelect(!record.selected, index)}
          >
            {/* &#xe720; */}
            &#xe72f;
          </span>
        ),
    },
  ];
  // console.log(listSelected);
  // console.log(vipState);
  // console.log(vipCountState);
  // console.log(selectList);

  return (
    <Modal
      title="选择会员卡"
      s
      zIndex={999}
      width={1287}
      visible={visible}
      onCancel={close}
      onOk={MemberMultiple}
      className="meber-select-box"
    >
      <div className="meber-select-data">
        <div className="left">
          <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            tabBarExtraContent={
              modalState.packageType ? countInquire : valueInquire
            }
          >
            <TabPane tab="储值类型" key="1">
              {" "}
              {vipState.list.length === 0 ? (
                <div style={{ margin: "90px 0" }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                </div>
              ) : (
                <>
                  {/* 套餐表格 */}
                  <Table
                    rowKey={(record) => record.id}
                    pagination={false}
                    dataSource={vipState.list}
                    columns={columns}
                    bordered
                    style={{ marginTop: "10px", marginBottom: "30px" }}
                  />
                  {/* 分页 */}
                  <PageControl
                    total={vipState.total}
                    pageSize={vipState.pageSize}
                    current={vipState.pageNo}
                    showSizeChanger
                    toPage={(page, count) => {
                      getVipList({ page, pages: count });
                    }}
                  />
                </>
              )}
            </TabPane>
            <TabPane tab="计次类型" key="2">
              {" "}
              {vipCountState.list.length === 0 ? (
                <div style={{ margin: "90px 0" }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                </div>
              ) : (
                <>
                  {/* 套餐表格 */}
                  <Table
                    rowKey={(record) => record.id}
                    pagination={false}
                    dataSource={vipCountState.list}
                    columns={columnsCount}
                    bordered
                    style={{ marginTop: "10px", marginBottom: "30px" }}
                  />
                  {/* 分页 */}
                  <PageControl
                    total={vipCountState.total}
                    pageSize={vipCountState.pageSize}
                    current={vipCountState.pageNo}
                    showSizeChanger
                    toPage={(page, count) => {
                      getVipList({ page, pages: count });
                    }}
                  />
                </>
              )}
            </TabPane>
          </Tabs>
        </div>
        <div className="right">
          <div className="title-top">
            <span>已选{selectList.length}张</span>
            <img onClick={emptySelectData} src={brushImgs} alt="" />
          </div>
          <div className="content">
            {selectList.map((item) => {
              return (
                <div key={item.id} className="content-item">
                  <div className="yuan">
                    {item.package_type === "储值卡" ? "储" : "计"}
                  </div>
                  <div className="member-card">{item.bianhao}</div>
                  <img
                    onClick={() => delSelectData(item)}
                    src={crossImgs}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default MeberMultiSelectModal;
