import { message, Upload, Modal } from "antd";
import React, { useState, useEffect } from "react";
import user from "../core/user";
import axios from "axios";
import "./UmultipleImage.less";

const UmultipleImage = ({
  carImageURL,
  handleBaseCarImgs,
  types,
  carImgs,
  title,
  count,
  type,
  max,
}) => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  // const onChange = ({ file, fileList: newFileList }) => {
  //   console.log(newFileList);
  // };
  const upload = (file) => {
    setVisible(true);
    console.log(file);
    // const name = file.name
    // const index = name.lastIndexOf(".")
    if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "image/gif") {
      message.error("仅支持上传jpg/png/gif/jpeg格式的文件");
      return setVisible(false)
    }
    if (file.size > 512 * 10000) {
      message.warning("请上传小于等于5M的图片");
      return setVisible(false);
    } else {
      uploadBlobToOSS(file).then((res) => {
        console.log(res);
        setFileList([...fileList, { url: res }]);
        setVisible(false);
        if (types === "other") {
          const list = fileList.map((item) => item.url);
          handleBaseCarImgs([...list, res], types);
        } else {
          handleBaseCarImgs([res], types);
        }
      });
    }
  };
  console.log(type);
  useEffect(() => {
    console.log(carImgs);
    if (type === "edit") {
      console.log("回填啦", carImgs);
      if (carImgs) {
        const obj = carImgs.map((item) => ({ url: item }));
        setFileList(obj);
      }
    }
  }, [carImgs]);
  // useEffect(() => {
  //   const list = fileList.map((item) => item.url);
  //   console.log(list);
  //   handleBaseCarImgs(list, types);
  // }, [fileList]);

  const uploadBlobToOSS = (file) => {
    return new Promise((resolve, reject) => {
      // const token = user.token();
      // Headers
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const formData = new FormData();
      // // 添加要上传的文件
      formData.append("file", file);
      formData.append("user_type", "shop");
      axios
        .post(
          "https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" +
            user.token(),
          formData,
          config
        )
        .then((res) => {
          if (res.data.response == 200) {
            message.success("图片上传成功");
            resolve(res.data.img_url);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setVisible(false);
        });
    });
  };
  // 点击预览
  const handlePreview = async (file) => {
    console.log(file);
    setPreviewImage(file.url);
    setPreviewVisible(true);
  };
  // 点击删除
  const onRemove = ({ url }) => {
    const list = fileList.filter((item) => item.url !== url);
    const lists = []
    fileList.forEach((item)=>{
      if(item.url !== url){
         lists.push(item.url)
      }
    })
    // console.log("删除", list);
    // console.log("删除", lists);
    setFileList(list);
    handleBaseCarImgs(lists, types);
    message.info("操作成功");
  };
  console.log(fileList);

  return (
    <div className="umulitiple-img">
      {max && <div className="umulitiple-max">{max}</div>}
      <Upload
        disabled={visible}
        avatar="avatar"
        listType="picture-card"
        className="AddUpload"
        accept="image"
        fileList={fileList}
        onRemove={onRemove}
        onPreview={handlePreview}
        beforeUpload={(file) => {
          upload(file);
        }}
      >
        {fileList.length < count && (
          <img
            src={carImageURL}
            alt="example"
            className="umultiple-img"
            // style={{
            //   width: "100%",
            // }}
          />
        )}
      </Upload>
      {!max && <p>{title}</p>}
      <Modal
        visible={previewVisible}
        title={title}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UmultipleImage;
