import { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table, Form, Select } from "antd";
import TitleBar from "../TitleBar";
import ServerTable from "../../modules/common/ServerTable";
import GoodsTable from "../../modules/common/GoodsTable";
import ModalButton from "../ModalButton";
import _Api from "../../core/api";
import BasicForm from "../../modules/common/BasicForm";
import imageFu from "../../core/imageFu";

const OrderDetailModal = ({
  // uploadCarImgs,
  handleCarImgs,
  handleBaseCarImgs,
  orderIndex,
  workingImage,
  uploadWorking,
  imageUrl,
  handlImageUrl,
  imageList,
  visible,
  viewMode,
  closeModal,
  curBasic,
  curService,
  curPart,
  handleModalState,
  handleSave,
  handleVipSet,
  MimageList,
  setMimageList,
}) => {
  // const [carImgs, setCarImgs] = useState({});
  // const uploadImgs = () => {
  //   console.log("编辑回填");
  //   const arr = workingImage;
  //   const index = orderIndex;
  //   console.log(arr,index);
  //   setCarImgs(arr[index]);
  // };
  // console.log("bianjiye", carImgs);
  // useEffect(() => {
  //   uploadImgs();
  // }, [workingImage,carImgs]);
  // useEffect(() => {
  //   uploadImgs();
  // }, [curBasic]);
  console.log(curBasic);
  return (
    <Modal
      title={viewMode ? "查看工单" : "编辑工单"}
      zIndex={990}
      width={1600}
      // width={1380}
      visible={visible}
      onCancel={closeModal}
      onOk={() => handleSave()}
    >
      <TitleBar>
        <span style={{ marginRight: "50px" }}>
          {"单 号：" + curBasic.bianhao}
        </span>
        <span>{"开单日期：" + curBasic.startTime}</span>
      </TitleBar>
      <BasicForm
        carImgs={curBasic.car_imgs}
        imageUrl={imageUrl}
        baseInfo={curBasic}
        handleBaseInfo={(data) =>
          handleModalState({ curOrderBasic: { ...curBasic, ...data } })
        }
        handleModalState={handleModalState}
        handleVipSet={handleVipSet}
        handleBaseCarImgs={handleBaseCarImgs}
        // imageList={carImageList()}
      />
      <TitleBar>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>服务项目</span>
          <div>
            <Button
              className="btn-green"
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                handleModalState({ serviceSelectV: true });
              }}
            >
              选择
            </Button>
            <Button
              className="btn-red"
              size="middle"
              onClick={() => {
                handleModalState({ serviceAddV: true });
              }}
            >
              新建
            </Button>
          </div>
        </div>
      </TitleBar>
      <ServerTable
        slip={curService.data && curService.data.length > 0}
        discount={curBasic.serviceZk}
        tableState={curService}
        setTable={(data) => {
          handleModalState({ curServiceContent: { ...data } });
        }}
      />
      <TitleBar>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>商品配件</span>
          <div>
            <Button
              className="btn-green"
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                handleModalState({ partSelectV: true });
              }}
            >
              选择
            </Button>
            <Button
              className="btn-red"
              size="middle"
              onClick={() => {
                handleModalState({ partAddV: true });
              }}
            >
              新建
            </Button>
          </div>
        </div>
      </TitleBar>
      <GoodsTable
        slip={curPart.data && curPart.data.length > 0}
        discount={curBasic.partZk}
        tableState={curPart}
        setTable={(data) => {
          handleModalState({ curPartContent: { ...data } });
        }}
      />
    </Modal>
  );
};
export default OrderDetailModal;
