import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Divider,
  Popconfirm,
  message,
  Tabs,
} from "antd";
import Api from "../../core/api";
import SearchBar from "../../components/SearchBar";
import SearchBarUpdata from "../../components/SearchBarUpdata";
import PackageEditModal from "../../components/modals/PackageEdit";
import PackageAdd from "../../components/modals/PackageAdd";
import PageControl from "../../components/PageControl";
import PackageTable from "../common/PackageTable";
import PackageCount from "../../components/PackageCount";
import "./PackageSetting.less";
const { TabPane } = Tabs;
const PackageSetting = () => {
  const [packageState, setPackageState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    package_type: "储值卡",
  });
  const [packageCount, setPackageCount] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    package_type: "计次卡",
  });
  const [modalState, setModalState] = useState({
    visible: false,
    visibleEdit: false,
    editType: false,
    curPackage: {},
    packageType: false,
  });

  const [activeKey, setActiveKey] = useState("1");
  const [deault, setDefault] = useState(false);
  useEffect(() => {
    getPackageList();
  }, [modalState.packageType]);
  //   获取套餐数据
  function getPackageList(data) {
    // console.log(data);
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.packageType) {
      // 计次请求

      let data = {
        keyword: packageCount.keyword,
        count: pageSize,
        page: pageNo,
        package_type: packageCount.package_type,
      };
      Api.packageSelect(data).then((res) => {
        setPackageCount({
          ...packageCount,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
        if (packageCount.keyword === "") {
          setDefault(false);
        } else {
          setDefault(true);
        }
      });
    } else {
      // 储值请求
      let data = {
        keyword: packageState.keyword,
        count: pageSize,
        page: pageNo,
        package_type: packageState.package_type,
      };
      Api.packageSelect(data).then((res) => {
        setPackageState({
          ...packageState,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
        if (packageState.keyword === "") {
          setDefault(false);
        } else {
          setDefault(true);
        }
      });
    }
  }
  // 标签页改变
  function onChange(key) {
    console.log(key);
    if (key === "1") {
      setActiveKey("1");
      setModalState({ ...modalState, packageType: false });
    }
    if (key === "2") {
      setActiveKey("2");
      setModalState({ ...modalState, packageType: true });
    }
  }
  function handleActiveKey(data) {
    setActiveKey(data);
    setModalState({ ...modalState, packageType: data === "1" ? false : true });
  }
  function handleDelete(id) {
    let data = { id };
    Api.packageDelete(data).then((res) => {
      message.success(res.msg);
      getPackageList();
    });
  }

  return (
    <div className="page package-setting-page">
      <div className="page-content">
        <section className="content-header">
          <h1>套餐设置</h1>
        </section>
        <div className="box package-seeting" style={{ padding: 0 }}>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            onChange={onChange}
            type="card"
          >
            <TabPane tab="储值类型" key="1" style={{ padding: "0 16px" }}>
              <div className="box-header">
                <Row type="flex" justify="space-around">
                  <Col>
                    <SearchBarUpdata
                      searchText={packageState.keyword}
                      onChangeText={(text) => {
                        setPackageState({ ...packageState, keyword: text });
                      }}
                      onSearch={() => {
                        if (packageState.keyword === "") {
                          setDefault(false);
                        } else {
                          setDefault(true);
                        }
                        getPackageList();
                      }}
                      // label="搜索"
                      placeholder="请输入套餐名称/说明"
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => {
                        setModalState({
                          ...modalState,
                          visible: true,
                          editType: false,
                        });
                      }}
                    >
                      新增套餐
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="box-body">
                <PackageTable
                  TableData={packageState.list}
                  deault={deault}
                  handleEdit={(record) => {
                    setModalState({
                      ...modalState,
                      visibleEdit: true,
                      editType: true,
                      curPackage: record,
                    });
                  }}
                  handleDelete={(id) => {
                    handleDelete(id);
                  }}
                />
                <PageControl
                  total={packageState.total}
                  pageSize={packageState.pageSize}
                  current={packageState.pageNo}
                  showSizeChanger
                  toPage={(pageNo, pageSize) => {
                    getPackageList({ page: pageNo, pages: pageSize });
                  }}
                />
              </div>
            </TabPane>
            <TabPane tab="计次类型" key="2" style={{ padding: "0 16px" }}>
              <div className="box-header">
                <Row type="flex" justify="space-around">
                  <Col>
                    <SearchBarUpdata
                      searchText={packageCount.keyword}
                      onChangeText={(text) => {
                        setPackageCount({ ...packageCount, keyword: text });
                      }}
                      onSearch={() => {
                        if (packageCount.keyword === "") {
                          setDefault(false);
                        } else {
                          setDefault(true);
                        }
                        getPackageList();
                      }}
                      // label="搜索"
                      placeholder="请输入套餐名称/说明"
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => {
                        setModalState({
                          ...modalState,
                          visible: true,
                          editType: false,
                        });
                      }}
                    >
                      新增套餐
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="box-body">
                <PackageCount
                  TableData={packageCount.list}
                  deault={deault}
                  handleEdit={(record) => {
                    setModalState({
                      ...modalState,
                      visibleEdit: true,
                      editType: true,
                      curPackage: record,
                    });
                  }}
                  handleDelete={(id) => {
                    handleDelete(id);
                  }}
                />
                <PageControl
                  total={packageCount.total}
                  pageSize={packageCount.pageSize}
                  current={packageCount.pageNo}
                  showSizeChanger
                  toPage={(pageNo, pageSize) => {
                    getPackageList({ page: pageNo, pages: pageSize });
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      {/* 新增 弹框 */}
      <PackageAdd
        visible={modalState.visible}
        editType={modalState.editType}
        packageType={modalState.packageType}
        curPackage={modalState.curPackage}
        getPackageList={getPackageList}
        handleActiveKey={handleActiveKey}
        closeModal={() => {
          setModalState({ ...modalState, visible: false });
        }}
      />
      <PackageEditModal
        visible={modalState.visibleEdit}
        editType={modalState.editType}
        packageType={modalState.packageType}
        curPackage={modalState.curPackage}
        getPackageList={getPackageList}
        closeModal={() => {
          setModalState({
            ...modalState,
            visibleEdit: false,
            editType: false,
            curPackage: {},
          });
        }}
      />
    </div>
  );
};
export default PackageSetting;
