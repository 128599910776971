import React, { useState, useEffect, useMemo } from "react";
import _Api from "../../core/api";
import moment from "moment";
import mathFunc from "../../core/mathfunc";

const PrintPreview = ({ printState }) => {
	const carInfo = { customer: "客户A", dipanId: "", fdjId: "" };
	let printContent = {
		bianhao: "W20211208",
		branch_id: "37557",
		car_id: "京A00010",
		car_linkman: "某某某",
		car_phone: "12345665432",
		car_type: "大众",
		cur_mileage: "10000",
		end_time: "2021-12-23 18:53:00",
		gmt_creat: "2021-12-08 10:36:35",
		gmt_modify: "2021-12-23 18:51:13",
		input_user: "收银员A",
		member_card: "M20220101-1",
		money_all: "1620",
		money_pay: "1620",
		next_maintain_mileage: "20000",
		next_maintain_remark: "下次保养备注",
		next_maintain_time: "2023-01-01",
		part_content: {
			amount: "0",
			ar: "0",
			data: [],
			ids: [],
		},
		service_content: {
			amount: "1800",
			ar: "1620",
			data: [{ bianhao: "SE20211125-1", hours: "6", id: "11338", name: "小保养", price: "300.00", worker: "技工A" }],
			ids: ["11338"],
		},
		worker: "技工A",
	};
	const nomalFontSize = "12px";
	const lineHeight = "26px";

	const slipaAount = useMemo(() => {
		let serviceAmount = (printContent.service_content && printContent.service_content.amount) || 0,
			partAmount = (printContent.part_content && printContent.part_content.amount) || 0;
		return parseFloat(serviceAmount) + parseFloat(partAmount);
	}, [printContent.service_content && printContent.service_content, printContent.part_content]);

	const DoubleItem = ({ spanGroup, textGroup }) => (
		<>
			<div
				style={{
					flex: spanGroup[0],
					borderRight: "1px solid #000",
					borderBottom: "1px solid #000",
					textAlign: "center",
					lineHeight: lineHeight,
					boxSizing: "inherit",
					fontSize: nomalFontSize,
				}}>
				{textGroup[0]}
			</div>
			<div
				style={{
					flex: spanGroup[1],
					borderRight: "1px solid #000",
					borderBottom: "1px solid #000",
					lineHeight,
					boxSizing: "inherit",
				}}>
				<span style={{ marginLeft: "10px", fontSize: nomalFontSize }}>{textGroup[1]}</span>
			</div>
		</>
	);
	const FlexItem = ({ span, text }) => (
		<div
			style={{
				flex: span,
				borderRight: "1px solid #000",
				borderBottom: "1px solid #000",
				textAlign: "center",
				lineHeight,
				boxSizing: "inherit",
				fontSize: nomalFontSize,
				// fontFamily: "SimSun",
			}}>
			{text}
		</div>
	);
	const TopOuter = ({ children }) => (
		<div
			style={{
				display: "flex",
				boxSizing: "border-box",
				borderTop: "1px #000 solid",
				borderLeft: "1px #000 solid",
				marginTop: "10px",
				height: lineHeight,
			}}>
			{children}
		</div>
	);
	const MiddleOuter = ({ children }) => (
		<div
			style={{
				display: "flex",
				boxSizing: "border-box",
				borderLeft: "1px #000 solid",
				height: lineHeight,
			}}>
			{children}
		</div>
	);
	const serviceTable =
		printContent.service_content &&
		printContent.service_content.data &&
		printContent.service_content.data.map((item, index) => (
			<MiddleOuter key={item.id}>
				<FlexItem span={1} text={index + 1} />
				<FlexItem span={3.5} text={item.bianhao} />
				<FlexItem span={6} text={item.name} />
				<FlexItem span={2} text={item.price} />
				<FlexItem span={1} text={item.hours} />
				<FlexItem span={2} text={parseFloat(item.price) * parseFloat(item.hours)} />
				<FlexItem span={3} text={item.worker} />
			</MiddleOuter>
		));
	const partTable =
		printContent.part_content &&
		printContent.part_content.data &&
		printContent.part_content.data.map((item, index) => (
			<MiddleOuter key={item.id}>
				<FlexItem span={1} text={index + 1} />
				<FlexItem span={3.5} text={item.bianhao} />
				<FlexItem span={6} text={`${item.name} (${item.brand} ${item.model})`} />
				<FlexItem span={2} text={item.priceSale} />
				<FlexItem span={1} text={item.num} />
				<FlexItem span={2} text={parseFloat(item.priceSale) * parseFloat(item.num)} />
				<FlexItem span={3} text={item.qualityDate} />
			</MiddleOuter>
		));
	return (
		<div id='print-content' className='a4-print-table' style={{ width: "100%", color: "#000" }}>
			{printState.title && (
				<header style={{ fontSize: "25px", fontWeight: "bold", textAlign: "center" }}>{printState.title}</header>
			)}
			{printState.subTitle && <header style={{ fontSize: "20px", textAlign: "center" }}>{printState.subTitle}</header>}
			<div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
				<span style={{ fontSize: nomalFontSize }}>{`单号：${printContent.bianhao}`}</span>

				{printState.qrcode && (
					<div style={{ fontSize: nomalFontSize, position: "relative" }}>
						扫码获取更多门店信息
						<div
							style={{
								width: "80px",
								height: "80px",
								position: "absolute",
								top: "-85px",
								left: "50%",
								marginLeft: "-40px",
							}}>
							<img src={printState.qrcode} alt='' style={{ width: "80px", height: "80px" }} />
						</div>
					</div>
				)}
			</div>
			<div style={{ position: "relative" }}>
				<TopOuter>
					<DoubleItem spanGroup={["12", "55.3"]} textGroup={["客户", carInfo.customer]} />
					<DoubleItem spanGroup={["11", "22.02"]} textGroup={["车牌号", printContent.car_id]} />
				</TopOuter>
				<MiddleOuter>
					<DoubleItem spanGroup={["12", "22"]} textGroup={["联系人", printContent.car_linkman]} />
					<DoubleItem spanGroup={["15", "18"]} textGroup={["联系电话", printContent.car_phone]} />
					<DoubleItem spanGroup={["11", "22"]} textGroup={["车型", printContent.car_type]} />
				</MiddleOuter>
				<MiddleOuter>
					<DoubleItem spanGroup={["12", "22"]} textGroup={["底盘号", carInfo.dipanId]} />
					<DoubleItem spanGroup={["15", "18"]} textGroup={["发动机号", carInfo.fdjId]} />
					<DoubleItem spanGroup={["11", "22"]} textGroup={["行驶里程", printContent.cur_mileage]} />
				</MiddleOuter>
				<MiddleOuter>
					<DoubleItem spanGroup={["15", "19"]} textGroup={["下次保养时间", printContent.next_maintain_time]} />
					<DoubleItem spanGroup={["15", "18"]} textGroup={["下次保养里程", printContent.next_maintain_mileage]} />
					<DoubleItem
						spanGroup={["11", "22"]}
						textGroup={["开单日期", moment(printContent.gmt_creat).format("YYYY-MM-DD")]}
					/>
				</MiddleOuter>
				{printContent.service_content.data && (
					<>
						<section style={{ fontWeight: "bold", margin: "20px 0 12px 0", textAlign: "center" }}>服务项目</section>
						<TopOuter>
							<FlexItem span={1} text='序号' />
							<FlexItem span={3.5} text='编号' />
							<FlexItem span={6} text='服务项目名称' />
							<FlexItem span={2} text='单价' />
							<FlexItem span={1} text='工时' />
							<FlexItem span={2} text='金额' />
							<FlexItem span={3} text='维修技师' />
						</TopOuter>
						{serviceTable}
						<div
							style={{
								height: "36px",
								borderWidth: "0 1px 1px 1px",
								borderColor: "#000",
								borderStyle: "solid",
								textAlign: "right",
							}}>
							<span
								style={{
									lineHeight: "36px",
									marginRight: "20px",
								}}>{`应收工时费：${printContent.service_content.amount}元`}</span>
							<span
								style={{
									lineHeight: "36px",
									marginRight: "12px",
								}}>{`实收工时费：${printContent.service_content.ar}元`}</span>
						</div>
					</>
				)}
				<section style={{ fontWeight: "bold", margin: "20px 0 12px 0", textAlign: "center" }}>结算金额</section>
				<TopOuter>
					<DoubleItem spanGroup={["1", "2"]} textGroup={["应收金额", printContent.money_all]} />
					<DoubleItem spanGroup={["1", "2"]} textGroup={["实收金额", printContent.money_pay]} />
					<DoubleItem
						spanGroup={["1", "2"]}
						textGroup={["欠收金额", parseFloat(printContent.money_all) - parseFloat(printContent.money_pay)]}
					/>
				</TopOuter>
				<MiddleOuter>
					<DoubleItem spanGroup={["1", "2"]} textGroup={["优惠", slipaAount - parseFloat(printContent.money_all)]} />
					<DoubleItem spanGroup={["1", "5.03"]} textGroup={["大写", mathFunc.cashToChinese(printContent.money_pay)]} />
				</MiddleOuter>
				<div
					style={{
						position: "absolute",
						width: "100%",
						height: "100%",
						backgroundColor: "#000",
						top: "0",

						opacity: "0.48",
					}}></div>
				<div
					style={{
						position: "absolute",
						top: "40%",
						left: "50%",
						marginLeft: "-200px",
						color: "#E38E56",
						fontSize: "18px",
						fontWeight: "bold",
					}}>
					结算单详情区仅作效果展示用,数据不具有参考价值
				</div>
			</div>

			<p style={{ marginTop: "5px" }}>{`备注：${printState.remark}`}</p>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
				<div style={{ minWidth: "60%", textAlign: "left" }}>
					<div style={{ lineHeight: "36px", height: "36px", fontFamily: "SimSun", whiteSpace: "nowrap" }}>
						{"联系地址：" + printState.address}
					</div>
					<div style={{ lineHeight: "36px", height: "36px", fontFamily: "SimSun" }}>
						{"服务顾问： " + printContent.worker}
					</div>
					<div style={{ lineHeight: "36px", height: "36px", fontFamily: "SimSun" }}>客户签名：</div>
				</div>
				<div style={{ flex: 1, textAlign: "left" }}>
					<div style={{ lineHeight: "36px", height: "36px", fontFamily: "SimSun" }}>
						{"联系电话：" + printState.phone}
					</div>
					<div style={{ lineHeight: "36px", height: "36px", fontFamily: "SimSun" }}>
						{"结算人员： " + (printContent.input_user ? printContent.input_user : "")}
					</div>
					<div style={{ lineHeight: "36px", height: "36px", fontFamily: "SimSun" }}>
						{`结算日期：${moment(printContent.end_time).format("YYYY年MM月DD日")}`}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrintPreview;
