import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Input, Form, Radio, message, Spin, Switch } from "antd";
import axios from "../../core/axios";
import Axios from "axios";
import User from "../../core/user";
import _Api from "../../core/api";
import _ from "underscore";
import E from "wangeditor";
import WxMultiSelectModal from "../../components/modals/WxMultiSelect";
import WxMsgPreviewModal from "../../components/modals/WxMsgPreview";
import "./SendMessageModal.less";

let editor = null;

const Send = ({ curModel, selectModel, goRecord }) => {
	const [state, setState] = useState({
		data: {},
		receiveType: "shop",
		isEdit: "",
		title: "",
		sureSetting: false,
	});
	const [wxBind, setWxBind] = useState(true);
	const [modalState, setModalState] = useState({
		customerV: false,
		selectedId: [],
		selectedCustomer: [],
		prewVisible: false,
		prewData: "",
	});
	const [sendFlag, setSendFlag] = useState(false);
	useEffect(() => {
		getWechatInfo();
		if (!editor) {
			editor = new E("#editor");
			editor.customConfig.customUploadImg = function (files, insert) {
				// Headers
				const config = {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
				const formData = new FormData();
				// // 添加要上传的文件
				formData.append("file", files[0]);
				Axios.post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" + User.token(), formData, config)
					.then((res) => {
						if (res.data.response == 200) {
							insert(res.data.img_url);
						} else {
						}
					})
					.catch((err) => {
						console.log(err);
					});
			};

			editor.create();
		}

		return () => {
			if (editor) {
				editor = null;
			}
		};
	}, []);
	useEffect(() => {
		console.log("Send", curModel);
		if (curModel.send_type) {
			const {
				name: title,
				content,
				send_type: receiveType,
				customer_ids: selectedId,
				is_confirm: sureSetting,
			} = curModel;
			editor.txt.html(content);
			setState({ ...state, title, receiveType, sureSetting });
			if (receiveType === "customer") {
				_Api.wxUserSelect({}).then((res) => {
					let allUser = res.data.content,
						selectedUser = [];
					selectedId.forEach((id) => {
						selectedUser.push(allUser.find((item) => item.id === id));
					});
					setModalState({ ...modalState, selectedId, selectedCustomer: selectedUser });
				});
			}
		} else {
			editor.txt.html(curModel.content);
			setState({ ...state, title: curModel.name });
		}
	}, [curModel]);
	const seletedStr = useMemo(() => {
		let leng = modalState.selectedCustomer.length;
		let nameArr = modalState.selectedCustomer.map((item) => item.name);
		if (leng == 0) {
			return "";
		} else if (leng > 0 && leng < 5) {
			return nameArr.join(",");
		} else {
			return nameArr.slice(0, 5).join(",") + "等";
		}
	}, [modalState.selectedCustomer]);
	function getWechatInfo() {
		_Api.wxInfoGet({}).then((res) => {
			console.log(res);
			if (res.response == 200) {
				setWxBind(true);
			} else {
				setWxBind(false);
			}
		});
	}
	function handleSend() {
		const { receiveType, title, sureSetting } = state,
			{ selectedId } = modalState;
		let content = editor.txt
			.html()
			.replace(/class=/g, "classes=")
			.replace(/\\\\\\/g, "")
			.replace(/&quot;/g, "")
			.replace(/microsoft/g, "")
			.replace(/helvetica/g, "")
			.replace(/microsoft/g, "")
			.replace(/helvetica/g, "")
			.replace(/neue/g, "")
			.replace(/blinkmacsystemfont/g, "")
			.replace(/word-spacing:/g, "")
			.replace(/-webkit-text-stroke-width:=/g, "")
			.replace(/orphans:=/g, "")
			.replace(/pingfang/g, "")
			.replace(/hiragino/g, "")
			.replace(/sans=/g, "")
			.replace(/sans-serif;/g, "")
			.replace(/font-variant:=/g, "")
			.replace(/for=/g, "")
			.replace(/&quot;/g, "")
			.replace(/letter-spacing/g, "")
			.replace(/-apple-system/g, "")
			.replace(/roboto/g, "")
			.replace(/font-weight/g, "")
			.replace(/text-indent/g, "")
			.replace(/number/g, "")
			.replace(/letter-spacing/g, "")
			.replace(/letter-spacing:/g, "")
			.replace(/white-space:/g, "")
			.replace(/arial/g, "")
			.replace(/widows/g, "")
			.replace(/="\\"/g, "")
			.replace(/segoe/g, "")
			.replace(/yahei/g, "")
			.replace(/\\/g, "")
			.replace(/=""/g, "")
			.replace(/auto/g, "")
			.replace(/normal/g, "")
			.replace(/text-transform/g, "");
		if (receiveType === "customer" && selectedId.length === 0) {
			return message.error("请选择要发送的客户");
		}
		if (!title) {
			return message.error("请输入标题");
		}
		if (!content) {
			return message.error("请输入消息内容");
		}
		let data = {
			send_type: receiveType,
			msg_title: title,
			msg_content: content,
			is_confirm: sureSetting ? "1" : "0",
		};
		if (receiveType === "customer") {
			data = { ...data, customer_ids: selectedId };
		}
		setSendFlag(true);
		_Api.wxMsgSend(data).then((res) => {
			setSendFlag(false);
			message.success(res.msg);
			initState();
			goRecord();
		});
	}
	function initState(params) {
		editor.txt.html("");
		setState({ ...state, receiveType: "shop", isEdit: "", title: "", sureSetting: false });
		setModalState({ ...modalState, selectedId: [], selectedCustomer: [] });
	}

	function showPreview() {
		let data = { name: state.title, content: editor.txt.html().replace("\\\\", "") };
		setModalState({ ...modalState, prewData: data, prewVisible: true });
	}

	const formItemLayout = {
		labelCol: { span: 2 },
		wrapperCol: { span: 20 },
	};
	return (
		<Spin size='large' tip='消息发送中，如果选择客户数量较多可能需要较长时间，请耐心等候...' spinning={sendFlag}>
			<div className='send-message'>
				{wxBind ? (
					<>
						<div className='box-body'>
							<div className='tips-wrap'>
								<span className='icon iconfont' style={{ fontSize: "14px", color: "#fc9c6b" }}>
									&#xe606;
								</span>
								为防止微信官方封禁公众号，请遵守
								<span
									className='btn-view theme-color click-able'
									onClick={() => {
										window.open(
											"http://weixin.qq.com/cgi-bin/readtemplate?t=weixin_external_links_content_management_specification"
										);
									}}>
									通知发送规则 &gt;
								</span>
							</div>
							<div className='modal-body'>
								<Form {...formItemLayout}>
									<Form.Item label='接收人'>
										{!state.isEdit && (
											<div>
												<div>
													<Radio.Group
														buttonStyle='solid'
														onChange={(e) => {
															setState({ ...state, receiveType: e.target.value });
														}}
														value={state.receiveType}>
														<Radio.Button id='all_radio' value='shop'>
															<span style={{ color: "#333" }}>全体客户</span>
														</Radio.Button>
														<Radio.Button id='some_radio' value='customer'>
															<span style={{ color: "#333" }}>部分客户</span>
														</Radio.Button>
													</Radio.Group>
													<br />
												</div>
												<div style={{ marginTop: "10px" }}>
													{"customer" === state.receiveType && (
														<div>
															<Button
																id='student_select_btn'
																className='mr20'
																onClick={() => setModalState({ ...modalState, customerV: true })}>
																选择客户
															</Button>
															<span
																style={{
																	color: "#B4B4B4",
																}}>
																&nbsp;已选择：
																{seletedStr}
																<span>
																	<span
																		style={{
																			color: "#ff8534",
																			marginLeft: "5px",
																		}}>
																		{modalState.selectedCustomer.length}
																	</span>
																	&nbsp;人
																</span>
															</span>
														</div>
													)}
												</div>
											</div>
										)}
										{state.isEdit && <div>{state.data.recipientName}</div>}
									</Form.Item>
									<Form.Item label='标题'>
										<Input
											type='text'
											id='msg_title'
											placeholder='40字以内'
											name='data.title'
											value={state.title}
											onChange={(e) => {
												setState({ ...state, title: e.target.value });
											}}
											className='form-control'
											style={{ width: "400px" }}
											maxLength={40}
										/>
									</Form.Item>
									<Form.Item label='模板'>
										<Button id='template_btn' onClick={selectModel}>
											模板库
										</Button>
									</Form.Item>
									<Form.Item label='内容'>
										<div id='editor'></div>
									</Form.Item>
									<Form.Item label='客户确认'>
										<Switch
											id='parent_confirm_switch'
											checked={state.sureSetting}
											onChange={(e) => {
												setState({ ...state, sureSetting: e });
											}}
										/>
										<span className='ml8' style={{ color: "#A1A1A4" }}>
											开启后，客户端接收到的通知会增加“确认收到”的操作
										</span>
									</Form.Item>
								</Form>
							</div>
						</div>
						<Row>
							<Col className='box-footer' span={20} push={2}>
								<Button id='send_btn' onClick={handleSend} className='ml8' type='primary'>
									发送
								</Button>
								<Button id='preview_btn' onClick={showPreview} className='ml8'>
									预览
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<div
						className='box-cover'
						style={{
							left: 0,
							bottom: 0,
							top: 0,
							right: 0,
							opacity: 0.8,
							backgroundColor: "#000",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 999999,
							height: "90vh",
						}}>
						<div style={{ color: "#fff", fontSize: "24px" }}>
							未绑定自己公众号的，无法使用此功能。
							<Link
								style={{ color: "#FC9C6B", cursor: "pointer", fontSize: "24px" }}
								to={{ pathname: "/publicaccount" }}>
								点击这里去绑定&gt;&gt;
							</Link>
						</div>
					</div>
				)}
				{modalState.customerV && (
					<WxMultiSelectModal
						visible={modalState.customerV}
						closeModal={() => {
							setModalState({ ...modalState, customerV: false });
						}}
						selectedList={{ ids: modalState.selectedId, items: [...modalState.selectedCustomer] }}
						handleComplete={(data) => {
							setModalState({
								...modalState,
								selectedId: data.ids,
								selectedCustomer: data.items,
								customerV: false,
							});
						}}
					/>
				)}
				{modalState.prewVisible && (
					<WxMsgPreviewModal
						visible={modalState.prewVisible}
						data={modalState.prewData}
						closeModal={() => {
							setModalState({
								...modalState,
								prewVisible: false,
								prewData: "",
							});
						}}
					/>
				)}
			</div>
		</Spin>
	);
};
export default Send;
