import React, { Component } from "react";
import { MainRoutes } from '../../routes'

export class Page extends Component {

    state = {
        menuType: true
    }

    componentDidMount() {

    }

    render() {
        const { menuFold } = this.props;
        return (
            <div className={!menuFold ? "right-container has-nav" : "right-container has-nav right-container-vertical"}>
                <MainRoutes></MainRoutes>
            </div>
        )
    }
}

export default Page;
