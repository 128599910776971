import { useState, useEffect, useContext } from "react";
import { Button, Col, Popconfirm, message, Row, Table, Divider } from "antd";
import PageControl from "../../components/PageControl";
import _Api from "../../core/api";
import SearchBar from "../../components/SearchBar";
import CarEditModal from "../../components/modals/CarEdit";
import ShopContext from "../../core/context-manager";

const CarManagement = ({}) => {
	const { carSearched, setCarSearched } = useContext(ShopContext);
	const [carState, setCarState] = useState({
		keyword: "",
		list: [],
		pageNo: "1",
		pageSize: "10",
		total: "",
	});
	const [modalState, setModalState] = useState({ visible: false, editType: false, curCarId: "" });
	const [flag, setFlag] = useState(true);
	useEffect(() => {
		if (!carSearched.searched) {
			getCarList(undefined, carSearched.id);
		} else {
			flag && getCarList();
		}
		setFlag(false);
	}, [carSearched.searched, flag]);

	function getCarList(pageState, keyword) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1";
		let data = {
			keyword: keyword || carState.keyword,
			count: pageSize,
			page: pageNo,
		};
		_Api.carGet(data).then((res) => {
			let _data = res.data;
			if (keyword) {
				setCarSearched({ ...carSearched, searched: true });
				setCarState({
					...carState,
					flag: false,
					keyword: keyword,
					list: _data.content,
					total: _data.allcount,
					pageNo: _data.curpage,
					pageSize: _data.pagecount,
				});
			} else {
				setCarState({
					...carState,
					list: _data.content,
					total: _data.allcount,
					pageNo: _data.curpage,
					pageSize: _data.pagecount,
				});
			}
		});
	}
	function handleDelete(id) {
		_Api.carDelete({ id }).then((res) => {
			message.success(res.msg);
			const { pageSize, pageNo } = carState;
			getCarList({ pageSize, pageNo });
		});
	}
	const columns = [
		{
			title: "车牌号",
			dataIndex: "car_id",
		},
		{
			title: "车型",
			dataIndex: "car_type",
			align: "center",
		},
		{
			title: "客户",
			dataIndex: "customer",
			align: "center",
		},
		{
			title: "联系人",
			dataIndex: "linkman",
			align: "center",
		},
		{
			title: "手机号码",
			dataIndex: "phone",
			align: "center",
		},
		{
			title: "进厂次数",
			dataIndex: "work_num",
			align: "center",
		},
		{
			title: "花销总额",
			dataIndex: "all_money",
			align: "center",
		},
		{
			title: "建档时间",
			dataIndex: "gmt_creat",
			align: "center",
		},
		{
			title: "操作",
			dataIndex: "name",
			align: "center",
			render: (text, record, index) => (
				<>
					<span
						className='theme-color click-able'
						onClick={() => {
							setModalState({ visible: true, editType: true, curCarId: record.car_id });
						}}>
						修改
					</span>
					<Divider type='vertical' />
					<Popconfirm
						placement='left'
						title='删除后无法恢复，是否确认删除？'
						okText='确认'
						cancelText='取消'
						onConfirm={() => {
							handleDelete(record.id);
						}}>
						<span className='theme-color click-able'>删除</span>
					</Popconfirm>
				</>
			),
		},
	];
	return (
		<div className='page home-school-intereact'>
			<section className='content-header'>
				<h1>车辆档案</h1>
			</section>
			<div className='box'>
				<div className='box-header'>
					<Row type='flex' justify='space-between'>
						<Col>
							<SearchBar
								searchText={carState.keyword}
								onChangeText={(text) => {
									setCarState({ ...carState, keyword: text });
								}}
								onSearch={getCarList}
								label='搜索'
								placeholder='请输入车牌号/车型/联系人/手机'
							/>
						</Col>
						<Col>
							<Button
								type='primary'
								onClick={() => {
									setModalState({ ...modalState, visible: true, editType: false });
								}}>
								新增
							</Button>
						</Col>
					</Row>
				</div>
				<div className='box-body'>
					<Table
						rowKey={(record) => record.id}
						pagination={false}
						dataSource={carState.list}
						columns={columns}
						bordered
						style={{ marginBottom: "40px" }}
					/>
					<PageControl
						total={carState.total}
						pageSize={carState.pageSize}
						current={carState.pageNo}
						showSizeChanger
						toPage={(page, count) => {
							getCarList({ pageNo: page, pageSize: count });
						}}
					/>
				</div>
			</div>
			{modalState.visible && (
				<CarEditModal
					visible={modalState.visible}
					editType={modalState.editType}
					curCarId={modalState.curCarId}
					openWxSelect={() => {}}
					closeModal={() => {
						setModalState({ visible: false, editType: false, curCarId: "" });
					}}
					setCurCar={() => {
						setModalState({ visible: false, editType: false, curCarId: "" });
						getCarList();
					}}
				/>
			)}
		</div>
	);
};

export default CarManagement;
