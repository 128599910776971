import { useState, useEffect } from "react";
import { Row, Col, Button, Select, Input, Form, Table, Tooltip } from "antd";
import mathFunc from "../../core/mathfunc";
import _Api from "../../core/api";

export default ({ slip, setTable, tableState, discount, viewmode }) => {
	const [workerOptions, setWorkerOptions] = useState([]);
	console.log(tableState);
	useEffect(() => {
		getWorkerList();
	}, []);

	function getWorkerList() {
		let data = { is_jishi: "1" };
		_Api.workerSelect(data).then((res) => {
			const options = res.data.content.map((item) => ({ value: item.name, label: item.name }));
			setWorkerOptions(options);
		});
	}

	const handleDataChange = (value, key, index) => {
		// console.log(value,key,index,);
		let _data = [...tableState.data];
		_data.splice(index, 1, { ..._data[index], [key]: value });
		let amount = 0;
		let ids = _data.map((item) => {
			let price = item.price ? parseFloat(item.price) : 0;
			let hours = item.hours ? parseFloat(item.hours) : 0;
			amount += price * hours;
			return item.id;
		});
		let ar = amount * parseFloat(discount);
		amount = mathFunc.returnFloat(amount);
		ar = mathFunc.returnFloat(ar);
		console.log(amount, ar);
		setTable({ data: _data, ids: ids, amount, ar });
	};

	let columns = [
		{
			title: "序号",
			dataIndex: "id",
			align: "center",
			render: (text, record, index) => index + 1,
		},
		{
			title: "编号",
			align: "center",
			dataIndex: "bianhao",
		},
		{
			title: "服务项目名称",
			align: "center",
			dataIndex: "name",
		},
		{
			title: "单价",
			align: "center",
			dataIndex: "price",
			render: (text, record, index) =>
				viewmode ? (
					text
				) : (
					<Input
						value={text}
						style={{ width: "80px", textAlign: "right" }}
						onChange={(e) => {
							if (e.target.value.length > 8) {
								return;
							}
							let price = mathFunc.checkPrice(e.target.value);
							handleDataChange(price, "price", index);
						}}
					/>
				),
		},
		{
			title: "工时",
			align: "center",
			dataIndex: "hours",
			render: (text, record, index) =>
				viewmode ? (
					text
				) : (
					<Input
						value={text}
						style={{ width: "80px", textAlign: "right" }}
						onChange={(e) => {
							let num = e.target.value.replace(/[^\d]/g, "");
							handleDataChange(num, "hours", index);
						}}
					/>
				),
		},
		{
			title: "金额",
			key: "sum",
			align: "center",
			render: (text, record) => {
				let price = record.price ? record.price : 0;
				let hours = record.hours ? record.hours : 0;
				return mathFunc.multi(price, hours);
			},
		},
		{
			title: (
				<Tooltip
					title={
						<p>
							添加技师请前往:
							<br />
							设置-&gt;员工管理
						</p>
					}>
					<div className='click-able'>
						技师
						<span className='icon iconfont theme-color' style={{ marginLeft: "3px" }}>
							&#xee7a;
						</span>
					</div>
				</Tooltip>
			),
			dataIndex: "worker",
			key: "worker",
			align: "center",
			width: "150px",
			render: (text, record, index) =>
				viewmode ? (
					text
				) : (
					<Select
						style={{ width: "100%" }}
						options={workerOptions}
						value={text}
						onChange={(e) => {
							record.worker = e;
							let _data = [...tableState.data];
							_data.splice(index, 1, record);
							setTable({ ...tableState, data: _data });
						}}></Select>
				),
		},
	];
	if (!viewmode) {
		columns = [
			...columns,
			{
				title: "删除",
				align: "center",
				key: "delete",
				render: (text, record, index) => (
					<span
						className='icon iconfont theme-color click-able'
						onClick={() => {
							console.log(index);
							let _data = [...tableState.data];
							_data.splice(index, 1);
							let amount = 0;
							let ids = _data.map((item) => {
								let price = item.price ? parseFloat(item.price) : 0;
								let hours = item.hours ? parseFloat(item.hours) : 0;
								amount += price * hours;
								return item.id;
							});
							let ar = amount * parseFloat(discount);
							setTable({ data: _data, ids: ids, amount, ar });
						}}>
						&#xe61b;
					</span>
				),
			},
		];
	}

	return (
		<Table
			rowKey={(record) => record.id}
			pagination={false}
			dataSource={tableState ? tableState.data : []}
			columns={columns}
			bordered
			style={{ marginTop: "20px", marginBottom: "30px" }}
			locale={{ emptyText: viewmode ? "暂无数据" : "请点击表格右上方按钮选择或新增服务项目" }}
			footer={
				slip
					? () => (
							<Row type='flex' justify='end' style={{ maxHeight: "20px" }}>
								<Form layout='inline'>
									<Form.Item name='shouldreceive' label='工时费'>
										<span>{tableState && mathFunc.returnFloat(tableState.amount || 0)}</span>
									</Form.Item>
									<Form.Item name='fee' label='项目折扣'>
										<span>{mathFunc.getPercent(discount) + "%"}</span>
									</Form.Item>
									<Form.Item name='realreceive' label='应收工时费'>
										<span>{tableState && mathFunc.returnFloat(tableState.ar || 0)}</span>
									</Form.Item>
								</Form>
							</Row>
					  )
					: null
			}
		/>
	);
};
