import { Table, Divider, Popconfirm, Tooltip, Empty, message } from "antd";
import mathFunc from "../core/mathfunc";
import { useHistory } from "react-router-dom";
import RechargeImg from "../libs/meber_recharge.png";
import ExpensesImg from "../libs/meber_expenses.png";
import LockImg from "../libs/meber_lock.png";
import moment from "moment";
const VipCountTable = ({
  TableData,
  selectType,
  handleEdit,
  handleDelete,
  handleSelect,
  handleRecharge,
  deault,
}) => {
  console.log(TableData);
  const history = useHistory();
  const columns = [
    {
      title: "会员卡号/充值消费明细",
      dataIndex: "bianhao",
      width: 230,
      render: (text, record) => (
        <>
          <div /*  style={{ position: "relative" }} */>
            <span>{text}</span>
            <Tooltip placement="top" title="充值明细">
              {/* <span
                onClick={() => {
                  history.push({
                    pathname: "/rechargedetail",
                    state: { card: text },
                  });
                }}
                className="icon iconfont theme-color"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              >
                &#xea47;
              </span> */}
              <img
                onClick={() => {
                  history.push({
                    pathname: "/rechargedetail",
                    state: { card: text, key: "2" },
                  });
                }}
                style={{
                  width: 19,
                  height: 19,
                  marginLeft: 9,
                  cursor: "pointer",
                }}
                src={RechargeImg}
                alt=""
              />
            </Tooltip>
            <Tooltip placement="top" title="消费明细">
              {/* <span
                onClick={() => {
                  history.push({
                    pathname: "/purchasedetail",
                    state: { card: text },
                  });
                }}
                className="icon iconfont theme-color"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              >
                &#xe95a;
              </span> */}
              <img
                onClick={() => {
                  history.push({
                    pathname: "/purchasedetail",
                    state: { card: text, key: "2" },
                  });
                }}
                style={{
                  width: 19,
                  height: 19,
                  marginLeft: 9,
                  cursor: "pointer",
                }}
                src={ExpensesImg}
                alt=""
              />
            </Tooltip>
            {record.password ? (
              // <span
              //   className="icon iconfont theme-color"
              //   style={{
              //     marginLeft: "7px",
              //     fontSize: "17px",
              //   }}
              // >
              //   &#xe66b;
              // </span>
              <img
                style={{ width: 19, height: 19, marginLeft: 9 }}
                src={LockImg}
                alt=""
              />
            ) : null}
          </div>
        </>
      ),
    },
    {
      title: "姓名",
      dataIndex: "name",
      align: "left",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      align: "left",
    },
    {
      title: "剩余次数",
      dataIndex: "left_num",
      align: "left",
    },
    {
      title: "套餐名称",
      dataIndex: "package_name",
      align: "left",
    },
    {
      title: "套餐内容",
      dataIndex: "package_content",
      width: "300px",
      align: "left",
    },
    // {
    //   title: "项目折扣",
    //   dataIndex: "service_zk",
    //   align: "left",
    //   render: (text) => mathFunc.getPercent(text) + "%",
    // },
    // {
    //   title: "配件折扣",
    //   dataIndex: "part_zk",
    //   align: "left",
    //   render: (text) => mathFunc.getPercent(text) + "%",
    // },
    {
      title: "有效期",
      dataIndex: "gmt_creat",
      align: "left",
      render: (text, record) => {
        if (record.end_time) {
          return (
            <div>
              <span>
                {`${moment(text).format("YYYY-MM-DD")} 至 ${moment(
                  record.end_time
                ).format("YYYY-MM-DD")}
                `}
              </span>
              {compareTime(
                moment().format("YYYY-MM-DD"),
                moment(record.end_time).format("YYYY-MM-DD")
              ) && (
                <span
                  style={{
                    marginLeft: 5,
                    backgroundColor: "#F5DBD7",
                    color: "#EA2504",
                    fontSize: 12,
                    padding: "0 5px",
                  }}
                >
                  已过期
                </span>
              )}
            </div>
          );
        } else {
          return "无限制";
        }
      },
    },
    // {
    //   title: "余额",
    //   align: "left",
    //   dataIndex: "left_money",
    // },
    // {
    //   title: "备注",
    //   dataIndex: "remark",
    // },
    {
      title: "操作",
      key: "operation",
      align: "left",
      render: (text, record, index) => (
        <>
          <span
            className="theme-color click-able"
            onClick={() => {
              console.log(record);
              handleEdit(record);
            }}
          >
            修改
          </span>
          <Divider type="vertical" />
          <span
            className="theme-color click-able"
            onClick={() => {
              //   handleCharge(record);
              const falg = compareTime(
                moment().format("YYYY-MM-DD"),
                moment(record.end_time).format("YYYY-MM-DD")
              );
              if (falg) {
                message.error("当前会员卡已过期，如需充值，请修改到期时间");
              } else {
                handleRecharge(record);
              }
            }}
          >
            充值
          </span>
          <Divider type="vertical" />
          <Popconfirm
            placement="left"
            title="该操作将会删除会员卡以及对应的充值消费记录"
            okText="确认"
            cancelText="取消"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <span style={{ color: "#EF0000" }} className="click-able">
              删除
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];
  function compareTime(date1, date2) {
    let date1_s = date1.replace(/\-/g, "/");
    let date2_s = date2.replace(/\-/g, "/");
    let date1_unix = Date.parse(date1_s);
    let date2_unix = Date.parse(date2_s);
    // console.log(date1_unix, date2_unix, date1_unix - date2_unix, date1_unix > date2_unix);
    return date1_unix > date2_unix;
  }
  return (
    <Table
      rowKey={(record) => record.id}
      pagination={false}
      dataSource={TableData ? TableData : []}
      columns={columns}
      bordered
      style={{ marginBottom: "30px" }}
      // locale={selectType ? {} : { emptyText: "请点击表格右上方按钮新增会员卡" }}
      locale={
        selectType
          ? {}
          : deault
          ? { emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
          : { emptyText: "请点击表格右上方按钮新增套餐" }
      }
    />
    //     <Table
    //     rowKey={(record) => record.id}
    //     pagination={false}
    //     dataSource={vipState.list}
    //     columns={columns}
    //     bordered
    //     style={{ marginBottom: "40px" }}
    //     locale={{ emptyText: "请点击表格右上方按钮新增会员" }}
    //   />
  );
};
export default VipCountTable;
