import { useState, useEffect, useMemo, useContext } from "react";
import { Input, Form, Modal, Row, Col, Button, message, Table } from "antd";
import PageControl from "../PageControl";
// import TitleBar from "../TitleBar";
import TitleBarTwo from "../TitleBarTwo";
import _Api from "../../core/api";
import ShopContext from "../../core/context-manager";
import mathFunc from "../../core/mathfunc";
import PromoteInput from "../PromoteInput";
const { TextArea } = Input;

const VipRecharge = ({
  visible,
  closeModal,
  bianhao,
  handleComplete,
  // scanMode,
}) => {
  const { userInfo } = useContext(ShopContext);
  const [vipInfo, setVipInfo] = useState({
    bianhao: "",
    name: "",
    phone: "",
    serviceZk: "",
    partZk: "",
    moneyLeft: "",
    moneyReal: "",
    moneyZs: "",
    inputUser: userInfo.name,
    payType: "",
    packageType: "",
    packageName: "",
    packageContent: "",
    leftNum: "",
    memberNum: "",
  });
  const [payTypes, setPayTypes] = useState([]);
  const [historyState, setHistoryState] = useState({
    data: [],
    total: "",
    pageSize: "10",
    pageNo: "1",
  });
  // 计算充值后余额
  const moneyTotal = useMemo(() => {
    let _moneyReal = vipInfo.moneyReal ? parseFloat(vipInfo.moneyReal) : 0,
      _moneyZs = vipInfo.moneyZs ? parseFloat(vipInfo.moneyZs) : 0;
    // return parseFloat(vipInfo.moneyLeft) + _moneyReal + _moneyZs;
    return mathFunc.returnFloat(parseFloat(vipInfo.moneyLeft) + _moneyReal + _moneyZs)
  }, [vipInfo.moneyReal, vipInfo.moneyZs, vipInfo.moneyLeft]);
  useEffect(() => {
    if (visible) {
      getVipInfo();
      getChargeHistory();
      getPayTypes();
    }
  }, [visible]);
  //获取支付类型
  function getPayTypes() {
    _Api.getPayType().then((res) => {
      setPayTypes(res.msg);
    });
  }
  // 发送请求 获取卡号基本数据
  function getVipInfo() {
    const data = { keyword: bianhao };
    _Api.cardSelect(data).then((res) => {
      let info = res.data.content[0];
      const {
        bianhao,
        name,
        left_money,
        phone,
        service_zk,
        part_zk,
        package_type,
        left_num,
        package_name,
        package_content,
      } = info;
      setVipInfo({
        ...vipInfo,
        bianhao,
        name,
        phone,
        moneyLeft: parseFloat(left_money),
        serviceZk: service_zk,
        partZk: part_zk,
        packageType: package_type,
        packageName: package_name,
        packageContent: package_content,
        leftNum: left_num,
      });
    });
  }
  // 初始化数据
  function initState(params) {
    setVipInfo({
      ...vipInfo,
      bianhao: "",
      name: "",
      moneyLeft: "",
      moneyReal: "",
      moneyZs: "",
      // inputUser: "",
      payType: "",
      memberNum: "",
    });
    setHistoryState({ data: [], total: "", pageSize: "10", pageNo: "1" });
  }
  // 关闭 
  function close() {
    initState()
    closeModal()
  }
  // 确定逻辑
  function handleOk() {
    let {
      bianhao,
      name,
      moneyLeft,
      moneyReal,
      moneyZs,
      inputUser,
      payType,
      memberNum,
      packageContent,
    } = vipInfo;

    console.log(vipInfo);
    if (vipInfo.packageType === "储值卡") {
      if (moneyReal === "") {
        return message.info("请输入充值金额");
      }
      if (moneyZs === "") {
        return message.info("请输入赠送金额");
      }
      let data = {
        member_card: bianhao,
        money_real: moneyReal,
        money_member: moneyZs,
        input_user: inputUser,
        pay_type: payType,
      };
      _Api.cardCharge(data).then((res) => {
        message.success(res.msg);
        initState();
        handleComplete();
      });
    } else {
      if (moneyReal === "") {
        return message.info("请输入实收金额");
      }
      if (memberNum === "") {
        return message.info("请输入充值次数");
      }
      console.log();
      if (parseFloat(memberNum) < 1) {
        return message.info("充值次数不能小于1");
      }
      let data = {
        member_card: bianhao,
        money_real: moneyReal,
        input_user: inputUser,
        pay_type: payType,
        member_num: memberNum,
      };
      console.log(data);
      _Api.cardCharge(data).then((res) => {
        message.success(res.msg);
        initState();
        handleComplete();
      });
    }
  }
  // 发送请求 获取充值历史 数据
  function getChargeHistory(pageState) {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    let data = { member_card: bianhao, count: pageSize, page: pageNo };
    _Api.cardChargeHistory(data).then((res) => {
      setHistoryState({
        data: res.data.content,
        total: res.data.allcount,
        pageNo: res.data.curpage,
        pageSize: res.data.pagecount,
      });
    });
  }
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const columns = [
    {
      title: "日期",
      width: 220,
      dataIndex: "gmt_creat",
      align: "left",
    },
    {
      title: "充值金额",
      dataIndex: "money_real",
      align: "left",
    },
    {
      title: "赠送金额",
      dataIndex: "money_member",
      align: "left",
    },
    {
      title: "充值方式",
      dataIndex: "pay_type",
      align: "left",
    },
  ];
  const columnCount = [
    {
      title: "日期",
      width: 220,
      dataIndex: "gmt_creat",
      align: "left",
    },
    {
      title: "实收金额",
      dataIndex: "money_real",
      align: "left",
    },
    {
      title: "充值次数",
      dataIndex: "member_num",
      align: "left",
    },
    {
      title: "充值方式",
      dataIndex: "pay_type",
      align: "left",
    },
  ];
  return (
    <Modal
      title="会员卡充值"
      width={900}
      visible={visible}
      onOk={handleOk}
      onCancel={close}
    >
      <Row justify="space-between" style={{ color: "#333", marginBottom: 30 }}>
        <Col span={8}>
          <span>卡号：{vipInfo.bianhao}</span>
          <span
            style={{
              marginLeft: 10,
              textAlign: "center",
              width: 40,
              height: 20,
              fontSize: "14px",
              borderRadius: 4,
              lineHeight: "20px",
              backgroundColor: "#FFF0E6",
              display: "inline-block",
              color: "#FF9551",
            }}
          >
            {vipInfo.packageType === "储值卡" ? "储值" : "计次"}
          </span>
        </Col>
        <Col span={7} offset={1}>
          <span>持有人：{vipInfo.name}</span>
        </Col>
        <Col span={6} offset={2}>
          {vipInfo.packageType === "储值卡" ? (
            <div>
              当前余额：
              <span style={{ color: "#F20000" }}>{vipInfo.moneyLeft}元</span>
            </div>
          ) : (
            <div>
              剩余次数：
              <span style={{ color: "#F20000" }}>{vipInfo.leftNum}次</span>
            </div>
          )}
        </Col>
      </Row>
      <Form {...layout}>
        <TitleBarTwo style={{ marginBottom: 20 }}>充值信息</TitleBarTwo>
        {vipInfo.packageType === "储值卡" ? (
          <Row>
            <Col span={8}>
              <Form.Item label="充值金额">
                <PromoteInput
                  width={166}
                  placeholder="请输入充值金额"
                  value={vipInfo.moneyReal}
                  onChange={(e) => {
                    setVipInfo({ ...vipInfo, moneyReal: e.target.value });
                  }}
                  unit="元"
                  type="价格"
                ></PromoteInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="赠送金额">
                <PromoteInput
                  width={166}
                  placeholder="请输入赠送金额"
                  value={vipInfo.moneyZs}
                  onChange={(e) => {
                    setVipInfo({ ...vipInfo, moneyZs: e.target.value });
                  }}
                  unit="元"
                  type="价格"
                ></PromoteInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                label="充值后余额"
              >
                <PromoteInput
                  width={166}
                  // placeholder="请输入赠送金额"
                  value={moneyTotal}
                  unit="元"
                  disabled={true}
                ></PromoteInput>
              </Form.Item>{" "}
            </Col>
          </Row>
        ) : (
          <>
            {" "}
            <Row>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  label="套餐名称"
                >
                  <Input
                    value={vipInfo.packageName}
                    disabled
                    // onChange={(e) => {
                    //   setVipInfo({
                    //     ...vipInfo,
                    //     packageName: e.target.value,
                    //   });
                    // }}
                    placeholder="请输入套餐名称"
                  />
                </Form.Item>
                <Form.Item
                  label="套餐"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <TextArea
                    // onChange={(e) => {
                    //   setVipInfo({
                    //     ...vipInfo,
                    //     packageContent: e.target.value,
                    //   });
                    // }}
                    disabled
                    value={vipInfo.packageContent}
                    rows={4}
                    placeholder="请输入套餐"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label="充值次数">
                  <PromoteInput
                    width={166}
                    placeholder="请输入充值次数"
                    value={vipInfo.memberNum}
                    unit="次"
                    type="次数"
                    onChange={(e) => {
                      setVipInfo({ ...vipInfo, memberNum: e.target.value });
                    }}
                  ></PromoteInput>
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item label="应收金额">
                  <PromoteInput
                    width={166}
                    // placeholder="请输入赠送金额"
                    value={vipInfo.moneyZs}
                    onChange={(e) => {
                      setVipInfo({ ...vipInfo, moneyZs: e.target.value });
                    }}
                    unit="元"
                    disabled={true}
                  ></PromoteInput>
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14 }}
                  label="实收金额"
                >
                  <PromoteInput
                    width={165}
                    placeholder="请输入实收金额"
                    value={vipInfo.moneyReal}
                    onChange={(e) => {
                      setVipInfo({ ...vipInfo, moneyReal: e.target.value });
                    }}
                    unit="元"
                  ></PromoteInput>
                </Form.Item>{" "}
              </Col>
            </Row>
          </>
        )}
        <Form.Item
          label="收款方式"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
        >
          <Row>
            {payTypes.map((item) => (
              <Col span={4}>
                {" "}
                <div
                  onClick={() => {
                    setVipInfo({ ...vipInfo, payType: item.text });
                  }}
                  key={item.id}
                  style={{
                    height: "36px",
                    lineHeight: "36px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor:
                      item.text === vipInfo.payType ? "#ff8534" : "#D9D9D9",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "10px 10px",
                    borderRadius: "5px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="icon iconfont"
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                    style={{
                      fontSize: "22px",
                      marginRight: "10px",
                      color: item.color,
                    }}
                  ></span>
                  {item.text}
                </div>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item
              label="收款人"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
            >
              <Input
                placeholder="请输入收款人"
                value={vipInfo.inputUser}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, inputUser: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TitleBarTwo>充值历史</TitleBarTwo>
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        dataSource={historyState.data}
        columns={vipInfo.packageType === "储值卡" ? columns : columnCount}
        bordered
        style={{ marginTop: "20px", marginBottom: "25px" }}
      />
      <PageControl
        toPage={(page, count) => {
          getChargeHistory({ pageNo: page, pageSize: count });
        }}
        style={{ margin: 0, padding: 0 }}
        total={historyState.total}
        pageSize={historyState.pageSize}
        current={historyState.pageNo}
        showSizeChanger
      />
    </Modal>
  );
};
export default VipRecharge;
