import React, { Component, useState, useEffect } from "react";
import { Button, Col, Dropdown, Input, message, Row, Table, Tooltip, Modal, Popconfirm } from "antd";
import SearchBar from "../../components/SearchBar";
import CommonSelect from "../../components/CommonSelect";
import PageControl from "../../components/PageControl";
import axios from "../../core/axios";
import _Api from "../../core/api";
import _ from "underscore";

const Record = ({ curTab, handleEdit }) => {
	const [state, setState] = useState({
		dataSet: [],
		accountList: [],
		detailData: [],
		title: "",
		type: "",
		userid: "",
		total: 0,
		pageNo: "1",
		pageSize: "10",
		editComponent: null,
		modal: null,
	});
	const [accountDate, setAccountData] = useState([]);
	useEffect(() => {
		getAccountList();
	}, []);
	useEffect(() => {
		if (curTab === "record") {
			fetchListData();
		}
	}, [state.type, state.userid, curTab]);
	function getAccountList() {
		_Api.userSelect().then((res) => {
			let data = res.data.content.map((item) => ({
				id: item.id,
				value: item.id,
				name: item.name,
			}));
			setAccountData(data);
		});
	}

	function fetchListData(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1";
		const { title, type, userid } = state;
		const body = {
			page: pageNo,
			count: pageSize,
			title: title,
			send_type: type,
			send_userid: userid,
		};
		_Api
			.wxMsgGet(body)
			.then((res) => {
				let data = res.data.content;
				setState({
					...state,
					dataSet: data,
					total: res.data.allcount,
					pageNo: res.data.curpage,
					pageSize: res.data.pagecount,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function handleComment(record) {
		let editComponent = (
			<Modal
				title={
					<span
						style={{
							padding: "0 20px 0 0",
							display: "inline-block",
							whiteSpace: "pre-wrap",
							fontSize: "16px",
						}}>
						通知详情：{record.msg_title || ""}
					</span>
				}
				className='homework-detail'
				visible={true}
				footer={null}
				onCancel={() => {
					setState({ ...state, editComponent: null });
				}}>
				<div className='t'>
					<div className='date-info'>
						<div className='name'>发送人：{record.from_user}</div>
						<div className='name'>接收者：{record.to_user}</div>
						<div className='date'>发送时间：{record.gmt_creat}</div>
					</div>
				</div>
				<div className='div'>
					<div className='b datas'>
						<div
							dangerouslySetInnerHTML={{
								__html: record.msg_content ? record.msg_content.replace(/class=/g, "classes=") : "",
							}}></div>
					</div>
				</div>
			</Modal>
		);
		setState({ ...state, editComponent });
	}

	function handleEditRecord(record) {
		console.log(record);
		let data = {
			name: record.msg_title,
			content: record.msg_content,
			send_type: record.send_type,
			customer_ids: JSON.parse(record.customer_ids),
			is_confirm: record.is_confirm === "1",
		};
		handleEdit(data);
	}

	function handleDeleteTem(id) {
		_Api.wxMsgDelete({ id }).then((res) => {
			message.success("删除成功");
			fetchListData();
		});
	}
	function parseColumnInformList(text) {
		let HomeworkReadStatus = {
			0: "未读",
			1: "已读",
		};
		let HomeworkSureStatus = {
			0: "未确认",
			1: "已确认",
		};

		let status = "readNum" == text ? HomeworkReadStatus : HomeworkSureStatus;
		return [
			{
				title: "客户姓名",
				width: 100,
				dataIndex: "name",
			},
			{
				title: "电话",
				width: 100,
				dataIndex: "phone",
			},
			{
				title: ("readNum" == text ? "阅读" : "确认") + "状态",
				width: 100,
				dataIndex: "status",
				render: (t, record) => {
					return status["readNum" == text ? record.has_read : record.has_confirm];
				},
			},
		];
	}

	function getRecipientList(record, text) {
		console.log(record, text);
		let editComponent = (
			<Modal
				className='homework-detail'
				title={"通知标题：" + (record.msg_title || "")}
				visible={true}
				footer={null}
				onCancel={() => {
					setState({ ...state, editComponent: null });
				}}>
				<Table
					className='table-no-scrollbar'
					size='middle'
					pagination={false}
					dataSource={record.to_customer_all}
					scroll={{ y: 300 }}
					columns={parseColumnInformList(text)}
					bordered
				/>
			</Modal>
		);
		setState({
			...state,
			editComponent,
		});
	}

	function filterStyle(e) {
		return !!e && _.unescape(e.replace(/<\/?([\w\-]+?)[^>]*>|<!--.+-->/g, "").replace(/&nbsp;/g, ""));
	}

	function reset() {
		setState({
			...state,
			title: "",
			type: "",
			userid: "",
		});
	}
	const sendTypes = [
		{
			id: "shop",
			value: "shop",
			name: "全体客户",
		},
		{
			id: "customer",
			value: "customer",
			name: "部分客户",
		},
	];

	const columns = [
		{
			title: "通知内容",
			dataIndex: "title",
			align: "left",
			render: (text, record) => (
				<div
					style={{
						maxWidth: "300px",
						height: 60,
						lineHeight: "30px",
					}}>
					<a
						style={{
							textAlign: "left",
							display: "block",
							fontWeight: "500",
							color: "#ff7519",
						}}
						onClick={() => handleComment(record)}>
						{record.msg_title}
					</a>
					<div className='description' style={{ color: "#666" }}>
						{filterStyle(record.msg_content) && (
							<div
								style={{
									float: "left",
									textAlign: "left",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "calc(100% - 34px)",
								}}>
								{filterStyle(record.msg_content)}
							</div>
						)}
						{record.msg_content && record.msg_content.includes("<img") && (
							<span
								style={{
									marginLeft: 4,
									float: "left",
									lineHeight: 1,
								}}
								className='icon iconfont'>
								&#xe66e;
							</span>
						)}
					</div>
				</div>
			),
		},
		{
			title: "接收者",
			dataIndex: "recipientName",
			align: "left",
			render: (text, record) => (
				<Tooltip placement='top' title={record.to_user}>
					<span
						className='one-line-text'
						style={{
							maxWidth: "15vw",
						}}>
						{record.to_user}
					</span>
				</Tooltip>
			),
		},
		{
			title: "已读人数",
			dataIndex: "readNum",
			align: "left",
			render: (text, record, index) => (
				<span
					style={
						record.to_user_read_num < record.to_user_all_num
							? {
									color: "red",
									cursor: "pointer",
							  }
							: {
									cursor: "pointer",
							  }
					}
					onClick={() => getRecipientList(record, "readNum")}>
					{record.to_user_read_num}
					&nbsp;/&nbsp;
					{record.to_user_all_num}
				</span>
			),
		},
		{
			title: "确认人数",
			dataIndex: "sureNum",
			align: "left",
			render: (text, record, index) => {
				return record.is_confirm == 1 ? (
					<span
						style={
							record.to_user_confirm_num < record.to_user_all_num
								? {
										color: "red",
										cursor: "pointer",
								  }
								: {
										cursor: "pointer",
								  }
						}
						onClick={() => getRecipientList(record, "sureNum")}>
						{record.to_user_confirm_num}
						&nbsp;/&nbsp;
						{record.to_user_all_num}
					</span>
				) : (
					"-"
				);
			},
		},
		{
			title: "发送人",
			dataIndex: "senderName",
			align: "left",
			render: (text, record, index) => <span>{record.from_user}</span>,
		},
		{
			title: "发送时间",
			dataIndex: "sendTime",
			align: "left",
			render: (text, record, index) => <span>{record.gmt_creat}</span>,
		},
		{
			title: "操作",
			dataIndex: "hasreadNum",
			align: "left",
			width: "10%",
			render: (text, record, index) => (
				<span>
					<span onClick={() => handleEditRecord(record)} className='click-able theme-color'>
						编辑
					</span>
					<span
						style={{
							margin: "0 5px",
							color: "#BFBFBF",
						}}>
						|
					</span>
					<Popconfirm
						placement='left'
						title='删除后无法恢复，是否确认删除？'
						okText='确认'
						cancelText='取消'
						onConfirm={() => {
							handleDeleteTem(record.id);
						}}>
						<span className='click-able theme-color'>删除</span>
					</Popconfirm>
				</span>
			),
		},
	];
	const { dataSet, editComponent, modal, title, type, userid } = state;
	return (
		<div>
			<div className='box-header'>
				<Row type='flex' justify='space-around' align='top'>
					<Col>
						<SearchBar
							searchText={title}
							onChangeText={(text) => {
								setState({ ...state, title: text });
							}}
							onSearch={fetchListData}
							label='搜索通知'
							placeholder='请输入通知的标题'
						/>
						<CommonSelect
							selectValue={type}
							options={sendTypes}
							onSelect={(value) => {
								setState({
									...state,
									type: value,
								});
							}}
							placeholder='请选择类型'
							label='接收者类型'
						/>
						<CommonSelect
							selectValue={userid}
							options={accountDate}
							onSelect={(value) => {
								setState({ ...state, userid: value });
							}}
							placeholder='选择发送人'
							label='发送人'
						/>
					</Col>
					<Col>
						<Tooltip title='清空筛选'>
							<span onClick={reset} id='reset_btn' className='resetBtn iconfont icon'>
								&#xe662;
							</span>
						</Tooltip>
					</Col>
				</Row>
			</div>
			<div className='box-body'>
				<Table rowKey={(record) => record.id} columns={columns} dataSource={dataSet} pagination={false} bordered />
			</div>
			<div className='box-footer'>
				<PageControl
					total={state.total}
					pageSize={state.pageSize}
					current={state.pageNo}
					showSizeChanger
					toPage={(page, count) => {
						fetchListData({ pageNo: page, pageSize: count });
					}}
				/>
			</div>
			{editComponent}
			{modal}
		</div>
	);
};

export default Record;
