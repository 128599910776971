import { useState, useEffect } from "react";
import axios from "../../core/axios";
import {
  Button,
  Input,
  Modal,
  Form,
  Row,
  Col,
  message,
  Radio,
  Avatar,
  Select,
  Spin,
  Tabs,
} from "antd";

import "./FastEditPermissionModal.less";
import "./EditAccountModal.less";
import CropperModal from "../../components/CropperModal";
import { UserOutlined } from "@ant-design/icons";
import _Api from "../../core/api";
const { TabPane } = Tabs;
const EditAccountModal = ({
  editType,
  visible,
  closeModal,
  handleComplete,
  curAccount,
  editPermission,
}) => {
  const [state, setState] = useState({
    id: "",
    name: "",
    head_img: "",
    cropperV: false,
    sex: "",
    isJiedai: "",
    isJishi: "",
    isLogin: "",
    remark: "",
    activeKey: "peizhi",
    functionList: [],
    fastList: [],
    editList: [],
  });
  const [fastList, setFastList] = useState([]);
  useEffect(() => {
    if (visible) {
      initState();
      getUserLimit();
    }
  }, [visible]);
  function getUserLimit() {
    _Api.userLimitArrGet({}).then((res) => {
      console.log(res);
      let list = res.map((item, index) => ({
        id: index,
        title: item.limit_name,
        content: item.limit_intro,
        selected: false,
        powerList: item.limit_item,
      }));
      setFastList(list);
    });
  }
  function initState() {
    if (editType) {
      console.log("修改");
      console.log(curAccount);
      let {
        id,
        head_img,
        name,
        phone,
        sex,
        remark,
        permissions,
        is_jiedai,
        is_jishi,
        is_login,
      } = curAccount;
      if (is_login === "1") {
        permissions = permissions ? JSON.parse(permissions) : [];
        let [functionList, editList] = permissTrans(
          permissions,
          window.userPermissionList,
          editPermission
        );
        setState({
          ...state,
          id,
          head_img,
          name,
          phone,
          sex,
          isJiedai: is_jiedai,
          isJishi: is_jishi,
          isLogin: is_login,
          remark,
          functionList,
          editList,
          activeKey: "quanxian",
        });
      } else {
        const functionList = window.userPermissionList.map((item) => {
          item.selected = false;
          return item;
        });
        const editList = editPermission.map((item) => {
          item.selected = false;
          return item;
        });
        setState({
          ...state,
          id,
          head_img,
          name,
          phone,
          sex,
          isJiedai: is_jiedai,
          isJishi: is_jishi,
          isLogin: is_login,
          remark,
          functionList,
          editList,
          activeKey: "peizhi",
        });
      }
    } else {
      console.log("新增");
      console.log(window.userPermissionList, editPermission);
      const list = window.userPermissionList.map((item) => {
        item.selected = false;
        return item;
      });
      const arr = editPermission.map((item) => {
        item.selected = false;
        return item;
      });
      setState({
        ...state,
        functionList: list,
        editList: arr,
        activeKey: "peizhi",
      });
    }
  }

  function fastSelect(id) {
    let data = fastList,
      _fastList;
    let selected = data.filter((item) => item.selected);
    if (selected.length == 0 || selected[0].id == id) {
      _fastList = data.map((item) => {
        if (item.id === id) {
          item.selected = !item.selected;
        }
        return item;
      });
    }
    if (selected.length == 1 && selected[0].id != id) {
      _fastList = data.map((item) => {
        item.selected = false;
        if (item.id === id) {
          item.selected = true;
        }
        return item;
      });
    }
    let [functionList, editList] = handleOneKeyOK(_fastList);
    setState({ ...state, functionList, editList });
    setFastList(_fastList);
  }
  // 编辑权限选择处理
  function editSelect(id) {
    let data = state.editList;
    let editList = data.map((item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      }
      return item;
    });
    setState({ ...state, editList });
  }
  // 功能权限选择处理
  function functionSelect(item) {
    let data = state.functionList;
    let statu = !item.selected;
    if (item.parent === "0") {
      let functionList = data.map((Item) => {
        if (Item.id === item.id || Item.parent === item.id) {
          Item.selected = statu;
        }
        return Item;
      });
      setState({ ...state, functionList });
    } else {
      if (statu) {
        let functionList = data.map((Item) => {
          if (Item.id === item.parent || Item.id === item.id) {
            Item.selected = statu;
          }
          return Item;
        });
        setState({ ...state, functionList });
      } else {
        let selectArr = [];
        let List = data.map((Item) => {
          if (Item.id === item.id) {
            Item.selected = statu;
          }
          if (Item.parent === item.parent && Item.id !== item.id) {
            selectArr.unshift(Item.selected);
          }
          return Item;
        });
        let functionList = [];
        if (selectArr.indexOf(true) === -1) {
          functionList = List.map((Item) => {
            if (Item.id === item.parent) {
              Item.selected = statu;
            }
            return Item;
          });
        } else {
          functionList = List;
        }
        setState({ ...state, functionList });
      }
    }
  }
  //处理快速选择选项
  function handleOneKeyOK(_fastList) {
    let arr = [];
    const { functionList, editList } = state;
    _fastList.forEach((item) => {
      if (item.selected) {
        arr = [...arr, ...item.powerList];
      }
    });
    let data = Array.from(new Set(arr));
    console.log(data);
    let _functionList = functionList.map((item) => {
      item.selected = data.indexOf(item.id) !== -1;
      return item;
    });
    let _editList = editList.map((item) => {
      item.selected = data.indexOf(item.id) !== -1;
      return item;
    });
    return [_functionList, _editList];
  }
  //处理权限列表
  function permissTrans(permiArr, _functionList, _editList) {
    let functionList = _functionList || state.functionList,
      editList = _editList || state.editList;

    editList = editList.map((item) => {
      if (permiArr.indexOf(item.id) !== -1) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    functionList = functionList.map((item) => {
      if (permiArr.indexOf(item.id) !== -1) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    return [functionList, editList];
  }

  //提交信息
  function handleOk() {
    let {
      editList,
      functionList,
      name,
      phone,
      remark,
      sex,
      isJiedai,
      isJishi,
      isLogin,
      head_img,
      id,
    } = state;
    let permissionsArr = [];
    editList.map((item) => item.selected && permissionsArr.unshift(item.id));
    functionList.map(
      (item) => item.selected && permissionsArr.unshift(item.id)
    );
    let body = {
      id,
      head_img,
      name,
      phone,
      remark,
      sex,
      is_jiedai: isJiedai,
      is_jishi: isJishi,
      is_login: isLogin,
      permissions: permissionsArr,
    };
    if (isLogin === "1" && permissionsArr.length === 0) {
      return message.warning("请选择员工权限");
    }
    _Api.userInsert(body).then((res) => {
      message.success(res.msg);
      emptyState();
      handleComplete();
    });
  }
  // 初始化数据
  function emptyState() {
    let _fastList = fastList.map((item) => {
      item.selected = false;
      return item;
    });
    setState({
      ...state,
      id: "",
      head_img: "",
      name: "",
      phone: "",
      sex: "",
      isJiedai: "",
      isJishi: "",
      isLogin: "",
      remark: "",
      activeKey: "peizhi",
      functionList: [],
      editList: [],
    });
    setFastList(_fastList);
    console.log("清理数据");
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  const typeOptions = [
    { value: "1", label: "是" },
    { value: "0", label: "否" },
  ];
  const isType = [
    { value: "1", label: "开通" },
    { value: "0", label: "关闭" },
  ];
  console.log(state);
  return (
    <Modal
      title={editType ? "编辑员工" : "新增员工"}
      visible={visible}
      className="add-account"
      onCancel={() => {
        emptyState();
        closeModal();
      }}
      onOk={handleOk}
      width={860}
    >
      <div>
        <Form id="editForm" {...layout}>
          <div style={{ width: "400px" }}>
            <Form.Item label="个人头像">
              <span id="avatar_edit">
                <span className="up-load-content">
                  <span
                    className="upload"
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                    }}
                  >
                    <Avatar
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      src={state.head_img}
                      size="large"
                      icon={<UserOutlined style={{ fontSize: "24px" }} />}
                    />
                  </span>
                  <div
                    className="edit"
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      setState({ ...state, cropperV: true });
                    }}
                  >
                    <span className="icon iconfont">&#xe60c;</span>
                  </div>
                </span>
              </span>
              <CropperModal
                visible={state.cropperV}
                type="user"
                src={state.head_img}
                onHandleOk={() => {
                  setState({ ...state, cropperV: false });
                }}
                onHandleCancel={() => {
                  setState({ ...state, cropperV: false });
                }}
                onHandleSuccess={(url) => {
                  setState({ ...state, head_img: url, cropperV: false });
                }}
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "400px" }}>
              <Form.Item
                label={
                  <p>
                    <span style={{ color: "#EB3628" }}>*</span>姓名
                  </p>
                }
              >
                <Input
                  placeholder="必填"
                  value={state.name}
                  onChange={(e) => {
                    setState({ ...state, name: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <p>
                    <span style={{ color: "#EB3628" }}>*</span>手机号
                  </p>
                }
              >
                <Input
                  placeholder="必填"
                  value={state.phone}
                  onChange={(e) => {
                    setState({ ...state, phone: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item label="接待人">
                <Radio.Group
                  options={typeOptions}
                  optionType="button"
                  value={state.isJiedai}
                  onChange={(e) => {
                    setState({
                      ...state,
                      isJiedai: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="登录权限">
                <Radio.Group
                  options={isType}
                  optionType="button"
                  value={state.isLogin}
                  onChange={(e) => {
                    setState({
                      ...state,
                      isLogin: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div style={{ width: "400px" }}>
              <Form.Item label="性别">
                <Radio.Group
                  value={state.sex}
                  onChange={(e) => {
                    setState({ ...state, sex: e.target.value });
                  }}
                >
                  <Radio value="未知">未知</Radio>
                  <Radio value="男">男</Radio>
                  <Radio value="女">女</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="备注">
                <Input
                  placeholder="选填"
                  maxLength={30}
                  value={state.remark}
                  onChange={(e) =>
                    setState({ ...state, remark: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="维修技师">
                <Radio.Group
                  options={typeOptions}
                  optionType="button"
                  value={state.isJishi}
                  onChange={(e) => {
                    setState({ ...state, isJishi: e.target.value });
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      {state.isLogin === "1" && (
        <div className="auths">
          <Tabs
            animated={false}
            activeKey={state.activeKey}
            onChange={(tab) => {
              setState({ ...state, activeKey: tab });
            }}
          >
            <TabPane tab="一键配置" key="peizhi">
              <div className="fast-edit-permission">
                <Row className="list-wrap" style={{ margin: "0 -10px" }}>
                  {fastList.map((item) => (
                    <Col span={8} style={{ padding: "0 10px" }} key={item.id}>
                      <div
                        className={
                          item.selected
                            ? "identity-item active"
                            : "identity-item"
                        }
                        onClick={() => fastSelect(item.id)}
                      >
                        <div className="title">{item.title}</div>
                        <div className="content">{item.content}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </TabPane>
            <TabPane tab="详细权限" key="quanxian">
              <h1>查看权限</h1>
              <ul className="clearfix">
                {state.functionList.map((item) =>
                  item.parent === "0" ? (
                    <li
                      className={
                        item.isparent
                          ? "level-0 tree-parent"
                          : "level-0 tree-children"
                      }
                      key={item.id}
                    >
                      {item.selected ? (
                        <span
                          className="icon iconfont checkbox"
                          onClick={() => functionSelect(item)}
                        >
                          &#xe679;
                        </span>
                      ) : (
                        <span
                          className="icon iconfont checkbox"
                          onClick={() => functionSelect(item)}
                        >
                          &#xe720;
                        </span>
                      )}
                      <span className="auth-name">{item.name}</span>
                      <ul>
                        {state.functionList.map((Item) =>
                          Item.parent === item.id ? (
                            <li
                              className={
                                item.isparent
                                  ? "level-1 tree-parent"
                                  : "level-1 tree-children"
                              }
                              key={Item.id}
                            >
                              {Item.selected ? (
                                <span
                                  className="icon iconfont checkbox"
                                  onClick={() => functionSelect(Item)}
                                >
                                  &#xe679;
                                </span>
                              ) : (
                                <span
                                  className="icon iconfont checkbox"
                                  onClick={() => functionSelect(Item)}
                                >
                                  &#xe720;
                                </span>
                              )}
                              <span className="auth-name">{Item.name}</span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                  ) : null
                )}
              </ul>
              <h1>管理权限</h1>
              <ul>
                {state.editList &&
                  state.editList.map(
                    (item) =>
                      item.parent == 0 && (
                        <li style={{ marginBottom: "10px" }} key={item.id}>
                          <p
                            style={{
                              height: "20px",
                              lineHeight: "20px",
                              fontSize: "16px",
                              marginBottom: "5px",
                            }}
                          >
                            {item.content}
                          </p>
                          <ul className="clearfix">
                            {state.editList.map(
                              (Item) =>
                                Item.parent === item.id && (
                                  <li
                                    style={{
                                      float: "left",
                                      width: "200px",
                                      paddingLeft: "20px",
                                    }}
                                    key={Item.id}
                                  >
                                    {Item.selected ? (
                                      <span
                                        className="icon iconfont checkbox"
                                        onClick={() => editSelect(Item.id)}
                                      >
                                        &#xe679;
                                      </span>
                                    ) : (
                                      <span
                                        className="icon iconfont checkbox"
                                        onClick={() => editSelect(Item.id)}
                                      >
                                        &#xe720;
                                      </span>
                                    )}
                                    {Item.content}
                                  </li>
                                )
                            )}
                          </ul>
                        </li>
                      )
                  )}
              </ul>
            </TabPane>
          </Tabs>
        </div>
      )}
    </Modal>
  );
};

export default EditAccountModal;
