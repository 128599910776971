import React from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Form,
  Table,
  Empty,
  Divider,
} from "antd";
import mathFunc from "../../core/mathfunc";
export default ({ openReceive, tableData, openDetail }) => {
  const dataSource = [
    {
      key: "1",
      id: "1",
      index: "001",
      brand: "京A88888",
      cartype: "奥迪",
      linkman: "张三",
      phone: "123321678",
      date: "2021-10-01",
      received: "200",
      num: "500",
    },
  ];

  const columns = [
    {
      title: "序号",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "单号",
      dataIndex: "bianhao",
    },
    {
      title: "车牌号",
      dataIndex: "car_id",
    },
    {
      title: "车型",
      dataIndex: "car_type",
    },
    {
      title: "联系人",
      dataIndex: "car_linkman",
      key: "linkman",
    },
    {
      title: "手机号码",
      dataIndex: "car_phone",
    },
    {
      title: "结算时间",
      dataIndex: "gmt_modify",
    },
    {
      title: "已收金额",
      dataIndex: "money_pay",
      key: "received",
      align: "center",
    //   render: (text) => <Button type='link'>{text}</Button>,
      render: (text) => text || 0,
    },
    {
      title: "欠款金额",
      dataIndex: "money_all",
      key: "num",
      align: "center",
      render: (text, record) =>
        mathFunc.returnFloat(
          parseFloat(text) - (parseFloat(record.money_pay) || 0)
        ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => openReceive(record)}>
            收款
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={() => openDetail({ slipType: "credit", data: record })}
          >
            查看结算单
          </Button>
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={false}
      dataSource={tableData.list || []}
      columns={columns}
      bordered
      style={{ marginTop: "20px", marginBottom: "40px" }}
    />
  );
};
