import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  Form,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import CarTypeSelect from "../CarTypeSelect";
import Api from "../../core/api";
import _Api from "../../core/api";
import WxSelectModal from "./WXSelect";
import "./CarEdit.less";

const { TextArea } = Input;

const CarEditModal = ({
  visible,
  closeModal,
  editType,
  curCarId,
  setCurCar,
  wxEdit,
}) => {
  const [carInfo, setCarInfo] = useState({
    id: "",
    carId: "",
    carType: "",
    brandId: "",
    dipanId: "",
    fdjId: "",
    color: "",
    customer: "",
    linkman: "",
    phone: "",
    dateNj: "",
    dateJqx: "",
    dateSyx: "",
    maintainDate: "",
    maintainMileage: "",
    curMileage: "",
    maintainContent: "",
    wxOpenid: "",
    filter: false,
  });
  const [wxModal, setWxModal] = useState({
    visible: false,
    wxName: "",
  });
  useEffect(() => {
    initCarInfo();
  }, [visible]);

  function initCarInfo() {
    if (editType || wxEdit) {
      let data = { keyword: curCarId };
      _Api.carGet(data).then((res) => {
        let data = res.data.content[0] || {};
        console.log(data);
        let {
          id,
          car_type,
          brand_id,
          car_id,
          dipan_id,
          fdj_id,
          customer,
          linkman,
          wx_openid,
          color,
          phone,
          date_nj,
          date_jqx,
          date_syx,
          cur_mileage,
          maintain_mileage,
          maintain_date,
          maintain_content,
        } = data;
        setCarInfo({
          id,
          carId: car_id,
          carType: car_type,
          brandId: brand_id,
          dipanId: dipan_id,
          fdjId: fdj_id,
          color,
          customer,
          linkman,
          phone,
          dateNj: date_nj,
          dateJqx: date_jqx,
          dateSyx: date_syx,
          curMileage: cur_mileage,
          maintainDate: maintain_date,
          maintainMileage: maintain_mileage,
          maintainContent: maintain_content,
          wxOpenid: wx_openid,
        });
        wx_openid && getWxName(wx_openid);
      });
    } else {
      setCarInfo({
        id: "",
        carId: "",
        carType: "",
        dipanId: "",
        fdjId: "",
        brandId: "",
        color: "",
        customer: "",
        linkman: "",
        phone: "",
        dateNj: "",
        dateJqx: "",
        dateSyx: "",
        maintainDate: "",
        maintainMileage: "",
        curMileage: "",
        maintainContent: "",
        wxOpenid: "",
      });
    }
  }
  function getWxName(openid) {
    _Api.wxUserSelect({ openid }).then((res) => {
        setWxModal({ ...wxModal, wxName: res.data.content[0]?.name });
    });
  }
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: "right",
  };
  const FullDatePicker = (props) => (
    <div
      style={{
        width: "100%",
        border: "#E8E8E8 1px solid",
        borderRadius: "5px",
      }}
    >
      <DatePicker bordered={false} style={{ width: "100%" }} {...props} />
    </div>
  );
  function handleSave() {
    // console.log(moment(carInfo.dateSyx).format('YYYY-MM-DD'))
    const {
      id,
      carId,
      brandId,
      carType,
      dipanId,
      fdjId,
      color,
      customer,
      linkman,
      phone,
      dateNj,
      dateJqx,
      dateSyx,
      maintainDate,
      maintainMileage,
      curMileage,
      maintainContent,
      wxOpenid,
    } = carInfo;
    let data = {
      car_type: carType,
      brand_id: brandId,
      car_id: carId,
      dipan_id: dipanId,
      fdj_id: fdjId,
      customer,
      linkman,
      wx_openid: wxOpenid,
      color,
      phone,
      date_nj: dateNj,
      date_jqx: dateJqx,
      date_syx: dateSyx,
      cur_mileage: curMileage,
      maintain_mileage: maintainMileage,
      maintain_date: maintainDate,
      maintain_content: maintainContent,
    };
    if (editType) {
      data = { ...data, id };
    }
    Api.carInsert(data).then((res) => {
      message.success(res.msg);
      setWxModal({ ...wxModal, wxName: "" });
      setCurCar({ carId, carType, linkman, phone });
      closeModal();
    });
  }
  function toggleFilter() {
    setCarInfo({ ...carInfo, filter: !carInfo.filter });
  }
  return (
    <>
      <Modal
        title={editType ? "车辆编辑" : <span style={{fontSize:16, fontWeight:700}}>车辆建档</span>}
        footer={null}
        width={1445}
        visible={visible}
        onCancel={closeModal}
        className="car-edit-box"
      >
        <Form {...layout}>
          <Row align="middle" style={{alignContent: "center"}}>
            <span
              className="icon iconfont"
              style={{ color: "#F09F74", fontSize: 20 }}
            >
              &#xe623;
            </span>
            <span style={{ fontSize: 16, marginLeft: 5, color: "#000" }}>
              车辆信息
            </span>
          </Row>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            style={{ marginTop: 10 }}
          >
            <Col span={6}>
              <Form.Item
                label={
                  <p>
                    <span style={{ color: "#EB3628" }}>*</span>车牌号
                  </p>
                }
              >
                <Input
                  disabled={editType}
                  value={carInfo.carId}
                  onChange={(e) => {
                    setCarInfo({ ...carInfo, carId: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item label="手机号">
                <Input
                  value={carInfo.phone}
                  onChange={(e) => {
                    setCarInfo({ ...carInfo, phone: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              {visible && (
                <Form.Item label="车型">
                  <CarTypeSelect
                    value={carInfo.carType}
                    onSelect={(data, id) => {
                      console.log("onSelect", data);
                      console.log("onSelect", id);
                      if (id) {
                        setCarInfo({ ...carInfo, carType: data, brandId: id });
                      } else {
                        setCarInfo({ ...carInfo, carType: data });
                      }
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item label="联系人">
                <Input
                  value={carInfo.linkman}
                  onChange={(e) => {
                    setCarInfo({ ...carInfo, linkman: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="微信客户">
                <Row type="flex" align="middle" justify="space-between">
                  <Col span={wxEdit ? 24 : 13}>
                    <Input
                      value={wxModal.wxName}
                      onChange={(e) => {
                        setCarInfo({ ...carInfo, wxOpenid: e.target.value });
                      }}
                      disabled
                    />
                  </Col>

                  {!wxEdit && (
                    <>
                      <Button
                        type="primary"
                        size="small"
                        onClick={() =>
                          setWxModal({ ...wxModal, visible: true })
                        }
                      >
                        选择
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setCarInfo({ ...carInfo, wxOpenid: "" });
                          setWxModal({ ...wxModal, wxName: "" });
                        }}
                      >
                        清空
                      </Button>
                    </>
                  )}
                </Row>
              </Form.Item>
              <Form.Item label="客户">
                <Input
                  value={carInfo.customer}
                  onChange={(e) => {
                    setCarInfo({ ...carInfo, customer: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {carInfo.filter && (
            <>
              {" "}
              <Row
                style={{
                  marginBottom: 16,
                  borderBottom: "#F0F0F0 1px solid",
                }}
              >
                <Col span={6}>
                  <Form.Item label="底盘号">
                    <Input
                      value={carInfo.dipanId}
                      onChange={(e) => {
                        setCarInfo({ ...carInfo, dipanId: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="发动机号">
                    <Input
                      value={carInfo.fdjId}
                      onChange={(e) => {
                        setCarInfo({ ...carInfo, fdjId: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="车身颜色">
                    <Input
                      value={carInfo.color}
                      onChange={(e) => {
                        setCarInfo({ ...carInfo, color: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" style={{alignContent: "center"}}>
                <span
                  className="icon iconfont"
                  style={{ color: "#F09F74", fontSize: 18 }}
                >
                  &#xe7d1;
                </span>
                <span
                  style={{ fontSize: 16, marginLeft: 7, color: "#000" }}
                >
                  提醒信息
                </span>
              </Row>
              <Row
                type="flex"
                align="middle"
                // justify="space-between"
                style={{ marginTop: 12 }}
              >
                <Col span={6}>
                  <Form.Item label="年检日期">
                    <FullDatePicker
                      value={carInfo.dateNj && moment(carInfo.dateNj)}
                      onChange={(mome, str) => {
                        setCarInfo({ ...carInfo, dateNj: str });
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="建议保养日期">
                    <FullDatePicker
                      value={
                        carInfo.maintainDate &&
                        moment(carInfo.maintainDate, "YYYY-MM-DD")
                      }
                      onChange={(mome, str) => {
                        setCarInfo({ ...carInfo, maintainDate: str });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="最新里程">
                    <Input
                      value={carInfo.curMileage}
                      onChange={(e) => {
                        setCarInfo({ ...carInfo, curMileage: e.target.value });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="交强险到期日期">
                    <FullDatePicker
                      value={
                        carInfo.dateJqx && moment(carInfo.dateJqx, "YYYY-MM-DD")
                      }
                      onChange={(mome, str) => {
                        setCarInfo({ ...carInfo, dateJqx: str });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="建议保养里程">
                    <Input
                      value={carInfo.maintainMileage}
                      onChange={(e) => {
                        setCarInfo({
                          ...carInfo,
                          maintainMileage: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="商业险到期日期">
                    <FullDatePicker
                      value={
                        carInfo.dateSyx && moment(carInfo.dateSyx, "YYYY-MM-DD")
                      }
                      onChange={(mome, str) => {
                        setCarInfo({ ...carInfo, dateSyx: str });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="建议保养内容"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                    // style={{ paddingLeft: "10px" }}
                  >
                    <TextArea
                      rows={3}
                      style={{ width: "100%" }}
                      value={carInfo.maintainContent}
                      onChange={(e) => {
                        setCarInfo({
                          ...carInfo,
                          maintainContent: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row justify="center" style={{ marginBottom: "16px" }}>
            <Col span={2}>
              <span
                className="text-sub "
                style={{ width: 105 }}
                onClick={toggleFilter}
                style={{ cursor: "pointer" }}
              >
                {carInfo.filter ? (
                  <span style={{ color: "#999" }}>
                    收起
                    <span
                      style={{ color: "#999" }}
                      className="iconfont icon ml5"
                    >
                      &#xe663;
                    </span>
                  </span>
                ) : (
                  <span style={{ color: "#999" }}>
                    完善更多信息
                    <span
                      style={{ color: "#999" }}
                      className="iconfont icon ml5"
                    >
                      &#xe665;
                    </span>
                  </span>
                )}
              </span>
            </Col>
          </Row>

          <Row
            style={{ borderTop: "#F0F0F0 1px solid", paddingTop: "20px" }}
            justify="end"
          >
            <div
              onClick={() => {
                handleSave();
              }}
              className="linear-button save-button"
              style={{
                width: "100px",
                height: "40px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
              }}
            >
              保存
            </div>
          </Row>
        </Form>
      </Modal>
      <WxSelectModal
        visible={wxModal.visible}
        closeModal={() => setWxModal({ ...wxModal, visible: false })}
        handleComplete={(data) => {
          let { name, wx_openid } = data;
          setCarInfo({ ...carInfo, wxOpenid: wx_openid });
          setWxModal({ visible: false, wxName: name });
        }}
      />
    </>
  );
};

export default CarEditModal;
