import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Antd from "antd";
import _ from "underscore";
import { createHashHistory } from "history";
import Permission from "../../core/permission";
import "./Menu.less";

const { SubMenu } = Antd.Menu;
const hashHistory = createHashHistory();

export class Menu extends Component {
  state = {
    selectedKey: "home",
    openKeys: [],
    activeMenu: "home",
    menuType: 1,
    showPermissionList: Permission.getUserLeftPermissionTree(),

    menu: [],
    current: [],
  };

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    const { showPermissionList } = this.state;
    let data = [...showPermissionList];
    data.push({
      name: "中心首页",
      link: "home",
      parent: "home",
    });
    data.push({
      name: "中心首页",
      link: "user_info",
      parent: "home",
    });
    data.push({
      name: "中心首页",
      link: "",
      parent: "home",
    });
    data.push({
      name: "添加工资结算",
      link: "add_salary_cal",
      parent: "home",
    });
    data.push({
      name: "设置老师工资",
      link: "salary_list",
      parent: "home",
    });
    data.push({
      name: "设置老师工资",
      link: "salary_teacher_info",
      parent: "home",
    });
    // let current = "";
    const current = location.pathname.split("/")[1];
    // const firstIndex = location.pathname.indexOf("/");
    // const lastIndex = location.pathname.indexOf("?");
    // if (lastIndex === -1) {
    //   current = location.pathname.split("/")[1];
    // } else {
    //   current = location.pathname.slice(firstIndex + 1, lastIndex);
    // }

    // console.log(location.pathname,firstIndex,lastIndex,aa);
    // console.log(current);
    if (current === "user_info" || current === "") {
      this.setState({ selectedKey: "home" });
    } else {
      this.setState({ selectedKey: current });
    }
    let activeKeyList = data.filter((item) => item.link == current);
    console.log(activeKeyList);
    this.setState({
      openKeys: [activeKeyList[0].parent],
      activeMenu: activeKeyList[0].parent,
    });
  }

  componentDidMount() {
    const { location } = this.props;
    const { showPermissionList } = this.state;
    let data = [...showPermissionList];
    data.push({
      name: "中心首页",
      link: "home",
      parent: "home",
    });
    data.push({
      name: "中心首页",
      link: "user_info",
      parent: "home",
    });
    data.push({
      name: "中心首页",
      link: "",
      parent: "home",
    });
    data.push({
      name: "添加工资结算",
      link: "add_salary_cal",
      parent: "home",
    });
    data.push({
      name: "设置老师工资",
      link: "salary_list",
      parent: "home",
    });
    data.push({
      name: "设置老师工资",
      link: "salary_teacher_info",
      parent: "home",
    });

    const current = location.pathname.split("/")[1];
    if (current === "user_info" || current === "") {
      this.setState({ selectedKey: "home" });
    } else {
      this.setState({ selectedKey: current });
    }
    let activeKeyList = data.filter((item) => item.link == current);
    this.setState({
      openKeys: [activeKeyList[0].parent],
      activeMenu: activeKeyList[0].parent,
    });
  }

  onOpenChange = (openKeys) => {
    this.setState({
      openKeys: _.last(openKeys, 1),
    });
  };

  render() {
    const { showPermissionList } = this.state;
    const { menuFold } = this.props;
    const { match } = this.props;
    return (
      <div
        id="left-container"
        className={
          !menuFold
            ? "left-container"
            : "left-container left-container-vertical"
        }
      >
        <div className="left">
          <div className="nav">
            <Antd.Menu
              openKeys={this.state.openKeys}
              selectedKeys={[this.state.selectedKey]}
              onOpenChange={this.onOpenChange}
              mode={menuFold === true ? "vertical" : "inline"}
              inlineCollapsed={menuFold}
            >
              <Antd.Menu.Item
                key="home"
                className={
                  "home" == this.state.activeMenu
                    ? " activeMenu fistLevel homeBar"
                    : "fistLevel homeBar"
                }
              >
                <span
                  className="home-title"
                  onClick={() => {
                    this.setState({
                      openKeys: [],
                    });
                    setTimeout(() => {
                      this.setState({
                        activeMenu: "home",
                        selectedKey: "home",
                      });
                      window.XZHistory.push("/home");
                    }, 300);
                  }}
                >
                  <span className="title">
                    <span className="iconfont icon">&#xe669;</span>
                    {menuFold ? null : (
                      <span className="menu-name" id="menu_home">
                        中心首页
                      </span>
                    )}
                  </span>
                </span>
              </Antd.Menu.Item>
              {showPermissionList.map((menu, index) => {
                return menu.parent == 0 ? (
                  <SubMenu
                    className={
                      this.state.activeMenu == menu.code
                        ? " activeMenu fistLevel "
                        : "fistLevel "
                    }
                    key={menu.code}
                    title={
                      <span>
                        <span className="title">
                          <span
                            className="iconfont icon"
                            dangerouslySetInnerHTML={{ __html: menu.icon }}
                          ></span>
                          {menuFold ? null : (
                            <span className="menu-name">{menu.name}</span>
                          )}
                        </span>
                      </span>
                    }
                  >
                    {showPermissionList.map((item, index) =>
                      menu.code == item.parent ? (
                        <Antd.Menu.Item
                          key={item.link}
                          onClick={() => {
                            window.XZHistory.push(`${match.url}${item.link}`);
                            this.setState({
                              selectedKey: item.link,
                              activeMenu: menu.code,
                            });
                          }}
                        >
                          <div>
                            <span className="listType"></span>
                            <span className="name" id={item.link}>
                              {item.name}
                            </span>
                          </div>
                        </Antd.Menu.Item>
                      ) : null
                    )}
                  </SubMenu>
                ) : null;
              })}
            </Antd.Menu>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
