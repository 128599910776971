window.Cache = {
    data: {},
    get(key) {
        return this.data[key];
    },
    set(key, value) {
        this.data[key] = value;
    },
    clear() {
        this.data = {};
    },
    remove(key) {
        delete this.data[key];
    },
};
