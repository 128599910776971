import { useState, useEffect } from "react";
import { Form, Row, Col, Switch, Input, message } from "antd";
import "./Brand.less";
import ImgPicker from "./ImgPicker";
import ColorPicker from "./ColorPicker";
import axios from "../../core/axios";
import _Api from "../../core/api";
import User from "../../core/user";
import SaleEditModal from "../../components/modals/SaleEdit";

const Brand = ({}) => {
	const [state, setState] = useState({
		domain: "",
		domain_state: false,
		domain_key: "close",
		logo: "",
		img_2: "",
		bg_color_2: "",
		bg_color_3: "",
		bg_color_1: "",
		font_color: "",
		addr: "",
		addr_state: false,
		tel: "",
		tel_state: false,
	});
	useEffect(() => {
		getLoginSet();
	}, []);
	function getLoginSet() {
		_Api.getLoginSet().then((res) => {
			if (!res.token_info.user_shop) {
				User.logout();
			} else {
				let web_login_set = JSON.parse(res.token_info.user_shop.cur_user.shop_info.web_login_set);
				if (web_login_set) {
					setState({
						...state,
						logo: web_login_set.logo || "",
						img_2: web_login_set.img_2,
						bg_color_2: web_login_set.bg_color_2,
						bg_color_3: web_login_set.bg_color_3,
						bg_color_1: web_login_set.bg_color_1,
						font_color: web_login_set.font_color,
						addr: web_login_set.addr,
						tel: web_login_set.tel,
						domain: res.token_info.user_shop.cur_user.shop_info.web_domain,
						domain_key: res.token_info.user_shop.cur_user.shop_info.web_self == 0 ? "close" : "open",
					});
				} else {
					setState({
						...state,
						domain: res.token_info.user_shop.cur_user.shop_info.web_domain,
						domain_key: res.token_info.user_shop.cur_user.shop_info.web_self == 0 ? "close" : "open",
					});
				}
			}
		});
	}
	function changeSwitch(checked) {
		console.log(checked);
		let body = { domain_key: checked ? "open" : "close" };
		_Api.webOpenClose(body).then((res) => {
			message.success(res.msg);
			getLoginSet();
		});
	}
	function handleDomainSet() {
		const { domain, domain_state } = state;
		_Api.webDomainSet({ domain }).then((res) => {
			message.success(res.msg);
			setState({ ...state, domain_state: !domain_state });
		});
	}

	function save(type, value) {
		const { logo, img_2, bg_color_2, bg_color_3, bg_color_1, font_color, addr, addr_state, tel, tel_state } = state;

		let body = {
			logo: logo,
			img_2: img_2,
			bg_color_2: bg_color_2,
			bg_color_3: bg_color_3,
			bg_color_1: bg_color_1,
			font_color: font_color,
			addr: addr,
			tel: tel,
		};
		if (type != "tel" && type != "addr") {
			body = { ...body, [type]: value };
		}
		_Api.setLoginSet(body).then((res) => {
			message.success(res.msg);
			if (type === "addr" || type === "tel") {
				setState({
					...state,
					addr_state: type == "addr" ? !addr_state : addr_state,
					tel_state: type == "tel" ? !tel_state : tel_state,
				});
			} else {
				setState({
					...state,
					[type]: value,
				});
			}
		});
	}
	const {
		domain,
		domain_state,
		domain_key,
		logo,
		img_2,
		bg_color_2,
		bg_color_3,
		bg_color_1,
		font_color,
		addr,
		addr_state,
		tel,
		tel_state,
	} = state;
	return (
		<>
			<div className='page brandManage'>
				<div className='page-content'>
					<section className='content-header'>
						<h1>品牌中心</h1>
					</section>
					<div className='box'>
						<div>
							<Row className='back-row'>
								<div>
									<span className='label'>域名：</span>

									{domain_state ? (
										<>
											https://
											<Input
												value={domain}
												onChange={(e) => {
													setState({ ...state, domain: e.target.value });
												}}
												style={{ width: 150 }}
											/>
											.sanfeng9.com
										</>
									) : (
										<a target='_blank' href={`https://${domain}.sanfeng9.com`} className='domain-link'>
											https://{domain}.sanfeng9.com
										</a>
									)}

									{domain_state ? (
										<span
											style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
											onClick={handleDomainSet}
											className='icon iconfont edit'
											title='保存'>
											&#xe680;
										</span>
									) : (
										<span
											style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
											onClick={() => {
												setState({ ...state, domain_state: !domain_state });
											}}
											className='icon iconfont edit'
											title='修改'>
											&#xe60c;
										</span>
									)}
								</div>
							</Row>
							<Row className='back-row'>
								<div>
									<span className='label'>自定义登录页：</span>
									<Switch onChange={changeSwitch} checked={domain_key == "open" ? true : false} />
									<span style={{ marginLeft: "20px", color: "#999" }}>
										【开启后将可以自定义以下设置,关闭后将使用系统默认设置】
									</span>
								</div>
							</Row>
						</div>
						{domain_key == "open" && (
							<>
								<Row className='no-back-row'>
									<div>
										<span className='label'>LOGO设置：</span>
										<ImgPicker
											flag='logo'
											imgdata={logo}
											type='shop_logo'
											SetImg={(value) => {
												save("logo", value);
											}}
										/>
										<span className='description'>图片尺寸建议是142*36像素，请上传小于1M的JPG/PNG图片</span>
									</div>
								</Row>
								<Row className='no-back-row'>
									<div>
										<span className='label'>背景图设置：</span>
										<ImgPicker
											flag='viewBackground'
											imgdata={img_2}
											SetImg={(value) => {
												save("img_2", value);
											}}
										/>
										<span className='description'>图片为居中显示,请上传小于1M的JPG/PNG图片</span>
									</div>
								</Row>
								<Row className='no-back-row'>
									<div>
										<span className='label'>颜色设置：</span>
										<div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "40px" }}>
											<Row align='middle'>
												主题颜色背景&nbsp;&nbsp;
												<ColorPicker
													colorValue={bg_color_2}
													setColor={(color) => {
														save("bg_color_2", color);
													}}
												/>
											</Row>
											<Row align='middle'>
												底部背景颜色&nbsp;&nbsp;
												<ColorPicker
													colorValue={bg_color_3}
													setColor={(color) => {
														save("bg_color_3", color);
													}}
												/>
											</Row>
										</div>
										<div style={{ display: "inline-block", verticalAlign: "middle" }}>
											<Row align='middle'>
												顶部背景颜色&nbsp;&nbsp;
												<ColorPicker
													colorValue={bg_color_1}
													setColor={(color) => {
														save("bg_color_1", color);
													}}
												/>
											</Row>
											<Row align='middle'>
												底部文字颜色&nbsp;&nbsp;
												<ColorPicker
													colorValue={font_color}
													setColor={(color) => {
														save("font_color", color);
													}}
												/>
											</Row>
										</div>
									</div>
								</Row>
								<Row className='no-back-row'>
									<div>
										<span className='label'>文字设置：</span>
										<div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "40px" }}>
											<Row>
												左下角文字：
												{addr_state ? (
													<Input
														value={addr}
														onChange={(e) => {
															setState({ ...state, addr: e.target.value });
														}}
														style={{ width: 300, marginLeft: 20 }}
													/>
												) : (
													<span style={{ marginLeft: 20 }}>{addr}</span>
												)}
												{addr_state ? (
													<span
														style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
														onClick={() => save("addr")}
														className='icon iconfont edit'
														title='保存'>
														&#xe680;
													</span>
												) : (
													<span
														style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
														onClick={() => {
															setState({ ...state, addr_state: !addr_state });
														}}
														className='icon iconfont edit'
														title='修改'>
														&#xe60c;
													</span>
												)}
											</Row>
											<Row>
												右下角文字：
												{tel_state ? (
													<Input
														value={tel}
														onChange={(e) => {
															setState({ ...state, tel: e.target.value });
														}}
														style={{ width: 300, marginLeft: 20 }}
													/>
												) : (
													<span style={{ marginLeft: 20 }}>{tel}</span>
												)}
												{tel_state ? (
													<span
														style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
														onClick={() => save("tel")}
														className='icon iconfont edit'
														title='保存'>
														&#xe680;
													</span>
												) : (
													<span
														style={{ marginLeft: 80, cursor: "pointer", verticalAlign: "top" }}
														onClick={() => {
															setState({ ...state, tel_state: !tel_state });
														}}
														className='icon iconfont edit'
														title='修改'>
														&#xe60c;
													</span>
												)}
											</Row>
										</div>
									</div>
								</Row>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Brand;
