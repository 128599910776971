import React, { Component, useEffect, useState } from "react";
import { Row, Button, Breadcrumb } from "antd";
import _Api from "../../core/api";
import "./Model.less";
import WxMsgPreviewModal from "../../components/modals/WxMsgPreview";

const Model = ({ handleUse }) => {
	const [state, setState] = useState({
		list: [],
		previewDate: {},
		prewVisible: false,
	});
	useEffect(() => {
		getModelList();
	}, []);
	function getModelList() {
		_Api.wxTemplateGet({}).then((res) => {
			setState({ ...state, list: res.msg });
		});
	}
	function showPreview(item) {
		console.log(item)
		setState({
			...state,
			previewDate: item,
			prewVisible: true,
		});
	}

	function sendMessage(data) {
		// console.log(data);
		handleUse(data);
	}
	return (
		<div className='message-item'>
			<div className='box-body'>
				<div className='modal-body'>
					<Row type='flex' justify='start'>
						{state.list.map((item, index) => (
							<div key={index} className='item'>
								<div className='img'>
									<img src={item.cover} alt='' />
									<div className='bacg'>
										<div className='btnGroup'>
											<Button
												id={"use_now_btn" + index}
												type='primary'
												onClick={() => {
													sendMessage(item);
												}}>
												立即使用
											</Button>
											<br />
											<Button
												id={"template_preview_btn" + index}
												className='mt20'
												onClick={() => {
													showPreview(item);
												}}>
												预览模板
											</Button>
										</div>
									</div>
								</div>
								<div className='bottom'>
									<div className='title text one-line-text'>{item.name}</div>
									<p className='summary'>{item.summary}</p>
								</div>
							</div>
						))}
					</Row>
				</div>
			</div>
			<WxMsgPreviewModal
				visible={state.prewVisible}
				data={state.previewDate}
				closeModal={() => {
					setState({
						...state,
						prewVisible: false,
					});
				}}
			/>
		</div>
	);
};

export default Model;
