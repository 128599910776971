export default ({ children, text }) => {
	return (
		<div
			style={{
				width: "100%",
				height: "32px",
        lineHeight:'30px',
        padding:'0 5px',
				backgroundColor: "#f5f5f5",
				border: "#E8E8E8 1px solid",
				borderRadius: "5px",
				// display:'inline',
				flex:1
			}}>
			{text && <span style={{color:'#666',fontSize:'15px',whiteSpace:'nowrap'}}>{text}</span>}
			{children}
		</div>
	);
};
