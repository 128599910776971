import { useState, Fragment, useEffect, useMemo } from "react";
import { Tooltip, Row, Col, DatePicker, InputNumber, Table, Tabs } from "antd";
import SearchBar from "../../components/SearchBar";
import SearchBarUpdata from "../../components/SearchBarUpdata";
import StaticSelect from "../../components/StaticSelect";
import CommenSelect from "../../components/CommonSelect";
import PageControl from "../../components/PageControl";
import PageControlImprove from "../../components/PageControlmprove";
import ChargeDetailsTable from "../../components/ChargeDetailsTable";
import moment from "moment";
import _Api from "../../core/api";
import { useHistory } from "react-router-dom";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const ChargeDetail = ({ location }) => {
  const [state, setState] = useState({
    cardId: "",
    chargeType: "",
    dateStart: "",
    dateEnd: "",
    inputUser: "",
    remark: "",
    moneyMin: "",
    moneyMax: "",
    filter: false,
    list: [],
    total: "0",
    pageNo: "1",
    pageSize: "10",
    realMoneyPage: 0,
    realMoneyAll: 0,
    zsMoneyPage: 0,
    zsMoneyAll: 0,
    memberCardType: "储值卡",
  });
  const [stateCount, setStateCount] = useState({
    cardId: "",
    chargeType: "",
    dateStart: "",
    dateEnd: "",
    inputUser: "",
    remark: "",
    moneyMin: "",
    moneyMax: "",
    filter: false,
    list: [],
    total: "0",
    pageNo: "1",
    pageSize: "10",
    realMoneyPage: 0,
    realMoneyAll: 0,
    zsMoneyPage: 0,
    zsMoneyAll: 0,
    memberCardType: "计次卡",
  });
  const [modalState, setModalState] = useState({
    memberType: false,
  });
  const [cardId, setCardId] = useState("");
  const [workerOptions, setWorkerOptions] = useState([]);
  const [payTypes, setPayTypes] = useState([]);
  const [initFlag, setInitFlag] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const history = useHistory();
  useEffect(() => {
    getWorkerList();
    getPayTypes();
  }, []);
  useEffect(() => {
    if (!initFlag && location.state) {
      getChargeList(undefined, location.state.card);
      setCardId(location.state.card);
    } else {
      getChargeList();
    }
  }, [
    state.chargeType,
    state.dateStart,
    state.dateEnd,
    state.inputUser,
    modalState.memberType,
  ]);
  useEffect(() => {
    const key = location.state?.key;
    console.log(key);
    if (key) {
      setActiveKey("2");
      setModalState({
        memberType: true,
      });
    }
    // if (location.state.key) {
    // }
  }, []);

  // 获取 充值明细 数据
  function getChargeList(data, card) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.memberType) {
      //计次
      const data = {
        caiwu_type: "会员卡充值",
        pay_type: stateCount.chargeType,
        member_card: card || cardId,
        member_num_min: stateCount.moneyMin,
        member_num_max: stateCount.moneyMax,
        remark: stateCount.remark,
        input_user: stateCount.inputUser,
        start_time: stateCount.dateStart,
        end_time: stateCount.dateEnd,
        count: pageSize,
        page: pageNo,
        member_card_type: stateCount.memberCardType,
      };
      _Api.caiwuSelect(data).then((res) => {
        !initFlag && setInitFlag(true);
        setStateCount({
          ...stateCount,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
          realMoneyPage: res.data.money_member_cz_page,
          realMoneyAll: res.data.money_member_cz_all,
          zsMoneyPage: res.data.money_member_czzs_page,
          zsMoneyAll: res.data.money_member_czzs_all,
        });
      });
    } else {
      // 储值
      const data = {
        caiwu_type: "会员卡充值",
        pay_type: state.chargeType,
        member_card: card || cardId,
        money_real_min: state.moneyMin,
        money_real_max: state.moneyMax,
        remark: state.remark,
        input_user: state.inputUser,
        start_time: state.dateStart,
        end_time: state.dateEnd,
        count: pageSize,
        page: pageNo,
        member_card_type: state.memberCardType,
      };
      _Api.caiwuSelect(data).then((res) => {
        !initFlag && setInitFlag(true);
        setState({
          ...state,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
          realMoneyPage: res.data.money_member_cz_page,
          realMoneyAll: res.data.money_member_cz_all,
          zsMoneyPage: res.data.money_member_czzs_page,
          zsMoneyAll: res.data.money_member_czzs_all,
        });
      });
    }
  }
  // 获取 经办人 数据
  function getWorkerList(params) {
    _Api.workerSelect().then((res) => {
      let data = res.data.content || [],
        options = data.map((item) => ({ id: item.id, name: item.name }));
      setWorkerOptions(options);
    });
  }
  // 获取 充值方式数据
  function getPayTypes() {
    _Api.getPayType().then((res) => {
      let types = res.msg.map((item) => ({ id: item.id, name: item.text }));
      setPayTypes(types);
    });
  }
  // 清空筛选 初始数据
  function reset() {
    setState({
      ...state,

      chargeType: "",
      dateStart: "",
      dateEnd: "",
      inputUser: "",
      remark: "",
      moneyMin: "",
      moneyMax: "",
    });
    setCardId("");
  }
  // 收起 展开逻辑
  function toggleFilter() {
    setState({ ...state, filter: !state.filter });
    setStateCount({ ...stateCount, filter: !stateCount.filter });
  }
  // 标签页改变
  function onChange(key) {
    console.log(key);
    if (key === "1") {
      setActiveKey("1");
      setModalState({ ...modalState, memberType: false });
    }
    if (key === "2") {
      setActiveKey("2");
      setModalState({ ...modalState, memberType: true });
    }
  }

  const columns = [
    {
      title: "会员卡号",
      dataIndex: "member_card",
      align: "left",
    },
    {
      title: "姓名",
      align: "left",
      dataIndex: "linkman",
    },

    {
      title: "充值日期",
      align: "left",
      dataIndex: "gmt_creat",
    },
    {
      title: "支付方式",
      align: "left",
      dataIndex: "pay_type",
    },

    {
      title: "实收金额",
      align: "left",
      dataIndex: "money_real",
    },
    {
      title: "赠送金额",
      align: "left",
      dataIndex: "money_member",
    },
    {
      title: "录入人员",
      align: "left",
      dataIndex: "input_user",
    },
    {
      title: "备注",
      align: "left",
      dataIndex: "remark",
    },
  ];
  return (
    <>
      <div className="template-student-index page settlemen">
        <div className="page-content">
          <section className="content-header">
            <h1>充值明细</h1>
          </section>
          <div className="box package-seeting" style={{ padding: 0 }}>
            <Tabs
              defaultActiveKey="1"
              activeKey={activeKey}
              onChange={onChange}
              type="card"
            >
              <TabPane tab="储值类型" key="1" style={{ padding: "0 16px" }}>
                <div className="box-header" style={{ paddingBottom: 0 }}>
                  <Row type="flex" justify="space-around" align="top">
                    <Col>
                      <SearchBarUpdata
                        searchText={cardId}
                        onChangeText={(text) => {
                          setCardId(text);
                        }}
                        onSearch={getChargeList}
                        label="会员卡号"
                        width={75}
                        placeholder="请输入充值卡号"
                      />

                      <CommenSelect
                        selectValue={state.chargeType}
                        label="充值方式"
                        options={payTypes}
                        onSelect={(selectValue) => {
                          setState({ ...state, chargeType: selectValue });
                        }}
                        placeholder="请选择充值方式"
                      />
                      <StaticSelect label="充值日期:">
                        <RangePicker
                          style={{ width: "100%" }}
                          value={[
                            state.dateStart && moment(state.dateStart),
                            state.dateEnd && moment(state.dateEnd),
                          ]}
                          onChange={(memo, str) => {
                            setState({
                              ...state,
                              dateStart: str[0],
                              dateEnd: str[1],
                            });
                          }}
                        />
                      </StaticSelect>
                      {state.filter && (
                        <>
                          <SearchBarUpdata
                            searchText={state.remark}
                            onChangeText={(text) => {
                              setState({ ...state, remark: text });
                            }}
                            onSearch={getChargeList}
                            label="备注"
                            placeholder="请输入备注信息"
                          />
                          <CommenSelect
                            selectValue={state.inputUser}
                            label="经办人"
                            options={workerOptions}
                            onSelect={(value) => {
                              setState({ ...state, inputUser: value });
                            }}
                            placeholder="请选择经办人"
                          />
                          <StaticSelect label="充值金额:">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flex: "1",
                              }}
                            >
                              <InputNumber
                                placeholder="最小值"
                                step={100}
                                style={{ flex: "1" }}
                                value={state.moneyMin}
                                onChange={(num) => {
                                  setState({ ...state, moneyMin: num });
                                }}
                                onPressEnter={getChargeList}
                              />
                              <span
                                style={{ margin: "0 5px", color: "#bfbfbf" }}
                              >
                                ~
                              </span>
                              <InputNumber
                                placeholder="最大值"
                                step={100}
                                style={{ flex: "1" }}
                                value={state.moneyMax}
                                onChange={(num) => {
                                  setState({ ...state, moneyMax: num });
                                }}
                                onPressEnter={getChargeList}
                              />
                            </div>
                          </StaticSelect>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Tooltip title="清空筛选">
                        <span
                          onClick={reset}
                          id="reset_btn"
                          className="resetBtn iconfont icon"
                        >
                          &#xe662;
                        </span>
                      </Tooltip>
                      <span
                        className="text-sub xm-filter"
                        onClick={toggleFilter}
                        style={{ cursor: "pointer" }}
                      >
                        {state.filter ? (
                          <span>
                            收起
                            <span className="iconfont icon ml5">&#xe663;</span>
                          </span>
                        ) : (
                          <span>
                            展开
                            <span className="iconfont icon ml5">&#xe665;</span>
                          </span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="box-body">
                  <Table
                    rowKey={(record) => record.id}
                    dataSource={state.list}
                    columns={columns}
                    bordered
                    style={{ marginTop: "20px", marginBottom: "30px" }}
                    pagination={false}
                  />

                  <PageControlImprove
                    total={state.total}
                    pageSize={state.pageSize}
                    current={state.pageNo}
                    showSizeChanger
                    toPage={(page, count) => {
                      getChargeList({ page, pages: count });
                    }}
                  >
                    <Row justify="end" align="center">
                      <Col span={24} style={{ textAlign: "right" }}>
                        <span
                          style={{ marginRight: "10px" }}
                        >{`本页充值金额${state.realMoneyPage}元`}</span>
                        <span
                          style={{ marginRight: "10px" }}
                        >{`累计充值金额${state.realMoneyAll}元`}</span>{" "}
                        <span
                          style={{ marginRight: "10px" }}
                        >{`本页赠送金额${state.zsMoneyPage}元`}</span>
                        <span>{`累计赠送金额${state.zsMoneyAll}元`}</span>
                      </Col>
                    </Row>
                  </PageControlImprove>
                </div>
              </TabPane>
              <TabPane tab="计次类型" key="2" style={{ padding: "0 16px" }}>
                <div className="box-header" style={{ paddingBottom: 0 }}>
                  <Row type="flex" justify="space-around" align="top">
                    <Col>
                      <SearchBarUpdata
                        searchText={cardId}
                        onChangeText={(text) => {
                          setCardId(text);
                        }}
                        onSearch={getChargeList}
                        label="会员卡号"
                        placeholder="请输入充值卡号"
                      />

                      <CommenSelect
                        selectValue={stateCount.chargeType}
                        label="充值方式"
                        options={payTypes}
                        onSelect={(selectValue) => {
                          setState({ ...stateCount, chargeType: selectValue });
                        }}
                        placeholder="请选择充值方式"
                      />
                      <StaticSelect label="充值日期:">
                        <RangePicker
                          style={{ width: "100%" }}
                          value={[
                            stateCount.dateStart &&
                              moment(stateCount.dateStart),
                            stateCount.dateEnd && moment(stateCount.dateEnd),
                          ]}
                          onChange={(memo, str) => {
                            setStateCount({
                              ...stateCount,
                              dateStart: str[0],
                              dateEnd: str[1],
                            });
                          }}
                        />
                      </StaticSelect>
                      {stateCount.filter && (
                        <>
                          <SearchBarUpdata
                            searchText={stateCount.remark}
                            onChangeText={(text) => {
                              setState({ ...stateCount, remark: text });
                            }}
                            onSearch={getChargeList}
                            label="备注"
                            placeholder="请输入备注信息"
                          />
                          <CommenSelect
                            selectValue={stateCount.inputUser}
                            label="经办人"
                            options={workerOptions}
                            onSelect={(value) => {
                              setState({ ...stateCount, inputUser: value });
                            }}
                            placeholder="请选择经办人"
                          />
                          <StaticSelect label="充值次数:">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flex: "1",
                              }}
                            >
                              <InputNumber
                                placeholder="最小值"
                                step={100}
                                style={{ flex: "1" }}
                                value={stateCount.moneyMin}
                                onChange={(num) => {
                                  setStateCount({
                                    ...stateCount,
                                    moneyMin: num,
                                  });
                                }}
                                onPressEnter={getChargeList}
                              />
                              <span
                                style={{ margin: "0 5px", color: "#bfbfbf" }}
                              >
                                ~
                              </span>
                              <InputNumber
                                placeholder="最大值"
                                step={100}
                                style={{ flex: "1" }}
                                value={stateCount.moneyMax}
                                onChange={(num) => {
                                  console.log();
                                  setStateCount({
                                    ...stateCount,
                                    moneyMax: num,
                                  });
                                }}
                                onPressEnter={getChargeList}
                              />
                            </div>
                          </StaticSelect>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Tooltip title="清空筛选">
                        <span
                          onClick={reset}
                          id="reset_btn"
                          className="resetBtn iconfont icon"
                        >
                          &#xe662;
                        </span>
                      </Tooltip>
                      <span
                        className="text-sub xm-filter"
                        onClick={toggleFilter}
                        style={{ cursor: "pointer" }}
                      >
                        {stateCount.filter ? (
                          <span>
                            收起
                            <span className="iconfont icon ml5">&#xe663;</span>
                          </span>
                        ) : (
                          <span>
                            展开
                            <span className="iconfont icon ml5">&#xe665;</span>
                          </span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="box-body">
                  <ChargeDetailsTable
                    state={stateCount}
                    getChargeList={getChargeList}
                  ></ChargeDetailsTable>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChargeDetail;
