import { useState, useEffect } from "react";
import { Tabs } from "antd";

import PrintSetting from "./PrintSetting";

const BranchSet = ({}) => {
	return (
		<div className='page'>
			<div className='page-content'>
				<section className='content-header'>
					<span>打印设置</span>
				</section>
				<div className='template-orgnization-account box'>
					<PrintSetting />
					{/* <Tabs
						activeKey={state.activeKey}
						onChange={(e) => {
							setState({ ...state, activeKey: e });
						}}>
						<TabPane tab='基础设置' key='basic'>
							<BasicSetting tabKey={state.activeKey} />
						</TabPane>
						<TabPane tab='收据打印' key='print'>
							
						</TabPane>
					</Tabs> */}
				</div>
			</div>
		</div>
	);
};
export default BranchSet;
