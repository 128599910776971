import React, { Component, useState, useEffect ,useRef} from "react";
import { Button, Modal, Input, message } from "antd";
import axios from "../../core/axios";
import Axios from "axios";
import _Api from "../../core/api";
import User from "../../core/user";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import DragableBodyCard from "../../components/DragableBodyCard";
import $ from "jquery";

const AlbumSet = ({ refreshFrame }) => {
	const [data, setDate] = useState([]);
	const [order, setOrder] = useState([]);
  const imgRef = useRef(null)
	useEffect(() => {
		getInfo();
	}, []);
	function handleImgUpload(e) {
		let file = e.target.files[0];
		const formData = new FormData();
		formData.append("file", file);
		formData.append("user_type", "shop");
		Axios.post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" + User.token(), formData)
			.then((res) => {
				if (res.data.response == 200) {
					handleAdd(res.data.img_url);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}
	function handleAdd(img) {
		const body = {
			type: "photo",
			image: img,
		};
		_Api.webInsert(body).then((res) => {
			message.success(res.msg);
			getInfo();
			refreshFrame();
		});
	}
	function getInfo() {
		const body = {
			type: "photo",
		};
		_Api.webSelect(body).then((res) => {
			let data = res.data.map((item, index) => {
				return {
					index: index,
					id: item.id,
					photo: item.image,
				};
			});
			let order = data.map((item) => item.id);
			setDate(data);
			setOrder(order);
		});
	}
	function handleDelete(id) {
		_Api.webDelete({ id }).then((res) => {
			message.success("删除成功");
			getInfo();
			refreshFrame();
		});
	}
	function changePos(order) {
		const body = {
			type: "photo",
			ids: order,
		};
		_Api.webOrders(body).then((res) => {
			message.success("排序成功");
			getInfo();
			refreshFrame();
		});
	}
	function moveCard(outIndex, inIndex) {
		const _order = order;
		const item = _order.splice(outIndex, 1);
		_order.splice(inIndex, 0, item[0]);
		changePos(_order);
	}
	return (
		<div className='setting-page' style={{ paddingBottom: "0px" }}>
			<div className='setting-body' style={{ marginTop: "16px" }}>
				<div className='album' style={{ paddingTop: "0px" }}>
					<div className='container'>
						<div class='tips'>
							<i class='fa fa-warning'></i>
							支持JPG,JPEG,PNG格式,单张大小不超过5M，最多50张，建议尺寸560＊300px
						</div>
						<ul className='album-list'>
							<li>
								<div className='img-container'>
									<span className='AddUpload'>
										<div
											className='uploadContent'
											onClick={() => {
												imgRef.current.click();
											}}>
											<i className='icon iconfont'>&#xe612;</i>
										</div>
										<input
											type='file'
											id='select-file'
											accept='image/gif,image/jpeg,image/png'
											style={{ display: "none" }}
											ref={imgRef}
											onChange={handleImgUpload}
										/>
									</span>
								</div>
							</li>
							<DndProvider backend={HTML5Backend}>
								{data.map((item) => {
									return (
										<DragableBodyCard
											index={item.index}
											id={item.id}
											img={item.photo}
											moveCard={moveCard}
											order={order}
											handleDelete={handleDelete}
										/>
									);
								})}
							</DndProvider>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlbumSet;
