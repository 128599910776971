import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Table, Popconfirm, Divider, Modal, Form, Input, Spin, message } from "antd";
import PageControl from "../../components/PageControl";
import SearchBar from "../../components/SearchBar";
import _Api from "../../core/api";
import ServeAdd from "../../components/modals/ServeAdd";
import SupplierEditModal from "../../components/modals/SupplierEdit";

const SupplierFile = ({}) => {
	const [supplierState, setSupplierState] = useState({
		keyword: "",
		list: [],
		pageNo: "1",
		pageSize: "10",
		total: "",
	});
	const [modalState, setModalState] = useState({ visible: false, editType: false, curSupplier: {} });

	useEffect(() => {
		getSupplierList();
	}, []);
	function getSupplierList(pageState) {
		let pageSize = pageState&&pageState.pageSize || "10";
		let pageNo =  pageState&&pageState.pageNo || "1";
		let data = {
			keyword: supplierState.keyword,
			count: pageSize,
			page: pageNo,
		};
		_Api.supplierSelect(data).then((res) => {
			let _data = res.data;
			setSupplierState({
				...supplierState,
				list: _data.content,
				total: _data.allcount,
				pageNo: _data.curpage,
				pageSize: _data.pagecount,
			});
		});
	}

	function handleDelete(id) {
		_Api.supplierDelete({ id }).then((res) => {
			message.success(res.msg);
			const { pageSize, pageNo } = supplierState;
			getSupplierList({ pageSize, pageNo });
		});
	}

	const columns = [
		{
			title: "序号",
			dataIndex: "id",
			key: "index",
		},
		{
			title: "供应商名称",
			dataIndex: "name",
			align: "center",
		},
		{
			title: "联系人",
			dataIndex: "linkman",
			align: "center",
		},
		{
			title: "手机",
			dataIndex: "mobile",
			align: "center",
		},
		{
			title: "电话",
			dataIndex: "phone",
			align: "center",
		},
		{
			title: "地区",
			dataIndex: "area",
			align: "center",
		},
		{
			title: "地址",
			dataIndex: "addr",
			align: "center",
		},
		{
			title: "备注",
			dataIndex: "remark",
			align: "center",
		},
		{
			title: "操作",
			dataIndex: "id",
			align: "center",
			key: "handle",
			render: (text, record) => (
				<>
					<span
						className='theme-color click-able'
						onClick={() => {
							setModalState({ visible: true, editType: true, curSupplier: record });
						}}>
						修改
					</span>
					<Divider type='vertical' />
					<Popconfirm
						placement='left'
						title='删除后无法恢复，是否确认删除？'
						okText='确认'
						cancelText='取消'
						onConfirm={() => {
							handleDelete(text);
						}}>
						<span className='theme-color click-able'>删除</span>
					</Popconfirm>
				</>
			),
		},
	];
	return (
		<div className='template-appointment-index page appointmentManage'>
			<div className='page-content'>
				<section className='content-header'>
					<h1>供应商档案</h1>
				</section>
				<div className='box'>
					<div className='box-header'>
						<Row type='flex' justify='space-between'>
							<Col>
								<SearchBar
									searchText={supplierState.keyword}
									onChangeText={(text) => {
										setSupplierState({ ...supplierState, keyword: text });
									}}
									onSearch={getSupplierList}
									label='搜索'
									placeholder='请输入名称/联系人/手机/电话/地址/备注'
								/>
							</Col>
							<Col>
								<Button
									type='primary'
									onClick={() => {
										setModalState({ ...modalState, visible: true, editType: false });
									}}>
									新增
								</Button>
							</Col>
						</Row>
					</div>
					<div className='box-body'>
						<Table
							rowKey={(record) => record.id}
							pagination={false}
							dataSource={supplierState.list}
							columns={columns}
							bordered
							style={{ marginBottom: "40px" }}
						/>
						<PageControl
							total={supplierState.total}
							pageSize={supplierState.pageSize}
							current={supplierState.pageNo}
							showSizeChanger
							toPage={(page, count) => {
								getSupplierList({ pageNo: page, pageSize: count });
							}}
						/>
					</div>
				</div>
			</div>
			<SupplierEditModal
				visible={modalState.visible}
				closeModal={() => {
					setModalState({ visible: false, editType: false, curSupplier: "" });
				}}
				supplierInfo={modalState.curSupplier}
				editType={modalState.editType}
				handleComplete={() => {
					setModalState({ visible: false, editType: false, curSupplier: "" });
					getSupplierList();
				}}
			/>
		</div>
	);
};

export default SupplierFile;
