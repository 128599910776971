import { useEffect } from "react";
import { Table, Row, Col, Radio } from "antd";
import _Api from "../../core/api";
import { useState } from "react";
import StaticSelect from "../../components/StaticSelect";
import LineChart from "../../components/LineChart";
const Income = ({ date }) => {
	const [incomeState, setIncomeState] = useState({
		fetchType: "days",
		date: [],
		money: [],
	});
	useEffect(() => {
		getIncomeData();
	}, [date, incomeState.fetchType]);
	function getIncomeData() {
		let data = { start_time: date[0], end_time: date[1], time_type: incomeState.fetchType };
		_Api.incomeQs(data).then((res) => {
			let dateArr = [],
				incomeArr = [];
			res.data.forEach((item) => {
				dateArr.push(item.date);
				incomeArr.push(item.all_money);
			});
			setIncomeState({ ...incomeState, date: dateArr, money: incomeArr });
		});
	}
	return (
		<Row justify='space-between'>
			<div style={{ width: "80%", height: "400px" }}>
				<StaticSelect label='统计方式：'>
					<Radio.Group
						onChange={(e) => {
							setIncomeState({ ...incomeState, fetchType: e.target.value });
						}}
						value={incomeState.fetchType}>
						<Radio value='days'>按天</Radio>
						<Radio value='month'>按月</Radio>
					</Radio.Group>
				</StaticSelect>
				<div style={{ height: "100%", width: "100%" }}>
					<LineChart chartTitle='' xArr={incomeState.date} yArr={incomeState.money} />
				</div>
			</div>
		</Row>
	);
};
export default Income;
