import { useEffect, useState } from "react";
import { Tabs } from "antd";

import Bind from "./Bind";
import Basic from "./Basic";
import Menu from "./Menu";

import "./WechatBind.less";

const { TabPane } = Tabs;

const PublicAccount = () => {
	const [state, setState] = useState({
		tab: "bind",
	});
	return (
		<div className='template-org-desc page' style={{ zIndex: 0 }}>
			<section className='content-header'>
				<h1>微信公众号</h1>
			</section>
			<div className='box' style={{ paddingTop: "12px" }}>
				<Tabs
					key='tabs'
					activeKey={state.tab}
					onChange={(a) => {
						setState({ ...state, tab: a });
					}}>
					<TabPane tab='公众号绑定' key='bind'>
						<Bind />
					</TabPane>
					<TabPane tab='基本设置' key='basic'>
						<Basic />
					</TabPane>
					<TabPane tab='菜单设置' key='menu'>
						<Menu />
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export default PublicAccount;
