import { useState, useRef, useEffect } from "react";
import "./CarouselEdit.less";
import _Api from "../../core/api";
import axios from "axios";
import User from "../../core/user";
import { message, Modal, Button, Switch, Input, Spin } from "antd";

const CarouselEditModal = ({ visible, closeModal, handleComplete, editType, curCarousel }) => {
	const [state, setState] = useState({ img: "", url: "", has_url: false, id: "" });
	const [uploadFlag, setUpload] = useState(false);
	const inputRef = useRef(null);
	useEffect(() => {
		if (visible && editType) {
			console.log(curCarousel);
			const { id, url, img } = curCarousel;
			setState({
				img,
				url,
				id,
				has_url: url ? true : false,
			});
		}
	}, [visible, editType, curCarousel]);
	function handleOk() {
		const body = {
			type: "index_img",
			id: state.id,
			image: state.img,
			link: state.url,
		};
		_Api.webInsert(body).then((res) => {
			message.success(res.msg);
			setState({ img: "", url: "", has_url: false, id: "" });
			handleComplete();
		});
	}
	function handleImgUpload(e) {
		setUpload(true);
		let file = e.target.files[0];
		const formData = new FormData();
		formData.append("file", file);
		formData.append("user_type", "shop");

		axios
			.post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" + User.token(), formData)
			.then((res) => {
				setUpload(false);
				if (res.data.response == 200) {
					setState({ ...state, img: res.data.img_url });
					message.success("图片已上传");
				}
			})
			.catch((err) => {
				setUpload(false);
				console.log(err);
			});
	}
	return (
		<Modal
			className='mo
        dal-add-carousel'
			width='700px'
			title={editType ? "编辑轮播图" : "新增轮播图"}
			visible={visible}
			onOk={handleOk}
			onCancel={closeModal}>
			<Spin spinning={uploadFlag} tip='图片上传中...'>
				<div className='modal-body'>
					<div className='form-control'>
						<label>上传图片：</label>
						<div className='input-wrap'>
							<input
								type='file'
								id='select-file'
								accept='image/gif,image/jpeg,image/png'
								style={{ display: "none" }}
								ref={inputRef}
								onChange={(e) => {
									handleImgUpload(e);
								}}
							/>
							<Button
								onClick={() => {
									inputRef.current.click();
								}}>
								选择图片
							</Button>
							<div className='tips'>建议尺寸：750 * 360像素</div>
							{state.img ? (
								<div className='img-wrap'>
									<img src={state.img} alt='' />
								</div>
							) : null}
						</div>
					</div>
					<div className='form-control'>
						<label>跳转链接：</label>
						<div className='input-wrap'>
							<div className='icon-wrap'>
								<Switch
									size='small'
									checked={state.has_url}
									onChange={() => {
										setState({ ...state, has_url: !state.has_url });
									}}
								/>
							</div>
							{state.has_url && (
								<div className='address-wrap' style={{ display: "flex", alignItems: "center" }}>
									<span>网址:</span>
									<div className='right-wrap'>
										<Input
											value={state.url}
											placeholder='网址开头加上http://或者https://'
											className='address'
											onChange={(e) => {
												setState({
													...state,
													url: e.target.value,
												});
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};
export default CarouselEditModal;
