import { Row, Col, Tabs, DatePicker, message, Button } from "antd";
import { useState, useEffect } from "react";
import StaticSelect from "../../components/StaticSelect";
import moment from "moment";
import BusinessCount from "./BusinessCount";
import PartCount from "./PartCount";
import Income from "./Income";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const BusinessChart = ({}) => {
	const [chartState, setChartState] = useState({
		tabKey: "1",
		date: [],
	});
	return (
		<>
			<div className='template-student-index page settlemen'>
				<div className='page-content'>
					<section className='content-header'>
						<h1>经营图表</h1>
					</section>
					<div className='box'>
						<div className='box-header'>
							<Row type='flex' justify='space-around' align='top'>
								<Col>
									<StaticSelect label='经营日期'>
										<RangePicker
											style={{ width: "350px" }}
											ranges={{
												本月: [moment().startOf("month"), moment().endOf("month")],
												本周: [moment().startOf("week"), moment().endOf("week")],
												上月: [
													moment().subtract(1, "months").startOf("month"),
													moment().subtract(1, "months").endOf("month"),
												],
												上周: [
													moment().subtract(1, "week").startOf("week"),
													moment().subtract(1, "week").endOf("week"),
												],
											}}
											value={[
												chartState.date[0] && moment(chartState.date[0]),
												chartState.date[1] && moment(chartState.date[1]),
											]}
											onChange={(memo, str) => {
												setChartState({ ...chartState, date: str });
											}}
										/>
									</StaticSelect>
								</Col>
							</Row>
						</div>
						<div className='box-body'>
							<Tabs
								activeKey={chartState.tabKey}
								onTabClick={(e) => {
									setChartState({ ...chartState, tabKey: e });
								}}>
								<TabPane tab={"经营统计"} key='1'>
									<BusinessCount date={chartState.date} tabKey={chartState.tabKey} />
								</TabPane>
								<TabPane tab={"配件销售"} key='2'>
									<PartCount date={chartState.date} tabKey={chartState.tabKey} />
								</TabPane>
								<TabPane tab={"收入趋势"} key='3'>
									<Income date={chartState.date} tabKey={chartState.tabKey} />
								</TabPane>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default BusinessChart;
