import { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Form, message } from "antd";
import _Api from "../../core/api";
import mathFunc from "../../core/mathfunc";

const InventoryAdjustModal = ({
  visible,
  closeModal,
  partInfo,
  handleComplete,
}) => {
  const [adjustState, setAdjustState] = useState({
    curNum: "",
    adjustNum: "",
    remark: "",
  });
  useEffect(() => {
    if (visible) {
      console.log(partInfo);
      const { cur_num } = partInfo;
      setAdjustState({ ...adjustState, curNum: cur_num });
    }
  }, [visible]);
  function handleOk() {
    const { id } = partInfo,
      { adjustNum, remark } = adjustState,
      data = { id, cur_num: adjustNum, remark };
    console.log(data);
    _Api.partReset(data).then((res) => {
      message.success(res.msg);
      setAdjustState({ curNum: "", adjustNum: "", remark: "" });
      handleComplete();
    });
  }
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title="库存数量调整"
      visible={visible}
      onCancel={closeModal}
      onOk={handleOk}
    >
      <Form {...layout} colon={false}>
        <Row type="flex" justify="space-between" align="bottom">
          <Col span={12}>
            <Form.Item label="调整前">
              <Input disabled value={adjustState.curNum} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="调整后">
              <Input
                value={adjustState.adjustNum}
                onChange={(e) => {
                  const num = mathFunc.reservedIntegerOnchage(e.target.value);
                  setAdjustState({ ...adjustState, adjustNum: num });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="调整原因"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Input
                value={adjustState.remark}
                onChange={(e) => {
                  setAdjustState({ ...adjustState, remark: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default InventoryAdjustModal;
