import React, { useState, useEffect, useReducer, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { ConfigProvider, Modal, Button } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import axios from "./core/axios";
import Axios from "axios";
import Loader from "./components/Loader";
import Header from "./modules/root/Header";
import Menu from "./modules/root/Menu";
import Page from "./modules/root/Page";
import "./App.less";
import "./components/Breadcrumbs.less";
import User from "./core/user";
import qs from "qs";
import ShopContext from "./core/context-manager";
import "moment/locale/zh-cn";
import moment from "moment";
import TopImgs from "./libs/home-top.png";
moment.locale("zh-cn");

const App = ({}) => {
  const [state, setState] = useState({
    branch_info: {},
    user_info: {},
    user_list: {},
    web_set: {},
    loading: true,
    menuFold: false,
  });
  const [serviceTerm, setServiceTerm] = useState({
    shop_notice: [],
    visible: false,
  });
  const [shopState, dispatch] = useReducer(
    (preState, action) => {
      switch (action.type) {
        case "SetPurchasingList":
          return {
            ...preState,
            tableDate: { ...preState.PurchasingList, ...action.data },
          };
        case "SetPurchasingItem":
          return { ...preState, PurchasingItem: action.data };
        case "SetMenuList":
          return { ...preState, MenuList: action.data };
        case "SetShopInfo":
          console.log("触发dispatch SetShopInfo");
          return {
            ...preState,
            UserInfo: action.data.user_info,
            BranchInfo: action.data.branch_info,
            ShopInfo: action.data.shop_info,
          };
        case "SetCarSearched":
          return {
            ...preState,
            CarSearched: action.data,
          };
      }
    },
    {
      UserInfo: {},
      BranchInfo: {},
      ShopInfo: {},
      PurchasingList: { editing: {}, saved: {} },
      PurchasingItem: {},
      MenuList: [],
      CarSearched: { id: "", searched: true },
    }
  );
  const shopContext = useMemo(() => ({
    setPurchasingList: (data) => dispatch({ type: "SetPurchasingList", data }),
    setPurchasingItem: (data) => dispatch({ type: "SetPurchasingItem", data }),
    setShopInfo: (data) => {
      dispatch({ type: "SetShopInfo", data });
    },
    setMenuList: (data) => dispatch({ type: "SetMenuList", data }),
    setCarSearched: (data) => {
      dispatch({ type: "SetCarSearched", data });
    },
    resetShopInfo: fetchUserInsts,
    purchasingList: shopState.PurchasingList,
    purchasingItem: shopState.PurchasingItem,
    userInfo: shopState.UserInfo,
    shopInfo: shopState.ShopInfo,
    branchInfo: shopState.BranchInfo,
    menuList: shopState.MenuList,
    carSearched: shopState.CarSearched,
  }));

  useEffect(() => {
    fetchUserInsts();
  }, []);

  function fetchUserInsts() {
    axios
      .post("shop/login.php?cmd=web_login_set", { user_type: "shop" })
      .then((res) => {
        if (!res.token_info || !res.token_info.user_shop) {
          User.logout();
        } else {
          let user_shop = res.token_info.user_shop;
          let user_list = user_shop.user_list
            ? user_shop.user_list.filter(
                (item) => item.user_info.id !== user_shop.cur_user.user_info.id
              )
            : [];
          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };
          Axios.post(
            "https://api.sanfeng9.com/shop/branch.php?cmd=branch_menu&token=" +
              User.token(),
            qs.stringify({ user_type: "shop" }),
            config
          ).then((Res) => {
            console.log(res);
            if (Res.data.response === "200") {
              window.userPermissionList = Res.data.msg;
              // dispatch({ type: "SetUserInfo", data: user_shop.cur_user.user_info });
              console.log("触发fetchUserInsts函数");
              dispatch({ type: "SetShopInfo", data: user_shop.cur_user });
              // shopContext.setShopInfo(user_shop.cur_user);
              setState({
                ...state,
                branch_info: user_shop.cur_user.branch_info,
                user_info: user_shop.cur_user.user_info,
                user_list: user_list,
                web_set: res.web_set,
                loading: false,
              });
              setServiceTerm({
                shop_notice: res.shop_notice || [],
                visible: res.shop_notice === null ? false : true,
              });
            } else {
              User.logout();
            }
          });
        }
      });
  }
  function cancelPurchase() {
    console.log("取消");
    const isGo = serviceTerm.shop_notice[0];
    if (isGo === "go") {
      setServiceTerm({
        ...serviceTerm,
        visible: false,
      });
    } else if (isGo === "exit") {
      axios.get("shop/login.php?cmd=logout").then((res) => {
        User.logout();
      });
    }
  }
  function onOKPurchase() {
    window.open("https://www.sanfeng9.com/version");
    // const isGo = serviceTerm.shop_notice[0];
    //   if (isGo === "go") {
    //     window.open("https://www.sanfeng9.com/version");
    //   } else if (isGo === "exit") {
    //     axios.get("shop/login.php?cmd=logout").then((res) => {
    //       User.logout();
    //     });
    //     window.open("https://www.sanfeng9.com/version");
    //   }
  }

  const { loading, menuFold, branch_info, user_info, user_list, web_set } =
    state;
  return (
    <React.Fragment>
      {loading ? (
        <Loader fullScreen={true} spinning={loading}></Loader>
      ) : (
        <div style={{ height: "100%" }}>
          <ShopContext.Provider value={shopContext}>
            <Header
              branch_info={branch_info}
              user_info={user_info}
              user_list={user_list}
              web_set={web_set}
              toggleCollapsed={() => {
                setState({
                  ...state,
                  menuFold: !menuFold,
                });
              }}
            ></Header>
            <Modal
              className="app-serviceTerm"
              title="aaaaa"
              width={773}
              visible={serviceTerm.visible}
              // visible={true}
              // onCancel={cancelPurchase}
              footer={null}
              maskClosable={false}
            >
              <div className="service-box">
                <img className="service-img" src={TopImgs} alt="" />
                <div className="service-info">
                  <h2>{serviceTerm?.shop_notice[1]}</h2>
                  <p>{serviceTerm?.shop_notice[2]}</p>
                  {/* <h2>服务到期</h2>
                  <p>您的试用服务已到期，继续使用请前往购买</p> */}
                  <div className="service-button">
                    <Button onClick={cancelPurchase} className="button-left">
                      取消
                    </Button>
                    <Button onClick={onOKPurchase} type="primary">
                      去购买
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            <Menu menuFold={menuFold}></Menu>
            <ConfigProvider locale={zhCN}>
              <Page menuFold={menuFold}></Page>
            </ConfigProvider>
          </ShopContext.Provider>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(App);
