import React, { useState } from "react";
import { Modal, Button, Row, Input, Col, Table } from "antd";
import PageControl from "../PageControl";
import Spinner from "../Spinner";
import Api from "../../core/api";
import UploadImage from "../UploadImage";
import UploadImages from "../UploadImages";
import UmultipleImage from "../UmultipleImage";
import NewImage from "../NewImage";
import carImageOne from "../../libs/carImage_1.png";
import carImageTow from "../../libs/carImage_2.png";
import carImageThree from "../../libs/carImage_3.png";
import carImageFour from "../../libs/carImage_4.png";
import uploadImageOne from "../../libs/uploadImage.png";
import "./CarImage.less";
export default ({
  type,
  carImgs,
  handleBaseCarImgs,
  handleBaseState,
  visible,
  handleModalState,
  imageList,
  handlImageUrl,
  imageUrl,
  // uploadMimage,
  uploadImageList,
  handleCarImgs
}) => {
  console.log("图片", carImgs);
  // 确认上传
  const cofirmUpload = () => {
    //传递上级
    // uploadImageList(imageUrl);
    // handlImageUrl([]);
    // handleCarImgs(carImgs)
    handleModalState({ carImageV: false });
  };
  // 关闭
  const canCel = () => {
    // handlImageUrl([]);
    handleModalState({ carImageV: false });
  };
  const title = (
    <div className="car-image-title">
      <h2>上传照片</h2>
      <p style={{ color: "#999999" }}>支持扩展名：jpg、png、gif格式</p>
    </div>
  );

  return (
    <Modal
      title={title}
      width={1449}
      visible={visible}
      onOk={cofirmUpload}
      onCancel={canCel}
    >
      <Row>
        <Col span={6}>
          <UmultipleImage
            type={type}
            count={1}
            carImageURL={carImageFour}
            carImgs={carImgs?.frontLeft}
            handleBaseCarImgs={handleBaseCarImgs}
            types="frontLeft"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="左前方"
          ></UmultipleImage>
          {/* <UploadImage
            handleBaseState={handleBaseState}
            carImgs={carImgs?.frontLeft}
            handleBaseCarImgs={handleBaseCarImgs}
            name="上传照片"
            title="左前方"
            types="frontLeft"
            type={0}
            handlImageUrl={handlImageUrl}
            imageUrl={imageUrl}
            // uploadMimage={uploadMimage}
            carImageURL={carImageFour}
            imageList={imageList}
            uploadImageList={uploadImageList}
          ></UploadImage> */}
        </Col>
        <Col span={6}>
          <UmultipleImage
            type={type}
            count={1}
            carImageURL={carImageOne}
            carImgs={carImgs?.frontRight}
            handleBaseCarImgs={handleBaseCarImgs}
            types="frontRight"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="右前方"
          ></UmultipleImage>
          {/* <UploadImage
            handleBaseState={handleBaseState}
            carImgs={carImgs?.frontRight}
            handleBaseCarImgs={handleBaseCarImgs}
            types="frontRight"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="右前方"
            type={1}
            carImageURL={carImageOne}
            uploadImageList={uploadImageList}
          ></UploadImage> */}
        </Col>
        <Col span={6}>
          <UmultipleImage
            type={type}
            count={1}
            carImageURL={carImageThree}
            carImgs={carImgs?.backLeft}
            handleBaseCarImgs={handleBaseCarImgs}
            types="backLeft"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="左后方"
          ></UmultipleImage>
          {/* <UploadImage
            handleBaseState={handleBaseState}
            carImgs={carImgs?.backLeft}
            handleBaseCarImgs={handleBaseCarImgs}
            types="backLeft"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="左后方"
            type={2}
            carImageURL={carImageThree}
            uploadImageList={uploadImageList}
          ></UploadImage> */}
        </Col>
        <Col span={6}>
          <UmultipleImage
            type={type}
            count={1}
            carImageURL={carImageTow}
            carImgs={carImgs?.backRight}
            handleBaseCarImgs={handleBaseCarImgs}
            types="backRight"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="右后方"
          ></UmultipleImage>
          {/* <UploadImage
            handleBaseState={handleBaseState}
            carImgs={carImgs?.backRight}
            handleBaseCarImgs={handleBaseCarImgs}
            types="backRight"
            imageUrl={imageUrl}
            handlImageUrl={handlImageUrl}
            name="上传照片"
            title="右后方"
            type={3}
            carImageURL={carImageTow}
            uploadImageList={uploadImageList}
          ></UploadImage> */}
        </Col>
      </Row>
      <div className="car-border"></div>
      <div className="car-image">
        {/* <NewImage></NewImage> */}
        <UmultipleImage
          type={type}
          count={4}
          carImageURL={uploadImageOne}
          carImgs={carImgs?.other}
          handleBaseCarImgs={handleBaseCarImgs}
          types="other"
          imageUrl={imageUrl}
          handlImageUrl={handlImageUrl}
          name="上传照片"
          max="其他（最多可上传4张）"
          title="其他"
        ></UmultipleImage>
      </div>
    </Modal>
  );
};
