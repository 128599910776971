import { useEffect, useState } from "react";
import { Input, Modal, Alert, Cascader } from "antd";
import address from "../../libs/Address.json";
import { useRef } from "react";
const { TextArea } = Input;

const ShopAddressModal = ({ visible, handleComplete, handleCancel, curAddressState }) => {
	const [state, setState] = useState({
		addressCode: "",
		addressData: "",
		province: "",
		city: "",
		area: "",
	});
	const [mapState, setMapState] = useState({
		mapData: { map: null, mask: null },
		longitude: "",
		latitude: "",
	});
	const { current } = useRef({ fn: null, timer: null });

	const Address = address;

	useEffect(() => {
		if (visible) {
			renderMap();
			initState();
		}
	}, [visible]);
	function initState() {
		let { addressData, addressCode } = curAddressState;
		addressCode = addressCode.constructor === Array ? addressCode.map((item) => parseInt(item)) : [];
		setState({ ...state, addressData, addressCode });
	}
	function handleCascader(value) {
		let [e, t, n] = value;
		const l = Address[(e || 0) + ""] || { label: "" },
			s = l.children[(t || 0) + ""] || { label: "" },
			r = (s.children && s.children[(n || 0) + ""]) || {
				label: "",
			},
			address = l.label + s.label + r.label;
		setState({
			...state,
			addressCode: value,
			addressData: address,
			province: l,
			city: s,
			area: r,
		});
		address && debounce(address);
	}
	function handleAddress(e) {
		let data = e.target.value;
		setState({ ...state, addressData: data });
		data && debounce(data);
	}
	function debounce(address) {
		if (current.timer) {
			clearTimeout(current.timer);
		}
		current.timer = setTimeout(() => {
			searchPlace(address);
		}, 500);
	}
	function searchPlace(value) {
		let mapData = mapState.mapData;
		let newsearch = new window.BMap.LocalSearch(mapData.map, {
			onSearchComplete: function (resaults) {
				console.log(resaults);
				if (resaults.getPoi(0)) {
					console.log("bb");
					const position = newsearch.getResults().getPoi(0).point;
					console.log(position);
					mapData.marker.setPosition(position);
					mapData.map.clearOverlays();
					mapData.map.addOverlay(mapData.marker);
					mapData.map.panTo(position);
					setMapState({
						...mapState,
						mapData,
						longitude: position.lng,
						latitude: position.lat,
					});
				}
			},
		});
		newsearch.search(value);
	}
	function renderMap() {
		let centerFlag = true;
		const { addressData, longitude, latitude } = curAddressState;
		const mark = {
			lng: longitude,
			lat: latitude,
		};
		let mapData = mapState.mapData;
		mapData.map = new window.BMap.Map("map"); //初始化地图，这个id和下面的id相对应，之所以将初始化的地图放到this对象上，是方便其他方法调用map对象
		mapData.marker = new window.BMap.Marker(mark);
		longitude
			? mapData.map.centerAndZoom(new window.BMap.Point(longitude, latitude), 13)
			: mapData.map.centerAndZoom(addressData ? addressData : "北京市建材城中路12号院", 13);
		mapData.map.enableScrollWheelZoom(true);
		mapData.map.addOverlay(mapData.marker);
		mapData.map.addControl(new window.BMap.NavigationControl());
		mapData.map.addEventListener("click", (e) => {
			console.log(e);
			selectLocation(e);
		});
		mapData.map.addEventListener("tilesloaded", function () {
			if (centerFlag && longitude) {
				mapData.map.setCenter(new window.BMap.Point(longitude, latitude));
			}
			centerFlag = false;
		});
		setMapState({ ...mapState, mapData });
	}
	function selectLocation(e) {
		let mapData = mapState.mapData;
		mapData.marker.setPosition(e.point);
		mapData.map.clearOverlays();
		mapData.map.addOverlay(mapData.marker);
		mapData.marker.enableDragging();
		mapData.map.panTo(e.point);
		setMapState({
			...mapState,
			mapData,
			longitude: e.point.lng,
			latitude: e.point.lat,
		});
	}
	function handleOk() {
		const { addressData, addressCode } = state,
			{ longitude, latitude } = mapState;
		let data = { addressData, addressCode, longitude, latitude };
		handleComplete(data);
		emptyState();
	}
	function emptyState() {
		setState({ addressCode: "", addressData: "", province: "", city: "", area: "" });
		setMapState({ mapData: { map: null, mask: null }, longitude: "", latitude: "" });
	}
	return (
		<Modal title='机构地址' visible={visible} onOk={handleOk} onCancel={handleCancel} width='680px'>
			<Alert message='客户查看时以地图定位为准，请注意保持地址与地图定位一致' type='warning' showIcon />
			<div
				className='form-group'
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "16px",
				}}>
				<Cascader
					className='form-control'
					allowClear={false}
					style={{ width: "300px" }}
					value={state.addressCode}
					options={Address}
					onChange={(e) => handleCascader(e)}
				/>
			</div>
			<div className='form-group' style={{ marginTop: "16px" }}>
				<TextArea
					id='addressDetail'
					type='text'
					placeholder='请输入具体地址'
					style={{ width: "300px" }}
					onChange={handleAddress}
					value={state.addressData}
				/>
				{/* <input type='hidden' id='address' value='飞飞汽修' autoComplete='off' /> */}
			</div>
			<div className='form-group' style={{ marginTop: "25px", marginBottom: "15px" }}>
				<div className='clearfix'></div>
				<div className='map-tips'>
					<h4>所在位置</h4>
				</div>
				<div style={{ width: "100%", height: 260 }} id='map'></div>
			</div>
		</Modal>
	);
};
export default ShopAddressModal;
