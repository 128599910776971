import React, { useState, useEffect } from "react";
import { Row, Col, Button, Select, Input, Form, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./BasicForm.less";
import GrayBox from "../../components/GrayBox";
import _Api from "../../core/api";
import CarTypeSelect from "../../components/CarTypeSelect";
import UploadImage from "../../components/UploadImage";
import UexhibitImage from "../../components/UexhibitImage";
import carImageURL from "../../libs/uploadImage.png";
import PreviewImage from "../../components/PreviewImage";

const BasicForm = ({
  handleBaseInfo,
  handleModalState,
  baseInfo,
  handleVipSet,
  // uploadImageList,
  // imageList,
  // handlImageUrl,
  // imageUrl,
  carImgs,
}) => {
  const [workerOptions, setWorkerOptions] = useState([]);
  //图片
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const handlePreview = (data)=> {
    setPreviewVisible(data)
  }
  console.log("编辑", carImgs);
  useEffect(() => {
    getWorkerList();
  }, []);

  function getWorkerList(params) {
    let data = { is_jiedai: "1" };
    _Api.workerSelect(data).then((res) => {
      const options = res.data.content.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setWorkerOptions(options);
    });
  }
  const ImageUrl = () => {
    console.log("2222-22222------------222");
    let arr = [];
    const carImage = carImgs;
    for (const key in carImage) {
      // console.log(carImage[key]);
      arr = [...arr, ...carImage[key]];
    }
    setImageUrl(arr);
  };
  useEffect(() => {
    ImageUrl();
  }, [carImgs]);

  return (
    <div className="basic-form">
      <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
        <Row
          className="basic-row"
          type="flex"
          //   gutter={100}
          // justify="space-around"
          justify="space-between"
        >
          <Col span={6} key="col-brand" className="col-brand">
            <Form.Item
              label={
                <p>
                  <span style={{ color: "#EB3628" }}>*</span>车牌号
                </p>
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Row type="flex" wrap={false} align="middle">
                <GrayBox>
                  <span
                    className="click-able theme-color"
                    onClick={() => {
                      handleModalState({ carEditV: true, carEditType: true });
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {baseInfo.carId}
                  </span>
                </GrayBox>
                <Button
                  className="btn-green"
                  size="middle"
                  onClick={() => {
                    handleModalState({ carSelectV: true });
                  }}
                >
                  选择
                </Button>
                {/* <Button
                  className="btn-red"
                  size="middle"
                  onClick={() => {
                    handleModalState({ carEditV: true, carEditType: false });
                  }}
                >
                  建档
                </Button> */}
              </Row>
            </Form.Item>
            <Form.Item name="car-linkman" label="联系人">
              <GrayBox text={baseInfo.linkman} />
            </Form.Item>
            <Form.Item name="car-vip" label="会员卡">
              <Row type="flex" wrap={false} align="middle">
                <Input
                  value={baseInfo.memberCard}
                  disabled
                  placeholder={baseInfo.hasMember ? "有会员卡消费记录" : null}
                />
                <Button
                  className="btn-green"
                  size="middle"
                  style={{ margin: "0 6px", padding: "0 3px" }}
                  onClick={() => {
                    handleModalState({ vipSelectV: true,packageV: true });
                  }}
                >
                  选择
                </Button>
                <Button
                  style={{ padding: "0 3px" }}
                  size="small"
                  onClick={() =>
                    handleVipSet({ bianhao: "", service_zk: 1, part_zk: 1 })
                  }
                >
                  清除
                </Button>
              </Row>
            </Form.Item>
          </Col>
          <Col span={6} key="col-type">
            <Form.Item labelCol={{ span: 6 }} name="car-type" label="车型">
              <GrayBox text={baseInfo.carType} />
            </Form.Item>
            <Form.Item labelCol={{ span: 6 }} name="car-phone" label="手机号码">
              <GrayBox text={baseInfo.phone} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 6 }}
              name="car-ins"
              label="下次保养说明"
            >
              <Row type="flex" wrap={false} align="middle">
                <GrayBox text={baseInfo.nextMaintainTime} />
                <Button
                  className="btn-green"
                  size="middle"
                  style={{ marginLeft: "6px" }}
                  onClick={() => {
                    handleModalState({ maintenanceV: true });
                  }}
                >
                  录入
                </Button>
              </Row>
            </Form.Item>

            {/* <Form.Item label='车型'>
							<CarTypeSelect
								value={baseInfo.carType}
								onSelect={(data) => {
									console.log(data);
									handleBaseInfo({ carType: data });
								}}
							/>
						</Form.Item> */}
          </Col>
          <Col span={6} key="col-linkman">
            <Form.Item label="本次里程">
              <Input
                value={baseInfo.curMileage}
                onChange={(e) => {
                  handleBaseInfo({ curMileage: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="接待人">
              {/* {workerOptions.length > 0 ? ( */}
              <Select
                options={workerOptions}
                value={baseInfo.worker}
                onSelect={(e) => {
                  handleBaseInfo({ worker: e });
                }}
              ></Select>
              {/* ):'暂无员工，请前往设置进行添加'} */}
            </Form.Item>
          </Col>
          <Col span={3} key="col-phone" className="basic-upload">
            <div className="basic-imgs">
              {imageUrl[0] ? (
                <img
                  src={imageUrl[0]}
                  alt="example"
                  className="umultiple-img"
                />
              ) : (
                <img
                  src={carImageURL}
                  alt="example"
                  className="umultiple-img"
                  onClick={() => {
                    handleModalState({ carImageV: true });
                  }}
                />
              )}
              {imageUrl[0] && (
                <>
                  <div
                    className="basic-count"
                    onClick={() => {
                      setPreviewVisible(true);
                    }}
                  >
                    <p> +{imageUrl.length}</p>
                  </div>
                  <div
                    className="basic-edit"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      handleModalState({ carImageV: true });
                    }}
                  >
                    <p>编辑</p>
                  </div>
                </>
              )}
              {/* <img src={carImageURL} alt="example" className="umultiple-img" /> */}
              <div className="basic-title">车辆照片</div>
            </div>

            {/* <UploadImage name="点击此处上传照片" title="车辆照片" onClick={ visibleCarImage }></UploadImage> */}
            {/* <div
              className="basic-image"
              onClick={() => {
                handleModalState({ carImageV: true });
              }}
            >
              {carImgs?.frontLeft[0] ||
              carImgs?.frontRight[0] ||
              carImgs?.backLeft[0] ||
              carImgs?.backRight[0] ||
              carImgs?.other[0] ? (
                <img
                  src={
                    carImgs?.frontLeft[0] ||
                    carImgs?.frontRight[0] ||
                    carImgs?.backLeft[0] ||
                    carImgs?.backRight[0] ||
                    carImgs?.other[0]
                  }
                  alt="avatar"
                  className="basic-car-image"
                />
              ) : (
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    点击此处上传照片
                  </div>
                </div>
              )}
            </div> */}
            {/* <p className="basic-title">车辆照片</p> */}
          </Col>
        </Row>
      </Form>
      <PreviewImage visible={previewVisible} list={imageUrl} handlePreview={handlePreview}></PreviewImage>
    </div>
  );
};
export default BasicForm;
