import React, { Component } from "react";
import axios from "axios";
import Cropper from "react-cropper";
import { Modal, Button, message, Spin } from "antd";
import User from "../../core/user";
export class ImgCutModal extends Component {
	state = {
		uploadFlag: false,
	};
	handleOk = () => {
		const { token, flag } = this.props;
		if (!this.state.uploadFlag) {
			this.setState({
				uploadFlag: true,
			});
			this.refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
				const formData = new FormData();
				formData.append("file", blob);
				formData.append("user_type", "shop");
				if (flag === "service_wx" || flag === "business_wx") {
					formData.append("img_type", flag);
				}
				axios
					.post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" + User.token(), formData)
					.then((res) => {
						this.setState({ uploadFlag: false });
						if (res.data.response === "200") {
							message.success("图片上传成功");
							console.log(res.data.img_url);
							this.props.setImg(res.data.img_url);
							this.props.onCancel();
						} else {
							message.info(res.data.msg);
						}
					});
			});
		}
	};
	render() {
		const { uploadFlag } = this.state;
		const { c_width, c_height } = this.props;
		return (
			<Modal title='图片裁剪' visible={this.props.cutVisible} onCancel={this.props.onCancel} footer={null}>
				<Spin spinning={uploadFlag} tip='图片上传中...'>
					<Cropper
						src={this.props.src}
						className='img-cropper'
						ref='cropper'
						viewMode={1} //定义裁剪框不能移到画布外
						dragMode='move' //鼠标拖动时移动画布
						zoomable={true}
						movable={true}
						background={true}
						cropBoxResizable={false} // 禁止拉伸裁剪框
						aspectRatio={c_width / c_height} // 这个是设置比例的参数
						guides={false}
						preview='.cropper-preview'
						checkCrossOrigin={false}
						style={{ height: 375, width: 480 }}
					/>

					<div className='cropper-handle' style={{ position: "relative", marginTop: "20px" }}>
						<div
							className='cropper-preview'
							style={{
								width: "201px",
								height: "154.8px",
								overflow: "hidden",
							}}></div>
						<Button
							style={{
								position: "absolute",
								bottom: "0px",
								right: "66px",
							}}
							onClick={this.props.onCancel}>
							取消
						</Button>
						<Button
							type='primary'
							style={{
								position: "absolute",
								bottom: "0px",
								right: "0px",
							}}
							onClick={this.handleOk}>
							确定
						</Button>
					</div>
				</Spin>
			</Modal>
		);
	}
}
export default ImgCutModal;
