import { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Input, message, Radio } from "antd";
import Api from "../../core/api";
import mathFunc from "../../core/mathfunc";
import PromoteInput from "../PromoteInput";
const { TextArea } = Input;
const PackageAdd = ({
  visible,
  closeModal,
  editType,
  packageType,
  curPackage,
  getPackageList,
}) => {
  const [pageState, setPageState] = useState({
    id: "",
    packageType: "储值卡",
    name: "",
    serviceZk: "",
    partZk: "",
    price: "",
    priceZs: "",
    remark: "",
    allNum: "",
  });
  // 修改 回填
  useEffect(() => {
    if (visible) {
      const {
        id,
        name,
        part_zk,
        price,
        price_zs,
        remark,
        service_zk,
        package_type,
        all_num,
      } = curPackage;
      setPageState({
        id,
        name,
        serviceZk: mathFunc.getPercent(service_zk),
        partZk: mathFunc.getPercent(part_zk),
        price: mathFunc.reservedDecimal(price),
        priceZs: price_zs,
        remark,
        packageType: package_type,
        allNum: mathFunc.reservedDecimal(all_num),
      });
    }
    // console.log("回填啦", curPackage);
  }, [visible, editType, curPackage]);
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  useEffect(() => {
    if (packageType) {
      setPageState({ ...pageState, packageType: "计次卡" });
    } else {
      setPageState({ ...pageState, packageType: "储值卡" });
    }
  }, [packageType]);
  // 类型改变
  function changeType(e) {
    console.log(e.target.value);
    setPageState({ ...pageState, packageType: e.target.value });
  }
  // 确定逻辑
  function handleOk(params) {
    const {
      id,
      packageType,
      name,
      serviceZk,
      partZk,
      price,
      priceZs,
      remark,
      allNum,
    } = pageState;
    console.log(pageState);
    if (packageType === "储值卡") {
      let data = {
        id,
        name,
        service_zk: serviceZk / 100,
        part_zk: partZk / 100,
        price,
        price_zs: priceZs,
        remark,
        package_type: packageType,
      };
      Api.packageInsert(data).then((res) => {
        message.success(res.msg);
        // initModal();
        getPackageList();
        closeModal();
      });
    }
    if (packageType === "计次卡") {
      let data = {
        id,
        name,
        price,
        all_num: allNum,
        remark,
        package_type: packageType,
      };
      Api.packageInsertCount(data).then((res) => {
        message.success(res.msg);
        // initModal();
        getPackageList();
        closeModal();
      });
    }
  }
  // 初始化数据
  function initModal() {
    setPageState({
      id: "",
      packageType: "储值卡",
      name: "",
      serviceZk: "",
      partZk: "",
      price: "",
      priceZs: "",
      remark: "",
      allNum: "",
    });
  }
  console.log(curPackage);
  // console.log(pageState);
  return (
    <Modal
      title="修改套餐"
      visible={visible}
      width={900}
      zIndex={1011}
      onCancel={() => {
        // initModal();
        closeModal();
      }}
      onOk={handleOk}
      className="top-spacing"
    >
      <Form {...layout}>
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 10 }}
              // label={
              //   <p>
              //     <span style={{ color: "#EB3628" }}>*</span>套餐类型
              //   </p>
              // }
              label="套餐类型"
            >
              <Radio.Group
                onChange={changeType}
                className="combo-add-type"
                value={pageState.packageType}
              >
                {curPackage.package_type === "储值卡" ? (
                  <Radio value="储值卡">储值套餐</Radio>
                ) : (
                  <Radio value="计次卡">计次套餐</Radio>
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {pageState.packageType === "计次卡" && (
          <Row>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                label="套餐名称"
              >
                <Input
                  value={pageState.name}
                  placeholder="请输入套餐名称"
                  onChange={(e) => {
                    setPageState({ ...pageState, name: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 4, offset: 2 }}
                wrapperCol={{ span: 20 }}
                label="套餐售价"
              >
                <PromoteInput
                  width={320}
                  placeholder="请输入售价"
                  value={pageState.price}
                  unit="元"
                  type="价格"
                  onChange={(e) => {
                    setPageState({ ...pageState, price: e.target.value });
                  }}
                ></PromoteInput>
              </Form.Item>
            </Col>
          </Row>
        )}
        {pageState.packageType === "储值卡" && (
          <>
            {" "}
            <Row>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                  // label={
                  //   <p>
                  //     <span style={{ color: "#EB3628" }}>*</span>套餐名称
                  //   </p>
                  // }
                  label="套餐名称"
                >
                  <Input
                    value={pageState.name}
                    placeholder="请输入套餐名称"
                    onChange={(e) => {
                      setPageState({ ...pageState, name: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                  // label={
                  //   <p>
                  //     <span style={{ color: "#EB3628" }}>*</span>套餐售价
                  //   </p>
                  // }
                  label="套餐售价"
                >
                  <PromoteInput
                    width={320}
                    placeholder="请输入售价"
                    value={pageState.price}
                    unit="元"
                    type="价格"
                    onChange={(e) => {
                      setPageState({ ...pageState, price: e.target.value });
                    }}
                  ></PromoteInput>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                  label="项目折扣"
                >
                  <PromoteInput
                    width={320}
                    placeholder="请输入折扣"
                    value={pageState.serviceZk}
                    unit="%"
                    type="折扣"
                    onChange={(e) => {
                      setPageState({ ...pageState, serviceZk: e.target.value });
                    }}
                  ></PromoteInput>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 4, offset: 2 }}
                  wrapperCol={{ span: 20 }}
                  label="赠送金额"
                >
                  <PromoteInput
                    width={320}
                    placeholder="请输入赠送金额"
                    value={pageState.priceZs}
                    unit="元"
                    type="价格"
                    onChange={(e) => {
                      setPageState({ ...pageState, priceZs: e.target.value });
                    }}
                  ></PromoteInput>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 4, offset: 2 }}
                  wrapperCol={{ span: 20 }}
                  label="配件折扣"
                >
                  <PromoteInput
                    width={320}
                    placeholder="请输入配件折扣"
                    value={pageState.partZk}
                    unit="%"
                    type="折扣"
                    onChange={(e) => {
                      setPageState({ ...pageState, partZk: e.target.value });
                    }}
                  ></PromoteInput>
                </Form.Item>
              </Col>
            </Row>{" "}
          </>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              label="套餐说明"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
            >
              {/* <Input
                value={pageState.remark}
                onChange={(e) => {
                  setPageState({ ...pageState, remark: e.target.value });
                }}
              /> */}
              <TextArea
                onChange={(e) => {
                  setPageState({ ...pageState, remark: e.target.value });
                }}
                value={pageState.remark}
                rows={8}
                placeholder="请输入套餐说明"
              />
            </Form.Item>
          </Col>
        </Row>
        {pageState.packageType === "计次卡" && (
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 9 }}
                label="次数"
              >
                <PromoteInput
                  width={320}
                  placeholder="请输入套餐次数"
                  value={pageState.allNum}
                  unit="次"
                  type="次数"
                  onChange={(e) => {
                    setPageState({ ...pageState, allNum: e.target.value });
                  }}
                ></PromoteInput>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};
export default PackageAdd;
