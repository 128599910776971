import { Progress, Modal } from "antd";
import "./ProgressBar.less";
import SuccessImgs from "../../libs/export_success.png";

const ProgressBar = ({
  visible,
  progress,
  canCelAxios,
  title,
  canCel,
  type,
}) => {
  return (
    <Modal
      width={1015}
      visible={visible}
      footer={null}
      className="progress-bar"
    >
      <div style={{ position: "relative" }}>
        <p>
          {type === "export"
            ? progress?.percent === 100
              ? "导出成功"
              : "正在导出"
            : "正在导入"}{" "}
          -{title}
          {type === "export" && progress?.percent === 100 && (
            <span>（你可在本地文件夹查看）</span>
          )}
        </p>
        <Progress
          strokeColor={{
            "0%": "#FF9551",
            "100%": "#FF9551",
          }}
          percent={progress?.percent}
          showInfo={false}
        ></Progress>
        {type === "export" && progress?.percent === 100 && (
          <div className="success">
            <img src={SuccessImgs} alt="" />
          </div>
        )}
        <div className="button">
          {progress?.percent === 100 ? (
            <button onClick={canCel}>取消</button>
          ) : (
            <button onClick={canCelAxios}>取消</button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProgressBar;
