import React, { Component } from "react";
import { Input, Modal, Tabs, Button } from "antd";

import "./preview.less";

class MobilePreview extends Component {
	state = {
		list: this.props.list,
	};
	componentDidMount() {}

	componentWillMount() {}

	edit = (menu, index, e) => {
		e.stopPropagation();
		let list = this.state.list;
		let flag = list[index].current;
		for (var i = 0; i < list.length; i++) {
			list[i].current = false;
			for (let j = 0; j < list[i].sub_button.length; j++) {
				list[i].sub_button[j].current = false;
			}
		}
		if (flag) {
			list[index].current = false;
		} else {
			list[index].current = true;
		}

		this.setState({
			list,
		});
	};

	sub_edit = (index, sub, sub_index, e) => {
		e.stopPropagation();
	};

	render() {
		const { list } = this.state;
		const { match, name } = this.props;
		return (
			<div className='mask' onClick={this.props.close}>
				<div className='mobile_preview'>
					<div className='mobile_preview_hd'>
						<strong className='nickname'>{name}</strong>
					</div>
					<div className='mobile_preview_bd'>
						<ul id='viewShow' className='show_list'></ul>
					</div>
					<div className='mobile_preview_ft'>
						<ul className='pre_menu_list grid_line'>
							{list.map((item, index) => (
								<li
									onClick={this.edit.bind(this, item, index)}
									key={item.id}
									className={`pre_menu_item size1of${list.length} ${item.current ? "current" : ""}`}>
									<a className='pre_menu_link'>
										<i className='icon_menu_dot js_icon_menu_dot'></i>
										<span>{item.name}</span>
									</a>
									{item.current && item.sub_button.length > 0 && (
										<div className='sub_pre_menu_box'>
											<ul className='sub_pre_menu_list'>
												{item.sub_button &&
													item.sub_button.map((sub, sub_index) => (
														<li
															onClick={this.sub_edit.bind(this, index, sub, sub_index)}
															key={sub.sub_id}
															className={`${sub.current ? "current" : ""}`}>
															<a>{sub.name}</a>
														</li>
													))}
											</ul>
										</div>
									)}
								</li>
							))}
						</ul>
					</div>
					<div style={{ textAlign: "center" }}>
						<Button onClick={this.props.close}>退出预览</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default MobilePreview;
