import { useEffect, useState } from "react";
import { Button, Col, Popconfirm, message, Row, Table, Divider } from "antd";
import PageControl from "../../components/PageControl";
import _Api from "../../core/api";
import SearchBar from "../../components/SearchBar";
import PartHistoryModal from "../../components/modals/PartHistory";
const InventoryAnalysis = ({}) => {
	const [partState, setPartState] = useState({
		keyword: "",
		list: [],
		pageNo: "1",
		pageSize: "10",
		total: "",
	});
	const [modalState, setModalState] = useState({ type: "", id: "", name: "", brand: "", model: "", visible: false });
	useEffect(() => {
		getPartList();
	}, []);

	function getPartList(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1";
		let data = {
			keyword: partState.keyword,
			count: pageSize,
			page: pageNo,
		};
		_Api.partSelect(data).then((res) => {
			let _data = res.data;
			setPartState({
				...partState,
				list: _data.content,
				total: _data.allcount,
				pageNo: _data.curpage,
				pageSize: _data.pagecount,
			});
		});
	}
	function scanHistory({ type, part }) {
		setModalState({
			type,
			id: part.bianhao,
			name: part.name,
			brand: part.brand,
			model: part.model,
			visible: true,
		});
	}

	const columns = [
		{
			title: "编号",
			dataIndex: "bianhao",
			render: (text) => (text ? text : "--"),
		},
		{
			title: "配件名称",
			dataIndex: "name",
			align: "center",
		},
		{
			title: "品牌及规格",
			dataIndex: "brand",
			align: "center",
			render: (text, record) => {
				let brand = text ? text : "-",
					model = record.model ? record.model : "-";
				return brand + " " + model;
			},
		},
		{
			title: "单位",
			dataIndex: "unit",
			align: "center",
			render: (text) => (text ? text : "-"),
		},

		{
			title: "安全库存",
			dataIndex: "safe_num",
			align: "center",
		},

		{
			title: "采购数量",
			dataIndex: "buy_num",
			align: "center",
			render: (text, record) => (
				<span
					className='click-able theme-color'
					onClick={() => {
						scanHistory({ type: "采购", part: record });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "采购退货",
			dataIndex: "buy_back_num",
			align: "center",
			render: (text, record) => (
				<span
					className='click-able theme-color'
					onClick={() => {
						scanHistory({ type: "采购退货", part: record });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "销售数量",
			dataIndex: "sale_num",
			align: "center",
			render: (text, record) => (
				<span
					className='click-able theme-color'
					onClick={() => {
						scanHistory({ type: "销售", part: record });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "销售退货",
			dataIndex: "sale_back_num",
			align: "center",
			render: (text, record) => (
				<span
					className='click-able theme-color'
					onClick={() => {
						scanHistory({ type: "销售退货", part: record });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "工单使用",
			dataIndex: "work_num",
			align: "center",
			render: (text, record) => (
				<span
					className='click-able theme-color'
					onClick={() => {
						scanHistory({ type: "工单使用", part: record });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "盘点数量",
			dataIndex: "pandian_num",
			align: "center",
			render: (text, record) => (
				<span
					className='click-able theme-color'
					onClick={() => {
						scanHistory({ type: "盘点", part: record });
					}}>
					{text}
				</span>
			),
		},
		{
			title: "当前库存",
			dataIndex: "cur_num",
			align: "center",
		},
	];
	return (
		<div className='template-dynamic-show-list page'>
			<section className='content-header'>
				<h1>库存分析</h1>
			</section>
			<div className='box'>
				<div className='box-header'>
					<Row type='flex' justify='space-between'>
						<Col>
							<SearchBar
								searchText={partState.keyword}
								onChangeText={(text) => {
									setPartState({ ...partState, keyword: text });
								}}
								onSearch={getPartList}
								label='搜索'
								placeholder='请输入编号/名称/品牌/型号'
							/>
						</Col>
					</Row>
				</div>
				<div className='box-body'>
					<Table
						rowKey={(record) => record.id}
						pagination={false}
						dataSource={partState.list}
						columns={columns}
						bordered
						style={{ marginBottom: "40px" }}
					/>
					<PageControl
						total={partState.total}
						pageSize={partState.pageSize}
						current={partState.pageNo}
						showSizeChanger
						toPage={(page, count) => {
							getPartList({ pageNo: page, pageSize: count });
						}}
					/>
				</div>
			</div>
			<PartHistoryModal
				visible={modalState.visible}
				partInfo={modalState}
				closeModal={() => {
					setModalState({ type: "", id: "", name: "", brand: "", model: "", visible: false });
				}}
			/>
		</div>
	);
};

export default InventoryAnalysis;
