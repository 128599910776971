import { useRef } from "react";
import { Modal, Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React from "react";
import "./PreviewImage.less";
const contentStyle = {
  height: "400px",
  lineHeight: "400px",
  textAlign: "center",
  background: "#364d79",
};

const PreviewImage = ({ visible, list, handlePreview }) => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const carouselEL = useRef(null);
  return (
    <Modal
      // mask={false}
      width={1000}
      visible={visible}
      title="预览图片"
      footer={null}
      onCancel={() => handlePreview(false)}
    >
      <div className="preview-image">
        <Button
          className="leftButton"
          style={{ left: 55 }}
          onClick={() => {
            carouselEL.current.prev();
          }}
          icon={<LeftOutlined />}
        ></Button>
        <Button
          className="rightButton"
          style={{ right: 55 }}
          onClick={() => {
            carouselEL.current.next();
          }}
          icon={<RightOutlined />}
        ></Button>
        <Carousel
          //   autoplay
        //   style={{ margin: "30px 50px 0 50px", paddingTop: 50 }}
          ref={carouselEL}
        >
          {list.map((item, index) => {
            return (
              <div key={index} style={contentStyle}>
                  <div className="img">
                      <img src={item} alt="" />
                  </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </Modal>
  );
};

export default PreviewImage;
