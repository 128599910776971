import "./index.less";
import TwoDefault from "../../libs/import_two_active.png";
import ThreeDefault from "../../libs/import_three_active.png";
const PublicTemplateActive = ({ type, title, isTrue, children }) => {
  return (
    <div
      className={
        isTrue ? "batch-download-active export" : "batch-download-active"
      }
    >
      <div className="batch-title">
        {type === "download" ? (
          <img src={TwoDefault} alt="" />
        ) : type === "export" ? (
          <img src={TwoDefault} alt="" />
        ) : (
          <img src={ThreeDefault} alt="" />
        )}

        <span>{title}</span>
      </div>
      {type === "download" && (
        <div className="batch-info">（如已下载过模版，可跳过此步）</div>
      )}
      {type === "upload" && (
        <div className="batch-info">（仅支持xls、xlsx格式文件）</div>
      )}

      <div
        className={type === "download" ? "batch-img" : "batch-img-two"}
      ></div>
      <div className="batch-button">{children}</div>
    </div>
  );
};
export default PublicTemplateActive;
