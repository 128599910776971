import { Row, Input, Form, Table, DatePicker } from "antd";
import mathFunc from "../../core/mathfunc";
import moment from "moment";
export default ({
  slip,
  setTable,
  tableState,
  discount,
  viewmode,
  purchase,
  isTrue,
}) => {
  const handleDataChange = (value, key, index) => {
    let _data = [...tableState.data];
    _data.splice(index, 1, { ..._data[index], [key]: value });
    let amount = 0;
    let ids = _data.map((item) => {
      let price;
      if (purchase) {
        price = item.priceBasic ? parseFloat(item.priceBasic) : 0;
      } else {
        price = item.priceSale ? parseFloat(item.priceSale) : 0;
      }

      let num = item.num ? parseInt(item.num) : 0;
      amount += price * num;
      return item.id;
    });
    if (discount) {
      let ar = amount * parseFloat(discount);
      amount = mathFunc.returnFloat(amount);
      ar = mathFunc.returnFloat(ar);
      setTable({ data: _data, ids: ids, amount, ar });
    } else {
      amount = mathFunc.returnFloat(amount);
      setTable({ data: _data, ids, amount });
    }
  };
  let columns = [
    {
      title: "序号",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "编号",
      dataIndex: "bianhao",
    },
    {
      title: "配件名称",
      dataIndex: "name",
    },
    {
      title: "品牌及规格型号",
      dataIndex: "brand",
      render: (text, record) => (
        <span>{(text || "-") + " " + (record.model || "-")}</span>
      ),
    },
    {
      title: isTrue ? "成本价" : "单价",
      dataIndex: purchase ? "priceBasic" : "priceSale",
      align: "center",
      render: (text, record, index) =>
        viewmode ? (
          text
        ) : (
          <Input
            value={text}
            style={{ width: "100px", textAlign: "right" }}
            onChange={(e) => {
              if (e.target.value.length > 8) {
                return;
              }
              let price = mathFunc.checkPrice(e.target.value);
              handleDataChange(price, "priceSale", index);
            }}
          />
        ),
    },
    {
      title: "数量",
      dataIndex: "num",
      align: "center",
      render: (text, record, index) =>
        viewmode ? (
          text + record.unit
        ) : (
          <Input
            value={text}
            suffix={record.unit}
            style={{ width: "60px", textAlign: "right" }}
            onChange={(e) => {
              let num = e.target.value.replace(/[^\d]/g, "");
              handleDataChange(num, "num", index);
            }}
          />
        ),
    },

    {
      title: "金额",
      key: "sum",
      render: (text, record) => {
        let price;
        if (purchase) {
          price = record.priceBasic ? record.priceBasic : 0;
        } else {
          price = record.priceSale ? record.priceSale : 0;
        }

        let hours = record.num ? record.num : 0;
        return mathFunc.multi(price, hours);
      },
    },
    {
      title: "质保时间",
      dataIndex: "qualityDate",
      align: "center",
      width: "200px",
      render: (text, record, index) =>
        viewmode ? (
          text
        ) : (
          <FullDatePicker
            value={text && moment(text)}
            onChange={(mome, str) => {
              handleDataChange(str, "qualityDate", index);
            }}
          />
        ),
    },
  ];
  if (!viewmode) {
    columns = [
      ...columns,
      {
        title: "删除",
        align: "center",
        key: "delete",
        render: (text, record, index) => (
          <span
            className="icon iconfont theme-color click-able"
            onClick={() => {
              let _data = [...tableState.data];
              _data.splice(index, 1);
              let amount = 0;
              let ids = _data.map((item) => {
                let price = item.priceSale ? parseFloat(item.priceSale) : 0;
                let hours = item.num ? parseFloat(item.num) : 0;
                amount += price * hours;
                return item.id;
              });
              if (tableState.ar) {
                let ar = amount * parseFloat(discount);
                setTable({ data: _data, ids: ids, amount, ar });
              } else {
                setTable({ data: _data, ids: ids, amount });
              }
            }}
          >
            &#xe61b;
          </span>
        ),
      },
    ];
  }
  const FullDatePicker = (props) => (
    <div
      style={{
        width: "100%",
        border: "#E8E8E8 1px solid",
        borderRadius: "5px",
      }}
    >
      <DatePicker bordered={false} style={{ width: "100%" }} {...props} />
    </div>
  );
  return (
    <Table
      rowKey={(record) => record.id}
      pagination={false}
      dataSource={tableState ? tableState.data : []}
      columns={columns}
      bordered
      style={{ marginTop: "20px", marginBottom: "40px" }}
      locale={{
        emptyText: slip ? "暂无数据" : "请点击表格右上方按钮选择或新增商品配件",
      }}
      footer={
        slip
          ? () => (
              <Row type="flex" justify="end" style={{ maxHeight: "20px" }}>
                <Form layout="inline">
                  <Form.Item
                    name="shouldreceive"
                    label={tableState.ar ? "配件费" : "合计"}
                  >
                    <span>{mathFunc.returnFloat(tableState.amount) || 0}</span>
                  </Form.Item>
                  {discount && (
                    <>
                      <Form.Item name="fee" label="配件折扣">
                        <span>{mathFunc.getPercent(discount) + "%"}</span>
                      </Form.Item>
                      <Form.Item name="realreceive" label="应收配件费">
                        {/* <span>{tableState.ar || 0}</span> */}
                        <span>{mathFunc.returnFloat(tableState.ar || 0)}</span>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </Row>
            )
          : null
      }
    />
  );
};
