import React, { Component } from "react";
import { Pagination } from "antd";
import "./PageControl.less";

export default class PageControl extends Component {
	render() {
		const { current, total, pageSize, showDataNums, showSizeChanger, size, toPage, className, onShowSizeChange } =
			this.props;
		const newClassName = "sf-page-control " + className;
		return (
			<div className={newClassName} style={{ ...this.props.style }}>
				{(undefined === showDataNums || showDataNums) && <span>共{total}条数据</span>}
				<div style={{ display: "inline", float: "right" }}>
					<Pagination
						size={size}
						current={parseInt(current)}
						total={total || 0}
						pageSizeOptions={["5", "10", "20", "30", "40", "50"]}
						onShowSizeChange={onShowSizeChange}
						showSizeChanger={showSizeChanger}
						pageSize={pageSize || 10}
						onChange={(page, count) => {
							toPage(page, count);
						}}
						showQuickJumper={true}
					/>
				</div>
			</div>
		);
	}
}
