import "./Spinner.less";
const Spinner = ({ spinner, text }) => (
	<div className={spinner ? "spinner" : "spinner hidden"}>
		<div className='warpper'>
			<div className='inner'></div>
			<div className='text'>{text ? text : "数据加载中..."}</div>
		</div>
	</div>
);
export default Spinner;
