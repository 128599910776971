import { Input } from "antd";
import { useRef, useState } from "react";
import "./PromoteInput.less";
const PromoteInput = ({
  placeholder,
  value,
  unit,
  onChange,
  width,
  disabled,
  type,
}) => {
  // console.log(disabled);
  const [visible, setVisible] = useState(true);
  const inputRef = useRef(null);
  //   点击显示input
  function setShow() {
    if (disabled) {
      return;
    }
    setVisible(false);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      inputRef.current.focus();
    }, 100);
  }
  //input 失焦
  function onBlur() {
    setVisible(true);
  }
  // 保留小数
  function keepDecimals(num) {
    let index = num.indexOf(".");
    console.log(index);
    if (index !== -1) {
      console.log(type);
      switch (type) {
        case "价格":
          console.log(num);
          if (num[index + 3]) {
            return true;
          }
          break
        case "折扣":
          return true;
          break
        case "次数":
          if (num[index + 2]) {
            return true;
          }
          break
        default:
          return false;
      }
    }else {
      return false
    }
  }
  return (
    <div>
      {visible ? (
        <div
          onClick={setShow}
          className={disabled ? "promote-input" : "promote-box "}
          style={{ width }}
        >
          {" "}
          {value ? (
            <>
              <span className="promote-value">{value}</span>
              <span>{unit}</span>
            </>
          ) : (
            <span className="promote-placeholder">{placeholder}</span>
          )}
        </div>
      ) : null}
      <Input
        disabled={disabled}
        style={{ display: visible ? "none" : "block" }}
        ref={inputRef}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const falg = keepDecimals(e.target.value);
          if (falg) return;
          onChange(e);
        }}
      />
    </div>
  );
};
export default PromoteInput;
