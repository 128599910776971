const config = {
    localServer: "https://c.sanfeng9.com/",

    server: {
        dev: 'https://api.sanfeng9.com/',
        dev1: 'https://api.sanfeng9.com/',
        rc: 'https://api.sanfeng9.com/',
        prod: 'https://api.sanfeng9.com/',
        gray: 'https://api.sanfeng9.com/'
    },
    moneyUnit:1
}
const env = 'dev';
window.CONFIG = {
    server: process.env.DEPLOY_ENV ? config.server[process.env.DEPLOY_ENV] : 'https://api.sanfeng9.com/',
    version: 'v5.0.0',
    isWindows: navigator.userAgent.indexOf("Windows", 0) !== -1 || navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1,
    env,
    moneyUnit:1
};



export default config;
