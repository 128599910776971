import axios from "./axios";
const _Api = {
  userInfoGet(data) {
    return axios.post("shop/login.php?cmd=web_login_set", data);
  },
  carInsert(data) {
    return axios.post("shop/car.php?cmd=car_insert", data);
  },
  carGet(data) {
    return axios.post("shop/car.php?cmd=car_select", data);
  },
  carDelete(data) {
    return axios.post("shop/car.php?cmd=car_delete", data);
  },
  carBrandGet(data) {
    return axios.post("shop/car.php?cmd=get_brands", data);
  },
  carSubBrandGet(data) {
    return axios.post("shop/car.php?cmd=get_brands_sub", data);
  },
  serviceInsert(data) {
    return axios.post("shop/service.php?cmd=service_insert", data);
  },
  serviceSelect(data) {
    return axios.post("shop/service.php?cmd=service_select", data);
  },
  serviceDelete(data) {
    return axios.post("shop/service.php?cmd=service_delete", data);
  },
  partInsert(data) {
    return axios.post("shop/part.php?cmd=part_insert", data);
  },
  partSelect(data) {
    return axios.post("shop/part.php?cmd=part_select", data);
  },
  partReset(data) {
    return axios.post("shop/part.php?cmd=part_reset", data);
  },
  partDelete(data) {
    return axios.post("shop/part.php?cmd=part_delete", data);
  },
  // 修改配件销售会员卡
  partMeberCardSet(data) {
    return axios.post(
      "shop/parts_sale.php?cmd=parts_sale_member_card_set",
      data
    );
  },
  orderInsert(data) {
    return axios.post("shop/work_orders.php?cmd=order_insert", data);
  },
  // 修改结算工单会员卡
  orderMeberCardSet(data) {
    return axios.post("shop/work_orders.php?cmd=order_member_card_set", data);
  },
  orderSelect(data) {
    return axios.post("shop/work_orders.php?cmd=order_select", data);
  },
  orderDelete(data) {
    return axios.post("shop/work_orders.php?cmd=order_delete", data);
  },
  orderCaiwuInsert(data) {
    return axios.post("shop/work_orders.php?cmd=order_caiwu_insert", data);
  },
  orderCaiwuDelete(data) {
    return axios.post("shop/work_orders.php?cmd=order_caiwu_delete", data);
  },
  packageInsert(data) {
    return axios.post("shop/member.php?cmd=package_insert", data);
  },
  packageInsertCount(data) {
    return axios.post("shop/member.php?cmd=package_insert_num", data);
  },
  packageSelect(data) {
    return axios.post("shop/member.php?cmd=package_select", data);
  },
  packageDelete(data) {
    return axios.post("shop/member.php?cmd=package_delete", data);
  },
  cardInsert(data) {
    return axios.post("shop/member.php?cmd=card_insert", data);
  },
  cardSelect(data) {
    return axios.post("shop/member.php?cmd=card_select", data);
  },
  cardDelete(data) {
    return axios.post("shop/member.php?cmd=card_delete", data);
  },
  cardCheck(data) {
    return axios.post("shop/member.php?cmd=member_card_check", data);
  },
  cardCharge(data) {
    return axios.post("shop/member.php?cmd=card_add_money", data);
  },
  cardChargeHistory(data) {
    return axios.post("shop/member.php?cmd=card_add_money_list", data);
  },
  workerInsert(data) {
    return axios.post("shop/worker.php?cmd=worker_insert", data);
  },
  workerSelect(data) {
    return axios.post("shop/worker.php?cmd=worker_select", data);
  },
  workerDelete(data) {
    return axios.post("shop/worker.php?cmd=worker_delete", data);
  },
  supplierInsert(data) {
    return axios.post(
      "shop/parts_supplier.php?cmd=parts_supplier_insert",
      data
    );
  },
  supplierSelect(data) {
    return axios.post(
      "shop/parts_supplier.php?cmd=parts_supplier_select",
      data
    );
  },
  supplierDelete(data) {
    return axios.post(
      "shop/parts_supplier.php?cmd=parts_supplier_delete",
      data
    );
  },
  partBuyInsert(data) {
    return axios.post("shop/parts_buy.php?cmd=parts_buy_insert", data);
  },
  partBuySelect(data) {
    return axios.post("shop/parts_buy.php?cmd=parts_buy_select", data);
  },
  partBuyDelete(data) {
    return axios.post("shop/parts_buy.php?cmd=parts_buy_delete", data);
  },
  partSaleSelect(data) {
    return axios.post("shop/parts_sale.php?cmd=parts_sale_select", data);
  },
  partSaleInsert(data) {
    return axios.post("shop/parts_sale.php?cmd=parts_sale_insert", data);
  },
  partSaleDelete(data) {
    return axios.post("shop/parts_sale.php?cmd=parts_sale_delete", data);
  },
  partCaiwuInsert(data) {
    return axios.post("shop/parts_sale.php?cmd=order_caiwu_insert", data);
  },
  partCaiwuDelete(data) {
    return axios.post("shop/parts_sale.php?cmd=order_caiwu_delete", data);
  },
  caiwuSelect(data) {
    return axios.post("shop/statistics.php?cmd=caiwu_select", data);
  },
  branchDetail(data) {
    return axios.post("shop/statistics.php?cmd=branch_detail", data);
  },
  payTypeBl(data) {
    return axios.post("shop/statistics.php?cmd=pay_type_bl", data);
  },
  partBl(data) {
    return axios.post("shop/statistics.php?cmd=parts_income_bl", data);
  },
  partPh(data) {
    return axios.post("shop/statistics.php?cmd=parts_sale_ph", data);
  },
  incomeQs(data) {
    return axios.post("shop/statistics.php?cmd=income_qs", data);
  },
  stockSelect(data) {
    return axios.post("shop/statistics.php?cmd=parts_in_out_list", data);
  },
  userSelect(data) {
    return axios.post("shop/user.php?cmd=all_userinfo", data);
  },
  userInsert(data) {
    return axios.post("shop/user.php?cmd=user_insert", data);
  },
  userDelete(data) {
    return axios.post("shop/user.php?cmd=user_delete", data);
  },
  userChange(data) {
    return axios.post("shop/user.php?cmd=user_change", data);
  },
  getLimitDic(data) {
    return axios.post("shop/user.php?cmd=limit_dic", data);
  },
  logSelect(data) {
    return axios.post("shop/branch.php?cmd=branch_log", data);
  },
  logTypeSelect(data) {
    return axios.post("shop/branch.php?cmd=get_log_type", data);
  },
  shopData(data) {
    return axios.post("shop/shop.php?cmd=shop_data", data);
  },
  branchNameSet(data) {
    return axios.post("shop/shop.php?cmd=branch_name_set", data);
  },
  branchChange(data) {
    return axios.post("shop/shop.php?cmd=branch_change", data);
  },
  getLoginSet(data) {
    return axios.post("shop/login.php?cmd=web_login_set", data);
  },
  webOpenClose(data) {
    return axios.post("shop/shop.php?cmd=web_open_close", data);
  },
  webDomainSet(data) {
    return axios.post("shop/shop.php?cmd=web_domain_set", data);
  },
  setLoginSet(data) {
    return axios.post("shop/shop.php?cmd=web_login_set", data);
  },
  getPayType() {
    return axios.post("shop/main.php?cmd=pay_type", {});
  },
  branchSet(data) {
    return axios.post("shop/branch.php?cmd=branch_set", data);
  },
  printSet(data) {
    return axios.post("shop/branch.php?cmd=print_set", data);
  },
  shopWxGet(data) {
    return axios.post("shop/wx_web.php?cmd=branch_info", data);
  },
  wxInfoGet(data) {
    return axios.post("shop/weixin.php?cmd=get_wx_basic_info", data);
  },
  wxUnbind(data) {
    return axios.post("shop/weixin.php?cmd=wx_gzh_release", data);
  },
  wxInfoSet(data) {
    return axios.post("shop/weixin.php?cmd=wxgzh_set_basic", data);
  },
  wxMenuCreat(data) {
    return axios.post("shop/weixin.php?cmd=creat_menu", data);
  },
  // wxUserSelect(data) {
  //   return axios.post("shop/wx_customer.php?cmd=user_select", data);
  // },
  wxUserSelect(data) {
    return axios.post("shop/wx_customer_v2.php?cmd=user_select", data);
  },
  // wxUserModify(data) {
  //   return axios.post("shop/wx_customer.php?cmd=user_modify", data);
  // },
  wxUserModify(data) {
    return axios.post("shop/wx_customer_v2.php?cmd=user_modify", data);
  },
  wxTemplateGet(data) {
    return axios.post("shop/weixin.php?cmd=wx_msg_template", data);
  },
  wxMsgSend(data) {
    return axios.post("shop/weixin.php?cmd=send_wx_msg", data);
  },
  wxMsgGet(data) {
    return axios.post("shop/weixin.php?cmd=wx_msg_list", data);
  },
  wxMsgDelete(data) {
    return axios.post("shop/weixin.php?cmd=wx_msg_del", data);
  },
  webSelect(data) {
    return axios.post("shop/wx_web.php?cmd=web_select", data);
  },
  webIsShow(data) {
    return axios.post("shop/wx_web.php?cmd=web_is_show", data);
  },
  webInsert(data) {
    return axios.post("shop/wx_web.php?cmd=web_insert", data);
  },
  webDelete(data) {
    return axios.post("shop/wx_web.php?cmd=web_delete", data);
  },
  webOrders(data) {
    return axios.post("shop/wx_web.php?cmd=web_orders", data);
  },
  webContactSet(data) {
    return axios.post("shop/wx_web.php?cmd=contact_modify", data);
  },
  userLimitArrGet(data) {
    return axios.post("shop/user.php?cmd=get_user_limit_arr", data);
  },
  keywordDic(data) {
    return axios.post("shop/main.php?cmd=keyword_dic", data);
  },
  wxImageUrl(data) {
    return axios.post("shop/shop_qrcode.php", data);
  },
  // 导入导出
  importData(data) {
    return axios.post("shop/data_import.php?cmd=data_creat", data);
  },
  // 导入导出日志
  importDataLog(data) {
    return axios.post("shop/data_import.php?cmd=data_log", data);
  },
};
export default _Api;
