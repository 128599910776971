import { useEffect } from "react";
import { Table, Row, Col } from "antd";
import _Api from "../../core/api";
import { useState } from "react";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
const PartCount = ({ date, tabKey }) => {
	const [blState, setBlState] = useState({
		data: [],
		type: [],
	});
	const [phState, setPhState] = useState({ xArr: [], yArr: [] });
	useEffect(() => {
		if (tabKey === "2") {
			getPartBl();
			getPartPh();
		}
	}, [date, tabKey]);
	let apiData = { start_time: date[0], end_time: date[1] };
	function getPartBl() {
		_Api.partBl(apiData).then((res) => {
			let dataArr = [],
				typeArr = [];
			res.data.map((item) => {
				dataArr.push({ name: item.part_name, value: item.price });
				typeArr.push(item.part_name);
			});
			setBlState({ data: dataArr, type: typeArr });
		});
	}

	function getPartPh() {
		_Api.partPh(apiData).then((res) => {
			let xArr = [],
				yArr = [];
			res.data.map((item) => {
				xArr.push(item.part_name);
				yArr.push(item.all_num);
			});
			setPhState({ xArr, yArr });
		});
	}

	return (
		<Row justify='space-between'>
			<div style={{ width: "100%", height: "400px" }}>
				
				{/* <PieChart pieData={blState.data} pieTitle='配件收入比例' seriesName='配件收入' legendArr={blState.type} /> */}
			</div>
			{/* <div style={{ width: "52%", height: "400px" }}>
				<BarChart chartTitle='配件销量排行' xArr={phState.xArr} yArr={phState.yArr} />
			</div> */}
		</Row>
	);
};
export default PartCount;
