import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table, Tabs, Empty } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";
import emptyData from "../../libs/empty_data.png";
import emptySearch from "../../libs/empty_search.png";
import "./VipSelect.less"
const { TabPane } = Tabs;

const VipSelectModal = ({
  visible,
  closeModal,
  handleModalState,
  carId,
  hasMember,
}) => {
  const [vipState, setVipState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    time_over: "200",
    package_type: "储值卡",
  });
  const [vipCountState, setVipCountState] = useState({
    keyword: "",
    list: [],
    pageNo: "1",
    pageSize: "10",
    total: "",
    time_over: "200",
    package_type: "计次卡",
  });
  const [modalState, setModalState] = useState({
    packageType: false,
    emptyType: false,
    emptyCountType: false,
  });
  const [addVisible, setAddVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  useEffect(() => {
    if (visible) {
      const dataValue = {
        keyword: "",
        car_id: carId || "",
        count: "10",
        page: "1",
        package_type: "储值卡",
        time_over: "200",
      };
      const dataCount = {
        keyword: "",
        car_id: carId || "",
        count: "10",
        page: "1",
        package_type: "计次卡",
        time_over: "200",
      };
      _Api.cardSelect(dataValue).then((res) => {
        console.log(res.data.content[0].last_use);
        if (res.data.content[0].last_use) {
          console.log("储值啦");
          setActiveKey("1");
          setModalState({ ...modalState, packageType: false });
        }
      });
      _Api.cardSelect(dataCount).then((res) => {
        console.log(res.data.content[0].last_use);
        if (res.data.content[0].last_use) {
          console.log("计次啦");
          setActiveKey("2");
          setModalState({ ...modalState, packageType: true });
        }
      });
    }
  }, [visible]);
  useEffect(() => {
    if (visible && vipState.keyword === "") {
      getVipList();
    }
  }, [vipState.keyword, visible, modalState.packageType]);
  // 获取数据
  function getVipList(data) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.packageType) {
      // 计次
      let data = {
        keyword: vipCountState.keyword,
        car_id: carId,
        count: pageSize,
        page: pageNo,
        time_over: "200",
        package_type: vipCountState.package_type,
      };
      _Api.cardSelect(data).then((res) => {
        let vipList = res.data.content;
        setVipCountState({
          ...vipCountState,
          list: vipList,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
      });
    } else {
      //储值
      let data = {
        keyword: vipState.keyword,
        car_id: carId,
        count: pageSize,
        page: pageNo,
        time_over: "200",
        package_type: vipState.package_type,
      };
      _Api.cardSelect(data).then((res) => {
        let vipList = res.data.content;
        setVipState({
          ...vipState,
          list: vipList,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
        });
      });
    }
  }
  //标签页发生改变
  function onChange(key) {
    console.log(key);
    if (key === "1") {
      setModalState({ ...modalState, packageType: false });
      setActiveKey("1");
    }
    if (key === "2") {
      setModalState({ ...modalState, packageType: true });
      setActiveKey("2");
    }
  }
  // tab 储值查询
  const valueInquire = (
    <Row align="middle">
      <Col span={17}>
        <Input
          placeholder="卡号/姓名/手机号码"
          value={vipState.keyword}
          onChange={(e) => {
            setVipState({ ...vipState, keyword: e.target.value });
          }}
        />
      </Col>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        onClick={getVipList}
      >
        查询
      </Button>
    </Row>
  );
  //   tab 计次查询
  const countInquire = (
    <Row align="middle">
      <Col span={17}>
        <Input
          placeholder="卡号/姓名/手机号码"
          value={vipCountState.keyword}
          onChange={(e) => {
            setVipCountState({ ...vipCountState, keyword: e.target.value });
          }}
        />
      </Col>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        onClick={getVipList}
      >
        查询
      </Button>
      {/* <Button
	  style={{ marginLeft: "10px" }}
	  onClick={() => {
		setVipState({ ...vipState, keyword: "" });
	  }}
	>
	  清除
	</Button> */}
    </Row>
  );

  const columns = [
    {
      title: "会员卡号",
      dataIndex: "bianhao",
      render: (text, record) =>
        record.last_use ? (
          <span>
            {text}
            <span
              className="theme-color"
              style={{ marginLeft: "5px", fontSize: "8px" }}
            >
              {record.last_use}
            </span>
          </span>
        ) : (
          text
        ),
    },
    {
      title: "会员姓名",
      dataIndex: "name",
      key: "name",
      align: "left"
    },

    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "left",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },

    {
      title: "套餐名称",
      dataIndex: "package_name",
      width: "200px",
      align: "left"
    },
    {
      title: "余额",
      dataIndex: "left_money",
      align: "left",
    },

    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      align: "left"
    },
    {
      title: "操作",
      dataIndex: "id",
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleModalState({ vipDetailV: true, curVip: record })}
        >
          选择
        </a>
      ),
    },
  ];
  const columnsCount = [
    {
      title: "会员卡号",
      dataIndex: "bianhao",
      render: (text, record) =>
        record.last_use ? (
          <span>
            {text}
            <span
              className="theme-color"
              style={{ marginLeft: "5px", fontSize: "8px" }}
            >
              {record.last_use}
            </span>
          </span>
        ) : (
          text
        ),
    },
    {
      title: "会员姓名",
      dataIndex: "name",
      key: "name",
      align:"left"
    },

    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "left",
      render: (text, record) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "包含次数",
      dataIndex: "left_num",
      align: "left",
    },
    {
      title: "套餐名称",
      dataIndex: "package_name",
      align:"left"
    },

    {
      title: "操作",
      dataIndex: "id",
      align: "left",
      render: (text, record) => (
        <a
          onClick={() => handleModalState({ vipDetailV: true, curVip: record })}
        >
          选择
        </a>
      ),
    },
  ];

  return (
    <Modal
      title="选择会员卡"
      zIndex={999}
      footer={null}
      width={1000}
      visible={visible}
      onCancel={closeModal}
      className="vip-select-box"
    >
      <Tabs
        // defaultActiveKey="1"
        activeKey={activeKey}
        onChange={onChange}
        tabBarExtraContent={
          modalState.packageType ? countInquire : valueInquire
        }
      >
        <TabPane tab="储值类型" key="1">
          {" "}
          {vipState.list.length === 0 ? (
            <div style={{ margin: "90px 0" }}>
              {/* {modalState.emptyType ? (
                <Empty
                  description={
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#999",
                      }}
                    >
                      暂未查询到此会员卡～
                    </div>
                  }
                  image={emptySearch}
                ></Empty>
              ) : (
                <Empty
                  description={
                    <div style={{ width: "100%", textAlign: "center" }}>
                      暂无套会员卡，请先
                      <span
                        style={{
                          display: "inline-block",
                          color: "#fc9c6b",
                          cursor: "pointer",
                          marginLeft: 10,
                          width: 80,
                          height: 17,
                          borderBottom: "2px solid #fc9c6b",
                        }}
                        onClick={() => {
                          setAddVisible(true);
                        }}
                      >
                        +添加会员卡
                      </span>
                    </div>
                  }
                  image={emptyData}
                ></Empty>
              )} */}
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            </div>
          ) : (
            <>
              {/* 套餐表格 */}
              <Table
                rowKey={(record) => record.id}
                pagination={false}
                dataSource={vipState.list}
                columns={columns}
                bordered
                style={{ marginBottom: "30px" }}
              />
              {/* 分页 */}
              <PageControl
                total={vipState.total}
                pageSize={vipState.pageSize}
                current={vipState.pageNo}
                showSizeChanger
                toPage={(page, count) => {
                  getVipList({ page, pages: count });
                }}
              />
            </>
          )}
        </TabPane>
        <TabPane tab="计次类型" key="2">
          {" "}
          {vipCountState.list.length === 0 ? (
            <div style={{ margin: "90px 0" }}>
              {/* {modalState.emptyCountType ? (
                <Empty
                  description={
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#999",
                      }}
                    >
                      暂未查询到此会员卡～
                    </div>
                  }
                  image={emptySearch}
                ></Empty>
              ) : (
                <Empty
                  description={
                    <div style={{ width: "100%", textAlign: "center" }}>
                      暂无会员卡，请先
                      <span
                        style={{
                          display: "inline-block",
                          color: "#fc9c6b",
                          cursor: "pointer",
                          marginLeft: 10,
                          width: 80,
                          height: 17,
                          borderBottom: "2px solid #fc9c6b",
                        }}
                        onClick={() => {
                          setAddVisible(true);
                        }}
                      >
                        +添加会员卡
                      </span>
                    </div>
                  }
                  image={emptyData}
                ></Empty>
              )} */}
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            </div>
          ) : (
            <>
              {/* 套餐表格 */}
              <Table
                rowKey={(record) => record.id}
                pagination={false}
                dataSource={vipCountState.list}
                columns={columnsCount}
                bordered
                style={{ marginBottom: "30px" }}
              />
              {/* 分页 */}
              <PageControl
                total={vipCountState.total}
                pageSize={vipCountState.pageSize}
                current={vipCountState.pageNo}
                showSizeChanger
                toPage={(page, count) => {
                  getVipList({ page, pages: count });
                }}
              />
            </>
          )}
        </TabPane>
      </Tabs>
    </Modal>
  );
};
export default VipSelectModal;
