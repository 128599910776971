import React, { Component, Fragment } from "react";
import { createHashHistory } from "history";
import EditDataModal from "./EditDataModal";
import NewEditFastEntryModal from "./NewEditFastEntryModal";
import Announcement from "../help/Announcement";
import axios from "../../core/axios";
import "./Home.less";
import User from "../../core/user";
const hashHistory = createHashHistory();

export class Home extends Component {
  state = {
    editVisible: false,
    newEditEntryVisible: false,
    selectedList: [],
    showDataIndex: [],
    showDataList: [],
    showData: !Number(window.localStorage.getItem("hiddenData")),
    list: [],
    editDataModal: null,
    newEditFastEntryModal: null,
    menu_index: [],
  };

  componentDidMount() {
    /*this.selectColumns();*/
    this.getMenu();
    this.selectHelps();
  }

  getMenu = () => {
    axios.post("shop/login.php?cmd=web_login_set", "").then((res) => {
      if (!res.token_info.user_shop) {
        User.logout();
      } else {
        let data_index =
          JSON.parse(res.token_info.user_shop.cur_user.user_info.data_index) ||
          [];
        let arr =
          JSON.parse(res.token_info.user_shop.cur_user.user_info.menu_index) ||
          [];
        let selectedList = arr
          .map((id, index) => {
            return window.userPermissionList.find((item) => item.code == id);
          })
          .filter((item) => item);
        /*let selectedList = window.userPermissionList.filter((item)=>arr.indexOf(item.id) != -1);*/
        this.setState(
          { selectedList, menu_index: arr, showDataIndex: data_index },
          () => {
            this.getData(data_index);
          }
        );
      }
    });
  };

  getData = (index) => {
    const body = {
      code: "",
    };
    axios
      .post("shop/branch.php?cmd=get_branch_data", body)
      .then((res) => {
        let dataList = res.data;
        console.log("index", index);
        console.log("aaa", dataList);
        let dataShow = index.map((id) => {
          return dataList.find((item) => item.code === id);
        });
        console.log(dataShow);
        dataShow.map((item) => {
          if (item.id === "10") {
            // console.log(`${item.link}?id=2`);
            item.link = `${item.link}?key=2`;
          }
          return item;
        });
        this.setState({ showDataList: dataShow });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  selectHelps = () => {
    axios
      .post("shop/public.php?cmd=help_query", { type_id: 283 })
      .then((res) => {
        this.setState({
          list: res.msg.content.slice(0, 5),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  editData = () => {
    let editDataModal = (
      <EditDataModal
        dataIndex={this.state.showDataIndex}
        onCancel={() => {
          this.setState({ editDataModal: null });
        }}
        onOk={() => {
          this.setState({ editDataModal: null });
          this.getMenu();
        }}
      />
    );
    this.setState({ editDataModal });
  };

  newEditEntry = () => {
    const { selectedList } = this.state;
    let newEditFastEntryModal = (
      <NewEditFastEntryModal
        selectedList={selectedList}
        totalItems={window.userPermissionList}
        onCancel={() => {
          this.setState({ newEditFastEntryModal: null });
        }}
        onOk={() => {
          this.setState({ newEditFastEntryModal: null });
          this.getMenu();
        }}
      />
    );
    this.setState({ newEditFastEntryModal });
  };
  render() {
    const {
      selectedList,
      showDataList,
      showData,
      list,
      editDataModal,
      newEditFastEntryModal,
    } = this.state;
    console.log(showDataList);
    return (
      <div className="container-wrap home">
        <div className="box-container home-box">
          <div className="box-header">
            <span className="box-title">机构数据</span>
            {showData ? (
              <span
                className="icon iconfont open"
                onClick={() => {
                  this.setState({
                    showData: !showData,
                  });
                  window.localStorage.setItem("hiddenData", 1);
                }}
              >
                &#xe633;
              </span>
            ) : (
              <span
                className="icon iconfont close"
                onClick={() => {
                  this.setState({
                    showData: !showData,
                  });
                  window.localStorage.setItem("hiddenData", 0);
                }}
              >
                &#xe634;
              </span>
            )}
            <div onClick={this.editData} className="setting">
              <span className="icon iconfont">&#xe661;</span>
            </div>
          </div>
          {editDataModal}
          {/*<EditDataModal
                        isOpen={this.state.editVisible}
                        onCancel={()=>{this.setState({editVisible:false})}}
                        onOk={()=>{
                            this.setState({editVisible:false})
                            this.selectColumns()
                        }}
                    />*/}
          <div className="box-body without-padding">
            <ul className="top-line-box">
              {showDataList.map((item, index) => (
                <li key={index} className="top-line-student">
                  <div className="line-border">
                    <div>
                      <div className="number-tip">
                        {showData ? (
                          <span
                            onClick={() => {
                              if (item.link) {
                                this.props.history.push(item.link);
                                // this.props.history.push(`${item.link}?key="2"`);
                                // this.props.history.push({
                                //   pathname: item.link,
                                //   state: {
                                //     key: "2",
                                //   },
                                // });
                              }
                            }}
                            className={`right-text ${
                              item.link ? "" : "nolink"
                            }`}
                          >
                            {item.val}
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              if (item.link) {
                                this.props.history.push(item.link);
                              }
                            }}
                            className={`right-text ${
                              item.link ? "" : "nolink"
                            }`}
                          >
                            ****
                          </span>
                        )}
                      </div>
                      <div className="small-title-tip">
                        <span
                          onClick={() => {
                            if (item.link) {
                              this.props.history.push(item.link);
                            }
                          }}
                          className={item.link ? "" : "nolink"}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="template-home-index">
          <div className="left">
            <div className="box-container">
              <div className="box-header fast-enter">
                <span className="box-title">快捷入口</span>
                <div onClick={this.newEditEntry} className="setting">
                  <span className="icon iconfont">&#xe661;</span>
                </div>
              </div>
              {newEditFastEntryModal}
              <div className="box-body">
                <ul className="content-one-box">
                  {selectedList.map((item, index) => (
                    <li
                      onClick={() => {
                        hashHistory.push(item.link);
                      }}
                      key={index}
                      className="row"
                    >
                      <div
                        className="item-block"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "200px",
                        }}
                      >
                        {/* <div
													style={{
														height: "40px",
														width: "40px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}> */}
                        <span
                          className="icon iconfont"
                          style={{ fontSize: "40px", display: "block" }}
                          dangerouslySetInnerHTML={{ __html: item.icon_index }}
                        ></span>
                        {/* </div> */}
                        <div
                          className="title middle"
                          style={{
                            fontSize: "23px",
                            lineHeight: "36px",
                            color: "#000",
                            overflow: "hidden",

                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.name}
                        </div>
                        <div className="detail">
                          <div className="ext-data"></div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="product-dynamic-container box-container">
              <Announcement data={list} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
