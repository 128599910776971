import _ from 'underscore';
import CONFIG from './config'

/**
 * 人名币格式
 * @param price 人名币
 *
 */
window.getMoneyFormat = function (price) {
    if (!_.isNumber(Number(price))) {
        return '0.00';
    }
    var n = parseFloat(Math.round(price) / CONFIG.moneyUnit).toFixed(2);
    var re = /(\d{1,3})(?=(\d{3})+(?:\.))/g;
    return n.replace(re, '$1,');
};

// 判断是否是数组且长度不为0
window.isLongArr = function (arr) {
    return Array.isArray(arr) && arr.length > 0;
};

window.moneyFormat = function (money, defaultValue) {
    var stringMoney = money ? (Math.round(money) / CONFIG.moneyUnit).toFixed(2) : defaultValue == undefined ? 0 : defaultValue;
    return Number(stringMoney);
};
