import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import _Api from "../../core/api";
import ShopContext from "../../core/context-manager";
import ShopAddressModal from "../../components/modals/ShopAddress";

const { TextArea } = Input;
const ContactSet = ({ refreshFrame, tabKey }) => {
	const { shopInfo, resetShopInfo } = useContext(ShopContext);
	const [state, setState] = useState({
		phone: "",
		description: "",
		linkman: "",
		address: "",
		addressCode: "",
		longitude: "",
		latitude: "",
		description: "",
	});
	const [modalState, setModalState] = useState({
		visible: false,
	});
	useEffect(() => {
		if (tabKey === "contact") {
			initState();
		}
	}, [tabKey, shopInfo]);

	function initState() {
		const { phone, linkman, address, latitude, longitude, address_code, description } = shopInfo;
		setState({
			...state,
			phone,
			linkman,
			address,
			latitude,
			longitude,
			addressCode: address_code ? address_code.split(",") : [],
			description,
		});
	}
	function handleUpdate() {
		const { phone, linkman, address, addressCode, longitude, latitude, description } = state;
		let data = { address_code: addressCode.join(","), phone, address, longitude, latitude, description, linkman };
		_Api.webContactSet(data).then((res) => {
			message.success(res.msg);
			resetShopInfo();
			refreshFrame();
		});
	}
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 16 },
	};
	return (
		<div className='setting-page'>
			<div style={{ width: "60%" }}>
				<Form {...layout}>
					<Form.Item label='公司名称'>
						<span>{shopInfo.name}</span>
					</Form.Item>

					<Form.Item label='公司介绍' style={{ paddingBottom: "20px" }}>
						<TextArea
							placeholder='请填写公司介绍(1000字以内)'
							maxLength={1000}
							showCount
							className='form-control'
							value={state.description}
							onChange={(e) => {
								setState({ ...state, description: e.target.value });
							}}></TextArea>
						<div className='msg-box valid-teacher-desc'></div>
					</Form.Item>
					<Form.Item label='联系人'>
						<Input
							placeholder='请输入联系人'
							value={state.linkman}
							onChange={(e) => {
								setState({ ...state, linkman: e.target.value });
							}}
						/>
					</Form.Item>
					<Form.Item label='联系电话'>
						<Input
							placeholder='请输入咨询电话'
							value={state.phone}
							onChange={(e) => {
								setState({ ...state, phone: e.target.value });
							}}
						/>
					</Form.Item>
					<Form.Item label='联系地址'>
						<div>
							<span>{state.address ? state.address : "暂未设置"}</span>
							<Button
								type='link'
								onClick={() => {
									setModalState({ ...modalState, visible: true });
								}}>
								修改
							</Button>
						</div>
					</Form.Item>
					<Form.Item wrapperCol={{ offset: 4, span: 16 }}>
						<Button
							type='primary'
							onClick={() => {
								handleUpdate();
							}}>
							更新信息
						</Button>
					</Form.Item>
				</Form>
			</div>
			<ShopAddressModal
				visible={modalState.visible}
				handleCancel={() => {
					setModalState({ visible: false });
				}}
				handleComplete={(data) => {
					let { addressData, addressCode, longitude, latitude } = data;
					setState({ ...state, address: addressData, addressCode, longitude, latitude });
					setModalState({ visible: false });
				}}
				curAddressState={{
					addressData: state.address,
					addressCode: state.addressCode,
					longitude: state.longitude,
					latitude: state.latitude,
				}}
			/>
		</div>
	);
};
export default ContactSet;
