import React, { Component } from "react";
import './Announcement.less'

export class Announcement extends Component {

    state = {

    };

    goToProductDynamic = (id,aid) => {
        console.log(id)
        if(id){
            window.open('https://www.sanfeng9.com/help/?id='+ id +'&aid='+aid)
        }else{
            window.open('https://www.sanfeng9.com/help/?id=283')
        }
    };

    render() {

        const { data } = this.props;

        return(
            <div className="product-dynamic-list">
                <div className="top">
                    <div className="name">
                        动态/公告
                    </div>
                    <div className="more" style={{cursor:"pointer"}} onClick={()=>this.goToProductDynamic(false)}>
                        更多
                        <span className="icon iconfont right-arrow">
                            &#xe666;
                        </span>
                    </div>
                </div>
                <ul>
                    {data.map((item,index)=>(
                        <li onClick={()=>{
                            this.goToProductDynamic(item.type_id,item.id)
                        }} key={index} style={{
                            cursor: "pointer",
                        }}>
                            <span className="title">
                                {item.title}
                            </span>
                            <span className="time">
                                {item.setdate.split('-')[1]}-{item.setdate.split('-')[2].split(' ')[0]}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
export default Announcement;
