import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
const Page = ({ pieData, pieTitle, legendArr, seriesName }) => {
	const option = {
		title: {
			text: pieTitle,
			x: "center",
		},
		tooltip: {
			trigger: "item",
			formatter: "{a} <br/>{b} : {c} ({d}%)",
		},
		legend: {
			orient: "vertical",
			left: "left",
			data: legendArr,
		},
		// color: ["#E38E56", "red", "#72A758", "#5796D0", "#F78AE0", "#38495C"],
		series: [
			{
				name: seriesName || "",
				type: "pie",
				radius: "55%",
				center: ["50%", "60%"],
				label: {
					normal: {
						show: true,
						formatter: "{b}({d}%)",
					},
				},

				data: pieData,
				itemStyle: {
					emphasis: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: "rgba(0, 0, 0, 0.5)",
					},
				},
			},
		],
	};
	function onChartReady(echarts) {
		console.log("echarts is ready", echarts);
	}
	// function onChartClick(param, echarts) {
	//     console.log(param, echarts);
	//     setCount(count + 1);
	// }
	function onChartLegendselectchanged(param, echarts) {
		console.log(param, echarts);
	}
	return (
		<>
			<ReactECharts
				option={option}
				style={{ height: 360 }}
				// onChartReady={onChartReady}
				onEvents={{
					// 'click': onChartClick,
					legendselectchanged: onChartLegendselectchanged,
				}}
			/>
		</>
	);
};
export default Page;
