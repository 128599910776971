import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Input, Col, Table } from "antd";
import PageControl from "../PageControl";
import _Api from "../../core/api";
const WxSelectModal = ({ visible, closeModal, handleComplete }) => {
	const [state, setState] = useState({
		keyword: "",
		list: [],
		pageNo: "1",
		pageSize: "10",
		total: "",
	});
	useEffect(() => {
		if (visible) {
			getWxList();
		}
	}, [visible]);
	function getWxList(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1";
		const { keyword } = state;
		let data = { keyword, count: pageSize, page: pageNo };
		_Api.wxUserSelect(data).then((res) => {
			setState({
				...state,
				list: res.data.content,
				total: res.data.allcount,
				pageNo: res.data.curpage,
				pageSize: res.data.pagecount,
			});
		});
	}
	const columns = [
		{
			title: "序号",
			dataIndex: "id",
		},
		{
			title: "姓名",
			dataIndex: "name",
		},
		{
			title: "电话",
			dataIndex: "phone",
			align: "center",
			render: (text) => (text ? <span>{text}</span> : <span>--</span>),
		},
		{
			title: "操作",
			dataIndex: "operation",
			key: "operation",
			align: "center",
			//eslint-disable-next-line
			render: (text, record) => (
				<a
					onClick={() => {
						handleComplete(record);
						// closeModal();
					}}>
					选择
				</a>
			),
		},
	];
	return (
		<Modal
			title={
				<div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
					<span style={{fonSize:'16px'}}>微信选择</span>
					<div style={{ color: "#fc9c6b", fontSize: "13px", marginLeft: "20px" }}>
						<span className='icon iconfont' style={{ fontSize: "14px", color: "#fc9c6b", marginRight: "3px" }}>
							&#xe606;
						</span>
						客户微信关注公众号后，将会出现在这里
					</div>
				</div>
			}
			footer={null}
			width={700}
			visible={visible}
			onCancel={closeModal}>
			<Row type='flex' align='middle'>
				<Col span={12}>
					<Input placeholder='姓名/电话' />
				</Col>
				<Button type='primary' style={{ marginLeft: "10px" }}>
					查询
				</Button>
			</Row>

			<Table
				rowKey={(record) => record.id}
				pagination={false}
				dataSource={state.list}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
				// locale={{ emptyText: "请点击表格右上方按钮选择或新增服务项目" }}
			/>
			<PageControl
				total={state.total}
				pageSize={state.pageSize}
				current={state.pageNo}
				showSizeChanger
				toPage={(page, count) => {
					getWxList({ pageNo: page, pageSize: count });
				}}
			/>
		</Modal>
	);
};
export default WxSelectModal;
