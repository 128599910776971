import { useState, Fragment, useEffect } from "react";
import {
  Tooltip,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Table,
  Tio,
  Tabs,
} from "antd";
import SearchBar from "../../components/SearchBar";
import SearchBarUpdata from "../../components/SearchBarUpdata";
import StaticSelect from "../../components/StaticSelect";
import CommenSelect from "../../components/CommonSelect";
// import PageControl from "../../components/PageControl";
import PageControlImprove from "../../components/PageControlmprove";
import ConsumerDetailsTable from "../../components/ConsumerDetailsTable";
import moment from "moment";
import _Api from "../../core/api";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const PurchaseDetail = ({ location }) => {
  const [state, setState] = useState({
    purchaseType: "",
    carId: "",
    moneyMin: "",
    moneyMax: "",
    bianhao: "",
    remark: "",
    dateStart: "",
    dateEnd: "",
    filter: false,
    list: [],
    total: "0",
    pageNo: "1",
    pageSize: "10",
    moneyPage: 0,
    moneyAll: 0,
    memberCardType: "储值卡",
  });
  const [stateCount, setStateCount] = useState({
    purchaseType: "",
    carId: "",
    moneyMin: "",
    moneyMax: "",
    bianhao: "",
    remark: "",
    dateStart: "",
    dateEnd: "",
    filter: false,
    list: [],
    total: "0",
    pageNo: "1",
    pageSize: "10",
    moneyPage: 0,
    moneyAll: 0,
    memberCardType: "计次卡",
  });
  const [cardId, setCardId] = useState("");
  const [initFlag, setInitFlag] = useState(false);
  const [modalState, setModalState] = useState({
    purchaseType: false,
  });
  const [activeKey, setActiveKey] = useState("1");
  useEffect(() => {
    if (!initFlag && location.state) {
      getPurchaseList(undefined, location.state.card);
      setCardId(location.state.card);
    } else {
      getPurchaseList();
    }
  }, [
    state.purchaseType,
    state.dateStart,
    state.dateEnd,
    modalState.purchaseType,
  ]);
  useEffect(() => {
    const key = location.state?.key;
    console.log(key);
    if (key) {
      setActiveKey("2");
      setModalState({
        purchaseType: true,
      });
    }
    // if (location.state.key) {
    //   setActiveKey("2");
    //   setModalState({
    //     purchaseType: true
    //   })
    // }
  }, []);
  function getPurchaseList(data, card) {
    let pageSize = data?.pages || "10";
    let pageNo = data?.page || "1";
    if (modalState.purchaseType) {
      const data = {
        caiwu_type: stateCount.purchaseType,
        pay_type: "会员卡",
        order_bianhao: stateCount.bianhao,
        car_id: stateCount.carId,
        member_card: card || cardId,
        member_num_min:
          stateCount.moneyMax === ""
            ? stateCount.moneyMax
            : -stateCount.moneyMax,
        member_num_max:
          stateCount.moneyMin === ""
            ? stateCount.moneyMin
            : -stateCount.moneyMin,
        remark: stateCount.remark,
        start_time: stateCount.dateStart,
        end_time: stateCount.dateEnd,
        count: pageSize,
        page: pageNo,
        member_card_type: stateCount.memberCardType,
      };
      _Api.caiwuSelect(data).then((res) => {
        !initFlag && setInitFlag(true);
        setStateCount({
          ...stateCount,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
          moneyAll: res.data.money_member_all,
          moneyPage: res.data.money_member_page,
        });
      });
    } else {
      const data = {
        caiwu_type: state.purchaseType,
        pay_type: "会员卡",
        order_bianhao: state.bianhao,
        car_id: state.carId,
        member_card: card || cardId,
        money_member_min: state.moneyMin,
        money_member_max: state.moneyMax,
        remark: state.remark,
        start_time: state.dateStart,
        end_time: state.dateEnd,
        count: pageSize,
        page: pageNo,
        member_card_type: state.memberCardType,
      };
      _Api.caiwuSelect(data).then((res) => {
        !initFlag && setInitFlag(true);
        setState({
          ...state,
          list: res.data.content,
          total: res.data.allcount,
          pageNo: res.data.curpage,
          pageSize: res.data.pagecount,
          moneyAll: res.data.money_member_all,
          moneyPage: res.data.money_member_page,
        });
      });
    }
  }
  //   清空筛选
  function reset() {
    setState({
      ...state,
      purchaseType: "",
      carId: "",
      moneyMin: "",
      moneyMax: "",
      bianhao: "",
      remark: "",
      dateStart: "",
      dateEnd: "",
    });
    setStateCount({
      ...stateCount,
      purchaseType: "",
      carId: "",
      moneyMin: "",
      moneyMax: "",
      bianhao: "",
      remark: "",
      dateStart: "",
      dateEnd: "",
    });
    setCardId("");
  }
  function toggleFilter() {
    setState({ ...state, filter: !state.filter });
    setStateCount({ ...stateCount, filter: !stateCount.filter });
  }
  // 标签页改变
  function onChange(key) {
    console.log(key);
    if (key === "1") {
      setActiveKey("1");
      setModalState({ ...modalState, purchaseType: false });
    }
    if (key === "2") {
      setActiveKey("2");
      setModalState({ ...modalState, purchaseType: true });
    }
  }
  const purchaseTypes = [
    { id: "工单结算", name: "工单结算" },
    { id: "配件销售", name: "配件销售" },
  ];
  const columns = [
    {
      title: "会员卡号",
      dataIndex: "member_card",
      align: "left",
    },
    {
      title: "消费类型",
      align: "left",
      dataIndex: "caiwu_type",
    },
    {
      title: "单据编号",
      align: "left",
      dataIndex: "order_bianhao",
    },
    {
      title: "车牌号",
      align: "left",
      dataIndex: "car_id",
    },
    {
      title: "联系人",
      align: "left",
      dataIndex: "linkman",
    },
    {
      title: "消费金额",
      align: "left",
      dataIndex: "money_member",
    },
    {
      title: "消费日期",
      align: "left",
      dataIndex: "gmt_creat",
    },
    {
      title: "录入人员",
      align: "left",
      dataIndex: "input_user",
    },
    {
      title: "备注",
      align: "left",
      dataIndex: "remark",
      render: (text) =>
        text.length > 5 ? (
          <Tooltip placement="topLeft" title={text}>
            <span>{text.substring(0, 5) + "..."}</span>
          </Tooltip>
        ) : (
          text
        ),
    },
  ];
  return (
    <>
      <div className="template-student-index page settlemen">
        <div className="page-content">
          <section className="content-header">
            <h1>消费明细</h1>
          </section>
          <div className="box package-seeting" style={{ padding: 0 }}>
            <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange} type="card">
              <TabPane tab="储值类型" key="1" style={{padding: "0 16px"}}>
                <div className="box-header" style={{paddingBottom: 0}}>
                  <Row type="flex" justify="space-around" align="top">
                    <Col>
                      <SearchBarUpdata
                        searchText={cardId}
                        onChangeText={(text) => {
                          setCardId(text);
                        }}
                        onSearch={getPurchaseList}
                        label="会员卡号"
                        placeholder="请输入会员卡号"
                      />

                      <CommenSelect
                        selectValue={state.purchaseType}
                        label="消费类型"
                        options={purchaseTypes}
                        onSelect={(selectValue) => {
                          setState({ ...state, purchaseType: selectValue });
                        }}
                        placeholder="请选择消费方式"
                      />
                      <SearchBarUpdata
                        searchText={state.carId}
                        onChangeText={(text) => {
                          setState({ ...state, carId: text });
                        }}
                        onSearch={getPurchaseList}
                        label="车牌号"
                        placeholder="请输入车牌号"
                      />
                      {state.filter && (
                        <>
                          <StaticSelect label="消费金额:">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flex: "1",
                              }}
                            >
                              <InputNumber
                                placeholder="最小值"
                                step={100}
                                style={{ flex: "1" }}
                                value={state.moneyMin}
                                onChange={(num) => {
                                  setState({ ...state, moneyMin: num });
                                }}
                                onPressEnter={getPurchaseList}
                              />
                              <span
                                style={{ margin: "0 5px", color: "#bfbfbf" }}
                              >
                                ~
                              </span>
                              <InputNumber
                                placeholder="最大值"
                                step={100}
                                style={{ flex: "1" }}
                                value={state.moneyMax}
                                onChange={(num) => {
                                  setState({ ...state, moneyMax: num });
                                }}
                                onPressEnter={getPurchaseList}
                              />
                            </div>
                          </StaticSelect>
                          <SearchBarUpdata
                            searchText={state.bianhao}
                            onChangeText={(text) => {
                              setState({ ...state, bianhao: text });
                            }}
                            onSearch={getPurchaseList}
                            label="单据编号"
                            placeholder="请输入单据编号"
                          />
                          <SearchBarUpdata
                            searchText={state.remark}
                            onChangeText={(text) => {
                              setState({ ...state, remark: text });
                            }}
                            onSearch={getPurchaseList}
                            label="备注"
                            placeholder="请输入备注信息"
                          />
                          <StaticSelect label="消费日期:">
                            <RangePicker
                              style={{ width: "100%" }}
                              value={[
                                state.dateStart && moment(state.dateStart),
                                state.dateEnd && moment(state.dateEnd),
                              ]}
                              onChange={(memo, str) => {
                                setState({
                                  ...state,
                                  dateStart: str[0],
                                  dateEnd: str[1],
                                });
                              }}
                            />
                          </StaticSelect>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Tooltip title="清空筛选">
                        <span
                          onClick={reset}
                          id="reset_btn"
                          className="resetBtn iconfont icon"
                        >
                          &#xe662;
                        </span>
                      </Tooltip>
                      <span
                        className="text-sub xm-filter"
                        onClick={toggleFilter}
                        style={{ cursor: "pointer" }}
                      >
                        {state.filter ? (
                          <span>
                            收起
                            <span className="iconfont icon ml5">&#xe663;</span>
                          </span>
                        ) : (
                          <span>
                            展开
                            <span className="iconfont icon ml5">&#xe665;</span>
                          </span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="box-body">
                  <Table
                    rowKey={(record) => record.id}
                    dataSource={state.list}
                    columns={columns}
                    bordered
                    style={{ marginTop: "20px", marginBottom: "40px" }}
                    pagination={false}
                  />

                  <PageControlImprove
                    total={state.total}
                    pageSize={state.pageSize}
                    current={state.pageNo}
                    showSizeChanger
                    toPage={(page, count) => {
                      getPurchaseList({ page, pages: count });
                    }}
                  >
                    <Row justify="end" align="center">
                      <Col span={24} style={{ textAlign: "right" }}>
                        <span
                          style={{ marginRight: 20 }}
                        >{`本页消费金额${state.moneyPage}元`}</span>
                        <span>{`累计消费金额${state.moneyAll}元`}</span>
                      </Col>
                    </Row>
                  </PageControlImprove>
                </div>
              </TabPane>
              <TabPane tab="计次类型" key="2" style={{padding: "0 16px"}}>
                <div className="box-header" style={{paddingBottom: 0}}>
                  <Row type="flex" justify="space-around" align="top">
                    <Col>
                      <SearchBarUpdata
                        searchText={cardId}
                        onChangeText={(text) => {
                          setCardId(text);
                        }}
                        onSearch={getPurchaseList}
                        label="会员卡号"
                        placeholder="请输入会员卡号"
                      />

                      <CommenSelect
                        selectValue={stateCount.purchaseType}
                        label="消费类型"
                        options={purchaseTypes}
                        onSelect={(selectValue) => {
                          setStateCount({
                            ...stateCount,
                            purchaseType: selectValue,
                          });
                        }}
                        placeholder="请选择消费方式"
                      />
                      <SearchBarUpdata
                        searchText={state.carId}
                        onChangeText={(text) => {
                          setStateCount({ ...stateCount, carId: text });
                        }}
                        onSearch={getPurchaseList}
                        label="车牌号"
                        placeholder="请输入车牌号"
                      />
                      {stateCount.filter && (
                        <>
                          <StaticSelect label="消费次数:">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                flex: "1",
                              }}
                            >
                              <InputNumber
                                placeholder="最小值"
                                step={100}
                                style={{ flex: "1" }}
                                value={stateCount.moneyMin}
                                onChange={(num) => {
                                  setStateCount({
                                    ...stateCount,
                                    moneyMin: num === null ? "" : num,
                                  });
                                }}
                                onPressEnter={getPurchaseList}
                              />
                              <span
                                style={{ margin: "0 5px", color: "#bfbfbf" }}
                              >
                                ~
                              </span>
                              <InputNumber
                                placeholder="最大值"
                                step={100}
                                style={{ flex: "1" }}
                                value={stateCount.moneyMax}
                                onChange={(num) => {
                                  setStateCount({
                                    ...stateCount,
                                    moneyMax: num === null ? "" : num,
                                  });
                                }}
                                onPressEnter={getPurchaseList}
                              />
                            </div>
                          </StaticSelect>
                          <SearchBarUpdata
                            searchText={stateCount.bianhao}
                            onChangeText={(text) => {
                              setStateCount({ ...stateCount, bianhao: text });
                            }}
                            onSearch={getPurchaseList}
                            label="单据编号"
                            placeholder="请输入单据编号"
                          />
                          <SearchBarUpdata
                            searchText={stateCount.remark}
                            onChangeText={(text) => {
                              setStateCount({ ...stateCount, remark: text });
                            }}
                            onSearch={getPurchaseList}
                            label="备注"
                            placeholder="请输入备注信息"
                          />
                          <StaticSelect label="消费日期:">
                            <RangePicker
                              style={{ width: "100%" }}
                              value={[
                                stateCount.dateStart &&
                                  moment(stateCount.dateStart),
                                stateCount.dateEnd &&
                                  moment(stateCount.dateEnd),
                              ]}
                              onChange={(memo, str) => {
                                setStateCount({
                                  ...stateCount,
                                  dateStart: str[0],
                                  dateEnd: str[1],
                                });
                              }}
                            />
                          </StaticSelect>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Tooltip title="清空筛选">
                        <span
                          onClick={reset}
                          id="reset_btn"
                          className="resetBtn iconfont icon"
                        >
                          &#xe662;
                        </span>
                      </Tooltip>
                      <span
                        className="text-sub xm-filter"
                        onClick={toggleFilter}
                        style={{ cursor: "pointer" }}
                      >
                        {stateCount.filter ? (
                          <span>
                            收起
                            <span className="iconfont icon ml5">&#xe663;</span>
                          </span>
                        ) : (
                          <span>
                            展开
                            <span className="iconfont icon ml5">&#xe665;</span>
                          </span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="box-body">
                  <ConsumerDetailsTable
                    state={stateCount}
                    getPurchaseList={getPurchaseList}
                  ></ConsumerDetailsTable>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseDetail;
