import { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  Form,
  message,
  Dropdown,
  Menu,
} from "antd";
import Api from "../../core/api";
import moment from "moment";
import mathFunc from "../../core/mathfunc";
//eslint-disable-next-line
const PartEdit = ({
  visible,
  closeModal,
  editType,
  handleComplete,
  partInfo,
}) => {
  const [partState, setPartState] = useState({
    id: "",
    name: "",
    brand: "",
    model: "",
    priceBasic: "",
    priceSale: "",
    unit: "",
    curNum: "",
    safeNum: "",
    qualityMonth: "",
  });
  const [menuItems, setMenu] = useState({
    name: [],
    brand: [],
    model: [],
  });
  const timerRef = useRef();

  useEffect(() => {
    if (visible && editType) {
      console.log(partInfo);
      const {
        id,
        name,
        brand,
        model,
        cur_num,
        price_basic,
        price_sale,
        safe_num,
        unit,
        quality_month,
      } = partInfo;
      setPartState({
        id,
        name,
        brand,
        model,
        priceBasic: price_basic,
        priceSale: price_sale,
        unit,
        curNum: cur_num,
        safeNum: safe_num,
        qualityMonth: quality_month,
      });
    }
  }, [visible]);
  const handleSave = () => {
    let {
      id,
      name,
      brand,
      model,
      priceBasic,
      priceSale,
      unit,
      curNum,
      safeNum,
      qualityMonth,
    } = partState;
    let body = {
      id,
      name,
      brand,
      model,
      price_basic: priceBasic,
      price_sale: priceSale,
      unit,
      cur_num: curNum,
      safe_num: safeNum,
      quality_month: qualityMonth,
    };
    Api.partInsert(body)
      .then((res) => {
        message.success(res.msg);
        let result = {
          bianhao: res.data.bianhao,
          brand,
          id: res.rs_result,
          model,
          name,
          num: 1,
          priceBasic,
          priceSale,
          qualityDate:
            qualityMonth !== "0"
              ? moment().add(parseInt(qualityMonth), "M").format("YYYY-MM-DD")
              : "",
          unit,
        };
        setPartState({
          id: "",
          name: "",
          brand: "",
          model: "",
          priceBasic: "",
          priceSale: "",

          unit: "",
          curNum: "",
          safeNum: "",
          qualityMonth: "",
        });
        handleComplete(result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const wordGet = (keyword, type) => {
    Api.keywordDic({
      keyword,
      dic_type: "part_" + type,
    }).then((res) => {
      let items = res.msg.map((item, index) => ({
        key: index + "",
        label: (
          <div
            onClick={() => {
              setMenu({ ...menuItems, [type]: [] });
              setPartState({ ...partState, [type]: item });
            }}
          >
            {item}
          </div>
        ),
      }));
      setMenu({ ...menuItems, [type]: items });
    });
  };
  const wordThink = (value, type) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      clearTimeout(timerRef.current);
      wordGet(value, type);
    }, 200);
  };
  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };
  function close() {
    setPartState({
      id: "",
      name: "",
      brand: "",
      model: "",
      priceBasic: "",
      priceSale: "",
      unit: "",
      curNum: "",
      safeNum: "",
      qualityMonth: "",
    });
    closeModal();
  }
  return (
    <Modal
      title={editType ? "编辑商品配件" : "新增商品配件"}
      footer={null}
      width={700}
      visible={visible}
      onCancel={close}
    >
      <Form {...layout} colon={false}>
        <Row type="flex" justify="space-between" align="bottom">
          <Col span={12}>
            <Form.Item
              label={
                <p>
                  <span style={{ color: "#EB3628" }}>*</span>配件名称
                </p>
              }
            >
              <Dropdown
                overlay={<Menu items={menuItems.name} />}
                placement="bottom"
              >
                <Input
                  value={partState.name}
                  onFocus={() => {
                    wordThink(partState.name, "name");
                  }}
                  onChange={(e) => {
                    setPartState({ ...partState, name: e.target.value });
                    wordThink(e.target.value, "name");
                  }}
                />
              </Dropdown>
            </Form.Item>
            <Form.Item label="品牌">
              <Dropdown
                overlay={<Menu items={menuItems.brand} />}
                placement="bottom"
              >
                <Input
                  value={partState.brand}
                  onFocus={() => {
                    wordThink(partState.brand, "brand");
                  }}
                  onChange={(e) => {
                    setPartState({ ...partState, brand: e.target.value });
                    wordThink(e.target.value, "brand");
                  }}
                />
              </Dropdown>
            </Form.Item>
            <Form.Item label="成本价">
              <Input
                value={partState.priceBasic}
                onChange={(e) => {
                  const num = mathFunc.reservedDecimalOnchage(e.target.value);
                  setPartState({ ...partState, priceBasic: num });
                }}
              />
            </Form.Item>
            <Form.Item label="当前库存">
              <Input
                disabled={editType}
                value={partState.curNum}
                onChange={(e) => {
                  console.log(e.target.value);
                  const num = mathFunc.reservedIntegerOnchage(e.target.value);
                  setPartState({ ...partState, curNum: num });
                }}
              />
            </Form.Item>
            <Form.Item label="单位">
              <Input
                value={partState.unit}
                onChange={(e) => {
                  setPartState({ ...partState, unit: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="规格型号">
              <Dropdown
                overlay={<Menu items={menuItems.model} />}
                placement="bottom"
              >
                <Input
                  value={partState.model}
                  onFocus={() => {
                    wordThink(partState.brand, "model");
                  }}
                  onChange={(e) => {
                    setPartState({ ...partState, model: e.target.value });
                    wordThink(e.target.value, "model");
                  }}
                />
              </Dropdown>
            </Form.Item>

            <Form.Item label="销售价">
              <Input
                value={partState.priceSale}
                onChange={(e) => {
                  const num = mathFunc.reservedDecimalOnchage(e.target.value);
                  setPartState({ ...partState, priceSale: num });
                }}
              />
            </Form.Item>

            <Form.Item label="安全库存">
              <Input
                value={partState.safeNum}
                onChange={(e) => {
                  const num = mathFunc.reservedIntegerOnchage(e.target.value);
                  setPartState({ ...partState, safeNum: num });
                }}
              />
            </Form.Item>
            <Form.Item label="质保时间">
              <Input
                value={partState.qualityMonth}
                suffix="个月"
                onChange={(e) => {
                  const num = mathFunc.reservedIntegerOnchage(e.target.value);
                  console.log(num);
                  setPartState({ ...partState, qualityMonth: num });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={handleSave}
          >
            保存
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
export default PartEdit;
