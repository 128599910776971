import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  Table,
  Popconfirm,
} from "antd";
import TitleBar from "../TitleBar";
import moment from "moment";
import GoodsTable from "../../modules/common/GoodsTable";
import _Api from "../../core/api";
import mathFunc from "../../core/mathfunc";

const SaleDetail = ({
  visible,
  readOnly,
  closeModal,
  modalState,
  orderEdit,
  openReceive,
  handleReturn,
  handleCanclePaid,
}) => {
  const [payHistory, setPayHistory] = useState([]);
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "收款时间",
      dataIndex: "gmt_creat",
    },
    {
      title: "收款方式",
      dataIndex: "pay_type",
      render: (text, res) => {
        if (res.member_card === "") {
          return <span>{`${text}`}</span>;
        } else {
          return <span>{`${text}(储值类型)`}</span>;
        }
      },
    },
    {
      title: "消费",
      dataIndex: "money_member",
      render: (text, res) => {
        if (res.member_card === "") {
          return `${res.money_real}元`;
        } else {
          return `${text}元`;
        }
      },
    },
    {
      title: "收款人",
      align: "center",
      dataIndex: "input_user",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "备注",
      align: "center",
      dataIndex: "remark",
      // render: (text) => (text ? text : "-"),
    },
  ];
  console.log(modalState);
  useEffect(() => {
    visible && getPayHistory();
  }, [visible]);
  function getPayHistory() {
    const data = { order_bianhao: modalState.bianhao };
    if (modalState.order_state === "已开单") {
      return;
    }
    _Api.caiwuSelect(data).then((res) => {
      setPayHistory(res.data.content);
    });
  }

  return (
    <Modal
      title={modalState.sale_type === "销售" ? "销售单" : "退货单"}
      zIndex={950}
      width={1200}
      visible={visible}
      footer={null}
      onCancel={closeModal}
    >
      <TitleBar>
        <span>
          {"开单日期：" + moment(modalState.gmt_creat).format("YYYY-MM-DD")}
        </span>
      </TitleBar>
      <Form {...layout} style={{ marginBottom: "24px" }}>
        <Row style={{ marginTop: "10px" }} justify="space-between">
          <Col span={6}>
            <Form.Item label="客户">
              <Input
                disabled={true}
                value={modalState.customer}
                // onChange={(e) => {
                //   handleModalState({ curCustomer: e.target.value });
                // }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="微信客户">{modalState.wx_name}</Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="会员卡">{modalState.member_card}</Form.Item>
          </Col>
        </Row>
      </Form>
      <TitleBar>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>商品配件</span>
        </div>
      </TitleBar>
      <GoodsTable
        viewmode={true}
        discount={modalState.part_zk || 1}
        slip={modalState?.parts && JSON.parse(modalState.parts).data.length > 0}
        tableState={modalState?.parts && JSON.parse(modalState?.parts)}
        // setTable={(data) => {
        //   handleModalState({ curPartContent: data });
        // }}
      />
      {modalState.sale_type === "销售" && modalState.order_state === "已结算" && (
        <>
          <TitleBar>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>收款记录</span>
            </div>
          </TitleBar>
          <Table
            dataSource={payHistory}
            columns={columns}
            style={{ paddingTop: "20px" }}
            bordered
            pagination={false}
          />
        </>
      )}
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: "50px" }}
      >
        <div>
          <span style={{ fontWeight: "700", marginRight: "50px" }}>
            {modalState.order_state === "已开单"
              ? `应收：
            ${
              modalState?.parts &&
              mathFunc.returnFloat(parseFloat(JSON.parse(modalState.parts).ar))
            }
            元`
              : `${modalState.sale_type === "退货" ? "总金额：" : "消费："} 
            ${
              modalState.sale_type === "退货"
                ? mathFunc.returnFloat(
                    parseFloat(JSON.parse(modalState.parts).ar)
                  )
                : modalState.member_card === ""
                ? mathFunc.returnFloat(parseFloat(payHistory[0]?.money_real))
                : mathFunc.returnFloat(parseFloat(payHistory[0]?.money_member))

              // modalState.member_card === ""
              //   ? mathFunc.returnFloat(parseFloat(payHistory[0]?.money_real))
              //   : mathFunc.returnFloat(parseFloat(payHistory[0]?.money_member))
            }
            元`}
          </span>
        </div>
        {readOnly ? (
          <div style={{ display: "flex" }}>
            <Button onClick={closeModal} style={{ marginRight: "10px" }}>
              取消
            </Button>
            <Button
              type="primary"
              onClick={closeModal}
              style={{ marginRight: "10px" }}
            >
              确认
            </Button>
          </div>
        ) : modalState.order_state === "已开单" ? (
          <div style={{ display: "flex" }}>
            {" "}
            <Button
              type="primary"
              onClick={() => orderEdit(modalState)}
              style={{ marginRight: "10px" }}
            >
              修改
            </Button>
            {modalState.sale_type === "退货" ? (
              <Popconfirm
                placement="top"
                title="是否确认完成退货？"
                onConfirm={() => handleReturn(modalState.id)}
                okText="确认"
                cancelText="取消"
              >
                <Button style={{ marginRight: "10px" }}>完成退货</Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={() => openReceive(modalState)}
                style={{ marginRight: "10px" }}
              >
                结算
              </Button>
            )}
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <Popconfirm
              placement="top"
              title={
                modalState.sale_type === "退货"
                  ? "是否确认撤销退货？"
                  : "是否确认取消结算？"
              }
              onConfirm={() => handleCanclePaid(modalState.id)}
              okText="确认"
              cancelText="取消"
            >
              <Button type="primary" style={{ marginRight: "10px" }}>
                {modalState.sale_type === "退货" ? "撤销退货" : "取消结算"}
              </Button>
            </Popconfirm>
          </div>
        )}
      </Row>
    </Modal>
  );
};
export default SaleDetail;
