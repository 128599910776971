import User from "./user";
import { message } from "antd";
import $ from "jquery";
import _ from "underscore";
import qs from "qs";

function doRequest(method, url, params, option = {}) {
	// console.log(process.env.NODE_ENV);
	const curUrl = url[0] === "/" ? url : "/" + url;
	function request(resolve, reject) {
		if (User.token()) {
			url += "&token=" + User.token();
		}
		const aURL = url;
		url = window.CONFIG.server + aURL;
		if (option.ignoreHost) {
			url = aURL;
		}
		if (params && !option.contentType) {
			if (!params.user_type) {
				params.user_type = "shop";
			}
		}

		const ajaxOptions = {
			data: option.contentType ? params : qs.stringify(params),
			type: method || "POST",
			async: !option.sync,
			url,
			contentType: option.contentType || "application/x-www-form-urlencoded; charset=UTF-8",
			timeout: 20000,
			dataType: option.dataType || "json",
			processData: option.processData || true,
			beforeSend(xhr, settings) {
				option.beforeSend && option.beforeSend(xhr);
			},
			success(res, status, xhr) {
				console.log(res);
				option.success && option.success(res);
				//返回200 返回数组
				if (res.response == 200 || !res.response) {
					const data = processSuccessData(res);
					console.log(data);
					resolve && resolve(data);
				} else if (url.indexOf("get_wx_basic_info") != -1) {
					const data = processSuccessData(res);
					console.log(data);
					resolve && resolve(data);
				} else if (url.indexOf("order_caiwu_insert") != -1) {
					const data = processSuccessData(res);
					console.log(data);
					resolve && resolve(data);
				} else if (url.indexOf("get_wx_basic_info") != -1) {
					const data = processSuccessData(res);
					console.log(data);
					resolve && resolve(data);
				} else if (url.indexOf("login_qrcode") != -1) {
					const data = processSuccessData(res);
					resolve && resolve(data);
				} else {
					processRequestError(res, reject, option);
				}
			},
			error(xhr, errorType, error) {
				if (url.indexOf("login_qrcode.php") != -1) {
					return resolve && resolve(xhr.responseText);
				}
				processHttpError(xhr, errorType, error, curUrl);
				reject && reject(xhr, errorType, error);
			},
			complete(xhr, status) {
				option.complete && option.complete(xhr, status);
			},
		};
		if (option.sync) {
			const responseText = $.ajax(ajaxOptions).responseText;
			return responseText ? responseText : "";
		}
		$.ajax(ajaxOptions);
	}

	if (option.sync) {
		return request();
	} else {
		const promise = new Promise((resolve, reject) => {
			request(resolve, reject);
		});

		return promise.catch((xhr, errorType, error) => {
			option.error && option.error(xhr);
			return new Promise((resolve, reject) => {
				if (option.reject) {
					reject(xhr);
				}
			});
		});
	}
}

function processSuccessData(data) {
	if (!data) {
		return {};
	}
	if (data && typeof data === "string") {
		return JSON.parse(data);
	}
	return data;
}

function processRequestError(res, reject, option) {
	console.log(res);
	/*if (res.response == 500003/!* || res.response == 500005 || res.response == 500223 || res.response == 500002 || res.response == 500520*!/) {
        User.logout();
    } else {
        message.error(res.msg);
    }*/

	message.error(res.msg);
}

function processHttpError(xhr, errorType, error, url) {
	let str = "";
	if (xhr.status === 401) {
		User.logout();
		return;
	} else if (xhr.status === 403) {
		str = "权限不足,请联系管理员(主账号)";
	} else if (xhr.status === 404) {
	} else if (xhr.status === 500) {
		str = "当前网络不稳定，请稍后重试，或联系客服处理！";
	} else if (xhr.status === 400) {
		str = "Oops! 400错误 \n 操作异常";
	} else if (xhr.status === 555) {
		window.location.href = "https://www.xiaomai5.com/updating.html";
		return;
	} else {
		str = "网络错误";
	}
	message.error(str);
}

function removeNull(param, option) {
	if (!param) {
		return;
	}
	for (let attr in param) {
		if (_.isNull(param[attr]) || _.isUndefined(param[attr])) {
			delete param[attr];
			continue;
		}
		if (typeof param[attr] == "object") {
			removeNull(param[attr]);
		}
	}
}

function post(url, params, option) {
	removeNull(params, option);
	if (params && params.hasOwnProperty("pageNo")) {
		params.pageNo = Math.max(0, params.pageNo);
	}
	return doRequest("post", url, params, option);
}
function get(url, params, option) {
	return doRequest("get", url, params, option);
}

function postJSON(url, params, option) {
	removeNull(params);
	option = option || {};
	option.contentType = "text/plain";
	return doRequest("post", url, JSON.stringify(params), option);
}
function Content(url, params, option) {
	return this.postJSON(`content/${url}`, params, option);
}

// window.axios = Service;
export default {
	post,
	get,
	postJSON,
	Content,
};
