import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Input, Col, Table, message } from "antd";
import PageControl from "../PageControl";
import Api from "../../core/api";
import Spinner from "../Spinner";
import moment from "moment";
//eslint-disable-next-line
export default ({ visible, closeModal, partAdd, selectedIds, purchase }) => {
  const [loadFlag, setLoadFlag] = useState(true);
  const [searchContent, setSearchContent] = useState("");
  const [tableState, setTableState] = useState({
    data: [],
    total: "",
    allPage: "",
    pageSize: "10",
    pageNo: "1",
  });
  useEffect(() => {
    if (visible) {
      getGoodsList();
    }
  }, [visible]);
  const getGoodsList = (pageState) => {
    let pageSize = (pageState && pageState.pageSize) || "10";
    let pageNo = (pageState && pageState.pageNo) || "1";
    setLoadFlag(true);
    let data = { keyword: searchContent, count: pageSize, page: pageNo };
    Api.partSelect(data)
      .then((res) => {
        let _data = res.data.content;
        _data.forEach((item) => {
          if (selectedIds.indexOf(item.id) > -1) {
            item.selected = 2;
          } else {
            item.selected = 0;
          }
        });
        console.log(_data);
        setTableState({
          data: _data,
          pageSize: res.data.pagecount,
          total: res.data.allcount,
          allPage: res.data.allpage,
          pageNo: res.data.curpage,
        });
        setLoadFlag(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleSelect = (id) => {
    let tableData = tableState.data;
    tableData.forEach((item) => {
      if (item.id == id) {
        if (!purchase && item.cur_num === "0") {
          return message.error("当前配件库存为0，无法添加");
        }
        item.selected = 1 - item.selected;
      }
    });
    setTableState({ ...tableState, data: tableData });
  };
  const handleOk = () => {
    let arr = [];
    tableState.data.forEach((item) => {
      if (item.selected === 1) {
        arr.push({
          id: item.id,
          bianhao: item.bianhao,
          name: item.name,
          brand: item.brand,
          model: item.model,
          priceBasic: item.price_basic,
          priceSale: item.price_sale,
          num: 1,
          unit: item.unit,
          qualityDate:
            item.quality_month !== "0"
              ? moment()
                  .add(parseInt(item.quality_month), "M")
                  .format("YYYY-MM-DD")
              : "",
        });
      }
    });
    console.log(arr);
    partAdd(arr);
    // closeModal();
  };
  const columns = [
    {
      title: "序号",
      key: "index",
      render: (text, record, index) => <span>{index}</span>,
    },
    {
      title: "编号",
      dataIndex: "bianhao",
    },
    {
      title: "配件名称",
      dataIndex: "name",
    },
    {
      title: "品牌及规格型号",
      dataIndex: "brand",

      align: "center",
      render: (text, record) => <span>{text + " " + record.model}</span>,
    },
    {
      title: "当前库存",
      dataIndex: "cur_num",
      align: "center",
      render: (text) => (text ? <span>{text}</span> : <span>--</span>),
    },
    {
      title: "单位",
      dataIndex: "unit",
      align: "center",
      render: (text) => (text ? <span>{text}</span> : <span>--</span>),
    },
    // {
    // 	title: "销售价",
    // 	dataIndex: "price_sale",
    // 	align: "center",
    // 	render: (text) => (text ? <span>{text}</span> : <span>--</span>),
    // },
    {
      title: "添加",
      dataIndex: "selected",
      key: "selected",
      align: "center",
      render: (text, record) =>
        text === 2 ? (
          <span
            className="icon iconfont"
            style={{
              color: "#C3C3C3",
              fontSize: "20px",
              cursor: "not-allowed",
            }}
          >
            &#xe679;
          </span>
        ) : text === 1 ? (
          <span
            className="icon iconfont"
            style={{ color: "#F09F74", fontSize: "20px", cursor: "pointer" }}
            onClick={() => handleSelect(record.id)}
          >
            &#xe679;
          </span>
        ) : (
          <span
            className="icon iconfont"
            style={{ color: "#C3C3C3", fontSize: "20px", cursor: "pointer" }}
            onClick={() => handleSelect(record.id)}
          >
            &#xe720;
          </span>
        ),
    },
  ];
  return (
    <Modal
      title="选择商品配件"
      width={900}
      visible={visible}
      onCancel={closeModal}
      onOk={handleOk}
    >
      <Row type="flex" align="middle">
        <Col span={12}>
          <Input
            placeholder="编号/配件名称"
            value={searchContent}
            onChange={(e) => {
              setSearchContent(e.target.value);
            }}
          />
        </Col>
        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          onClick={getGoodsList}
        >
          查询
        </Button>
      </Row>
      {loadFlag ? (
        <Spinner spinner />
      ) : (
        <>
          <Table
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={tableState.data}
            columns={columns}
            bordered
            style={{ marginTop: "20px", marginBottom: "40px" }}
          />
          <PageControl
            total={tableState.total}
            pageSize={tableState.pageSize}
            current={tableState.pageNo}
            showSizeChanger
            toPage={(page, count) => {
              getGoodsList({ pageNo: page, pageSize: count });
            }}
          />
        </>
      )}
    </Modal>
  );
};
