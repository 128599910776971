import { useState, useEffect, useMemo, useContext } from "react";
import { Input, Form, Modal, Row, Col, Button, message, Table } from "antd";
import PageControl from "../PageControl";
import TitleBar from "../TitleBar";
import _Api from "../../core/api";
import ShopContext from "../../core/context-manager";
import mathFunc from "../../core/mathfunc";

const VipChargeModal = ({ visible, closeModal, bianhao, handleComplete, scanMode }) => {
	const { userInfo } = useContext(ShopContext);
	const [vipInfo, setVipInfo] = useState({
		bianhao: "",
		name: "",
		phone: "",
		serviceZk: "",
		partZk: "",
		moneyLeft: 0,
		moneyReal: 0,
		moneyZs: 0,
		inputUser: userInfo.name,
		payType: "",
	});
	const [payTypes, setPayTypes] = useState([]);
	const [historyState, setHistoryState] = useState({ data: [], total: "", pageSize: "10", pageNo: "1" });
	// 计算充值后余额
	const moneyTotal = useMemo(() => {
		let _moneyReal = vipInfo.moneyReal ? parseFloat(vipInfo.moneyReal) : 0,
			_moneyZs = vipInfo.moneyZs ? parseFloat(vipInfo.moneyZs) : 0;
		return parseFloat(vipInfo.moneyLeft) + _moneyReal + _moneyZs;
	}, [vipInfo.moneyReal, vipInfo.moneyZs, vipInfo.moneyLeft]);
	useEffect(() => {
		if (visible) {
			getVipInfo();
			getChargeHistory();
			getPayTypes();
		}
	}, [visible]);
	//获取支付类型
	function getPayTypes() {
		_Api.getPayType().then((res) => {
			setPayTypes(res.msg);
		});
	}
	// 发送请求 获取卡号基本数据
	function getVipInfo() {
		const data = { keyword: bianhao };
		_Api.cardSelect(data).then((res) => {
			let info = res.data.content[0];
			const { bianhao, name, left_money, phone, service_zk, part_zk } = info;
			setVipInfo({
				...vipInfo,
				bianhao,
				name,
				phone,
				moneyLeft: parseFloat(left_money),
				serviceZk: service_zk,
				partZk: part_zk,
			});
		});
	}
	// 初始化数据
	function initState(params) {
		setVipInfo({ bianhao: "", name: "", moneyLeft: 0, moneyReal: 0, moneyZs: 0, inputUser: "", payType: "" });
		setHistoryState({ data: [], total: "", pageSize: "10", pageNo: "1" });
	}
	// 确定逻辑
	function handleOk() {
		if (scanMode) {
			initState();
			closeModal();
			return;
		}
		let { bianhao, name, moneyLeft, moneyReal, moneyZs, inputUser, payType } = vipInfo;
		let data = {
			member_card: bianhao,
			money_real: moneyReal,
			money_member: moneyZs,
			input_user: inputUser,
			pay_type: payType,
		};
		_Api.cardCharge(data).then((res) => {
			message.success(res.msg);
			initState();
			handleComplete();
		});
	}
	// 发送请求 获取充值历史 数据
	function getChargeHistory(pageState) {
		let pageSize = (pageState && pageState.pageSize) || "10";
		let pageNo = (pageState && pageState.pageNo) || "1";
		let data = { member_card: bianhao, count: pageSize, page: pageNo };
		_Api.cardChargeHistory(data).then((res) => {
			setHistoryState({
				data: res.data.content,
				total: res.data.allcount,
				pageNo: res.data.curpage,
				pageSize: res.data.pagecount,
			});
		});
	}
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};

	const columns = [
		{
			title: "日期",
			dataIndex: "gmt_creat",
			align: "center",
		},
		{
			title: "充值金额",
			dataIndex: "money_real",
			align: "center",
		},
		{
			title: "赠送金额",
			dataIndex: "money_member",
		},
		{
			title: "充值方式",
			dataIndex: "pay_type",

			align: "center",
		},
	];
	return (
		<Modal
			title={scanMode ? "充值记录" : "会员卡充值"}
			width={700}
			visible={visible}
			onOk={handleOk}
			onCancel={closeModal}>
			<Form {...layout}>
				<Row justify='space-between'>
					<Col span={12}>
						<Form.Item label='卡号'>{vipInfo.bianhao}</Form.Item>
						{scanMode ? (
							<Form.Item label='手机'>{vipInfo.phone}</Form.Item>
						) : (
							<Form.Item label='当前余额'>
								<Input addonAfter='元' value={vipInfo.moneyLeft} disabled />
							</Form.Item>
						)}
						{scanMode ? (
							<Form.Item label='配件折扣'>{mathFunc.getPercent(vipInfo.partZk) + "%"}</Form.Item>
						) : (
							<Form.Item label='充值金额'>
								<Input
									addonAfter='元'
									value={vipInfo.moneyReal}
									onChange={(e) => {
										setVipInfo({ ...vipInfo, moneyReal: e.target.value });
									}}
								/>
							</Form.Item>
						)}
						{!scanMode && (
							<Form.Item label='收款人'>
								<Input
									value={vipInfo.inputUser}
									onChange={(e) => {
										setVipInfo({ ...vipInfo, inputUser: e.target.value });
									}}
								/>
							</Form.Item>
						)}
					</Col>
					<Col span={12}>
						<Form.Item label='持有人'>{vipInfo.name}</Form.Item>

						{scanMode ? (
							<>
								<Form.Item label='当前余额'>{vipInfo.moneyLeft + "元"}</Form.Item>
								<Form.Item label='服务折扣'>{mathFunc.getPercent(vipInfo.partZk) + "%"}</Form.Item>
							</>
						) : (
							<>
								<Form.Item label='充值后余额'>
									<Input addonAfter='元' value={moneyTotal} disabled />
								</Form.Item>{" "}
								<Form.Item label='赠送金额'>
									<Input
										addonAfter='元'
										value={vipInfo.moneyZs}
										onChange={(e) => {
											setVipInfo({ ...vipInfo, moneyZs: e.target.value });
										}}
									/>
								</Form.Item>
							</>
						)}
					</Col>
				</Row>

				{!scanMode && (
					<Form.Item label='收款方式' labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
						<Row>
							{payTypes.map((item) => (
								<div
									onClick={() => {
										setVipInfo({ ...vipInfo, payType: item.text });
									}}
									key={item.id}
									style={{
										height: "36px",
										lineHeight: "36px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										borderColor: item.text === vipInfo.payType ? "#ff8534" : "#D9D9D9",
										borderWidth: "1px",
										borderStyle: "solid",
										padding: "10px 10px",
										borderRadius: "5px",
										marginRight: "5px",
										cursor: "pointer",
									}}>
									<span
										className='icon iconfont'
										dangerouslySetInnerHTML={{ __html: item.icon }}
										style={{ fontSize: "22px", marginRight: "3px", color: item.color }}></span>
									{item.text}
								</div>
							))}
						</Row>
					</Form.Item>
				)}
			</Form>
			<TitleBar>充值历史</TitleBar>
			<Table
				pagination={false}
				rowKey={(record) => record.id}
				dataSource={historyState.data}
				columns={columns}
				bordered
				style={{ marginTop: "20px", marginBottom: "40px" }}
			/>
			<PageControl
				toPage={(page, count) => {
					getChargeHistory({ pageNo: page, pageSize: count });
				}}
				total={historyState.total}
				pageSize={historyState.pageSize}
				current={historyState.pageNo}
				showSizeChanger
			/>
		</Modal>
	);
};
export default VipChargeModal;
