import React from 'react';
import ReactECharts from 'echarts-for-react';
const LineChart = ({chartTitle,xArr,yArr}) => {
    const option = {
        title: {
            text: chartTitle
        },
        tooltip: {},
        legend: {
            data: ['收入']
        },
        xAxis: {
            data: xArr
        },
        yAxis: {},
        series: [{
                name: '收入',
                type: 'line',
                data: yArr
            }]
    };
    return <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: 'svg' }}/>;
};
export default LineChart;
