import { useState, useEffect } from "react";
import { Form, Row, Col, Modal, Input, Button, message } from "antd";
import StaticSelect from "../StaticSelect";
import _Api from "../../core/api";
import mathFunc from "../../core/mathfunc";
import MeberPassword from "../MeberPassword";
const VipDetailModal = ({
  visible,
  closeModal,
  cardInfo,
  handleVipSet,
  type,
}) => {
  const [vipInfo, setVipInfo] = useState({
    bianHao: "",
    hasPassword: "",
    name: "",
    phone: "",
    leftMoney: "",
    leftNum: "",
    partZk: "",
    serviceZk: "",
    password: "",
    packageName: "",
    packageType: "",
    packageContent: "",
  });
  useEffect(() => {
    if (visible) {
      console.log(cardInfo);
      const {
        bianhao,
        name,
        phone,
        left_money,
        part_zk,
        service_zk,
        password,
        left_num,
        package_name,
        package_type,
        package_content,
      } = cardInfo || {};
      setVipInfo({
        ...vipInfo,
        hasPassword: password,
        bianHao: bianhao,
        name,
        phone,
        leftMoney: left_money,
        leftNum: left_num,
        partZk: part_zk,
        serviceZk: service_zk,
        packageName: package_name,
        packageType: package_type,
        packageContent: package_content,
      });
    }
  }, [visible]);

  function handleCheck(params) {
    let data = { card_id: vipInfo.bianHao, password: vipInfo.password };
    _Api.cardCheck(data).then((res) => {
      message.success(res.msg);
      setVipInfo({ ...vipInfo, password: "" });
      if (type === "开单") {
        handleVipSet(res.rs[0]);
      } else if (type === "销售") {
        handleVipSet(res.rs[0], vipInfo);
      } else {
        handleVipSet(vipInfo, res.rs[0]);
      }
    });
  }
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="会员卡"
      footer={null}
      zIndex={1000}
      width={500}
      visible={visible}
      onCancel={closeModal}
    >
      <Form labelAlign="left" {...layout}>
        <Row>
          <Col span={12}>
            <Form.Item label="会员卡号">
              <span>{vipInfo.bianHao}</span>
            </Form.Item>
            <Form.Item label="姓名" labelCol={2} wrapperCol={18}>
              <span>{vipInfo.name}</span>
            </Form.Item>
          </Col>
          <Col span={10} offset={2}>
            {cardInfo.package_type === "储值卡" && (
              <Form.Item label="余额">
                <span>{vipInfo.leftMoney}</span>
              </Form.Item>
            )}
            {cardInfo.package_type === "计次卡" && (
              <Form.Item label="剩余次数">
                <span>{vipInfo.leftNum}</span>
              </Form.Item>
            )}

            <Form.Item label="电话" labelCol={2} wrapperCol={18}>
              <span>{vipInfo.phone}</span>
            </Form.Item>
          </Col>
        </Row>
        {cardInfo.package_type === "储值卡" && (
          <Row>
            <Col span={12}>
              <Form.Item label="配件折扣">
                <span>{mathFunc.getPercent(vipInfo.partZk) + "%"}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="项目折扣">
                <span>{mathFunc.getPercent(vipInfo.serviceZk) + "%"}</span>
              </Form.Item>
            </Col>
          </Row>
        )}
        {cardInfo.package_type === "计次卡" && (
          <Row>
            <Col span={24}>
              <Form.Item labelCol={4} wrapperCol={18} label="套餐内容">
                <div
                  style={{
                    paddingTop: "5px",
                    paddingLeft: "6px",
                  }}
                >
                  {vipInfo.packageContent}
                </div>
                {/* <span>{vipInfo.packageContent}</span> */}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {!vipInfo.hasPassword && (
        <p
          className="theme-color"
          style={{ textAlign: "center", fontSize: "9px" }}
        >
          <span
            className="icon iconfont "
            style={{ fontSize: "6px", marginRight: "3px" }}
          >
            &#xe606;
          </span>
          该卡无密码,可直接点击"确定"按钮
        </p>
      )}
      <Row type="flex" justify="center" style={{ marginTop: "20px" }}>
        <Col>
          <StaticSelect label="密码:">
            <>
              {/* <Input.Password
                value={vipInfo.password}
                onChange={(e) => {
                  setVipInfo({ ...vipInfo, password: e.target.value });
                }}
              /> */}
              <MeberPassword
                placeholder="请输入密码"
                value={vipInfo.password}
                onChange={(value) => {
                  setVipInfo({ ...vipInfo, password: value });
                }}
              ></MeberPassword>
              <Button
                type="primary"
                style={{ marginLeft: "20px" }}
                onClick={handleCheck}
              >
                确定
              </Button>
            </>
          </StaticSelect>
        </Col>
      </Row>
    </Modal>
  );
};
export default VipDetailModal;
