import _ from "underscore";

class User {
    constructor() {
        this.jumpToLogin = _.debounce(() => window.XZHistory.push('/login'), 1000, true);
    }

    logined() {
        return !!this.token();
    }

    logout() {
        window.Cache.clear();
        localStorage.getItem("server-apis");
        window.LS.remove("token");
        window.LS.remove("isCrmInstAdmin");
        window.LS.remove("crmInstIds");
        window.LS.clearCookie();
        this.jumpToLogin();
    }

    uid() {
        return window.LS.get("uid") || "";
    }

    token() {
        return window.LS.get("token") || "";
    }

}

export default new User();
