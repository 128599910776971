const JSON = window.JSON;
const support = supportLocalStorage();
function supportLocalStorage() {
    if (window.localStorage) {
        try {
            window.localStorage.setItem('test', 1);
            window.localStorage.removeItem('test');
            return true;
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
}


class Storage {
    get(key) {
        let value = null;
        if (support) {
            value = window.localStorage.getItem(key);
        }
        return value;
    }

    set(key, value) {
        if (support) {
            window.localStorage.setItem(key, value);
            return;
        }
    }

    setToCookie(key, value) {
        if (support) {
            window.localStorage.setItem(key, value);
        }
    }

    setObj(key, obj) {
        if (support) {
            window.localStorage.setItem(key, JSON.stringify(obj));
            return;
        }
    }

    setObjToCookie(key, obj) {
        if (support) {
            window.localStorage.setItem(key, JSON.stringify(obj));
        }
    }

    getObj(key) {
        let value = null;
        if (support) {
            if (localStorage.getItem(key)) {
                value = JSON.parse(localStorage.getItem(key));
            }
        }
        return value;
    }

    clearCookie() {

    }

    clear() {
        if (support) {
            window.localStorage.clear();
        }
    }

    remove(key) {
        if (support) {
            window.localStorage.removeItem(key);
        }
    }
}

window.LS = new Storage();
