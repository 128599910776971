import React, { Component } from "react";
import { Modal } from "antd";
import axios from "../../core/axios";

import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";

import "./NewEditFastEntryModal.less";

let dragingIndex = -1;

class CSSTransitionGroupChild extends React.Component {
	render() {
		const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;

		let { className } = restProps;
		if (isOver) {
			if (restProps.index > dragingIndex) {
				className += " drop-over-downward";
			}
			if (restProps.index < dragingIndex) {
				className += " drop-over-upward";
			}
		}

		return connectDragSource(
			connectDropTarget(
				<li className='row'>
					<div className='item-block'>
						<span
							onClick={() => {
								let arr = this.props.selectedList.filter((item) => item.code != this.props.code);
								this.props.totalItems.map((x) => {
									if (x.code === this.props.code) {
										x.isSelelcted = false;
									}
								});
								this.props.deleteRow(arr, this.props.totalItems);
							}}
							className='icon iconfont play handle-iconfont'>
							&#xe693;
						</span>
						{/* <div className='pic-icon'>
							<span className={`fast-enter-icon ${this.props.icon}`}></span>
						</div> */}
						<div
							style={{
								height: "40px",
								width: "40px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<span
								className='icon iconfont'
								style={{ fontSize: "40px", display: "block" }}
								dangerouslySetInnerHTML={{ __html: `${this.props.icon}` }}></span>
						</div>
						<div className='title'>{this.props.name}</div>
					</div>
				</li>
			)
		);
	}
}

const rowSource = {
	beginDrag(props) {
		dragingIndex = props.index;
		console.log(dragingIndex);
		return {
			index: props.index,
		};
	},
};

const rowTarget = {
	drop(props, monitor) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;
		console.log(hoverIndex);

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Time to actually perform the action
		props.moveRow(dragIndex, hoverIndex);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	},
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))(
	DragSource("row", rowSource, (connect) => ({
		connectDragSource: connect.dragSource(),
	}))(CSSTransitionGroupChild)
);

export class NewEditFastEntryModal extends Component {
	state = {
		dataSet: [],
		loading: false,
		selectedList: [],
		totalItems: [],
	};

	componentDidMount() {
		const selectedList = [...this.props.selectedList];
		const totalItems = [...this.props.totalItems];
		let selectedIds = selectedList.map((item) => item.id);
		let arr = totalItems.map((item) => {
			return {
				id: item.id,
				code: item.code,
				icon_index: item.icon_index,
				type: item.type,
				name: item.name,
				link: item.link,
				intro: item.intro,
				example: item.example,
				is_show: item.is_show,
				sequence: item.sequence,
				parent: item.parent,
				isSelelcted: selectedIds.indexOf(item.code) === -1 ? false : true,
			};
		});
		this.setState({ selectedList });
		this.setState({ totalItems: arr });
	}

	onOK = () => {
		const { selectedList } = this.state;

		let arr = [];
		selectedList.map((item) => {
			arr.push(item.code);
		});

		let body = {
			menu_code: arr,
			menu_type: "menu",
		};
		axios
			.post("shop/branch.php?cmd=set_index_menu", body)
			.then((res) => {
				this.props.onOk();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const { selectedList, totalItems } = this.state;

		const moveRow = (dragIndex, hoverIndex) => {
			const { selectedList } = this.state;
			const dragRow = selectedList[dragIndex];

			this.setState(
				update(this.state, {
					selectedList: {
						$splice: [
							[dragIndex, 1],
							[hoverIndex, 0, dragRow],
						],
					},
				})
			);
		};

		const deleteRow = (selectedList, totalItems) => {
			this.setState({ selectedList });
			this.setState({ totalItems });
		};
		let iconStr = "&#xe6b3;";
		return (
			<Modal
				title='自定义数据'
				visible={true}
				okText='保存'
				cancelText='取消'
				onOk={this.onOK}
				onCancel={this.props.onCancel}
				width={771}
				className='edit-data-modal'
				destroyOnClose>
				<div className='edit-fast-entry-index'>
					<div className='container'>
						<div className='box-container box-selected'>
							<div className='box-body'>
								<div className='selected-title'>
									已选功能
									<span className='tip'>（点击拖动调整排序）</span>
								</div>
								<ul className='content-one-box-kjrk'>
									<DndProvider backend={HTML5Backend}>
										{selectedList.map((item, index) => {
											return (
												<DragableBodyRow
													key={index}
													selectedList={selectedList}
													totalItems={totalItems}
													code={item.code}
													name={item.name}
													icon={item.icon_index}
													index={index}
													moveRow={moveRow}
													deleteRow={deleteRow}
												/>
											);
										})}
									</DndProvider>
								</ul>
							</div>
						</div>
						<div className='box-container box-all-items'>
							<div className='box-body'>
								{totalItems
									.filter((item) => item.parent == 0)
									.map((x, y) => (
										<div className='selectable-container' key={y}>
											<div className='selectable-title'>{x.name}</div>
											<div className='selectable-items'>
												<ul className='content-one-box'>
													{totalItems
														.filter((item) => item.parent === x.id)
														.map((item, index) => (
															<li key={index} className={`row ${!item.isSelelcted ? "active" : "false"}`}>
																<div className='item-block'>
																	{item.isSelelcted ?null
																	//  (
																	// 	<span
																	// 		onClick={() => {
																	// 			let arr = selectedList.filter((x) => x.code != item.code);
																	// 			totalItems.map((x) => {
																	// 				if (x.code === item.code) {
																	// 					x.isSelelcted = false;
																	// 				}
																	// 			});
																	// 			this.setState({ selectedList: arr });
																	// 			this.setState({ totalItems });
																	// 		}}
																	// 		className='icon iconfont play handle-iconfont'>
																	// 		&#xe693;
																	// 	</span>
																	// ) 
																	: (
																		<span
																			onClick={() => {
																				let obj = totalItems.filter((x) => x.code === item.code)[0];
																				let a = [];
																				selectedList.map((item) => {
																					a.push(item.code);
																				});
																				if (a.indexOf(obj.code) == -1) {
																					selectedList.push(obj);
																				}
																				totalItems.map((x) => {
																					if (x.code === item.code) {
																						x.isSelelcted = true;
																					}
																				});
																				this.setState({ selectedList });
																				this.setState({ totalItems });
																			}}
																			className='icon iconfont play handle-iconfont add-icon'>
																			&#xe612;
																		</span>
																	)}

																	{/* <span className={`fast-enter-icon ${item.icon_index}`}></span> */}
																	{/* <span className='icon iconfont' style={{ fontSize: "40px" }}>
																			&#xe6b3;
																		</span> */}

																	<div
																		style={{
																			height: "40px",
																			width: "40px",
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		<span
																			className='icon iconfont'
																			style={{ fontSize: "40px", display: "block" }}
																			dangerouslySetInnerHTML={{ __html: item.icon_index }}></span>
																	</div>
																	<div className='title'>{item.name}</div>
																</div>
															</li>
														))}
												</ul>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default NewEditFastEntryModal;
