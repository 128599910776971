import React, { Component, Fragment } from "react";
import axios from "axios";
import { Input, Modal, message, Spin } from "antd";
import "./ImgPicker.less";
import ImgCutModal from "./ImgCutModal";
import User from '../../core/user'
export class ImgPicker extends Component {
    state = {
        cutVisible: false,
        previewVisible: false,
        uploadFlag: false,
        _uploadFlag: false,
        isGif: false,
    };
    readImg = (file) => {
        console.log(file);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const dataURL = e.target.result;
            this.setState({ src: dataURL, cutVisible: true });
        };

        fileReader.readAsDataURL(file);
    };

    handleSelectImg = (data) => {
        let file = data.target.files[0],
            maxfilesize = 1024 * 1024 * 10;

        if (file) {
            if (file.type.indexOf("gif") > 0) {
                this.setState({ isGif: true }, () => {
                    this.uploadImg(file);
                });
            } else {
                this.setState({ isGif: false }, () => {
                    if (file.size <= maxfilesize) {
                        console.log(this.props.c_width);
                        if (this.props.c_width) {
                            this.readImg(file);
                        } else {
                            this.uploadImg(file);
                        }
                    } else {
                        message.warning("请上传小于10M的图片");
                    }
                });
            }
        }
    };
    uploadImg = (file) => {
        console.log(file);
        this.setState({ _uploadFlag: true });
        const { token } = this.props;
        const formData = new FormData();
        formData.append("file", file);
        if(this.props.type == 'shop_logo'){
            formData.append("img_type", "shop_logo");
        }
        formData.append("user_type", "shop");
        axios.post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token=" + User.token(), formData).then((res) => {
            this.setState({ _uploadFlag: false, src: null });
            if (res.data.response === "200") {
                message.success("图片上传成功");
                console.log(res.data.img_url);
                this.props.SetImg(res.data.img_url);
            } else {
                message.info(res.data.msg);
            }
        });
    };

    render() {
        const { imgdata, c_width, c_height } = this.props;
        const { cutVisible, _uploadFlag, src, previewVisible, isGif } = this.state;
        return (
            <Fragment>
                <div className='img-wrap' style={c_width ? { height: "80px" } : { height: "60px" }}>
                    {imgdata ? (
                        <div
                            className='img-view'
                            style={
                                c_width
                                    ? { width: (80 * c_width) / c_height }
                                    : {
                                        width: "200px",
                                        border: "1px dashed #e8e8e8",

                                    }
                            }>
                            <img
                                src={imgdata}
                                alt=''
                                style={
                                    c_width
                                        ? {
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "100%",
                                        }
                                        : {
                                            position: "absolute",
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "100%",
                                        }
                                }
                            />
                            <div className='mask' style={c_width ? { lineHeight: "80px" } : { lineHeight: "60px" }}>
								<span
                                    className='icon iconfont open-img'
                                    onClick={() => {
                                        this.setState({ previewVisible: true });
                                    }}>
									&#xe633;
								</span>
                                <span className='icon iconfont delete-img' onClick={() => this.props.SetImg("")}>
                                &#xe61b;
								</span>
                            </div>
                        </div>
                    ) : (
                        <div
                            className='img-upload'
                            style={
                                c_width
                                    ? { width: (80 * c_width) / c_height }
                                    : {
                                        width: "200px",
                                    }
                            }>
                            <Spin spinning={_uploadFlag}>
                                <label htmlFor={"template-form-" + this.props.flag}>
                                    <div
                                        className='upload-icon'
                                        style={
                                            c_width
                                                ? {}
                                                : {
                                                    lineHeight: "60px",
                                                    height: "60px",
                                                }
                                        }>
                                        <span className='icon iconfont'>&#xe6a3;</span>
                                    </div>
                                </label>
                            </Spin>
                        </div>
                    )}
                    <Input
                        type='file'
                        id={"template-form-" + this.props.flag}
                        accept='image/gif,image/jpeg,image/png'
                        style={{ display: "none" }}
                        onChange={this.handleSelectImg}
                    />
                </div>
                {src && !isGif && (
                    <ImgCutModal
                        flag={this.props.flag}
                        cutVisible={cutVisible}
                        c_width={c_width}
                        c_height={c_height}
                        src={src}
                        setImg={this.props.SetImg}
                        onCancel={() => {
                            this.setState({
                                cutVisible: false,
                                src: "",
                            });
                        }}
                    />
                )}
                {previewVisible && (
                    <Modal
                        title='图片预览'
                        visible={previewVisible}
                        onCancel={() => {
                            this.setState({ previewVisible: false });
                        }}
                        footer={null}>
                        <div className='img-open' style={{ width: "480px", height: "400px" }}>
                            <img
                                src={imgdata}
                                style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "100%",
                                }}
                                alt=''
                            />
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}
export default ImgPicker;
