import { Modal } from "antd";
import "./index.less";
import successImgs from "../../libs/import_success.png";
const ImportResults = ({ visible, msg, handleVisible }) => {
  return (
    <Modal
      width={688}
      title="导入结果"
      visible={visible}
      footer={null}
      className="import-results"
      onCancel={() => handleVisible({ ResultsV: false })}
    >
      <div className="img">
        <img src={successImgs} alt="" />
      </div>
      <p>{msg}</p>
    </Modal>
  );
};

export default ImportResults;
