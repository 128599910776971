import { useEffect } from "react";
import { Table, Row, Col } from "antd";
import _Api from "../../core/api";
import { useState } from "react";
import PieChart from "../../components/PieChart";
import TitleBar from "../../components/TitleBar";
const BusinessCount = ({ date, tabKey }) => {
	const [detailState, setDetailState] = useState([]);
	const [typeState, setTypeState] = useState({ data: [], type: [] });
	useEffect(() => {
		if (tabKey === "1") {
			getBranchDetail();
			getPayTypeBl();
		}
	}, [date, tabKey]);
	function getBranchDetail() {
		let data = { start_time: date[0], end_time: date[1] };
		_Api.branchDetail(data).then((res) => {
			let arr = [];
			for (let key in res) {
				arr.push({ title: key, data: res[key] });
			}
			arr.pop();
			setDetailState(arr);
		});
	}
	function keyTrans(params) {
		switch (params) {
			case "arr_income":
				return "营业收入";
			case "arr_orders":
				return "进厂车辆";
			case "arr_cards":
				return "办卡数量";
			case "arr_income_member":
				return "会员卡充值";
			default:
				break;
		}
	}
	function getPayTypeBl() {
		let data = { start_time: date[0], end_time: date[1] };
		_Api.payTypeBl(data).then((res) => {
			let _data = [],
				typeData = [];
			res.data.map((item) => {
				if (item.pay_type) {
					_data.push({ name: item.pay_type, value: item.all_money });
					typeData.push(item.pay_type);
				}
			});
			setTypeState({ data: _data, type: typeData });
		});
	}
	const borderStyle = "1px solid #d9d9d9",
		titleArr = ["", "本月", "本周", "今日", "自定义"],
		TableItem = ({ text, id, style }) => (
			<div
				key={id}
				style={{
					height: "45px",
					lineHeight: "45px",
					flex: 1,
					borderRight: borderStyle,
					borderBottom: borderStyle,
					textAlign: "center",
					...style,
				}}>
				{text}
			</div>
		),
		TableOuter = ({ children, id }) => (
			<div key={id} style={{ borderLeft: borderStyle, display: "flex" }}>
				{children}
			</div>
		),
		tableBody = detailState.map((item, index) => (
			<TableOuter id={"line" + index} key={"table-outer" + index}>
				<TableItem
					key={item.title + index}
					text={keyTrans(item.title)}
					id={item.title}
					style={{ fontWeight: "bold" }}
				/>
				{item.data.map((Item, Index) => (
					<TableItem key={"table-item" + index + Index} text={Item || 0} id={item.title + Index} />
				))}
			</TableOuter>
		));
	return (
		<Row justify='space-between'>
			<div style={{ width: "50%", paddingTop: "18px" }}>
				<TitleBar style={{ marginBottom: "60px" }}>
					<span style={{ fontWeight: "bold", fontSize: "18px" }}>经营报告</span>
				</TitleBar>
				<div style={{ width: "100%", borderTop: borderStyle }}>
					<TableOuter id='col-title'>
						{titleArr.map((item, index) => (
							<TableItem
								text={item}
								key={"col-title" + index}
								id={"col-title" + index}
								style={{ height: "50px", lineHeight: "50px", fontSize: "18px", fontWeight: "bold" }}
							/>
						))}
					</TableOuter>

					{tableBody}
				</div>
			</div>
			<div style={{ height: "400px", width: "44%" }}>
				<PieChart pieData={typeState.data} pieTitle='付款方式比例' seriesName='付款方式' legendArr={typeState.type} />
			</div>
		</Row>
	);
};
export default BusinessCount;
